/** @jsx jsx */
import React, { useEffect, useRef } from 'react';
import { jsx, CSSObject, keyframes } from '@emotion/core';
import useOverlay from '../hooks/useOverlay';
import { IBaseOverlay } from '../contexts/overlayContext';
import useTheme from '../hooks/useTheme';
import { IMeetingInfo } from '../contexts/meetingContext';
import Clickable from '../components/clickable';
import useLanguage from '../hooks/useLanguage';
import { images } from '../constants/assets';
import layout from '../constants/layout';
import useAudio from '../hooks/useAudio';
import useNotifications from '../hooks/useNotifications';

export interface ICallingScreenOverlay extends IBaseOverlay {
  meetingInfo: IMeetingInfo;

  onCallAccepted: () => void;
  onCallDeclined: () => void;
}

const CallingScreenOverlay = ({
  meetingInfo,
  onCallAccepted,
  onCallDeclined,
}: ICallingScreenOverlay) => {
  const { font, color } = useTheme();
  const overlay = useOverlay();
  const language = useLanguage();
  const audio = useAudio();
  const notifications = useNotifications();

  const meetingInfoRef = useRef(meetingInfo);

  const rippleAnimation = keyframes`
    0% {transform: scale(1) opacity: 0.1}
    100% {transform: scale(3); opacity: 0}
  `;

  const BASE_STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around', // center
    flex: 1,
    padding: 20,

    background: `radial-gradient(${color.CIRCULAR_GRADIENT_LIGHT}, ${color.CIRCULAR_GRADIENT_DARK})`,
    color: color.LOCAL_WHITE,
    textAlign: 'center',
    overflow: 'hidden',

    [`@media (min-width: ${layout.BREAKPOINT_MEDIUM}px)`]: {
      borderRadius: 7,
      maxHeight: 600,
    },
  };

  const IMAGE_WIDTH_ANIMATION_STYLE: CSSObject = {
    position: 'relative',
    width: 140,
    height: 140,
    borderRadius: '50%',
    backgroundColor: color.LOCAL_WHITE,
    backgroundImage: `url(${meetingInfo.doctor?.doctorImage})`,
    backgroundSize: 'cover',
    margin: '16px auto',
    boxShadow: '0px 12px 19px rgba(21, 61, 87, 0.2)',

    [`@media (min-width: ${layout.BREAKPOINT_MEDIUM}px)`]: {
      width: 196,
      height: 196,
      margin: '40px auto',
    },

    ':before': {
      content: `''`,
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      borderRadius: '50%',
      border: '5px solid white',
      opacity: 0.1,
      animation: `${rippleAnimation} 3s linear infinite`,
    },

    ':after': {
      content: `''`,
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      borderRadius: '50%',
      border: '5px solid white',
      opacity: 0.1,
      animation: `${rippleAnimation} 3s linear 1.5s infinite`,
    },
  };

  useEffect(() => {
    meetingInfoRef.current = meetingInfo;
  }, [meetingInfo]);

  // Attempt to show a local notification and play the ring tone when the calling screen appears.
  // Close the notification and stop the playback when it dismisses.
  useEffect(() => {
    const notification = notifications.presentNotification({
      title: meetingInfoRef.current.callingTitle || '',
      message: `${meetingInfoRef.current.callerTitle}, ${meetingInfoRef.current.callerSubtitle}`,
    });
    audio.startPlayback('RINGTONE', true);

    // Stop the playback once the calling screen dismisses.
    return () => {
      audio.stopPlayback();
      notification?.close();
    };
  }, [audio, notifications]);

  return (
    <div css={BASE_STYLE}>
      {/* Clinician image and info */}
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 3,
          justifyContent: 'center',
        }}
      >
        {/* Clinician image */}
        <div css={IMAGE_WIDTH_ANIMATION_STYLE} />

        {/* Clinician info */}
        <div
          css={{
            fontSize: 24,
            fontFamily: font.FAMILY_MEDIUM,
          }}
        >
          {meetingInfo.callerTitle}
        </div>
        <div>{meetingInfo.callerSubtitle}</div>
      </div>

      {/* Accept/decline buttons */}
      <div
        css={{
          display: 'flex',
          flexGrow: 1,
          width: '100%',
          maxWidth: 300,
          justifyContent: 'space-between',

          button: {
            fontSize: 18,
            color: color.LOCAL_WHITE,
            backgroundColor: 'transparent',
            padding: 20,
            overflow: 'visible',
            width: 'auto',

            div: {
              position: 'relative',
              width: 84,
              height: 84,
              borderRadius: '50%',
              backgroundColor: color.LOCAL_WHITE,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              boxShadow: '0px 12px 19px rgba(21, 61, 87, 0.2)',
              marginBottom: 12,
            },
          },
        }}
      >
        {/* Accept call button */}
        <Clickable
          scale
          onClick={() => {
            overlay.dismiss();
            onCallAccepted();
          }}
        >
          <div
            css={{
              backgroundImage: `url(${images.ACCEPT_ICON})`,
            }}
          />
          {language.get('answer')}
        </Clickable>

        {/* Decline call button */}
        <Clickable
          scale
          onClick={() => {
            overlay.dismiss();
            onCallDeclined();
          }}
        >
          <div
            css={{
              backgroundImage: `url(${images.DECLINE_ICON})`,
            }}
          />
          {language.get('call_screen_decline')}
        </Clickable>
      </div>
    </div>
  );
};

export default CallingScreenOverlay;
