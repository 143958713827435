/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { motion, AnimatePresence } from 'framer-motion';
import Clickable from '../../../../../components/clickable';
import AttributedText from '../../../../../components/attributedText';
import { IMapPin } from '../../../../../components/interactiveMap/map';
import LocalImage from '../../../../../components/localImage';
import { IFlexNavigation } from '../../../../../hooks/flex/useFlexNavigation';

export interface IMapPinDetailsCard {
  index: number;
  mapPin?: IMapPin;
  onClick?: (flexNavigation?: IFlexNavigation) => void;
}

const MapPinDetailsCard = ({ index, mapPin, onClick }: IMapPinDetailsCard) => {
  return (
    <Clickable
      styleAs='card'
      onClick={
        onClick
          ? () => {
              onClick(mapPin?.details?.navigation);
            }
          : undefined
      }
    >
      <AnimatePresence exitBeforeEnter>
        {index > -1 && (
          <motion.div
            key={index}
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '12px 20px',
              minHeight: 62,
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            {/* Text details */}
            <AttributedText text={mapPin?.details?.attributedText} />

            {/* Chevron image */}
            {mapPin?.details?.navigation && (
              <LocalImage src='CHEVRON_RIGHT' tint='CHEVRON' alt='' />
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </Clickable>
  );
};
export default MapPinDetailsCard;
