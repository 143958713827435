/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';
import AttributedText from '../../../components/attributedText';
import useTheme from '../../../hooks/useTheme';
import BottomSticky from '../../../components/bottomSticky';

export interface IBottomLabel {
  attributedText: string;
  topCornerRadius: number;
  bgColor: string;
  topMargin: number;
  bottomMargin: number;
  leadingMargin: number;
  trailingMargin: number;
}

interface IBottomLabelProps {
  data: IBottomLabel;
}

const BottomLabel = ({ data }: IBottomLabelProps) => {
  const { resolveColor } = useTheme();

  const STYLE: CSSObject = {
    width: '100%',
    boxSizing: 'border-box',
    paddingLeft: data.leadingMargin,
    paddingRight: data.trailingMargin,
    paddingTop: data.topMargin,
    paddingBottom: data.bottomMargin,

    backgroundColor: resolveColor(data.bgColor),
  };

  return (
    <BottomSticky>
      <div css={STYLE}>
        <AttributedText text={data.attributedText} />
      </div>
    </BottomSticky>
  );
};

export default BottomLabel;
