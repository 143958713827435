/** @jsx jsx */
import React from 'react';
import { CSSObject, jsx } from '@emotion/core';
import useTheme, { IColors } from '../hooks/useTheme';
import { images } from '../constants/assets';
import useLanguage from '../hooks/useLanguage';
import { colorFilter } from '../utils/colorFilter';

interface ILocalImage {
  src: keyof typeof images;
  alt?: string;
  tint?: keyof typeof IColors;
}

const LocalImage = ({ src, tint, alt, ...props }: ILocalImage) => {
  const { color } = useTheme();
  const language = useLanguage();

  const getAltLabel = () => {
    if (alt !== undefined) {
      return alt;
    }

    return language.get(src.toLowerCase());
  };

  const STYLE: CSSObject = {
    ...(tint && {
      filter: colorFilter(color[tint]),
    }),
  };

  return <img src={images[src]} alt={getAltLabel()} css={STYLE} {...props} />;
};

export default LocalImage;
