/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { IFlexNode } from '../flexNode';

interface IUnsupportedFlexNode {
  data: IFlexNode;
}

const UnsupportedFlexNode = ({ data }: IUnsupportedFlexNode) => {
  return (
    <div
      css={{
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      Unsupported flex node:
      <span css={{ fontWeight: 700, marginLeft: 8 }}>{data.type}</span>
    </div>
  );
};

export default UnsupportedFlexNode;
