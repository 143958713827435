import useOverlay from '../../../useOverlay';
import { IRemoteImage } from '../../../../components/RemoteImage';
import { IFlexNavigation } from '../../useFlexNavigation';

export interface IFlexOverlaySplash {
  type: 'splash';
  image: IRemoteImage;
  title: string;
  subtitle?: string;
  completionNavigation: IFlexNavigation;
  autoDismissTime: number;
}

const useFlexOverlaySplash = () => {
  const overlay = useOverlay();

  const handleFlexOverlaySplash = (
    flexOverlay: IFlexOverlaySplash
  ): Promise<IFlexNavigation> => {
    return new Promise((resolve) => {
      overlay.presentSplash({
        title: flexOverlay.title,
        subtitle: flexOverlay.subtitle,
        image: flexOverlay.image,
        autoDismissTime: flexOverlay.autoDismissTime,
        onDismiss: () => {
          if (flexOverlay.completionNavigation) {
            resolve(flexOverlay.completionNavigation);
          }
        },
      });
    });
  };

  return handleFlexOverlaySplash;
};

export default useFlexOverlaySplash;
