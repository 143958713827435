import React, { Fragment, useEffect } from 'react';
import AsyncMessagingFlexNode, {
  IAsyncMessagingFlexNode,
} from './flexNodes/asyncMessagingFlexNode/asyncMessagingFlexNode';
import ScreenFlexNode, { IScreenFlexNode } from './flexNodes/screenFlexNode';
import SearchFlexNode, { ISearchFlexNode } from './flexNodes/SearchFlexNode';
import UnsupportedFlexNode from './flexNodes/unsupportedFlexNode';
import SurveyFlexNode, {
  ISurveyFlexNode,
} from './flexNodes/surveyFlexNode/surveyFlexNode';
import PaymentFlexNode, { IPaymentFlexNode } from './flexNodes/paymentFlexNode';
import MeetingRoomFlexNode, {
  IMeetingRoomFlexNode,
} from './flexNodes/meetingRoomFlexNode/meetingRoomFlexNode';
import ScheduleFlexNode, {
  IScheduleFlexNode,
} from './flexNodes/scheduleFlexNode/scheduleFlexNode';
import { IFlexTopRightButton } from './flex';
import useAdjustTracker, { IAdjustEvent } from '../../hooks/useAdjustTracker';
import useSnowplowTracker, {
  IStructuredSnowplowEvent,
} from '../../hooks/useSnowplowTracker';
import MapFlexNode, { IMapFlexNode } from './flexNodes/mapFlexNode/mapFlexNode';
import { IAction } from '../../hooks/useAction';

export interface IFlexMemory {
  flex: {
    containers: object;
  };
}

export interface IBaseFlexNode {
  id: string;
  title?: string;
  revisitable: boolean;
  visibleNavbar: boolean;
  topRightButton?: IFlexTopRightButton;
  memory?: IFlexMemory;

  adjustEvent?: IAdjustEvent;
  snowplowEvent?: IStructuredSnowplowEvent;

  // Flex unwind data, appended locally.
  destinationScreenAction?: IAction;
  nodeUpdatedAt?: number;
}

export type IFlexNode =
  | IScreenFlexNode
  | ISearchFlexNode
  | ISurveyFlexNode
  | IScheduleFlexNode
  | IPaymentFlexNode
  | IMeetingRoomFlexNode
  | IAsyncMessagingFlexNode
  | IMapFlexNode;

interface IFlexNodeProps {
  data: IFlexNode;
}

const FlexNode = ({ data }: IFlexNodeProps) => {
  const adjustTracker = useAdjustTracker();
  const snowplowTracker = useSnowplowTracker();

  const getNodeComponent = (nodeData: IFlexNode) => {
    switch (nodeData.type) {
      case 'screen':
        return <ScreenFlexNode data={nodeData} />;
      case 'search':
        return <SearchFlexNode data={nodeData} />;
      case 'survey':
        return <SurveyFlexNode data={nodeData} />;
      case 'schedule':
        return <ScheduleFlexNode data={nodeData} />;
      case 'payment':
        return <PaymentFlexNode data={nodeData} />;
      case 'meeting_room':
        return <MeetingRoomFlexNode data={nodeData} />;
      case 'async_messaging':
        return <AsyncMessagingFlexNode data={nodeData} />;
      case 'map':
        return <MapFlexNode data={nodeData} />;
      default:
        return <UnsupportedFlexNode data={nodeData} />;
    }
  };

  // Tracking.
  useEffect(() => {
    // Adjust.
    if (data.adjustEvent) {
      adjustTracker.trackEvent(data.adjustEvent);
    }

    // Snowplow.
    if (data.snowplowEvent) {
      snowplowTracker.trackEvent(data.snowplowEvent);
    }
  }, [adjustTracker, snowplowTracker, data.adjustEvent, data.snowplowEvent]);

  return <Fragment>{getNodeComponent(data)}</Fragment>;
};

export default FlexNode;
