/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';
import useTheme from '../hooks/useTheme';
import useLanguage from '../hooks/useLanguage';
import AttributedText from './attributedText';

export interface INotificationBadge {
  text?: string;
  color?: string;
  strokeColor?: string;
  variation?: 'default' | 'small';
  accessibilityLabel?: string;
}

const NotificationBadge = ({
  text,
  color: badgeBackgroundColor,
  variation,
  strokeColor,
  accessibilityLabel,
  ...props
}: INotificationBadge) => {
  const { font, color, resolveColor } = useTheme();
  const language = useLanguage();

  const STYLE: CSSObject = {
    backgroundColor: resolveColor(badgeBackgroundColor) || color.NOTIFICATION,
    borderRadius: '12px',
    ...(strokeColor && { outline: `solid 2px ${resolveColor(strokeColor)}` }),
    minWidth: text ? '24px' : '12px',
    height: text ? '24px' : '12px',

    ...(variation === 'small' && {
      minWidth: text ? '16px' : '8px',
      height: text ? '16px' : '8px',
    }),
  };

  const SPAN_STYLE: CSSObject = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: text ? '0 8px' : '0 4px',
    height: '100%',
    color: color.TEXT_INVERSE,
    fontSize: 15,
    lineHeight: '24px',
    fontFamily: font.FAMILY_MEDIUM,

    // Figma specifies the badge size to be 16x16, but that results in the text
    // not looking properly centered on low-resolution displays.
    ...(variation === 'small' && {
      fontSize: 11,
      lineHeight: '17px',
      minWidth: '8px',
      height: text ? '17px' : '8px',
      padding: text ? '0 4px' : 0,
    }),
  };

  const getAriaLabel = (textArg?: string) => {
    if (!Number.isNaN(Number(textArg))) {
      return Number(text) > 1
        ? `${text} ${language.get('accessibility_label_unread_notifications')}`
        : `${text} ${language.get('accessibility_label_unread_notification')}`;
    }
  };

  return (
    <div css={STYLE} {...props}>
      <span
        css={SPAN_STYLE}
        aria-label={accessibilityLabel || getAriaLabel(text)}
      >
        <AttributedText text={text} />
      </span>
    </div>
  );
};

export default NotificationBadge;
