import { IBaseAction } from '../useAction';

export interface IEmptyAction extends IBaseAction {
  type: 'empty';
}

const useEmptyAction = () => {
  const handleEmptyAction = () => {
    // Empty action is empty
  };

  return handleEmptyAction;
};

export default useEmptyAction;
