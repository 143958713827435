/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';

import { IBasePart } from '../part';
import AttributedText from '../../../components/attributedText';

export interface IBillboardPart extends IBasePart {
  type: 'billboard';

  attributedText: string;
  imageUrls: Array<string>;
}

const BillboardPart = (data: IBillboardPart) => {
  const WRAPPER_STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 20,
  };

  const getImageOrder = (i: number) => {
    const center = Math.ceil(data.imageUrls.length / 2);

    if (i % 2 === 0) {
      return center + Math.ceil(i / 2);
    }
    return center - Math.ceil(i / 2);
  };

  return (
    <div css={WRAPPER_STYLE}>
      <div css={{ display: 'flex', alignItems: 'center' }}>
        {data.imageUrls.map((img, i) => (
          <img
            key={i}
            css={{
              height: 72 - 10 * Math.ceil(i / 2),
              borderRadius: '50%',
              margin: '0 -5px',
              order: getImageOrder(i),
              zIndex:
                Math.ceil(data.imageUrls.length / 2) - 1 - Math.ceil(i / 2),
            }}
            src={img}
            alt=''
          />
        ))}
      </div>

      <AttributedText
        css={{ padding: '20px 20px 0 20px' }}
        text={data.attributedText}
      />
    </div>
  );
};

export default BillboardPart;
