/** @jsx jsx */
import React, { useEffect, useRef, useState } from 'react';
import { jsx, CSSObject } from '@emotion/core';
import YouTube from 'react-youtube';
import { IVideoPlayerPlaybackEvent } from './videoPlayer';

interface IYoutubePlayer {
  videoId: string;
  onPause: IVideoPlayerPlaybackEvent;
  onPlay: IVideoPlayerPlaybackEvent;
  onEnd: IVideoPlayerPlaybackEvent;
  onReady: (startPlayback: () => void) => void;
  onDisposeWhenPlaying: IVideoPlayerPlaybackEvent;
  styles: CSSObject;
}

const YoutubePlayer = ({
  videoId,
  onPause,
  onPlay,
  onEnd,
  onReady,
  onDisposeWhenPlaying,
  styles,
}: IYoutubePlayer) => {
  const playerRef = useRef<any>();
  const isPlayingRef = useRef(false);
  const onDisposeWhenPlayingRef = useRef<IVideoPlayerPlaybackEvent>();

  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    // Keep track of isPlaying state value for possible
    // unmounting purposes
    isPlayingRef.current = isPlaying;
  }, [isPlaying]);

  useEffect(() => {
    onDisposeWhenPlayingRef.current = onDisposeWhenPlaying;
  }, [onDisposeWhenPlaying]);

  useEffect(() => {
    // Unmount of component, we want to track the video
    // has 'stopped' if it's still playing
    return () => {
      if (
        isPlayingRef.current &&
        playerRef.current &&
        onDisposeWhenPlayingRef.current
      ) {
        onDisposeWhenPlayingRef.current(playerRef.current.getCurrentTime());
      }
    };
  }, []);

  return (
    <YouTube
      videoId={videoId}
      css={styles}
      onPause={(event) => {
        setIsPlaying(false);
        onPause(event.target.getCurrentTime());
      }}
      onReady={(event) => {
        playerRef.current = event.target;
        onReady(() => playerRef.current.playVideo());
      }}
      onPlay={(event) => {
        setIsPlaying(true);
        onPlay(event.target.getCurrentTime());
      }}
      onEnd={(event) => {
        setIsPlaying(false);
        onEnd(event.target.getCurrentTime());
      }}
    />
  );
};

export default YoutubePlayer;
