import { useCallback, useContext, useMemo } from 'react';
import { camelize } from 'humps';
import { AppConfigContext } from '../contexts/appConfigContext';
import currentPartner from '../constants/partners';

export interface IClientTheme {
  colorPalette: {
    colors: {
      actionFloatingSoft: string;
      alert: string;
      alertPress: string;
      alertSecondary: string;
      alertSecondaryPress: string;
      androidStatusBar: string;
      buttonBgPrimaryDisabled: string;
      chatBubbleIncoming: string;
      chatBubbleOutgoing: string;
      chevron: string;
      circularGradientDark: string;
      circularGradientLight: string;
      dividerLine: string;
      iconInverse: string;
      inputPlaceholder: string;
      listPress: string;
      notification: string;
      primary: string;
      primaryPress: string;
      searchFieldBg: string;
      secondary: string;
      secondaryPress: string;
      statusBgAlert: string;
      statusBgNotice: string;
      statusFgHighlightAlert: string;
      surfaceBgMain: string;
      surfaceBgSecondary: string;
      text: string;
      textHeadlines: string;
      textInverse: string;
      textSectionHeadline: string;
      textSoft: string;
      textSupportHeadline: string;
      uiIdle: string;
    };
  };
  fontStyles: {
    fonts: Record<
      'regular' | 'medium' | 'bold' | string,
      | {
          type: 'system';
          webWeightValue: number;
        }
      | {
          type: 'custom';
          name: string;
        }
    >;
  };
}

export enum IColors {
  // Local colors.
  LOCAL_APP_BG,
  LOCAL_FOCUS_BORDER,
  LOCAL_INITIAL_LOADER,
  LOCAL_WHITE,

  // Remote palette colors.
  ACTION_FLOATING_SOFT,
  ALERT,
  ALERT_PRESS,
  ALERT_SECONDARY,
  ALERT_SECONDARY_PRESS,
  ANDROID_STATUS_BAR,
  BUTTON_BG_PRIMARY_DISABLED,
  CHAT_BUBBLE_INCOMING,
  CHAT_BUBBLE_OUTGOING,
  CHEVRON,
  CIRCULAR_GRADIENT_DARK,
  CIRCULAR_GRADIENT_LIGHT,
  DIVIDER_LINE,
  ICON_INVERSE,
  INPUT_PLACEHOLDER,
  LIST_PRESS,
  NOTIFICATION,
  PRIMARY,
  PRIMARY_PRESS,
  SEARCH_FIELD_BG,
  SECONDARY,
  SECONDARY_PRESS,
  STATUS_BG_ALERT,
  STATUS_BG_NOTICE,
  STATUS_FG_HIGHLIGHT_ALERT,
  SURFACE_BG_MAIN,
  SURFACE_BG_SECONDARY,
  TEXT,
  TEXT_HEADLINES,
  TEXT_INVERSE,
  TEXT_SECTION_HEADLINE,
  TEXT_SOFT,
  TEXT_SUPPORT_HEADLINE,
  UI_IDLE,
}

const useTheme = () => {
  const { clientTheme } = useContext(AppConfigContext);
  const paletteColors = clientTheme?.colorPalette.colors;

  const partnerColors = currentPartner?.theme?.colors;

  const color = useMemo<Record<keyof typeof IColors, string>>(
    () => ({
      // Colors used by the client not present in the clientTheme color palette.
      LOCAL_APP_BG: '#f0f5f8',
      LOCAL_FOCUS_BORDER: '#004484',
      LOCAL_INITIAL_LOADER: '#c2c2c2',
      LOCAL_WHITE: '#fff',

      // Remote palette colors.
      ACTION_FLOATING_SOFT: paletteColors?.actionFloatingSoft,
      ALERT: paletteColors?.alert,
      ALERT_PRESS: paletteColors?.alertPress,
      ALERT_SECONDARY: paletteColors?.alertSecondary,
      ALERT_SECONDARY_PRESS: paletteColors?.alertSecondaryPress,
      ANDROID_STATUS_BAR: paletteColors?.androidStatusBar,
      BUTTON_BG_PRIMARY_DISABLED: paletteColors?.buttonBgPrimaryDisabled,
      CHAT_BUBBLE_INCOMING: paletteColors?.chatBubbleIncoming,
      CHAT_BUBBLE_OUTGOING: paletteColors?.chatBubbleOutgoing,
      CHEVRON: paletteColors?.chevron,
      CIRCULAR_GRADIENT_DARK: paletteColors?.circularGradientDark,
      CIRCULAR_GRADIENT_LIGHT: paletteColors?.circularGradientLight,
      DIVIDER_LINE: paletteColors?.dividerLine,
      ICON_INVERSE: paletteColors?.iconInverse,
      INPUT_PLACEHOLDER: paletteColors?.inputPlaceholder,
      LIST_PRESS: paletteColors?.listPress,
      NOTIFICATION: paletteColors?.notification,
      PRIMARY: paletteColors?.primary,
      PRIMARY_PRESS: paletteColors?.primaryPress,
      SEARCH_FIELD_BG: paletteColors?.searchFieldBg,
      SECONDARY: paletteColors?.secondary,
      SECONDARY_PRESS: paletteColors?.secondaryPress,
      STATUS_BG_ALERT: paletteColors?.statusBgAlert,
      STATUS_BG_NOTICE: paletteColors?.statusBgNotice,
      STATUS_FG_HIGHLIGHT_ALERT: paletteColors?.statusFgHighlightAlert,
      SURFACE_BG_MAIN: paletteColors?.surfaceBgMain,
      SURFACE_BG_SECONDARY: paletteColors?.surfaceBgSecondary,
      TEXT: paletteColors?.text,
      TEXT_HEADLINES: paletteColors?.textHeadlines,
      TEXT_INVERSE: paletteColors?.textInverse,
      TEXT_SECTION_HEADLINE: paletteColors?.textSectionHeadline,
      TEXT_SOFT: paletteColors?.textSoft,
      TEXT_SUPPORT_HEADLINE: paletteColors?.textSupportHeadline,
      UI_IDLE: paletteColors?.uiIdle,

      // Overwrite key:value pair for colors specified in the partners object. Temporary
      // workaround until we have proper tokens / theming support.
      ...partnerColors,
    }),
    [paletteColors, partnerColors]
  );

  const resolveColor = useCallback(
    (colorValue?: string) => {
      // Temporary hack to overwrite hex value given from VL of primary
      // and primary pressed color values to match the current color
      // theme including partner overwrites. Will be removed when all colors uses
      // colors names instead of hardcoded value (current work ongoing)
      switch (colorValue) {
        case paletteColors?.primary:
          return color.PRIMARY;
        case paletteColors?.primaryPress:
          return color.PRIMARY_PRESS;
        case paletteColors?.secondary:
          return color.SECONDARY;
        case paletteColors?.secondaryPress:
          return color.SECONDARY_PRESS;
      }
      return colorValue
        ? ((paletteColors || {}) as Record<string, string>)[
            // regex to bypass any values with a number preceding an underscore (e.g DECORATIVE_1_ACCENT)
            /.*_[0-9].*/gi.test(colorValue) ? colorValue : camelize(colorValue)
          ] || colorValue
        : colorValue;
    },
    [color, paletteColors]
  );

  const resolveFont = useCallback(
    (fontWeight: string): string => {
      const font = clientTheme?.fontStyles.fonts[fontWeight];

      switch (font?.type) {
        case 'system':
          return `System-${font.webWeightValue || 400}`;
        case 'custom':
          return font.name;
        default:
          return 'System-400';
      }
    },
    [clientTheme]
  );

  const font = useMemo(
    () => ({
      FAMILY_REGULAR: resolveFont('regular'),
      FAMILY_MEDIUM: resolveFont('medium'),
      FAMILY_BOLD: resolveFont('bold'),
    }),
    [resolveFont]
  );

  return { font, color, resolveColor, resolveFont };
};

export default useTheme;
