import { useContext } from 'react';
import { CSSObject } from '@emotion/core';
import useTheme from './useTheme';
import { fonts } from '../constants/assets';
import layout from '../constants/layout';
import currentPartner from '../constants/partners';
import { AppConfigContext } from '../contexts/appConfigContext';

const useGlobalStyles = () => {
  const { loginViewContent } = useContext(AppConfigContext);
  const { color, font } = useTheme();

  const FONT_FACES: CSSObject = {
    faces: [
      // Family-specific font-faces for YouSans.
      {
        '@font-face': {
          fontFamily: 'YouSans-Italic',
          fontStyle: 'italic',
          fontWeight: 400,
          src: `url(${fonts.YOU_SANS_ITALIC}) format('woff2')`,
        },
      },
      {
        '@font-face': {
          fontFamily: 'YouSans-Light',
          fontWeight: 300,
          src: `url(${fonts.YOU_SANS_LIGHT}) format('woff2')`,
        },
      },
      {
        '@font-face': {
          fontFamily: 'YouSans-Regular',
          fontWeight: 400,
          src: `url(${fonts.YOU_SANS_REGULAR}) format('woff2')`,
        },
      },
      {
        '@font-face': {
          fontFamily: 'YouSans-Medium',
          fontWeight: 500,
          src: `url(${fonts.YOU_SANS_MEDIUM}) format('woff2')`,
        },
      },
      {
        '@font-face': {
          fontFamily: 'YouSans-Bold',
          fontWeight: 700,
          src: `url(${fonts.YOU_SANS_BOLD}) format('woff2')`,
        },
      },
      {
        '@font-face': {
          fontFamily: 'YouSans-Black',
          fontWeight: 900,
          src: `url(${fonts.YOU_SANS_BLACK}) format('woff2')`,
        },
      },

      // Mapping of Montserrat to YouSans.
      {
        '@font-face': {
          fontFamily: 'Montserrat',
          fontWeight: 500,
          src: `url(${fonts.YOU_SANS_REGULAR}) format('woff2')`,
        },
      },
      {
        '@font-face': {
          fontFamily: 'Montserrat',
          fontWeight: 600,
          src: `url(${fonts.YOU_SANS_MEDIUM}) format('woff2')`,
        },
      },

      {
        '@font-face': {
          fontFamily: 'Montserrat',
          fontWeight: 700,
          src: `url(${fonts.YOU_SANS_BOLD}) format('woff2')`,
        },
      },
      {
        '@font-face': {
          fontFamily: 'Montserrat-Medium',
          fontWeight: 400,
          src: `url(${fonts.YOU_SANS_REGULAR}) format('woff2')`,
        },
      },
      {
        '@font-face': {
          fontFamily: 'Montserrat-SemiBold',
          fontWeight: 500,
          src: `url(${fonts.YOU_SANS_MEDIUM}) format('woff2')`,
        },
      },
      {
        '@font-face': {
          fontFamily: 'Montserrat-Bold',
          fontWeight: 700,
          src: `url(${fonts.YOU_SANS_BOLD}) format('woff2')`,
        },
      },
      {
        '@font-face': {
          fontFamily: 'Montserrat-ExtraBold',
          fontWeight: 700,
          src: `url(${fonts.YOU_SANS_BOLD}) format('woff2')`,
        },
      },
      {
        '@font-face': {
          fontFamily: 'Montserrat-Black',
          fontWeight: 900,
          src: `url(${fonts.YOU_SANS_BLACK}) format('woff2')`,
        },
      },

      // System / Work Sans
      {
        '@font-face': {
          fontFamily: 'System-100',
          fontWeight: 100,
          src: `url(https://fonts.gstatic.com/s/worksans/v8/QGYsz_wNahGAdqQ43Rh_fKDptfpA4Q.woff2) format('woff2')`,
        },
      },
      {
        '@font-face': {
          fontFamily: 'System-200',
          fontWeight: 200,
          src: `url(https://fonts.gstatic.com/s/worksans/v8/QGYsz_wNahGAdqQ43Rh_fKDptfpA4Q.woff2) format('woff2')`,
        },
      },
      {
        '@font-face': {
          fontFamily: 'System-300',
          fontWeight: 300,
          src: `url(https://fonts.gstatic.com/s/worksans/v8/QGYsz_wNahGAdqQ43Rh_fKDptfpA4Q.woff2) format('woff2')`,
        },
      },
      {
        '@font-face': {
          fontFamily: 'System-400',
          fontWeight: 400,
          src: `url(https://fonts.gstatic.com/s/worksans/v8/QGYsz_wNahGAdqQ43Rh_fKDptfpA4Q.woff2) format('woff2')`,
        },
      },
      {
        '@font-face': {
          fontFamily: 'System-500',
          fontWeight: 500,
          src: `url(https://fonts.gstatic.com/s/worksans/v8/QGYsz_wNahGAdqQ43Rh_fKDptfpA4Q.woff2) format('woff2')`,
        },
      },
      {
        '@font-face': {
          fontFamily: 'System-600',
          fontWeight: 600,
          src: `url(https://fonts.gstatic.com/s/worksans/v8/QGYsz_wNahGAdqQ43Rh_fKDptfpA4Q.woff2) format('woff2')`,
        },
      },
      {
        '@font-face': {
          fontFamily: 'System-700',
          fontWeight: 700,
          src: `url(https://fonts.gstatic.com/s/worksans/v8/QGYsz_wNahGAdqQ43Rh_fKDptfpA4Q.woff2) format('woff2')`,
        },
      },
      {
        '@font-face': {
          fontFamily: 'System-800',
          fontWeight: 800,
          src: `url(https://fonts.gstatic.com/s/worksans/v8/QGYsz_wNahGAdqQ43Rh_fKDptfpA4Q.woff2) format('woff2')`,
        },
      },
      {
        '@font-face': {
          fontFamily: 'System-900',
          fontWeight: 900,
          src: `url(https://fonts.gstatic.com/s/worksans/v8/QGYsz_wNahGAdqQ43Rh_fKDptfpA4Q.woff2) format('woff2')`,
        },
      },
    ],
  };

  const CSS_RESET: CSSObject = {
    '*': {
      margin: 0,
      padding: 0,
      border: 0,
      fontSize: '100%',
      font: 'inherit',
      verticalAlign: 'baseline',
      textDecoration: 'none',
    },

    button: {
      display: 'block',
      color: color.TEXT,
      backgroundColor: 'unset',
      textAlign: 'inherit',
      textTransform: 'none',
      webkitAppearance: 'button',
      width: 'fill-available',
    },
  };

  const GLOBAL_RULES: CSSObject = {
    body: {
      margin: 0,
      fontFamily: font.FAMILY_REGULAR,
      fontSize: 15,
      lineHeight: 1.3,
      color: color.TEXT,
      backgroundColor: color.LOCAL_APP_BG,
      overflowWrap: 'anywhere',
      textRendering: 'geometricPrecision',

      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',

      // Only display on desktop and tablet as no background is shown
      // on mobile devices
      [`@media (min-width: ${layout.BREAKPOINT_MEDIUM}px)`]: {
        backgroundImage: `url(${
          currentPartner?.backgroundImage ||
          loginViewContent.webBackgroundImage?.url
        })`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
    },

    '#root[aria-hidden]': {
      filter: 'blur(4px)',
    },

    'input, textarea, select, button': {
      fontFamily: font.FAMILY_REGULAR,
      textRendering: 'inherit',
      WebkitAppearance: 'none',
    },

    'input::-webkit-date-and-time-value': {
      textAlign: 'left',
    },

    '* :focus': {
      outline: 'none',
    },

    '* :focus:not(input,textarea,select)': {
      boxShadow: `inset 0px 0px 0px 3px ${color.LOCAL_FOCUS_BORDER}`,
    },

    '* :focus:not([data-focus-visible-added])': {
      boxShadow: 'none',
    },

    // Remove the background color added by Chrome for auto-filled inputs
    'input:autofill, select:autofill': {
      transition: 'background-color 9999s ease-in-out 0s;',
    },

    // Remove spinners from input fields
    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: '0',
    },

    'input[type=number]': {
      MozAppearance: 'textfield',
      WebkitAppearance: 'textfield',
    },
  };

  return { ...CSS_RESET, ...FONT_FACES, ...GLOBAL_RULES };
};

export default useGlobalStyles;
