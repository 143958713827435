/** @jsx jsx */
import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { jsx, CSSObject } from '@emotion/core';
import { SearchContext } from '../../../contexts/searchContext';
import layout from '../../../constants/layout';
import useNavigation from '../../../hooks/useNavigation';
import useLanguage from '../../../hooks/useLanguage';
import useTheme from '../../../hooks/useTheme';
import Clickable from '../../../components/clickable';
import { ViewContext } from '../../../contexts/viewContext';
import { images } from '../../../constants/assets';

const SearchBar = React.memo(() => {
  const { headerElement } = useContext(ViewContext);
  const { searchPlaceholder, query, setQuery } = useContext(SearchContext);

  const { color } = useTheme();
  const navigation = useNavigation();
  const language = useLanguage();

  const WRAPPER_STYLE: CSSObject = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: color.LOCAL_WHITE,
    top: 0,
    zIndex: layout.BASIC_ZINDEX,
    padding: '0 12px',
    height: layout.APP_NAVBAR_HEIGHT,
  };

  const INPUT_STYLE: CSSObject = {
    display: 'flex',
    flex: 1,
    padding: '0px 0px 0px 42px',
    height: 36,
    boxSizing: 'border-box',
    backgroundColor: color.SEARCH_FIELD_BG,
    borderRadius: 8,
    backgroundImage: `url(${images.SEARCHBAR_ICON})`,
    backgroundSize: 24,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 10,

    '::-webkit-search-cancel-button': {
      display: 'none',
      webkitAppearance: 'none',
    },
    '::-webkit-search-results-button': {
      display: 'none',
      webkitAppearance: 'none',
    },
  };

  const CANCEL_BUTTON_STYLE: CSSObject = {
    width: 'auto',
    height: 38,
    padding: '0px 7px',
    margin: '0px -7px 0 3px',
    color: color.PRIMARY,
    fontSize: 16,
  };

  const componentOutput = (
    <div css={WRAPPER_STYLE}>
      <input
        type='search'
        placeholder={searchPlaceholder}
        value={query}
        onChange={(e) => {
          setQuery(e.currentTarget.value);
        }}
        css={INPUT_STYLE}
        autoFocus={!query}
        autoCapitalize='none'
        spellCheck={false}
        aria-label={language.get('accessibility_label_search_description')}
      />

      <Clickable
        dim
        css={CANCEL_BUTTON_STYLE}
        onClick={() => {
          navigation.pop();
        }}
      >
        {language.get('cancel')}
      </Clickable>
    </div>
  );

  return headerElement && ReactDOM.createPortal(componentOutput, headerElement);
});

export default SearchBar;
