import { useRef } from 'react';
import device from '../utils/device';
import { logInfo, logWarning } from '../utils/remoteLogger';
import useLanguage from './useLanguage';
import useOverlay from './useOverlay';

const useMediaDevicePermission = () => {
  const language = useLanguage();
  const overlay = useOverlay();

  const getMediaDevicePermission = (constraints: MediaStreamConstraints) => {
    const checkPermission = () => {
      return new Promise<boolean>((resolve) => {
        let videoPermissionGranted = false;
        let audioPermissionGranted = false;

        navigator.mediaDevices
          .enumerateDevices()
          .then((mediaDevices) => {
            mediaDevices.forEach((mediaDevice) => {
              if (mediaDevice.kind === 'videoinput' && mediaDevice.label) {
                videoPermissionGranted = true;
              }
              if (mediaDevice.kind === 'audioinput' && mediaDevice.label) {
                audioPermissionGranted = true;
              }
            });

            if (videoPermissionGranted && audioPermissionGranted) {
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch(() => {
            resolve(false);
          });
      });
    };

    return new Promise<void>((resolve) => {
      const requestPermission = (isRetry?: boolean) => {
        navigator.mediaDevices
          ?.getUserMedia(constraints)
          .then((mediaStream) => {
            // Immediately stop all tracks, as we're not using them directly.
            mediaStream.getVideoTracks().forEach((track) => {
              track.stop();
            });

            // Log permission grant after a retry.
            if (isRetry) {
              logInfo(
                'useMediaDevicePermission',
                `Media device permission granted after a retry`
              );
            }

            overlay.dismiss();
            resolve();
          })
          .catch((error) => {
            overlay.presentAlert({
              title: language.get(
                'patient_web.media_device_permission.blocked.title'
              ),
              message: device.IS_MOBILE
                ? language.get(
                    'patient_web.media_device_permission.blocked.message_mobile'
                  )
                : language.get(
                    'patient_web.media_device_permission.blocked.message_desktop'
                  ),
              items: [
                {
                  title: language.get('try_again'),
                  onClick: () => {
                    requestPermission(true);
                  },
                },
              ],
            });

            logWarning(
              'useMediaDevicePermission',
              `Media device permission has been denied`,
              error
            );
          });
      };

      // Check for a previously granted permission. If permission has already been granted, resolve,
      // else request permission.
      checkPermission().then((hasPermission) => {
        if (hasPermission) {
          resolve();
          return;
        }

        overlay.presentBasicAlert({
          title: language.get(
            'patient_web.media_device_permission.query.title'
          ),
          message: language.get(
            'patient_web.media_device_permission.query.message'
          ),
        });

        requestPermission();
      });
    });
  };

  return useRef({
    getMediaDevicePermission,
  }).current;
};

export default useMediaDevicePermission;
