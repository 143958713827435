/** @jsx jsx */
import React, { Fragment, useContext } from 'react';
import { jsx, Global } from '@emotion/core';
import { Prompt } from 'react-router-dom';

import { AppConfigContext } from './contexts/appConfigContext';
import AppFrame from './components/appFrame';

import Routes from './components/routing/routes';
import useGlobalStyles from './hooks/useGlobalStyles';

let shouldIgnoreNextNavigation = false;

const App = React.memo(() => {
  const { status } = useContext(AppConfigContext);
  const globalStyles = useGlobalStyles();

  return (
    <Fragment>
      {/* Global styles */}
      <Global styles={globalStyles} />

      {/* On iOS, when the BankId app redirects back to the browser upon a finished or cancelled action,
          it creates a new browser history entry. We want to prevent navigation in that case.
          @TODO: Move this to the Swedish bankId login and bankId flex sign respectively, no need for it to be global. */}
      <Prompt
        message={(location) => {
          if (shouldIgnoreNextNavigation) {
            shouldIgnoreNextNavigation = false;
            return false;
          }

          // Offset the unwanted history entry.
          if (location.hash.startsWith('#redirect_from_bankid')) {
            return false;
          }

          return true;
        }}
      />

      <AppFrame>{status === 'OK' && <Routes />}</AppFrame>
    </Fragment>
  );
});

export default App;
