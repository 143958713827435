/** @jsx jsx */
import React, { ReactNode, useContext } from 'react';
import ReactDOM from 'react-dom';
import { jsx, CSSObject } from '@emotion/core';
import layout from '../constants/layout';
import { ViewContext } from '../contexts/viewContext';

interface IBottomSticky {
  children: ReactNode;
}

const BottomSticky = ({ children, ...props }: IBottomSticky) => {
  const { footerElement } = useContext(ViewContext);

  const LAYOUT_STYLE: CSSObject = {
    zIndex: layout.FOOTER_ZINDEX,
    boxShadow: layout.DEFAULT_SHADOW_BOTTOM_ALIGNED,
  };

  return (
    footerElement &&
    ReactDOM.createPortal(
      <div css={LAYOUT_STYLE} {...props}>
        {children}
      </div>,
      footerElement
    )
  );
};

export default BottomSticky;
