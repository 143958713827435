import useFlexOverlayAlert, {
  IFlexOverlayAlert,
} from './flexOverlay/useFlexOverlayAlert';
import useFlexOverlaySplash, {
  IFlexOverlaySplash,
} from './flexOverlay/useFlexOverlaySplash';
import useFlexOverlaySearch, {
  IFlexOverlaySearch,
} from './flexOverlay/useFlexOverlaySearch';
import useFlexOverlayBankidSigning, {
  IFlexOverlayBankidSigning,
} from './flexOverlay/useFlexOverlayBankidSigning';
import { IFlexNavigation } from '../useFlexNavigation';
import { IStructuredSnowplowEvent } from '../../useSnowplowTracker';

type IFlexOverlay =
  | IFlexOverlayAlert
  | IFlexOverlaySplash
  | IFlexOverlaySearch
  | IFlexOverlayBankidSigning;

export interface IFlexNavigateOverlay {
  type: 'overlay';
  overlay: IFlexOverlay;
  snowplowEvent?: IStructuredSnowplowEvent;
}

const useFlexNavigateOverlay = () => {
  const handleFlexOverlayAlert = useFlexOverlayAlert();
  const handleFlexOverlaySplash = useFlexOverlaySplash();
  const handleFlexOverlaySearch = useFlexOverlaySearch();
  const handleFlexOverlayBankidSigning = useFlexOverlayBankidSigning();

  const handleFlexNavigateOverlay = (
    flexNavigate: IFlexNavigateOverlay
  ): Promise<IFlexNavigation> => {
    const flexOverlay = flexNavigate.overlay;

    switch (flexOverlay.type) {
      case 'alert':
        return handleFlexOverlayAlert(flexOverlay);
      case 'splash':
        return handleFlexOverlaySplash(flexOverlay);
      case 'search':
        return handleFlexOverlaySearch(flexOverlay);
      case 'bankid_signing':
        return handleFlexOverlayBankidSigning(flexOverlay);
    }
  };

  return handleFlexNavigateOverlay;
};

export default useFlexNavigateOverlay;
