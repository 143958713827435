import { useEffect, useState } from 'react';
import layout from '../constants/layout';

type IViewport = 'desktop' | 'tablet' | 'mobile';

const getCurrentViewport = (): IViewport => {
  if (window.innerWidth > layout.BREAKPOINT_LARGE) {
    return 'desktop';
  }

  if (
    window.innerWidth > layout.BREAKPOINT_MEDIUM &&
    window.innerWidth < layout.BREAKPOINT_LARGE
  ) {
    return 'tablet';
  }

  return 'mobile';
};

const useViewport = () => {
  const [viewport, setViewport] = useState<IViewport>(getCurrentViewport);

  useEffect(() => {
    const handleWindowResize = () => {
      setViewport(getCurrentViewport);
    };

    window.addEventListener('resize', handleWindowResize);
    handleWindowResize();

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return viewport;
};

export default useViewport;
