/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { IBasePart } from '../part';
import BlockItem, { IBlockItem } from './blocks/blockItem';

export interface IBlocksPart extends IBasePart {
  type: 'blocks';
  content: IBlockItem;
}

const BlocksPart = (data: IBlocksPart) => {
  return <BlockItem {...data.content} />;
};

export default BlocksPart;
