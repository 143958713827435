import React, { useCallback, useEffect, useState } from 'react';
import LanguageSelectorView from '../views/languageSelector/languageSelectorView';
import useLanguage from '../hooks/useLanguage';
import useNavigation from '../hooks/useNavigation';
import useRequestError from '../hooks/useRequestError';
import environment from '../utils/environment';
import request, { setClientLanguageHeader } from '../utils/request';

interface ILanguageOption {
  languageCode: string;
  languageName: string;
}

interface IAppCountriesResponse {
  countryCode: string;
  countryName: string;
  languageOptions: Array<ILanguageOption>;
}

interface ILanguageSelectorContextState {
  languageOptions: Array<ILanguageOption>;
  currentLanguage: string;
}

interface ILanguageSelectorContext extends ILanguageSelectorContextState {
  setPreferredLanguage: (language: string) => void;
}

export const LanguageSelectorContext = React.createContext(
  {} as ILanguageSelectorContext
);

export const LanguageSelectorContextProvider = () => {
  const handleRequestError = useRequestError();
  const language = useLanguage();

  const [state, setState] = useState<ILanguageSelectorContextState>({
    currentLanguage: language.getCurrentLanguage(),
    languageOptions: [],
  });

  const navigation = useNavigation();

  const setPreferredLanguage = useCallback(
    (preferredLanguage: string) => {
      if (state.currentLanguage === preferredLanguage) {
        navigation.pop();
      } else {
        setClientLanguageHeader(preferredLanguage);
        navigation.reinitializeApp();
      }
    },
    [navigation, state.currentLanguage]
  );

  useEffect(() => {
    const loadLanguageOptions = async () => {
      try {
        const response = await request<{
          appCountries: Array<IAppCountriesResponse>;
        }>('/api/view/appcountries');

        response.appCountries.forEach((country) => {
          // Pick available language options for current country.
          if (country.countryCode === environment.COUNTRY) {
            setState((prevState) => {
              return {
                ...prevState,
                languageOptions: country.languageOptions || [],
              };
            });
          }
        });
      } catch (e) {
        handleRequestError(e);
      }
    };

    loadLanguageOptions();
  }, [handleRequestError]);

  return (
    <LanguageSelectorContext.Provider
      value={{
        ...state,
        setPreferredLanguage,
      }}
    >
      <LanguageSelectorView />
    </LanguageSelectorContext.Provider>
  );
};
