/** @jsx jsx */
import React, { createRef, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { fonts } from '../constants/assets';

interface IIFrame extends React.IframeHTMLAttributes<HTMLIFrameElement> {
  title?: string;
  urlSource?: string;
  HTMLSource?: string;

  fitContent?: boolean;
  injectFontFaces?: boolean;

  onLinkClick?: (href: string) => void;
  onFrameLoaded?: () => void;
}

const IFrame = ({
  title,
  urlSource,
  HTMLSource,

  fitContent,
  injectFontFaces,
  onLinkClick,
  onFrameLoaded,
  ...props
}: IIFrame) => {
  const iframeRef = createRef<HTMLIFrameElement>();

  const handleLinkClick = (e: MouseEvent) => {
    e.preventDefault();
    const { href } = e.target as HTMLLinkElement;

    // If the user clicked on a valid link, trigger the onLinkClick callback.
    if (href && onLinkClick) {
      onLinkClick(href);
    }
  };

  const handleOnLoad = (e: React.SyntheticEvent<HTMLIFrameElement, Event>) => {
    const iframe = e.currentTarget;

    // If the iframe has loaded an url, then it's probably from a different domain
    // and we can't access the content window.
    if (urlSource) {
      return;
    }

    // Set the height of the iFrame element to its body height.
    if (fitContent) {
      // Needs to run after the initial render, as initially, the inline css has not yet been applied.
      setTimeout(() => {
        const docHeight =
          iframe.contentWindow?.document.documentElement.scrollHeight;
        iframe.style.height = `${docHeight}px`;
      }, 0);
    }

    // Inject the YouSans font faces.
    if (injectFontFaces) {
      const styleTag = document.createElement('style');
      styleTag.innerHTML =
        `@font-face { font-family: 'YouSans'; src: url('${fonts.YOU_SANS_REGULAR}'); }` +
        `@font-face { font-family: 'YouSans'; src: url('${fonts.YOU_SANS_MEDIUM}'); font-weight: 500; }` +
        `@font-face { font-family: 'YouSans'; src: url('${fonts.YOU_SANS_BOLD}'); font-weight: 700; }`;

      iframe.contentWindow?.document.head.appendChild(styleTag);
    }

    // Trigger the onFrameLoaded callback.
    if (onFrameLoaded) {
      onFrameLoaded();
    }

    iframe.contentWindow?.addEventListener('click', handleLinkClick);
  };

  useEffect(() => {
    const iframe = iframeRef.current;

    if (iframe && HTMLSource) {
      iframe.contentWindow?.document.write(HTMLSource);
      iframe.contentWindow?.document.close();
    }
  }, [iframeRef, HTMLSource]);

  return (
    <iframe
      title={title}
      ref={iframeRef}
      src={urlSource}
      onLoad={handleOnLoad}
      {...props}
    />
  );
};

export default IFrame;
