/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';

import AttributedText from '../../../components/attributedText';
import { IBasePart } from '../part';

export interface IDataTextRowBoxPart extends IBasePart {
  type: 'data_text_row_box';

  topMargin: number;
  bottomMargin: number;
  title: string;
  space: number;
  text: string;
}

const DataTextRowBoxPart = (data: IDataTextRowBoxPart) => {
  const STYLE: CSSObject = {
    marginTop: data.topMargin || 20,
    marginBottom: data.bottomMargin || 20,
    marginLeft: 20,
    marginRight: 20,
  };

  return (
    <div css={STYLE}>
      <AttributedText
        css={{ marginBottom: data.space || 6 }}
        text={data.title}
      />
      <AttributedText text={data.text} />
    </div>
  );
};

export default DataTextRowBoxPart;
