/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import AttributedText from '../../../../../components/attributedText';
import { IBlockBaseItem } from '../blockItem';

export interface IBlockTextItem extends IBlockBaseItem {
  type: 'text';
  text: string;
}

interface IBlockTextProps {
  data: IBlockTextItem;
}

const BlockTextItem = ({ data, ...props }: IBlockTextProps) => {
  const getTag = (): keyof JSX.IntrinsicElements => {
    switch (data.accessibility?.descriptor?.type) {
      case 'heading':
        switch (data.accessibility.descriptor.level) {
          case 1:
            return 'h2';
          case 2:
            return 'h3';
          case 3:
            return 'h4';
          case 4:
            return 'h5';
          case 5:
            return 'h6';
          default:
            return 'h2';
        }
      case 'paragraph':
        return 'p';
      default:
        return 'div';
    }
  };

  return <AttributedText text={data.text} renderAs={getTag()} {...props} />;
};

export default BlockTextItem;
