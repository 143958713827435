/** @jsx jsx */
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { CSSObject, jsx } from '@emotion/core';
import Clickable from './clickable';
import useTheme from '../hooks/useTheme';
import RemoteImage from './RemoteImage';
import LocalImage from './localImage';
import environment from '../utils/environment';
import useLanguage from '../hooks/useLanguage';
import appStoreLinks from '../constants/appStoreLinks';
import localStorage from '../utils/localStorage';
import { AppConfigContext } from '../contexts/appConfigContext';
import { AppContext } from '../contexts/appContext';
import useSnowplowTracker from '../hooks/useSnowplowTracker';
import device from '../utils/device';
import { logInfo } from '../utils/remoteLogger';

const SmartBanner = () => {
  const { color } = useTheme();
  const [isClosed, setIsClosed] = useState(!!localStorage.get('isClosed'));
  const { appConfig } = useContext(AppConfigContext);
  const { userLoggedIn } = useContext(AppContext);

  const snowplowTracker = useSnowplowTracker();

  const language = useLanguage();

  type AppStoreLinkKeys = keyof typeof appStoreLinks;

  // Wait for 24 hours before displaying the banner again if the user closes it
  const waitTimeInMilliseconds = 60 * 60 * 24 * 1000;

  const getAppLogo = () => {
    return environment.IS_KRY
      ? 'https://appresources.kry.se/images/kry.png'
      : 'https://appresources.kry.se/images/livi.png';
  };

  const getLinkToRedirectToApp = (linkType: string) => {
    if (environment.IS_KRY) {
      switch (environment.COUNTRY) {
        case 'NO':
          return appStoreLinks[
            `NO_KRY_${linkType}_TO_OPEN_APP` as AppStoreLinkKeys
          ];
        default:
          return appStoreLinks[
            `KRY_${linkType}_TO_OPEN_APP` as AppStoreLinkKeys
          ];
      }
    }
    return appStoreLinks[`LIVI_${linkType}_TO_OPEN_APP` as AppStoreLinkKeys];
  };

  const getDeeplinkToRedirectToApp = () =>
    getLinkToRedirectToApp('ADJUST_DEEP_LINK');
  const getUniversalLinkToRedirectToApp = () =>
    getLinkToRedirectToApp('UNIVERSAL_LINK');

  // Use adjust link after user is logged in and adjust is enabled other wise use universal link
  const redirectToInstalledAppOrAppStore = () => {
    window.location.href =
      userLoggedIn && appConfig.adjustState === 'enabled'
        ? getDeeplinkToRedirectToApp()
        : getUniversalLinkToRedirectToApp();
  };

  const closeBanner = () => {
    setIsClosed(true);
    localStorage.set('isClosedTime', new Date().getTime());
    // Added logInfo to track the banner close event on non-logged in users
    // since tracking is not allowed for non-logged screen
    if (!userLoggedIn) {
      logInfo('SmartBanner', 'Banner closed');
    }

    snowplowTracker.trackEvent(snowplowTracker.event.BANNER_CLOSE);
  };
  const redirectToApp = () => {
    redirectToInstalledAppOrAppStore();
    setIsClosed(true);
    if (!userLoggedIn) {
      logInfo('SmartBanner', 'Redirect to app store');
    }
    snowplowTracker.trackEvent(snowplowTracker.event.BANNER_REDIRECT);
  };

  useEffect(() => {
    // check if the banner should be closed when the page is refreshed
    if (isClosed === false && localStorage.get('isClosedTime')) {
      setIsClosed(true);
    }
    const intervalId = setInterval(() => {
      if (
        new Date().getTime() >=
        parseInt(localStorage.get('isClosedTime') || '0', 10) +
          waitTimeInMilliseconds
      ) {
        localStorage.remove('isClosedTime');
        setIsClosed(false);
      }
    }, waitTimeInMilliseconds);
    return () => {
      clearInterval(intervalId);
    };
  }, [isClosed, waitTimeInMilliseconds]);

  const BUTTON_STYLE: CSSObject = {
    width: 100,
    height: 38,
    padding: '0 7px',
    marginLeft: 'auto',
    fontSize: 'smaller',
  };

  return (
    <Fragment>
      {!isClosed && device.IS_MOBILE && (
        <div
          css={{
            position: 'sticky',
            top: 0,
            backgroundColor: color.LOCAL_WHITE,
            zIndex: 11,
            padding: '10px',
            borderBottom: `2px solid ${color.DIVIDER_LINE}`,
          }}
        >
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              transform: 'translateY(4%)',
            }}
          >
            <Clickable dim renderAs='div' onClick={() => closeBanner()}>
              <LocalImage src='ICON32_CROSS' tint='TEXT' css={{ height: 24 }} />
            </Clickable>

            <RemoteImage
              url={getAppLogo()}
              css={{ height: '60px', width: '60px', margin: '0 10px' }}
            />
            <div css={{ fontSize: 'smaller' }}>
              <div css={{ fontWeight: 700 }}>
                {language.get('smart_banner_title')}
              </div>
              <div>{language.get('smart_banner_sub_title')}</div>
              <RemoteImage
                url='https://appresources.kry.se/images/stars.png'
                css={{ height: '14px' }}
              />
            </div>
            <Clickable
              styleAs='button'
              onClick={redirectToApp}
              css={BUTTON_STYLE}
            >
              {language.get('smart_banner_button')}
            </Clickable>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default SmartBanner;
