/** @jsx jsx */
import React, { Fragment, useEffect, useState } from 'react';
import { jsx, CSSObject } from '@emotion/core';
import Lottie from 'react-lottie-player';

import { IBasePart } from '../part';
import { screenPartsImages } from '../../../constants/assets';
import AttributedText from '../../../components/attributedText';
import request from '../../../utils/request';

export interface IWaitTimeHeaderPart extends IBasePart {
  type: 'wait_time_header';

  timeText: string;
  accessibilityLabel: string;

  blobColor: string;
  innerBlobColor: string;
  outerBlobColor: string;
  maskColor: string;

  animationUrl?: string;
}

const WaitTimeHeaderPart = (data: IWaitTimeHeaderPart) => {
  const {
    timeText,
    accessibilityLabel,
    // blobColor,
    // innerBlobColor,
    // outerBlobColor,
    // maskColor,
    animationUrl,
  } = data;

  const [animationPending, setAnimationPending] = useState(!!animationUrl);
  const [animation, setAnimation] = useState<object | null>(null);

  const OUTER_CONTAINER: CSSObject = {
    position: 'relative',
    overflow: 'hidden',
    height: 0,
    paddingBottom: 228,
  };

  const INNER_CONTAINER: CSSObject = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const ANIMATION_CONTAINER: CSSObject = {
    width: 300,
    minWidth: 300,
  };

  const OUTER_BLOB: CSSObject = {
    width: 300,
    // filter: colorFilter(resolveColor(outerBlobColor)),
  };

  const INNER_BLOB: CSSObject = {
    position: 'absolute',
    width: 232,
    // filter: colorFilter(resolveColor(innerBlobColor)),
  };

  const BLOB_TEXT_CONTAINER: CSSObject = {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bottom: 0,
    height: '139px',
  };

  const BLOB: CSSObject = {
    // filter: colorFilter(resolveColor(blobColor)),
  };

  const TEXT: CSSObject = {
    position: 'absolute',
    textAlign: 'center',
  };

  const BLOB_MASK: CSSObject = {
    position: 'absolute',
    alignSelf: 'center',
    bottom: -1,
    height: 70,
    // filter: colorFilter(resolveColor(maskColor)),
  };

  useEffect(() => {
    if (!animationUrl) {
      return;
    }

    let partIsMounted = true;

    request<object>(animationUrl, {
      credentials: 'omit',
      noCamelization: true,
    })
      .then((animationData) => {
        if (partIsMounted) {
          setAnimation(animationData);
          setAnimationPending(false);
        }
      })
      .catch(() => {
        if (partIsMounted) {
          setAnimation(null);
          setAnimationPending(false);
        }
      });

    return () => {
      partIsMounted = false;
    };
  }, [animationUrl]);

  return (
    <header css={OUTER_CONTAINER} aria-label={accessibilityLabel}>
      <div css={INNER_CONTAINER}>
        {/* Lottie player, rendered only once we've fetched a potential animation. */}
        {animation && (
          <div css={ANIMATION_CONTAINER}>
            <Lottie loop play animationData={animation} />
          </div>
        )}

        {/* The static background blobs, rendered only if no animationUrl is provided
            or if the animation failed to load. */}
        {!animationPending && !animation && (
          <Fragment>
            <img
              css={OUTER_BLOB}
              src={screenPartsImages.WAIT_TIME_HEADER_OUTER_BLOB}
              alt=''
            />
            <img
              css={INNER_BLOB}
              src={screenPartsImages.WAIT_TIME_HEADER_INNER_BLOB}
              alt=''
            />
          </Fragment>
        )}

        {/* The overlay mask used to cover the bottom of both the static images and the Lottie animation. */}
        <img
          css={BLOB_MASK}
          src={screenPartsImages.WAIT_TIME_HEADER_MASK}
          alt=''
        />

        {/* The text background blob and the text itself. */}
        <div css={BLOB_TEXT_CONTAINER} aria-hidden>
          <img
            css={BLOB}
            src={screenPartsImages.WAIT_TIME_HEADER_BLOB}
            alt=''
          />
          <AttributedText css={TEXT} text={timeText} />
        </div>
      </div>
    </header>
  );
};

export default WaitTimeHeaderPart;
