/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';
import useOverlay from '../hooks/useOverlay';
import Clickable from '../components/clickable';
import { IBaseOverlay } from '../contexts/overlayContext';
import useTheme from '../hooks/useTheme';
import RemoteImage, { IRemoteImage } from '../components/RemoteImage';

export interface IIconAlertOverlay extends IBaseOverlay {
  title?: string;
  message?: string;
  image?: IRemoteImage;
  orientation: 'horizontal' | 'vertical';

  items: Array<{
    title: string;
    style: 'normal' | 'close' | 'link' | 'cancel';
    onClick: () => void;
  }>;
}

const IconAlertOverlay = (props: IIconAlertOverlay) => {
  const overlay = useOverlay();
  const { font, color } = useTheme();

  const DIALOG_STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    backgroundColor: color.LOCAL_WHITE,
    padding: 20,
    borderRadius: 7,
  };

  const CANCEL_BUTTON_STYLE: CSSObject = {
    backgroundColor: color.LOCAL_WHITE,
    color: color.ALERT,
    height: 32,
    marginTop: 0,
    '&[active]': {
      backgroundColor: color.LOCAL_WHITE,
    },
  };

  const CLOSE_BUTTON_STYLE: CSSObject = {
    backgroundColor: color.LOCAL_WHITE,
    color: color.PRIMARY,
    height: 32,
    marginTop: 0,
    '&[active]': {
      backgroundColor: color.LOCAL_WHITE,
    },
  };

  return (
    <div css={DIALOG_STYLE}>
      {/* Image, title and message */}
      <div css={{ textAlign: 'center' }}>
        {/* Image */}
        {props.image && (
          <RemoteImage
            {...props.image}
            css={{
              height: 72,
              marginTop: -56,
              marginBottom: 2,
            }}
          />
        )}

        {/* Title */}
        <h2 css={{ fontFamily: font.FAMILY_BOLD, fontSize: 20 }}>
          {props.title}
        </h2>

        {/* Message */}
        {!!props.message && (
          <div
            css={{
              marginTop: 8,
              overflowWrap: 'break-word',
            }}
          >
            {props.message}
          </div>
        )}
      </div>

      {/* Buttons */}
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: props.orientation === 'horizontal' ? 'row' : 'column',
          margin: '12px -8px -8px',
        }}
      >
        {props.items.map((item, i) => (
          <Clickable
            styleAs='button'
            key={i}
            autoFocus={i === 0}
            onClick={() => {
              overlay.dismiss();
              item.onClick();
            }}
            css={{
              margin: 8,
              textAlign: 'center',
              ...(item.style === 'cancel' && CANCEL_BUTTON_STYLE),
              ...(item.style === 'close' && CLOSE_BUTTON_STYLE),
            }}
          >
            {item.title}
          </Clickable>
        ))}
      </div>
    </div>
  );
};

export default IconAlertOverlay;
