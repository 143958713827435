import { useContext, useRef } from 'react';
import { loadStripe } from '@stripe/stripe-js/pure';
import { IAction, IBaseAction } from '../useAction';
import request from '../../utils/request';
import { FlexContext } from '../../contexts/flexContext';
import useOverlay from '../useOverlay';
import useLanguage from '../useLanguage';
import { IFlexNavigateAction } from './useFlexNavigateAction';
import useFlexNavigation from '../flex/useFlexNavigation';

export interface IPaymentStripePayAction extends IBaseAction {
  type: 'payment_stripe_pi_pay';

  data: {
    centesimalValue: number;
    currency: string;
    publishableKey: string;
    defaultPaymentMethodId?: string;
    adjustPurchase: {
      revenue: string;
      currency: string;
    };
    relayPayload: object;
    attemptUrl: string;
  };
}

interface StripeAttemptResult {
  paymentIntentId: string;
  intentStatus:
    | 'succeeded'
    | 'requires_action'
    | 'requires_capture'
    | 'requires_confirmation'
    | 'canceled';
  clientSecret: string;
  confirmRequiredActionUrl: string;
}

const usePaymentStripePayAction = () => {
  const flexContext = useContext(FlexContext);
  const overlay = useOverlay();
  const language = useLanguage();
  const initialHistoryLengthRef = useRef(window.history.length);

  const handleFlexNavigation = useFlexNavigation();

  const handlePaymentStripePayAction = async (
    action: IPaymentStripePayAction
  ): Promise<IAction> => {
    const stripe = await loadStripe(action.data.publishableKey);

    const attemptPayment = (
      paymentMethodId: string,
      shouldSaveCard: boolean
    ) => {
      return new Promise<IFlexNavigateAction>((resolve, reject) => {
        const handleCompletionNavigation = () => {
          if (flexContext.node?.type === 'payment') {
            handleFlexNavigation(flexContext.node.completionNavigation);
          }
        };

        const confirmIntent = (confirmUrl: string, intentId: string) => {
          request<StripeAttemptResult>(confirmUrl, {
            method: 'POST',
            body: {
              intent_id: intentId,
            },
          })
            .then((confirmIntentResult) => {
              switch (confirmIntentResult.intentStatus) {
                case 'succeeded':
                case 'requires_capture':
                  handleCompletionNavigation();
                  break;

                case 'canceled':
                  // Note: This message is hard coded in the apps as well.
                  overlay.presentBasicAlert({
                    message: 'Payment cancelled',
                  });
                  break;

                default:
                  // Note: This message is hard coded in the apps as well.
                  overlay.presentBasicAlert({
                    message: 'Cannot confirm payment intent',
                  });
                  break;
              }
            })
            .catch((error) => reject(error));
        };

        const retrievePaymentIntent = (
          clientSecret: string,
          confirmUrl: string
        ) => {
          const on3DSComplete = (e: MessageEvent) => {
            if (e.data === '3ds-authentication-complete') {
              window.removeEventListener('message', on3DSComplete);

              const iframeHistoryLength =
                window.history.length - initialHistoryLengthRef.current;

              // Any navigation within the iframe also affects the main window browser history.
              // As the 3D Secure flow has it's own redirects, we need to negate them
              // before closing the overlay and destroying the iframe.
              if (iframeHistoryLength > 0) {
                window.history.go(iframeHistoryLength * -1);
              }

              setTimeout(() => {
                overlay.dismiss();
                retrievePaymentIntent(clientSecret, confirmUrl);
              }, 300);
            }
          };

          stripe
            ?.retrievePaymentIntent(clientSecret)
            .then(({ paymentIntent }) => {
              if (paymentIntent) {
                // Sigh. If the Stripe API version used predates 2019-02-11, 'requires_action'
                // appears as 'requires_source_action'. This of course does not match Stripe.js type definitions,
                // hence casting the status to string.
                switch (paymentIntent.status as string) {
                  case 'succeeded':
                  case 'requires_capture':
                    handleCompletionNavigation();
                    break;

                  case 'requires_confirmation':
                    confirmIntent(confirmUrl, paymentIntent.id);
                    break;

                  case 'requires_source_action':
                    const url =
                      paymentIntent?.next_action?.redirect_to_url?.url;

                    if (url) {
                      window.addEventListener('message', on3DSComplete);

                      overlay.presentIFrame({
                        urlSource: url,
                        closeButtonLabel: language.get('cancel'),
                        onDismiss: () => {
                          window.removeEventListener('message', on3DSComplete);
                        },
                      });
                    } else {
                      reject();
                    }
                    break;

                  case 'requires_source':
                    overlay.presentBasicAlert({
                      message: 'Payment cancelled',
                    });
                    break;
                  default:
                    reject();
                    break;
                }
              } else {
                reject();
              }
            });
        };

        request<StripeAttemptResult>(action.data.attemptUrl, {
          method: 'POST',
          body: {
            payment_method_id: paymentMethodId,
            save_card: shouldSaveCard,
            return_url: `${window.location.origin}/stripe-redirect.html`,
            payload: action.data.relayPayload,
          },
        })
          .then((attemptResult) => {
            switch (attemptResult.intentStatus) {
              case 'requires_confirmation':
                confirmIntent(
                  attemptResult.confirmRequiredActionUrl,
                  attemptResult.paymentIntentId
                );
                break;

              case 'requires_action':
                retrievePaymentIntent(
                  attemptResult.clientSecret,
                  attemptResult.confirmRequiredActionUrl
                );
                break;

              case 'requires_capture':
              case 'succeeded':
                handleCompletionNavigation();
                break;
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    };

    return new Promise((resolve, reject) => {
      if (action.data.defaultPaymentMethodId) {
        attemptPayment(action.data.defaultPaymentMethodId, false)
          .then((flexNavigationAction) => resolve(flexNavigationAction))
          .catch((error) => reject(error));
      } else if (stripe) {
        overlay.presentStripeCheckout({
          stripe,
          onError: (error) => {
            reject(error);
          },
          onPaymentMethod: (paymentMethod, shouldSaveCard) => {
            attemptPayment(paymentMethod.id, shouldSaveCard)
              .then((flexNavigationAction) => resolve(flexNavigationAction))
              .catch((error) => reject(error));
          },
        });
      }
    });
  };

  return handlePaymentStripePayAction;
};

export default usePaymentStripePayAction;
