/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';
import useStatefulInput, {
  IInputValidator,
} from '../../../hooks/useStatefulInput';
import { IBasePart, IMargins } from '../part';
import useTheme from '../../../hooks/useTheme';
import PhoneInput from '../../../components/phoneInput';
import callingCodes from '../../../constants/callingCodes';
import environment from '../../../utils/environment';
import useAction, { IAction } from '../../../hooks/useAction';
import InputValidationError from '../components/inputValidationError';

export interface IPhoneInputPart extends IBasePart {
  type: 'phone_input';

  inputId: string;

  countryCode?: string;
  phoneNumber?: string;
  label?: string;
  placeholder?: string;
  validators?: Array<IInputValidator>;

  returnKeyType?: string;
  returnKeyAction?: IAction;

  inactiveLineColor: string;
  activeLineColor: string;

  margins: IMargins;
}

export interface IPhoneInputValue {
  countryCode: string;
  phoneNumber: string;
}

const PhoneInputPart = (data: IPhoneInputPart) => {
  const {
    inputValue,
    isValid,
    isDirty,
    isTouched,
    errorMessage,
    setInputValue,
    setIsTouched,
  } = useStatefulInput<IPhoneInputValue>({
    inputId: data.inputId,
    initialValue: {
      // If no default country code is provided,
      // find the code for the current market, else fallback to 00.
      countryCode:
        data.countryCode ||
        callingCodes.find((entry) => entry.country === environment.COUNTRY)
          ?.code ||
        '00',
      phoneNumber: data.phoneNumber || '',
    },
    validators: data.validators,
  });

  const { color, resolveColor } = useTheme();
  const handleAction = useAction();

  const shouldShowErrorState = !isValid && isDirty && isTouched;

  const STYLE: CSSObject = {
    marginLeft: data.margins.leading,
    marginRight: data.margins.trailing,
    marginTop: data.margins.top,
    marginBottom: data.margins.bottom,
  };

  return (
    <div css={STYLE}>
      <PhoneInput
        label={data.label}
        phoneInputLabel={data.placeholder}
        countryCode={inputValue.countryCode}
        phoneNumber={inputValue.phoneNumber}
        activeLineColor={
          shouldShowErrorState
            ? color.ALERT
            : resolveColor(data.activeLineColor)
        }
        inactiveLineColor={
          shouldShowErrorState
            ? color.ALERT_SECONDARY_PRESS
            : resolveColor(data.inactiveLineColor)
        }
        returnKeyType={data.returnKeyType}
        onChange={(countryCode, phoneNumber) => {
          setInputValue({ countryCode, phoneNumber });
        }}
        onReturnKeyPress={() => {
          handleAction(data.returnKeyAction);
        }}
        onBlur={() => {
          setIsTouched();
        }}
        aria-invalid={shouldShowErrorState}
        aria-errormessage={`${data.inputId}-validation-error`}
      />

      {/* Validation error message */}
      {shouldShowErrorState && (
        <InputValidationError inputId={data.inputId} message={errorMessage} />
      )}
    </div>
  );
};

export default PhoneInputPart;
