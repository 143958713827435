import { useContext } from 'react';
import { ScreenContext } from '../../contexts/screenContext';
import { IBaseAction } from '../useAction';

export interface IReloadAction extends IBaseAction {
  type: 'reload';
}

const useReloadAction = () => {
  const screenContext = useContext(ScreenContext);

  const handleReloadAction = () => {
    screenContext.reloadScreen();
  };

  return handleReloadAction;
};

export default useReloadAction;
