import { decamelizeKeys } from 'humps';
import useNavigation from '../useNavigation';

import { IAction, IBaseAction } from '../useAction';
import request, { IRequestOptions } from '../../utils/request';

export interface IHttpAction extends IBaseAction {
  type: 'http';

  data: {
    method: IRequestOptions['method'];
    path: string;
    body?: object;
    completionType: 'reload' | 'finish' | 'relaunch' | 'action';
    newResource?: string;
    completionAction?: IAction;
  };
}

const useHttpAction = () => {
  const navigation = useNavigation();

  const handleHttpAction = (action: IHttpAction): Promise<IAction> => {
    return new Promise((resolve, reject) => {
      const method =
        action.data.method?.toUpperCase() as IRequestOptions['method'];

      request(action.data.path, {
        method,
        body:
          method !== 'GET'
            ? {
                ...(typeof action.data.body === 'object' && {
                  ...decamelizeKeys(action.data.body),
                }),
              }
            : undefined,
      })
        .then(() => {
          if (action.data.newResource) {
            // Resolve with a makeshift change action.
            resolve({
              type: 'change',
              data: { value: action.data.newResource },
            });
            return;
          }

          switch (action.data.completionType) {
            case 'reload':
              // Resolve with a makeshift reload action.
              resolve({ type: 'reload' });
              break;
            case 'finish':
              navigation.dismiss();
              break;
            case 'relaunch':
              resolve({
                type: 'relaunch',
              });
              break;
            case 'action':
              if (action.data.completionAction) {
                resolve(action.data.completionAction);
              }
              break;
            default:
              break;
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return handleHttpAction;
};

export default useHttpAction;
