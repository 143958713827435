/** @jsx jsx */
import React, { useEffect, useMemo, useState } from 'react';
import { jsx, CSSObject } from '@emotion/core';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import useOverlay from '../hooks/useOverlay';
import { IBaseOverlay } from '../contexts/overlayContext';
import Clickable from '../components/clickable';
import useLanguage from '../hooks/useLanguage';
import layout from '../constants/layout';
import LocalImage from '../components/localImage';
import useTheme from '../hooks/useTheme';

export interface IImagePreviewOverlay extends IBaseOverlay {
  url: string;
  accessibilityLabel?: string;
}

const MAX_ZOOM = 8;

const ImagePreviewOverlay = ({
  url,
  accessibilityLabel,
}: IImagePreviewOverlay) => {
  const [container, setContainer] = useState<HTMLDivElement>();
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const [imageNaturalWidth, setImageNaturalWidth] = useState<number>(0);
  const [imageNaturalHeight, setImageNaturalHeight] = useState<number>(0);

  const overlay = useOverlay();
  const language = useLanguage();
  const { color } = useTheme();

  const imageScale = useMemo(() => {
    if (
      !containerWidth ||
      !containerHeight ||
      !imageNaturalWidth ||
      !imageNaturalHeight
    ) {
      return 0;
    }

    return Math.min(
      containerWidth / imageNaturalWidth,
      containerHeight / imageNaturalHeight
    );
  }, [containerWidth, containerHeight, imageNaturalWidth, imageNaturalHeight]);

  useEffect(() => {
    const handleResize = () => {
      if (container) {
        const rect = container.getBoundingClientRect();
        setContainerWidth(rect.width);
        setContainerHeight(rect.height);
      } else {
        setContainerWidth(0);
        setContainerHeight(0);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [container]);

  useEffect(() => {
    const image = new Image();

    image.src = url;
    image.onload = () => {
      setImageNaturalWidth(image.naturalWidth);
      setImageNaturalHeight(image.naturalHeight);
    };
  }, [url]);

  const BASE_STYLE: CSSObject = {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    backgroundColor: '#000',
    overflow: 'hidden',

    [`@media (min-width: ${layout.BREAKPOINT_MEDIUM}px)`]: {
      borderRadius: 7,
    },

    '.react-transform-wrapper': {
      cursor: 'grab',
    },
  };

  const CLOSE_BUTTON_STYLE: CSSObject = {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 20,
    right: 20,
    width: 44,
    height: 44,
    backgroundColor: color.ACTION_FLOATING_SOFT,
    borderRadius: '100%',

    img: {
      width: 14,
      height: 14,
    },
  };

  return (
    <div
      css={BASE_STYLE}
      ref={(element) => {
        if (element) {
          setContainer(element);
        }
      }}
    >
      {imageScale > 0 && (
        <TransformWrapper
          key={`${containerWidth}x${containerHeight}`}
          initialScale={imageScale}
          minScale={imageScale}
          maxScale={imageScale * MAX_ZOOM}
          centerOnInit
        >
          <TransformComponent
            wrapperStyle={{
              width: '100%',
              height: '100%',
            }}
          >
            <img src={url} alt={accessibilityLabel || ''} />
          </TransformComponent>
        </TransformWrapper>
      )}

      {/* Close button */}
      <Clickable
        scale
        onClick={() => {
          overlay.dismiss();
        }}
        css={CLOSE_BUTTON_STYLE}
      >
        <LocalImage
          src='APP_IMAGE_REMOVE_PHOTO'
          tint='ICON_INVERSE'
          alt={language.get('close')}
        />
      </Clickable>
    </div>
  );
};

export default ImagePreviewOverlay;
