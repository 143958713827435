/** @jsx jsx */
import React, { Fragment } from 'react';
import { jsx } from '@emotion/core';
import useTheme from '../../../../../../hooks/useTheme';
import useLanguage from '../../../../../../hooks/useLanguage';
import ImageUploader, {
  IImageUploader,
} from '../../../../../../components/imageUploader';
import Loader from '../../../../../../components/loader';
import LocalImage from '../../../../../../components/localImage';

interface ISurveyImageUploader extends IImageUploader<string> {
  taggable?: boolean;
}

const SurveyImageUploader = ({ taggable, ...props }: ISurveyImageUploader) => {
  const { color, font } = useTheme();
  const language = useLanguage();

  return (
    <ImageUploader
      endpointUrl='/api/meeting/symtoms/image'
      // Default state
      infoElementDefault={
        <Fragment>
          <LocalImage
            src='ICON32_CAMERA'
            tint='PRIMARY'
            css={{ width: 28, height: 23, marginBottom: 8 }}
            alt=''
          />
          <span
            css={{
              fontSize: 12,
              fontFamily: font.FAMILY_MEDIUM,
              color: color.PRIMARY,
              textAlign: 'center',
            }}
          >
            {language.get('add_photo')}
          </span>
        </Fragment>
      }
      // Progress state
      infoElementProgress={
        <Fragment>
          <div css={{ position: 'relative', width: 23, height: 23, margin: 5 }}>
            <Loader show size={23} stroke={3} transparent strokeColor='white' />
          </div>
        </Fragment>
      }
      // Upload failed state
      infoElementError={
        <Fragment>
          <LocalImage
            src='APP_IMAGE_WARNING_CIRCLE'
            alt=''
            css={{ width: 16, height: 16, marginBottom: 8 }}
          />
          <span
            css={{
              fontSize: 12,
              fontFamily: font.FAMILY_MEDIUM,
              color: color.LOCAL_WHITE,
              textAlign: 'center',
            }}
          >
            {language.get('photo_upload_sending_failed_retry')}
          </span>
        </Fragment>
      }
      // Styling
      height={taggable ? 90 : 100}
      width={taggable ? 90 : 100}
      marginBottom={taggable ? 0 : 20}
      marginRight={20}
      borderColor={color.DIVIDER_LINE}
      borderRadius={18}
      {...props}
    />
  );
};

export default SurveyImageUploader;
