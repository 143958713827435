/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';

import { getUnixTime, fromUnixTime } from 'date-fns';

import useStatefulInput, {
  IInputValidator,
} from '../../../../../hooks/useStatefulInput';
import { IRemoteImage } from '../../../../../components/RemoteImage';
import { IBlockBaseItem } from '../blockItem';
import DateTimePickerInput from '../../../../../components/dateTimePickerInput/dateTimePickerInput';
import useTheme from '../../../../../hooks/useTheme';
import InputValidationError from '../../../components/inputValidationError';

export interface IBlockDateTimePickerItem extends IBlockBaseItem {
  type: 'date_time_picker';

  inputId: string;
  label?: string;
  placeholder: string;

  dateTimeType: 'date' | 'time' | 'datetime';
  dateInputStyle?: 'os_default' | 'calendar' | 'numbers';
  format: string;

  value?: number;
  minValue?: number;
  maxValue?: number;
  defaultValue?: number;

  inactiveLineColor?: string;
  activeLineColor?: string;
  expandIcon?: IRemoteImage;
  validators?: Array<IInputValidator>;
}

interface IFiveColumnDateTimePickerCellProps {
  data: IBlockDateTimePickerItem;
}

const BlockDateTimePickerItem = ({
  data,
  ...props
}: IFiveColumnDateTimePickerCellProps) => {
  const {
    inputValue,
    isValid,
    isDirty,
    isTouched,
    errorMessage,
    setInputValue,
    setIsTouched,
  } = useStatefulInput<number | null>({
    inputId: data.inputId,
    initialValue: data.value || null,
    validators: data.validators,
  });

  const { color, resolveColor } = useTheme();

  const shouldShowErrorState = !isValid && isDirty && isTouched;

  const timestampToDate = (value: number) => {
    return fromUnixTime(value / 1000);
  };

  const handleOnChange = (dateTime: Date | null): void => {
    if (dateTime) {
      setInputValue(getUnixTime(dateTime) * 1000);
    } else {
      setInputValue(null);
    }
  };

  return (
    <div {...props}>
      <DateTimePickerInput
        type={data.dateTimeType}
        inputStyle={data.dateInputStyle}
        format={data.format}
        label={data.label}
        placeholder={data.placeholder}
        value={inputValue ? timestampToDate(inputValue) : undefined}
        defaultValue={
          data?.defaultValue ? timestampToDate(data.defaultValue) : undefined
        }
        minDate={data.minValue ? timestampToDate(data.minValue) : undefined}
        maxDate={data.maxValue ? timestampToDate(data.maxValue) : undefined}
        activeLineColor={
          shouldShowErrorState
            ? color.ALERT
            : resolveColor(data.activeLineColor)
        }
        inactiveLineColor={
          shouldShowErrorState
            ? color.ALERT_SECONDARY_PRESS
            : resolveColor(data.inactiveLineColor)
        }
        expandIcon={data.expandIcon}
        onChange={handleOnChange}
        aria-invalid={shouldShowErrorState}
        aria-errormessage={`${data.inputId}-validation-error`}
        onBlur={() => {
          setIsTouched();
        }}
      />

      {/* Validation error message */}
      {shouldShowErrorState && (
        <InputValidationError inputId={data.inputId} message={errorMessage} />
      )}
    </div>
  );
};

export default BlockDateTimePickerItem;
