/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import useTheme from '../../../../../hooks/useTheme';
import Clickable from '../../../../../components/clickable';
import LocalImage from '../../../../../components/localImage';
import layout from '../../../../../constants/layout';

export interface IMapSearchBar {
  placeholder: string;
  onClick?: () => void;
}

const MapSearchBar = ({ placeholder, onClick }: IMapSearchBar) => {
  const { color } = useTheme();

  return (
    <Clickable
      styleAs='card'
      css={{
        display: 'flex',
        alignItems: 'center',
        height: 36,
        position: 'relative',
        margin: '20px 20px -56px',
        color: color.TEXT_SOFT,
        boxSizing: 'border-box',
        zIndex: layout.BASIC_ZINDEX,
      }}
      onClick={onClick}
    >
      {/* Search icon */}
      <LocalImage
        src='SEARCHBAR_ICON'
        tint='TEXT'
        alt=''
        css={{
          margin: '0 10px',
        }}
      />

      {/* Placeholder text */}
      <div>{placeholder}</div>
    </Clickable>
  );
};
export default MapSearchBar;
