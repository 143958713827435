/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';

import { IBasePart } from '../part';
import CardItem, { ICardItem } from './card/cardItem';
import Card from '../../../components/card';
import useStatefulPart from '../../../hooks/useStatefulPart';
import useTheme from '../../../hooks/useTheme';

export interface ICardPart extends IBasePart {
  type: 'card';

  items: Array<ICardItem<any>>;
  style: 'elevated' | 'plain';
  cardColor: string;
  cornerRadius: number;

  initialClientState: {
    hiddenItemIds: Array<string>;
  };

  margins: {
    top: number;
    bottom: number;
    leading: number;
    trailing: number;
  };
}

const CardPart = (data: ICardPart) => {
  const { resolveColor } = useTheme();

  const [state] = useStatefulPart<Array<string>>(
    data.id,
    data.initialClientState.hiddenItemIds
  );

  const STYLE: CSSObject = {
    marginTop: data.margins.top,
    marginBottom: data.margins.bottom,
    marginLeft: data.margins.leading,
    marginRight: data.margins.trailing,
    backgroundColor: resolveColor(data.cardColor),
    borderRadius: data.cornerRadius,
  };

  return (
    <Card css={STYLE} plain={data.style === 'plain'}>
      {data.items.map((item, i) => (
        <CardItem key={i} data={item} hidden={state.includes(item.id)} />
      ))}
    </Card>
  );
};

export default CardPart;
