export function dataURItoBlob(dataURI: string): Blob {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
}

export function resizeImage(
  imageURI: string,
  maxWidthOrHeight: number
): Promise<string> {
  return new Promise((resolve) => {
    const img = document.createElement('img');

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const ratio = img.height / img.width;

      const targetWidth =
        ratio <= 1 ? maxWidthOrHeight : Math.round(maxWidthOrHeight / ratio);

      const targetHeight =
        ratio <= 1 ? Math.round(maxWidthOrHeight * ratio) : maxWidthOrHeight;

      canvas.width = targetWidth;
      canvas.height = targetHeight;

      ctx?.drawImage(img, 0, 0, targetWidth, targetHeight);
      resolve(canvas.toDataURL('image/jpeg'));
    };

    img.src = imageURI;
  });
}
