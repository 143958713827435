/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';

import AttributedText from '../../../components/attributedText';
import { IBasePart } from '../part';
import { IAction } from '../../../hooks/useAction';
import Clickable from '../../../components/clickable';
import RemoteImage, { IRemoteImage } from '../../../components/RemoteImage';

export interface IActionableImageAttributedLabelPart extends IBasePart {
  type: 'actionable_image_attributed_label';

  layoutMode: 'aligned_trailing' | 'inline';
  attributedText: string;

  image: IRemoteImage;
  imageHeight: number;
  imageWidth: number;

  topMargin: number;
  bottomMargin: number;
  leadingMargin: number;
  trailingMargin: number;

  action: IAction;
}

const ActionableImageAttributedLabelPart = (
  data: IActionableImageAttributedLabelPart
) => {
  const STYLE: CSSObject = {
    display: 'flex',
    alignItems: 'center',
    justifyContent:
      data.layoutMode === 'inline' ? 'flex-start' : 'space-between',
    paddingLeft: data.leadingMargin,
    paddingRight: data.trailingMargin,
    paddingTop: data.topMargin,
    paddingBottom: data.bottomMargin,
  };

  const IMAGE_STYLE: CSSObject = {
    width: data.imageWidth,
    height: data.imageHeight,
    marginLeft: 16,
  };

  return (
    <Clickable css={STYLE} action={data.action}>
      <AttributedText text={data.attributedText} />
      {data.image.name && <RemoteImage {...data.image} css={IMAGE_STYLE} />}
    </Clickable>
  );
};

export default ActionableImageAttributedLabelPart;
