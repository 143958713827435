/** @jsx jsx */
import React, { useEffect, useRef } from 'react';
import { jsx, CSSObject } from '@emotion/core';

import useLanguage from '../hooks/useLanguage';
import Loader from '../components/loader';
import useTheme from '../hooks/useTheme';
import LocalImage from '../components/localImage';
import { IBaseOverlay } from '../contexts/overlayContext';

export interface IBankIdOverlay extends IBaseOverlay {
  role: 'login' | 'sign';
  startUrl?: string;
  qrCode?: string;
}

const BankIdOverlay = ({ role, startUrl, qrCode }: IBankIdOverlay) => {
  const { color, font } = useTheme();
  const language = useLanguage();

  const svgContainerRef: React.RefObject<HTMLDivElement> = useRef(null);

  const BASE_STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    alignItems: 'center',
    textAlign: 'center',
  };

  useEffect(() => {
    if (qrCode) {
      const decodedSvg = atob(qrCode);
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(decodedSvg, 'image/svg+xml');
      const svgElement = svgDoc.documentElement;

      if (svgContainerRef.current) {
        svgContainerRef.current.innerHTML = '';
        svgContainerRef.current.appendChild(svgElement);
      }
    }
  }, [qrCode]);

  return (
    <div css={BASE_STYLE}>
      {/* BankID logo */}
      {!startUrl && (
        <LocalImage
          src='BANKID_MONO'
          css={{ height: 80, width: 80, padding: 20 }}
          alt=''
        />
      )}

      {/* QR code */}
      {startUrl && (
        <div
          css={{
            padding: 10,
            backgroundColor: color.LOCAL_WHITE,
          }}
        >
          {qrCode ? (
            <div ref={svgContainerRef} />
          ) : (
            <div>{language.get('patient_web.bankid_login.noQrcode')}</div>
          )}
        </div>
      )}

      {/* Instructions on starting the BankID app on this device. */}
      {!startUrl && (
        <div css={{ display: 'flex', alignItems: 'center', marginTop: 4 }}>
          {/* Loader */}
          <div css={{ position: 'relative', width: 28, height: 28 }}>
            <Loader
              transparent
              strokeColor={color.LOCAL_WHITE}
              show
              size={28}
              stroke={2}
            />
          </div>

          {/* Text */}
          <div
            css={{
              width: 'auto',
              textAlign: 'left',
              fontSize: 17,
              marginLeft: 18,
              color: color.LOCAL_WHITE,
            }}
          >
            {language.get(
              role === 'login'
                ? 'patient_web.bankid_login.bankid_pending'
                : 'patient_web.bankid_login.bankid_pending'
            )}
          </div>
        </div>
      )}

      {/* Instructions on starting the BankID app on another device. */}
      {startUrl && (
        <div
          css={{
            width: 'auto',
            textAlign: 'left',
            padding: 20,
            fontSize: 17,
            marginTop: 20,
            color: color.LOCAL_WHITE,
          }}
        >
          {/* Header */}
          <div css={{ fontFamily: font.FAMILY_BOLD, marginBottom: 12 }}>
            {language.get(
              'patient_web.bankid_login.another_device.instructions_header'
            )}
          </div>

          {/* Step-by-step list */}
          <ol
            css={{
              marginLeft: 18,

              li: {
                marginBottom: 8,
                paddingLeft: 8,
              },
            }}
          >
            <li>
              {language.get(
                'patient_web.bankid_login.another_device.instructions_step_1'
              )}
            </li>
            <li>
              {language.get(
                'patient_web.bankid_login.another_device.instructions_step_2'
              )}
            </li>
            <li>
              {language.get(
                'patient_web.bankid_login.another_device.instructions_step_3'
              )}
            </li>
          </ol>
        </div>
      )}
    </div>
  );
};

export default BankIdOverlay;
