/** @jsx jsx */
import React, { ReactNode } from 'react';
import { jsx, CSSObject, keyframes } from '@emotion/core';
import Modal from 'react-modal';
import layout from '../constants/layout';
import FullHeightDiv from '../components/fullHeightDiv';

Modal.setAppElement('#root');

interface IOverlay {
  open: boolean;
  dismissible?: boolean;
  fullscreenOnMobile?: boolean;
  constrainWidth?: boolean;
  onRequestClose?: () => void;
  children: ReactNode;
}

const Overlay = ({
  open,
  dismissible,
  fullscreenOnMobile,
  constrainWidth,
  children,
  onRequestClose,
}: IOverlay) => {
  const fadeInBgAnimation = keyframes`
    0% {opacity: 0}
    100% {opacity: 1}
  `;

  const fadeInContentAnimation = keyframes`
    0% {transform: translateY(30px)}
    100% {transform: translateY(0)}
  `;

  const MODAL_CONTENT_STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0 auto',
    boxSizing: 'border-box',
    padding: fullscreenOnMobile ? 0 : layout.GUTTER_SIZE,
    maxWidth: fullscreenOnMobile ? 'unset' : layout.APP_MAX_WIDTH,
    animation: `${fadeInContentAnimation} 0.2s`,

    [`@media (min-width: ${layout.BREAKPOINT_MEDIUM}px)`]: {
      padding: layout.GUTTER_SIZE,
      maxWidth: constrainWidth ? layout.APP_MAX_WIDTH : 'unset',
      borderRadius: 7,
    },

    '> div': {
      pointerEvents: 'auto',
    },
  };

  return (
    <Modal
      isOpen={open}
      shouldCloseOnEsc={!!dismissible}
      shouldCloseOnOverlayClick={!!dismissible}
      onRequestClose={onRequestClose}
      className='modal-content'
      style={{
        overlay: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.9)',
          animationName: fadeInBgAnimation.name,
          animationDuration: '0.15s',
          zIndex: layout.MODAL_ZINDEX,
        },
        content: {
          pointerEvents: 'none',
        },
      }}
    >
      <FullHeightDiv css={MODAL_CONTENT_STYLE}>{children}</FullHeightDiv>
    </Modal>
  );
};

export default Overlay;
