/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import { jsx } from '@emotion/core';
import request from '../utils/request';
import Clickable from './clickable';
import useTheme from '../hooks/useTheme';
import { dataURItoBlob, resizeImage } from '../utils/imageUtils';
import DropTarget from './dropTarget';
import useLanguage from '../hooks/useLanguage';
import LocalImage from './localImage';

export interface IImageUploader<T> {
  endpointUrl?: string;
  endpointBody?: object;

  presetThumbnail?: string;
  hideClearButton?: boolean;

  infoElementDefault?: JSX.Element;
  infoElementProgress?: JSX.Element;
  infoElementError?: JSX.Element;

  height?: number;
  width?: number;
  marginRight?: number;
  marginBottom?: number;
  borderRadius?: number;
  borderColor?: string;

  onImageUploaded?: (response: T, thumbnail: string) => void;
  onImageCleared?: () => void;
  onPreviewRequested?: () => void;
  onBlur?: () => void;
}

type IUploaderStatus = 'DEFAULT' | 'PROGRESS' | 'DONE' | 'ERROR';

const ImageUploader = <T,>({
  endpointUrl,
  endpointBody,
  presetThumbnail,
  hideClearButton,
  infoElementDefault,
  infoElementProgress,
  infoElementError,

  height,
  width,
  marginRight,
  marginBottom,
  borderRadius,
  borderColor,

  onImageUploaded,
  onImageCleared,
  onPreviewRequested,
  onBlur,
}: IImageUploader<T>) => {
  const [status, setStatus] = useState<IUploaderStatus>('DEFAULT');
  const [selectedFile, setSelectedFile] = useState<File>();
  const [thumbnailUri, setThumbnailUri] = useState(presetThumbnail);

  const { color } = useTheme();
  const language = useLanguage();

  const uploadImage = (imageURI: string, thumbnailURI: string) => {
    const formData = new FormData();
    formData.append('file', dataURItoBlob(imageURI));

    if (endpointBody) {
      formData.append('context', JSON.stringify(endpointBody));
    }

    if (endpointUrl) {
      request<T>(endpointUrl, {
        method: 'POST',
        formData,
        silent: true,
      })
        .then((response) => {
          setStatus('DONE');
          setSelectedFile(undefined);
          if (onImageUploaded) {
            onImageUploaded(response, thumbnailURI);
          }
        })
        .catch(() => {
          setStatus('ERROR');
        });
    }
  };

  const resizeAndUpload = (file: File) => {
    setSelectedFile(file);
    setStatus('PROGRESS');

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      if (reader.result) {
        const imgData = reader.result as string;

        // Generate a thumbnail.
        resizeImage(imgData, 100).then((thumbnailURI) => {
          setThumbnailUri(thumbnailURI);

          // Generate the scaled-down version of the image and attempt upload.
          resizeImage(imgData, 2880).then((finalImageURI) => {
            uploadImage(finalImageURI, thumbnailURI);
          });
        });
      }
    };
  };

  const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];
    if (file) {
      resizeAndUpload(file);
    }
  };

  const handleFileDropped = (file: File) => {
    resizeAndUpload(file);
  };

  const handleRetryUpload = () => {
    if (selectedFile) {
      resizeAndUpload(selectedFile);
    }
  };

  const handleClearImage = () => {
    setStatus('DEFAULT');
    setThumbnailUri('');
    if (onImageCleared) {
      onImageCleared();
    }
  };

  useEffect(() => {
    setStatus(presetThumbnail !== undefined ? 'DONE' : 'DEFAULT');
  }, [presetThumbnail]);

  return (
    <DropTarget
      active={status === 'DEFAULT'}
      onFileDropped={handleFileDropped}
      css={{
        position: 'relative',
        marginRight,
        marginBottom,
        borderRadius: borderRadius || 18,
      }}
    >
      <Clickable
        renderAs='label'
        scale
        defaultHandler={status === 'DEFAULT'}
        onClick={
          status === 'ERROR'
            ? handleRetryUpload
            : status === 'DONE'
            ? onPreviewRequested
            : undefined
        }
        css={{
          position: 'relative',
          display: 'block',
          width: width || 100,
          height: height || 100,
          boxSizing: 'border-box',
          border:
            status === 'DEFAULT'
              ? `1px solid ${borderColor || color.DIVIDER_LINE}`
              : 'none',
          borderRadius: borderRadius || 18,
          overflow: 'hidden',

          // Override the focus border as the default inset one is poorly visible
          // when an image is selected.
          ':focus': {
            boxShadow: `0px 0px 0px 3px ${color.LOCAL_FOCUS_BORDER}`,
          },
          ':focus:not([data-focus-visible-added])': {
            boxShadow: 'unset',
          },
        }}
        onBlur={onBlur}
      >
        {/* Thumbnail image */}
        {(presetThumbnail || thumbnailUri) && (
          <div
            css={{
              position: 'absolute',
              overflow: 'hidden',
              // A hack to mask the blur edges. Seems to be the most reliable way.
              boxShadow:
                status !== 'DONE'
                  ? 'inset 0 0 200px rgb(0, 0, 0, 0.7)'
                  : 'unset',
            }}
          >
            <div
              css={{
                left: 0,
                top: 0,
                width: width || 100,
                height: height || 100,
                backgroundColor: color.SEARCH_FIELD_BG,
                backgroundImage: `url(${presetThumbnail || thumbnailUri})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                filter:
                  status !== 'DONE' ? 'blur(4px) brightness(40%)' : 'unset',
              }}
            />
          </div>
        )}

        {/* State info elements */}
        {(status !== 'DONE' || (status === 'DONE' && !hideClearButton)) && (
          <div
            role='button'
            aria-label={
              status === 'DONE' && !hideClearButton
                ? language.get('photo_upload_preview_photo')
                : ''
            }
            css={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              padding: 10,
              boxSizing: 'border-box',
            }}
          >
            {status === 'DEFAULT' && infoElementDefault}
            {status === 'PROGRESS' && infoElementProgress}
            {status === 'ERROR' && infoElementError}
          </div>
        )}

        {/* The hidden file input field */}
        {status === 'DEFAULT' && (
          <input
            type='file'
            accept='image/*'
            css={{ display: 'none' }}
            onChange={handleFileSelected}
          />
        )}
      </Clickable>

      {/* Clear button */}
      {(status === 'ERROR' || (status === 'DONE' && !hideClearButton)) && (
        <Clickable
          scale
          darken
          css={{
            display: 'flex',
            position: 'absolute',
            top: -10,
            right: -10,
            height: 32,
            width: 32,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: color.ALERT_SECONDARY,
            border: `2px solid ${color.LOCAL_WHITE}`,
            borderRadius: '50%',
          }}
          onClick={handleClearImage}
        >
          <LocalImage
            src='APP_IMAGE_REMOVE_PHOTO'
            tint='ALERT'
            alt={language.get('remove')}
            css={{
              width: 11,
              height: 11,
            }}
          />
        </Clickable>
      )}
    </DropTarget>
  );
};

export default ImageUploader;
