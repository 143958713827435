import { IAction, IBaseAction } from '../useAction';
import useOverlay from '../useOverlay';

export interface IActionListAction extends IBaseAction {
  type: 'action_list';

  data: {
    title?: string;
    message?: string;

    style: 'alert' | 'action_sheet';

    actions: Array<{
      title: string;
      isDestructive: boolean;
      action: IAction;
    }>;
  };
}

const useActionListAction = () => {
  const overlay = useOverlay();

  const handleActionListAction = (
    action: IActionListAction
  ): Promise<IAction> => {
    return new Promise((resolve) => {
      switch (action.data.style) {
        case 'alert':
          overlay.presentAlert({
            title: action.data.title,
            message: action.data.message,
            items: action.data.actions.map((buttonAction) => {
              return {
                title: buttonAction.title,
                isDestructive: buttonAction.isDestructive,
                onClick: () => {
                  resolve(buttonAction.action);
                },
              };
            }),
          });
          break;
        default:
          overlay.presentActionList({
            message: action.data.title,
            items: action.data.actions.map((dataAction) => {
              return {
                title: dataAction.title,
                isDestructive: dataAction.isDestructive,
                onClick: () => {
                  resolve(dataAction.action);
                },
              };
            }),
          });
          break;
      }
    });
  };

  return handleActionListAction;
};

export default useActionListAction;
