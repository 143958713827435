import { IBaseAction } from '../useAction';

export interface IWebAuthenticationAction extends IBaseAction {
  type: 'web_authentication';

  data: {
    url: string;
    ephemeralSession: boolean;
  };
}

const useWebAuthenticationAction = () => {
  const handleWebAuthenticationAction = ({
    data: { url },
  }: IWebAuthenticationAction) => {
    const isCriipto =
      url &&
      (url.startsWith('https://kry.criipto.id/') ||
        url.startsWith('https://kry-test.criipto.id/'));
    const height = isCriipto ? 618 : 500;

    window.open(url, '', `width=375,height=${height}`);
  };

  return handleWebAuthenticationAction;
};

export default useWebAuthenticationAction;
