import { useContext } from 'react';

import { ScreenContext } from '../../contexts/screenContext';
import { IBaseAction } from '../useAction';

export interface IUpdateInputsAction extends IBaseAction {
  type: 'update_inputs';

  data: {
    inputsData: Array<{
      inputId: string;
      value: string;
      toggle: boolean;
    }>;
  };
}

const useUpdateInputsAction = () => {
  const screenContext = useContext(ScreenContext);

  const handleUpdateInputsAction = (action: IUpdateInputsAction) => {
    const state = { ...screenContext.inputStates };

    action.data.inputsData.forEach((input) => {
      if (input.toggle) {
        if (!state[input.inputId].value) {
          state[input.inputId] = {
            value: true,
            isValid: true,
            isDirty: true,
            isTouched: true,
          };
        } else {
          state[input.inputId] = {
            value: false,
            isValid: true,
            isDirty: true,
            isTouched: true,
          };
        }
      } else {
        state[input.inputId] = {
          value: input.value,
          isValid: true,
          isDirty: true,
          isTouched: true,
        };
      }
    });

    screenContext.setInputState(state);
  };

  return handleUpdateInputsAction;
};

export default useUpdateInputsAction;
