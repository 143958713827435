/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';

import { IBasePart } from '../part';
import useTheme from '../../../hooks/useTheme';

export interface IHeaderPart extends IBasePart {
  type: 'header';

  text: string;
  textColor: string;
  topMargin: number;
}

const HeaderPart = (data: IHeaderPart) => {
  const { resolveColor } = useTheme();

  const STYLE: CSSObject = {
    marginTop: data.topMargin,
    padding: '0 20px 4px',
    color: resolveColor(data.textColor),
    fontSize: '12px',
  };

  return <div css={STYLE}>{data.text}</div>;
};

export default HeaderPart;
