import 'focus-visible';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { AppContextProvider } from './contexts/appContext';
import { MeetingContextProvider } from './contexts/meetingContext';
import { OverlayContextProvider } from './contexts/overlayContext';
import { AppConfigContextProvider } from './contexts/appConfigContext';

import App from './app';

import currentPartner from './constants/partners';
import { ErrorBoundary } from './errorBoundary';
import { ViewContextProvider } from './contexts/viewContext';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
  <ErrorBoundary>
    <BrowserRouter basename={currentPartner?.basePath}>
      <AppConfigContextProvider>
        <ViewContextProvider>
          <OverlayContextProvider>
            <AppContextProvider>
              <MeetingContextProvider>
                <App />
              </MeetingContextProvider>
            </AppContextProvider>
          </OverlayContextProvider>
        </ViewContextProvider>
      </AppConfigContextProvider>
    </BrowserRouter>
  </ErrorBoundary>,

  document.getElementById('root')
);

serviceWorkerRegistration.register({
  bypassNodeEnvProduction: true,
});
