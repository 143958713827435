import React from 'react';
import { CSSObject } from '@emotion/core';
import GoogleMapReact from 'google-map-react';
import Supercluster from 'supercluster';

import environment from '../../utils/environment';
import apiKeys from '../../constants/apiKeys';
import MapUserLocationPin from './mapUserLocationPin';
import MapPinCluster from './mapPinCluster';
import MapPin, { IMapPinIcon } from './mapPin';
import { IFlexNavigation } from '../../hooks/flex/useFlexNavigation';
import { IRemoteImage } from '../RemoteImage';

interface IMapInitialPositionCentered {
  coordinates: IMapCoordinates;
  zoomLevel: number;
  type: 'centered_with_zoom_level';
}

interface IMapInitialPositionFit {
  coordinates: Array<IMapCoordinates>;
  type: 'fit_to_coordinates';
}

export type IMapInitialPosition =
  | IMapInitialPositionCentered
  | IMapInitialPositionFit;

export interface IMapPin {
  coordinates: IMapCoordinates;
  icon: IMapPinIcon;
  selectedIcon?: IMapPinIcon;
  selectable: boolean;

  details?: {
    attributedText: string;
    navigation?: IFlexNavigation;
  };
}

export interface IMapCoordinates {
  lat: number;
  lng: number;
}

export interface IMapCircularButton {
  backgroundColor: string;
  diameter: number;
  highlightColor: string; // contains alpha, which we do not support yet
  icon: IRemoteImage;
  iconSize: {
    height: number;
    width: number;
  };
}

// Typings for Supercluster are unfortunately all over the place,
// we don't store any custom data in the properties object,
// but need to type the properties set by the lib itself.
export interface IGeoJsonProperties extends Supercluster.AnyProps {
  cluster?: boolean;
  point_count?: number;
  onClick?: (
    mapPoint: Supercluster.PointFeature<IGeoJsonProperties>,
    pointId: number
  ) => void;
}

interface IMapConfig {
  defaultCenter?: GoogleMapReact.Coords;
  defaultZoom?: number;
  center: GoogleMapReact.Coords;
  zoom: number;
  onClick: () => void;
  onChange?: (value: GoogleMapReact.ChangeEventValue) => void;
  options: GoogleMapReact.MapOptions;
  onGoogleApiLoaded: (maps: { map: any; maps: any }) => void;
}

interface IMapContentCSS {
  cluster?: CSSObject;
  pin?: CSSObject;
}

interface IMapContent {
  userCoordinates?: IMapCoordinates;
  mapPoints: Array<Supercluster.PointFeature<IGeoJsonProperties>>;
  pins: Array<IMapPin>;
  selectedPinIndex?: number;
  setSelectedPinIndex?: (index: number) => void;
  css?: IMapContentCSS;
}

interface IMap {
  config: IMapConfig;
  content: IMapContent;
}

// Return map bounds based on list of pins coordinates
// Based on https://github.com/google-map-react/google-map-react-examples/blob/master/src/examples/Main.js#L14
export const getMapBoundsToFitPins = (maps: any, pins: Array<IMapPin>) => {
  const bounds = new maps.LatLngBounds();

  pins.forEach((pin) => {
    bounds.extend(new maps.LatLng(pin.coordinates.lat, pin.coordinates.lng));
  });
  return bounds;
};

const Map = (data: IMap) => {
  const {
    content: {
      userCoordinates,
      mapPoints,
      pins,
      selectedPinIndex,
      setSelectedPinIndex,
      css,
    },
    config,
  } = data;

  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: environment.IS_DEV ? '' : apiKeys.GOOGLE_MAPS,
      }}
      yesIWantToUseGoogleMapApiInternals
      {...config}
    >
      {/* User location marker */}
      {userCoordinates && (
        <MapUserLocationPin
          lat={userCoordinates.lat}
          lng={userCoordinates.lng}
        />
      )}

      {/* Map clusters and pins. */}
      {mapPoints.map((mapPoint) => {
        // The Supercluster id property is typed as "string | number".
        const pointId = mapPoint.id as number;

        // If the current point is a cluster, render the MapPinCluster component.
        if (mapPoint.properties.cluster) {
          return (
            <MapPinCluster
              key={pointId}
              lat={mapPoint.geometry.coordinates[1]}
              lng={mapPoint.geometry.coordinates[0]}
              count={mapPoint.properties.point_count}
              css={css?.cluster}
              onClick={() => {
                if (mapPoint.properties.onClick) {
                  mapPoint.properties.onClick(mapPoint, pointId);
                }
              }}
            />
          );
        }

        // If the current point is a pin, get the pin corresponding to the point id
        // and render the MapPin component.
        const pin = pins[pointId];
        return (
          <MapPin
            key={pointId}
            lat={pin.coordinates.lat}
            lng={pin.coordinates.lng}
            icon={pin.icon}
            selectedIcon={pin.selectedIcon}
            selected={pointId === selectedPinIndex}
            css={css?.pin}
            onClick={() => {
              if (pin.selectable && setSelectedPinIndex) {
                setSelectedPinIndex(pointId);
              }
            }}
          />
        );
      })}
    </GoogleMapReact>
  );
};

export default Map;
