/** @jsx jsx */
import React, { createRef, useEffect, useState } from 'react';
import { jsx, CSSObject } from '@emotion/core';

import { IBasePart } from '../part';
import { IAction } from '../../../hooks/useAction';
import AttributedText from '../../../components/attributedText';
import Card from '../../../components/card';
import RemoteImage, { IRemoteImage } from '../../../components/RemoteImage';
import Clickable from '../../../components/clickable';
import useTheme from '../../../hooks/useTheme';
import apiKeys from '../../../constants/apiKeys';
import layout from '../../../constants/layout';

export interface IMapSnapshotPart extends IBasePart {
  type: 'map_snapshot';

  attributedText?: string;

  visibleMapHeight: number;
  gradientHeight: number;

  lat: number;
  lng: number;
  coordinateDelta: number;

  pinImageVerticalOffset: number;
  pinImageName?: string;
  pinImage?: IRemoteImage;

  cornerRadius?: number;
  leadingMargin?: number;
  trailingMargin?: number;

  action?: IAction;
}

const MapSnapshotPart = (data: IMapSnapshotPart) => {
  const [componentWidth, setComponentWidth] = useState(0);
  const mapContainerRef = createRef<HTMLDivElement>();

  const { color } = useTheme();

  const mapWidth = componentWidth;
  const mapHeight = data.visibleMapHeight;
  const mapScale = window.devicePixelRatio;
  const mapStyle =
    'style=feature:poi%7Cvisibility:off&style=feature:road%7Celement:labels.icon%7Cvisibility:off&style=feature:transit%7Cvisibility:off';

  const STYLE: CSSObject = {
    position: 'relative',
    marginLeft: data.leadingMargin,
    marginRight: data.trailingMargin,
  };

  const MAP_STYLE: CSSObject = {
    position: 'relative',
    height: mapHeight,
    backgroundImage: componentWidth
      ? `url(https://maps.googleapis.com/maps/api/staticmap?center=${data.lat},${data.lng}&zoom=16&size=${mapWidth}x${mapHeight}&scale=${mapScale}&${mapStyle}&key=${apiKeys.GOOGLE_STATIC_MAPS})`
      : 'unset',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    borderTopLeftRadius: data.cornerRadius,
    borderTopRightRadius: data.cornerRadius,
    borderBottomLeftRadius: data.attributedText ? 0 : data.cornerRadius,
    borderBottomRightRadius: data.attributedText ? 0 : data.cornerRadius,
    backgroundColor: color.SEARCH_FIELD_BG,
  };

  const PIN_STYLE: CSSObject = {
    position: 'absolute',
    width: 49,
    left: '50%',
    top: '50%',
    transform: `translate(-50%, calc(-50% - ${data.pinImageVerticalOffset}px))`,
  };

  const GRADIENT_STYLE: CSSObject = {
    position: 'absolute',
    width: '100%',
    height: data.gradientHeight,
    bottom: 0,
    backgroundImage: `linear-gradient(rgba(255,255,255,0.001), #fff)`,
    boxSizing: 'border-box',
  };

  const CARD_STYLE: CSSObject = {
    position: 'relative',
    padding: 20,
    boxSizing: 'border-box',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: data.cornerRadius,
    borderBottomRightRadius: data.cornerRadius,
    zIndex: layout.BELOW_ALL_ZINDEX,
  };

  // Update the container width when it becomes available.
  useEffect(() => {
    setComponentWidth(mapContainerRef.current?.offsetWidth || 0);
  }, [mapContainerRef]);

  return (
    <Clickable action={data.action} css={STYLE}>
      {/* The map container and the pin */}
      <div css={MAP_STYLE} ref={mapContainerRef}>
        <RemoteImage {...data.pinImage} css={PIN_STYLE} />
      </div>

      {/* Bottom gradient */}
      <div css={GRADIENT_STYLE} />

      {/* Card */}
      {data.attributedText && (
        <Card css={CARD_STYLE}>
          <AttributedText text={data.attributedText} />
        </Card>
      )}
    </Clickable>
  );
};

export default MapSnapshotPart;
