import React, { useContext, Fragment } from 'react';
import { FlexContext } from '../../contexts/flexContext';
import FlexNode from './flexNode';
import { IRemoteImage } from '../../components/RemoteImage';
import { IFlexNavigation } from '../../hooks/flex/useFlexNavigation';

export interface IFlexProgressMap {
  segments: Array<{
    nodeId: string;
    from: number;
    to: number;
  }>;
}

export type IFlexTopRightButton = {
  navigation: IFlexNavigation;
  image?: IRemoteImage;
  title: string;
};

export interface IFlexStartConfig {
  navigation: IFlexNavigation;
  progressMap?: IFlexProgressMap;
  topRightButton?: IFlexTopRightButton;
}

const Flex = () => {
  const { node } = useContext(FlexContext);

  return <Fragment>{node && <FlexNode data={node} />}</Fragment>;
};

export default Flex;
