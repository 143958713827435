/** @jsx jsx */
import React from 'react';
import { CSSObject, jsx, keyframes } from '@emotion/core';
import RemoteImage, { IRemoteImage } from '../RemoteImage';

export interface IMapPinIcon {
  image: IRemoteImage;
  size: {
    width: number;
    height: number;
  };
  xOffset: number;
  yOffset: number;
}

interface IMapPin {
  lat: number;
  lng: number;
  icon: IMapPinIcon;
  selectedIcon?: IMapPinIcon;
  selected?: boolean;
  css?: CSSObject;

  onClick?: () => void;
}

const MapPin = ({ icon, selectedIcon, selected, css, onClick }: IMapPin) => {
  const pinIcon = selected ? selectedIcon || icon : icon;

  const fadeInAnimation = keyframes`
    0% {opacity: 0}
    100% {opacity: 1}
  `;

  return (
    <div
      onClick={(event) => {
        if (onClick) {
          event.preventDefault();
          event.stopPropagation();
          onClick();
        }
      }}
      css={{
        position: 'absolute',
        transform: `translate(calc(-50% + ${pinIcon.xOffset}px), calc(-100% + ${pinIcon.yOffset}px)) `,
        transformOrigin: 'bottom',
        width: pinIcon.size.width,
        height: pinIcon.size.height,
        cursor: 'pointer',
        animation: `${fadeInAnimation} 0.3s forwards`,
        ...css,
      }}
    >
      <RemoteImage
        {...pinIcon.image}
        css={{
          display: 'block',
          maxWidth: '100%',
          maxHeight: '100%',
          transform: `scale(${selected ? 1.4 : 1})`,
          transformOrigin: `${pinIcon.size.width / 2 - pinIcon.xOffset}px ${
            pinIcon.size.height - pinIcon.yOffset
          }px`,
          transition: 'transform 0.3s ease',
        }}
      />
    </div>
  );
};

export default MapPin;
