import { IBaseAction } from '../useAction';
import useNavigation from '../useNavigation';

export interface IBackAction extends IBaseAction {
  type: 'back';
}

const useBackAction = () => {
  const navigation = useNavigation();

  const handleBackAction = () => {
    navigation.pop();
  };

  return handleBackAction;
};

export default useBackAction;
