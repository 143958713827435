/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { motion } from 'framer-motion';
import useTheme from '../../../../../hooks/useTheme';
import useLanguage from '../../../../../hooks/useLanguage';
import Clickable from '../../../../../components/clickable';
import LocalImage from '../../../../../components/localImage';

interface IVideoControls {
  camDisabled: boolean;
  micDisabled: boolean;
  inFullscreen: boolean;

  onToggleCam: () => void;
  onToggleMic: () => void;
  onToggleFullscreen: () => void;
  onHangupClick: () => void;
  onFocus: () => void;
}

const VideoControls = ({
  camDisabled,
  micDisabled,
  inFullscreen,

  onToggleCam,
  onToggleMic,
  onToggleFullscreen,
  onHangupClick,
  onFocus,
}: IVideoControls) => {
  const { color } = useTheme();
  const language = useLanguage();

  return (
    <motion.div
      initial={{ opacity: 0, y: '200%' }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: '200%' }}
      transition={{ duration: 0.3 }}
      css={{
        display: 'flex',
        position: 'absolute',
        left: 0,
        bottom: 40,
        width: '100%',
        justifyContent: 'center',

        button: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '0 5px',
          width: 64,
          height: 64,
          borderRadius: '50%',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',

          boxShadow: '0px 12px 19px rgba(21, 61, 87, 0.2)',
          ':focus': {
            boxShadow: `0px 12px 19px rgba(21, 61, 87, 0.2), inset 0px 0px 0px 3px ${color.LOCAL_FOCUS_BORDER}`,
          },
          '&[active], :focus:not([data-focus-visible-added]):not([active])': {
            boxShadow: '0px 12px 19px rgba(21, 61, 87, 0.2)',
          },

          '&[aria-pressed="true"]': {
            backgroundColor: color.LOCAL_WHITE,
          },
        },
      }}
    >
      {/* Toggle camera button */}
      <Clickable
        scale
        aria-label={
          camDisabled
            ? language.get('meeting_room_camera_on')
            : language.get('meeting_room_camera_off')
        }
        onClick={onToggleCam}
        onFocus={onFocus}
      >
        <LocalImage
          src={
            camDisabled
              ? 'APP_IMAGE_MEETING_ENABLE_VIDEO'
              : 'APP_IMAGE_MEETING_DISABLE_VIDEO'
          }
        />
      </Clickable>

      {/* Toggle mic button */}
      <Clickable
        scale
        aria-label={
          micDisabled
            ? language.get('meeting_room_microphone_on')
            : language.get('meeting_room_microphone_off')
        }
        onClick={onToggleMic}
        onFocus={onFocus}
      >
        <LocalImage
          src={
            micDisabled
              ? 'APP_IMAGE_MEETING_ENABLE_MIC'
              : 'APP_IMAGE_MEETING_DISABLE_MIC'
          }
        />
      </Clickable>

      {/* Toggle fullscreen button */}
      {document.fullscreenEnabled && (
        <Clickable
          scale
          aria-label={
            inFullscreen
              ? language.get('meeting_room_full_screen_exit')
              : language.get('meeting_room_full_screen')
          }
          onClick={onToggleFullscreen}
          onFocus={onFocus}
        >
          <LocalImage
            src={inFullscreen ? 'FULLSCREEN_EXIT' : 'FULLSCREEN_ENTER'}
          />
        </Clickable>
      )}

      {/* Hang-up button */}
      <Clickable
        scale
        aria-label={language.get('meeting_room_hangup_confirmation_title')}
        css={{
          backgroundColor: `${color.ALERT} !important`,
        }}
        onClick={onHangupClick}
        onFocus={onFocus}
      >
        <LocalImage src='APP_IMAGE_MEETING_HANGUP' />
      </Clickable>
    </motion.div>
  );
};

export default VideoControls;
