/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';

import { getUnixTime, fromUnixTime } from 'date-fns';

import DateTimePickerInput from '../../../../../components/dateTimePickerInput/dateTimePickerInput';
import useStatefulInput from '../../../../../hooks/useStatefulInput';
import { IRemoteImage } from '../../../../../components/RemoteImage';

export interface IFiveColumnDateTimePickerCell {
  type: 'date_time_picker';
  inputId: string;

  label?: string;
  placeholder: string;

  dateTimeType: 'date' | 'time' | 'datetime';
  format: string;

  value?: number;
  minValue?: number;
  maxValue?: number;
  defaultValue?: number;

  inactiveLineColor?: string;
  activeLineColor?: string;
  expandIcon?: IRemoteImage;
}

interface IFiveColumnDateTimePickerCellProps {
  data: IFiveColumnDateTimePickerCell;
}

const FiveColumnDateTimePickerCell = ({
  data,
}: IFiveColumnDateTimePickerCellProps) => {
  const { inputValue, setInputValue } = useStatefulInput<number | null>({
    inputId: data.inputId,
    initialValue: data.value || null,
  });

  const timestampToDate = (value: number) => {
    return fromUnixTime(value / 1000);
  };

  const handleOnChange = (dateTime: Date | null): void => {
    if (dateTime) {
      setInputValue(getUnixTime(dateTime) * 1000);
    } else {
      setInputValue(null);
    }
  };

  return (
    <DateTimePickerInput
      type={data.dateTimeType}
      format={data.format}
      label={data.label}
      placeholder={data.placeholder}
      value={inputValue ? timestampToDate(inputValue) : undefined}
      minDate={data.minValue ? timestampToDate(data.minValue) : undefined}
      maxDate={data.maxValue ? timestampToDate(data.maxValue) : undefined}
      inactiveLineColor={data.inactiveLineColor}
      activeLineColor={data.activeLineColor}
      expandIcon={data.expandIcon}
      onChange={handleOnChange}
    />
  );
};

export default FiveColumnDateTimePickerCell;
