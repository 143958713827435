/** @jsx jsx */
import React, { useContext } from 'react';
import { jsx, CSSObject } from '@emotion/core';
import AttributedText from '../../../../../components/attributedText';
import { IAction } from '../../../../../hooks/useAction';
import RemoteImage, {
  IRemoteImage,
} from '../../../../../components/RemoteImage';
import useTheme from '../../../../../hooks/useTheme';
import { IBlockBaseItem } from '../blockItem';
import Clickable from '../../../../../components/clickable';
import { ScreenContext } from '../../../../../contexts/screenContext';

interface IButtonIcon {
  image: IRemoteImage;
  side: 'start' | 'end';

  size: {
    height: number;
    width: number;
  };
}

interface IButtonDisabledState {
  title: string;
  color: string;
  icon?: IButtonIcon;
  edgeIcon?: IButtonIcon;

  enabledByInputIds: Array<string>;
}

export interface IBlockButtonItem extends IBlockBaseItem {
  type: 'button';

  title: string;
  color: string;
  highlightColor: string;

  icon?: IButtonIcon;
  edgeIcon?: IButtonIcon;

  disabledState?: IButtonDisabledState;
  iconTitleGutter?: number;
  action?: IAction;
}

interface IBlockButtonItemProps {
  data: IBlockButtonItem;
}

const BlockButtonItem = ({ data, ...props }: IBlockButtonItemProps) => {
  const screenContext = useContext(ScreenContext);
  const { resolveColor } = useTheme();

  // The button is disabled if any of the inputs listed in "enabledByInputsIds" are invalid.
  const isDisabled = !!data.disabledState?.enabledByInputIds.find(
    (inputId) => !screenContext.inputStates[inputId]?.isValid
  );

  // Pick the button properties either from the root object or from disabledState,
  // depending on whether the button is enabled or not.
  const { title, color, icon, edgeIcon } =
    data.disabledState && isDisabled ? data.disabledState : data;

  const BUTTON_STYLE: CSSObject = {
    borderRadius: data.cornerRadius,
    backgroundColor: resolveColor(color),

    width: 'auto',
    height: 'auto',

    '&[active]': {
      backgroundColor: resolveColor(data.highlightColor),
    },
  };

  const ICON_STYLE: CSSObject = {
    width: icon?.size?.width,
    height: icon?.size?.height,
    order: icon?.side === 'end' ? 1 : 0,
    marginLeft: icon?.side === 'end' ? data.iconTitleGutter : 0,
    marginRight: icon?.side === 'start' ? data.iconTitleGutter : 0,
  };

  const EDGE_ICON_STYLE: CSSObject = {
    position: 'absolute',
    ...(edgeIcon?.side === 'end'
      ? { right: data.padding.trailing }
      : { left: data.padding.leading }),
    width: edgeIcon?.size?.width,
    height: edgeIcon?.size?.height,
  };

  return (
    <Clickable
      styleAs='button'
      action={data.action}
      disabled={isDisabled}
      css={BUTTON_STYLE}
      {...props}
    >
      {/* Regular icon */}
      <RemoteImage {...icon?.image} css={ICON_STYLE} />

      {/* Edge icon */}
      <RemoteImage {...edgeIcon?.image} css={EDGE_ICON_STYLE} />

      {/* Title text */}
      <AttributedText text={title} />
    </Clickable>
  );
};

export default BlockButtonItem;
