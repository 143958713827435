/** @jsx jsx */
import React, { Fragment } from 'react';
import { CSSObject, jsx } from '@emotion/core';

import useTheme from '../../../../hooks/useTheme';
import { IMargins } from '../../part';

import BlockAnimationItem, {
  IBlockAnimationItem,
} from './items/blockAnimationItem';
import BlockButtonItem, { IBlockButtonItem } from './items/blockButtonItem';
import BlockCheckboxItem, {
  IBlockCheckboxItem,
} from './items/blockCheckboxItem';
import BlockDateTimePickerItem, {
  IBlockDateTimePickerItem,
} from './items/blockDateTimePickerItem';
import BlockImageItem, { IBlockImageItem } from './items/blockImageItem';
import BlockInputValidationErrorItem, {
  IBlockInputValidationErrorItem,
} from './items/blockInputValidationErrorItem';
import BlockPdfItem, { IBlockPdfItem } from './items/blockPdfItem';
import BlockPickerItem, { IBlockPickerItem } from './items/blockPickerItem';
import BlockRadioButtonItem, {
  IBlockRadioButtonItem,
} from './items/blockRadioButtonItem';
import BlockShapeItem, { IBlockShapeItem } from './items/blockShapeItem';
import BlockStackContainerItem, {
  IBlockContainerOrientation,
  IBlockStackContainerItem,
} from './items/blockStackContainerItem';
import BlockTextItem, { IBlockTextItem } from './items/blockTextItem';
import BlockToggleItem, { IBlockToggleItem } from './items/blockToggleItem';

export type IBlockItem =
  | IBlockAnimationItem
  | IBlockButtonItem
  | IBlockCheckboxItem
  | IBlockDateTimePickerItem
  | IBlockImageItem
  | IBlockInputValidationErrorItem
  | IBlockPdfItem
  | IBlockPickerItem
  | IBlockRadioButtonItem
  | IBlockShapeItem
  | IBlockStackContainerItem
  | IBlockTextItem
  | IBlockToggleItem;

export const blockItems: Record<string, (data: any) => JSX.Element> = {
  animation: BlockAnimationItem,
  button: BlockButtonItem,
  checkbox: BlockCheckboxItem,
  date_time_picker: BlockDateTimePickerItem,
  image: BlockImageItem,
  input_validation_error: BlockInputValidationErrorItem,
  pdf: BlockPdfItem,
  picker: BlockPickerItem,
  radio_button: BlockRadioButtonItem,
  shape: BlockShapeItem,
  stack_container: BlockStackContainerItem,
  text: BlockTextItem,
  toggle: BlockToggleItem,
};

export interface IBlockBaseItem {
  horizontalLayout: IBlockLayout;
  verticalLayout: IBlockLayout;
  horizontalAlignment: IBlockAlignment;
  verticalAlignment: IBlockAlignment;
  margins: IMargins;
  padding: IMargins;
  bgColor: string;
  cornerRadius: string;

  accessibility?: IBlockAccessibility;

  // Appended locally.
  parentOrientation?: IBlockContainerOrientation;
}

export type IBlockAlignment = 'start' | 'center' | 'end';

export type IBlockLayout =
  | { type: 'stretch'; weight: number }
  | { type: 'hug'; minSize: number }
  | { type: 'fixed'; size: number };

export type IBlockAccessibility = {
  hideForAssistiveTechnology?: boolean;
  label?: string;
  descriptor?:
    | {
        type: 'heading';
        level: number;
      }
    | {
        type: 'paragraph';
      };
};

const BlockItem = (item: IBlockItem) => {
  const { resolveColor } = useTheme();

  const {
    type,
    padding,
    margins,
    horizontalLayout,
    verticalLayout,
    horizontalAlignment,
    verticalAlignment,
    parentOrientation,
    bgColor,
    cornerRadius,
  } = item;

  const blockFlexAlignment: Record<IBlockAlignment, string> = {
    start: 'flex-start',
    center: 'center',
    end: 'flex-end',
  };

  const ITEM_STYLE: CSSObject = {
    boxSizing: 'border-box',

    paddingTop: padding.top,
    paddingBottom: padding.bottom,
    paddingLeft: padding.leading,
    paddingRight: padding.trailing,
    marginTop: margins.top,
    marginBottom: margins.bottom,
    marginLeft: margins.leading,
    marginRight: margins.trailing,

    // Stretch layout within a horizontal parent.
    ...(parentOrientation === 'horizontal' && {
      alignSelf: blockFlexAlignment[verticalAlignment],

      // Vertical layout
      ...(verticalLayout.type === 'stretch' && {
        alignSelf: 'stretch',
      }),

      // Horizontal layout
      ...(horizontalLayout.type === 'stretch' && {
        flex: horizontalLayout.weight,
      }),
    }),

    // Stretch layout within a vertical parent.
    ...(parentOrientation === 'vertical' && {
      alignSelf: blockFlexAlignment[horizontalAlignment],

      // Vertical layout
      ...(verticalLayout.type === 'stretch' && {
        flex: verticalLayout.weight,
      }),

      // Horizontal layout
      ...(horizontalLayout.type === 'stretch' && {
        alignSelf: 'stretch',
      }),
    }),

    // Stretch layout within a parent.
    ...(!parentOrientation && {
      ...(verticalLayout.type === 'stretch' && { height: 'fill-available' }),
      ...(horizontalLayout.type === 'stretch' && {
        width: 'fill-available',
      }),
    }),

    // Vertical layout
    ...(verticalLayout.type === 'fixed' && { height: verticalLayout.size }),
    ...(verticalLayout.type === 'hug' && {
      minHeight: verticalLayout.minSize,
    }),

    // Horizontal layout
    ...(horizontalLayout.type === 'fixed' && {
      width: horizontalLayout.size,
    }),
    ...(horizontalLayout.type === 'hug' && {
      minWidth: horizontalLayout.minSize,
    }),

    backgroundColor: resolveColor(bgColor),
    borderRadius: cornerRadius,
  };

  const BlockItemComponent = type ? blockItems[type] : null;

  return (
    <Fragment>
      {BlockItemComponent && item && (
        <BlockItemComponent
          data={item}
          css={ITEM_STYLE}
          aria-label={item.accessibility?.label}
          aria-hidden={item.accessibility?.hideForAssistiveTechnology}
        />
      )}
    </Fragment>
  );
};

export default BlockItem;
