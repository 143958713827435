import React, {
  useEffect,
  useRef,
  createContext,
  useReducer,
  ReactNode,
  useCallback,
} from 'react';

import { flexContextReducer } from '../reducers/flexContextReducer';
import {
  IFlexStartConfig,
  IFlexProgressMap,
  IFlexTopRightButton,
} from '../views/flex/flex';
import { IFlexMemory, IFlexNode } from '../views/flex/flexNode';
import { IAction } from '../hooks/useAction';
import useSessionStorage from '../hooks/useSessionStorage';
import { IFlexHttpCall } from '../hooks/useFlexHttpCall';
import useHttpCall from '../hooks/useHttpCall';
import useNavigation from '../hooks/useNavigation';
import useFlexNavigation from '../hooks/flex/useFlexNavigation';

export interface IFlexState {
  node?: IFlexNode;
  progressMap?: IFlexProgressMap;
  progress?: number;
  topRightButton?: IFlexTopRightButton;

  nodeSequence: Array<string>;
}

export interface IFlexContext extends IFlexState {
  progress?: number;
  topRightButton?: IFlexTopRightButton;
  getState: () => IFlexState;

  prepareUnwind: (data: IUnwindData) => void;
  unwind: (data: IUnwindData, targetNode: IFlexNode) => void;
  finish: (urlToOpen?: string) => void;
}

export interface IFlexSource {
  call?: IFlexHttpCall;
  node?: IFlexNode;
}

export interface IUnwindData {
  destinationScreenAction?: IAction;
  destinationMemory?: IFlexMemory;

  destinationUpdate?: {
    node: IFlexNode;
  };
}

export const FlexContext = createContext({} as IFlexContext);

const initialState: IFlexState = {
  nodeSequence: [],
};

interface IFlexContextProvider {
  routeSource: IFlexSource;
  children: ReactNode;
}

export const FlexContextProvider = ({
  children,
  routeSource,
}: IFlexContextProvider) => {
  const [getCache, setCache] = useSessionStorage<IFlexState>('FLEX_CONTEXT', {
    locationAware: true,
  });
  const [state, dispatch] = useReducer(flexContextReducer, initialState);

  const navigation = useNavigation();
  const handleHttpCall = useHttpCall();
  const handleFlexNavigation = useFlexNavigation();

  const setCacheRef = useRef(setCache);
  const getCacheRef = useRef(getCache);
  const unwindDataRef = useRef<IUnwindData>();

  const getState = () => {
    return state;
  };

  const prepareUnwind = (data: IUnwindData) => {
    unwindDataRef.current = data;
  };

  const unwind = (data: IUnwindData, targetNode: IFlexNode) => {
    // Update the cached node memory or replace it completely with the provided one.
    // The node should only be replaced if the type and the id match.
    // The memory in the provided node should be discarded.
    const { destinationMemory, destinationScreenAction } = data;

    const newNode = data.destinationUpdate?.node;

    const finalNode =
      newNode?.type === targetNode?.type && newNode?.id === targetNode?.id
        ? newNode
        : targetNode;

    if (finalNode) {
      dispatch({
        type: 'SET_FLEX_NODE',
        payload: {
          unwind: true,
          node: {
            ...finalNode,
            memory: destinationMemory || targetNode?.memory,
            destinationScreenAction,
            nodeUpdatedAt: Date.now(),
          },
        },
      });
    }

    // Reset unwind data as it should only be used once.
    unwindDataRef.current = undefined;
  };

  const startFlex = useCallback(
    (call: IFlexHttpCall) => {
      // Reset the state, in case we're starting the new flex flow from another one.
      dispatch({
        type: 'SET_FLEX_STATE',
        payload: initialState,
      });

      // useFlexHttpCall depends on this context and cannot be used from here.
      // Starting a new Flex flow doesn't rely on pre-existing memory anyway.
      handleHttpCall<IFlexStartConfig>(call)
        .then((config) => {
          // Set the progress map and the top right button.
          dispatch({
            type: 'SET_FLEX_CONFIG',
            payload: config,
          });

          // Handle the navigation.
          handleFlexNavigation(config.navigation);
        })
        .catch(() => {
          // If fetching the FlexConfig fails, close the modal.
          navigation.dismiss();
        });
    },
    [handleHttpCall, handleFlexNavigation, navigation]
  );

  const finish = useCallback(
    (urlToOpen?: string) => {
      navigation.pop({
        internalUrl: urlToOpen,
        delta: state.nodeSequence?.length || 1,
      });
    },
    [state, navigation]
  );

  useEffect(() => {
    getCacheRef.current = getCache;
    setCacheRef.current = setCache;
  }, [getCache, setCache]);

  useEffect(() => {
    const cachedState = getCacheRef.current();

    // If we've navigated backwards and have a cached flex state, restore it.
    if (cachedState) {
      dispatch({
        type: 'SET_FLEX_STATE',
        payload: {
          ...cachedState,
        },
      });

      // Update the cached node if it was unwound to.
      if (unwindDataRef.current && cachedState.node) {
        unwind(unwindDataRef.current, cachedState.node);
      }

      return;
    }

    // Forward navigation to a new flex node.
    if (routeSource.node) {
      dispatch({
        type: 'SET_FLEX_NODE',
        payload: {
          node: routeSource.node,
        },
      });

      return;
    }

    // Initial flex start.
    if (routeSource.call) {
      startFlex(routeSource.call);
    }
  }, [routeSource, startFlex]);

  useEffect(() => {
    if (state.node) {
      setCacheRef.current(state);
    }
  }, [state]);

  return (
    <FlexContext.Provider
      value={{
        node: state.node,
        nodeSequence: state.nodeSequence,
        progress: state.progress,
        topRightButton: state.topRightButton,
        getState,
        prepareUnwind,
        unwind,
        finish,
      }}
    >
      {children}
    </FlexContext.Provider>
  );
};
