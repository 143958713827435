/** @jsx jsx */
import React, { Fragment, useContext, useEffect, useRef } from 'react';
import { jsx } from '@emotion/core';
import { ISurveyNode } from '../surveyNode';
import { SurveyContext } from '../../../../../../contexts/surveyContext';
import useTheme from '../../../../../../hooks/useTheme';

interface IInfotextSurveyNode {
  node: ISurveyNode;
}

const InfotextSurveyNode = ({ node }: IInfotextSurveyNode) => {
  const { color } = useTheme();
  const nodeVisistedAt = useRef(Date.now());
  const { registerAnswer } = useContext(SurveyContext);

  useEffect(() => {
    registerAnswer({
      nodeName: node.name,
      path: node.path,
      property: node.property,
      answeredAt: Date.now(),
      visitedAt: nodeVisistedAt.current,
    });
  }, [node, registerAnswer]);

  return (
    <Fragment>
      {/* Node title */}
      <div
        css={{
          backgroundColor: color.STATUS_BG_NOTICE,
          padding: 20,
          margin: 20,
          borderRadius: 4,
        }}
      >
        {node.title && (
          <h2
            css={{
              position: 'relative',
              fontSize: '20px',
              marginBottom: 20,
            }}
          >
            {node.title}
          </h2>
        )}

        {/* Node subtitle */}
        {node.subtitle && (
          <div
            css={{
              fontSize: '14px',
              lineHeight: '22px',
              whiteSpace: 'break-spaces',
            }}
          >
            {node.subtitle}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default InfotextSurveyNode;
