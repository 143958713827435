import { useContext } from 'react';
import { AppContext } from '../../contexts/appContext';
import { IBaseAction } from '../useAction';

export interface IRelaunchAction extends IBaseAction {
  type: 'relaunch';
}

const useRelaunchAction = () => {
  const { reinitializeApp } = useContext(AppContext);

  const handleRelaunchAction = () => {
    reinitializeApp();
  };

  return handleRelaunchAction;
};

export default useRelaunchAction;
