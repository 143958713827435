import React, { useContext } from 'react';
import SwedishBankIdLoginView from '../views/login/swedishBankId/swedishBankIdLoginView';
import apiKeys from '../constants/apiKeys';
import useLanguage from '../hooks/useLanguage';
import useOverlay from '../hooks/useOverlay';
import useRequestError from '../hooks/useRequestError';
import { attemptToLaunchBankId } from '../utils/bankid';
import request from '../utils/request';
import RequestError from '../utils/requestError';
import { AppContext } from './appContext';

interface IInitLoginData {
  passwordLogin: boolean;
  startUrl: string;
  bankIdSession: string;
  qrcode: string;
}

interface ILoginSessionData {
  sessionId: string;
  loginStatus?: 'not_logged_in' | 'logged_in';
  deviceId?: string;
  qrcode: string;
}

interface ISwedishBankIdLoginContext {
  login: (bankIdOnThisDevice: boolean) => void;
  demoAccountLogin: () => void;
}

export const SwedishBankIdLoginContext = React.createContext(
  {} as ISwedishBankIdLoginContext
);

export const SwedishBankIdLoginContextProvider = () => {
  const { saveSession } = useContext(AppContext);
  const language = useLanguage();
  const overlay = useOverlay();

  const handleRequestError = useRequestError();

  const initiateLogin = (personalNumber?: string): Promise<IInitLoginData> => {
    const url = '/api/view/login/se/start';

    return new Promise((resolve, reject) => {
      request<IInitLoginData>(url, {
        method: 'POST',
        body: {
          id_number: personalNumber,
        },
      })
        .then((initLoginData) => {
          resolve(initLoginData);
        })
        .catch((error) => reject(error));
    });
  };

  // The password authentication flow is debug only, used for demo accounts.
  const startPasswordAuthentication = (
    personalNumber?: string
  ): Promise<ILoginSessionData> => {
    return new Promise((resolve, reject) => {
      // Prompt the user for the password.
      const password = prompt(language.get('enter_password_message'));

      // Validate the password for the given personal number.
      const validatePassword = () => {
        return new Promise(
          (resolveValidatePassword, rejectValidatePassword) => {
            request('/api/view/login/se/password', {
              method: 'POST',
              body: {
                id_number: personalNumber,
                password,
              },
            })
              .then(() => resolveValidatePassword(password))
              .catch((error) => rejectValidatePassword(error));
          }
        );
      };

      // Ask for and validate the 2fa auth code.
      const validateAuthCode = () => {
        const authCode = prompt(language.get('enter_sms_code_message'));

        return request<ILoginSessionData>(
          '/api/view/login/se/password/auth-code',
          {
            method: 'POST',
            body: {
              id_number: personalNumber,
              public_key: apiKeys.KRY_PUB,
              auth_code: authCode,
              password,
            },
          }
        );
      };

      validatePassword()
        .then(() => validateAuthCode())
        .then((session) => resolve(session))
        .catch((error) => reject(error));
    });
  };

  const startBankIdAuthentication = (
    initLoginData: IInitLoginData,
    withThisDevice: boolean
  ): Promise<ILoginSessionData> => {
    // Present BankID login overlay.
    overlay.presentBankId({
      role: 'login',
      startUrl: withThisDevice ? undefined : initLoginData.startUrl,
      qrCode: initLoginData.qrcode,
    });

    // Try launching the BankID app on supported devices.
    if (withThisDevice) {
      attemptToLaunchBankId(initLoginData.startUrl, true);
    }

    return new Promise((resolve, reject) => {
      const pollStatus = () => {
        request<ILoginSessionData>('/api/view/login/se/complete', {
          method: 'POST',
          body: {
            bank_id_session: initLoginData.bankIdSession,
            public_key: apiKeys.KRY_PUB,
          },
          silent: true,
          retryable: true,
        })
          .then((res) => {
            const freshQrCode = res.qrcode;

            if (freshQrCode) {
              // BankID login overlay with refreshing QR code.
              overlay.presentBankId({
                role: 'login',
                startUrl: withThisDevice ? undefined : initLoginData.startUrl,
                qrCode: freshQrCode,
              });
            }

            if (res.loginStatus === 'logged_in' && res.sessionId) {
              resolve(res);
            } else {
              setTimeout(() => {
                pollStatus();
              }, 2000);
            }
          })
          .catch((error) => reject(error));
      };

      pollStatus();
    });
  };

  const login = (withThisDevice: boolean) => {
    initiateLogin()
      .then((initLoginData) =>
        startBankIdAuthentication(initLoginData, withThisDevice)
      )
      .then((session) => {
        // Save session and reinitialize app.
        saveSession(session.sessionId, session.deviceId || '', true);
      })
      .catch((error: RequestError) => {
        if (error.body === 'user_cancelled') {
          overlay.presentBasicAlert({
            title: language.get('error'),
            message: language.get('cancelled_login_message'),
          });
        } else {
          handleRequestError(error);
        }
      });
  };

  const demoAccountLogin = () => {
    // Present a personal number prompt, used for demo accounts.
    const personalNumber =
      prompt(language.get('app_login_se.bankid_external_primary')) || '';

    if (!personalNumber) {
      return;
    }

    initiateLogin(personalNumber)
      .then((initLoginData) => {
        if (initLoginData.passwordLogin) {
          startPasswordAuthentication(personalNumber).then((session) => {
            // Save session and reinitialize app.
            saveSession(session.sessionId, session.deviceId || '', true);
          });
        } else {
          overlay.presentBasicAlert({
            title: 'Login failed',
            message: 'This is not a demo account',
          });
        }
      })
      .catch((error: RequestError) => {
        handleRequestError(error);
      });
  };

  return (
    <SwedishBankIdLoginContext.Provider
      value={{
        login,
        demoAccountLogin,
      }}
    >
      <SwedishBankIdLoginView />
    </SwedishBankIdLoginContext.Provider>
  );
};
