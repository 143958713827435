import { hexToRgba } from './hexToRgba';

// Reference to the div containing all the dynamically created filters.
const filterContainerEl = document.getElementById('svg-filters');

// Keeps track of the filters already created, so that we don't have to
// scrape the DOM every time, and reuse them.
const filters: Array<string> = [];

export const colorFilter = (hexColor?: string) => {
  // If no color is provided, return no filter.
  if (!hexColor) {
    return 'unset';
  }

  // If the filter for the requested color already exists, just reuse it.
  if (filters.includes(hexColor)) {
    return `url(#color-filter-${hexColor})`;
  }

  // If the filter doesn't exist, create the svg element
  // with it corresponding children and add the color to the filters array.

  if (!filterContainerEl) {
    return;
  }

  const rgba = hexToRgba(hexColor);
  const ns = 'http://www.w3.org/2000/svg';

  const svgEl = document.createElementNS(ns, 'svg');
  const defsEl = document.createElementNS(ns, 'defs');
  const filterEl = document.createElementNS(ns, 'filter');
  const matrixEl = document.createElementNS(ns, 'feColorMatrix');

  svgEl.style.display = 'none';

  filterEl.setAttribute('id', `color-filter-${hexColor}`);
  filterEl.setAttribute('color-interpolation-filters', 'sRGB');

  // More info about this magic here:
  // https://stackoverflow.com/questions/42966641/how-to-transform-black-into-any-given-color-using-only-css-filters/54000884#54000884
  matrixEl.setAttribute('type', 'matrix');
  matrixEl.setAttribute(
    'values',
    `0 0 0 0 ${rgba[0] / 255} 0 0 0 0 ${rgba[1] / 255} 0 0 0 0 ${
      rgba[2] / 255
    } 0 0 0 ${rgba[3]} 0`
  );

  filterEl.appendChild(matrixEl);
  defsEl.appendChild(filterEl);
  svgEl.appendChild(defsEl);
  filterContainerEl.appendChild(svgEl);

  filters.push(hexColor);
  return `url(#color-filter-${hexColor})`;
};
