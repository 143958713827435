import { useContext } from 'react';
import { IBaseAction } from '../useAction';
import { ScreenContext } from '../../contexts/screenContext';
import request from '../../utils/request';
import useRequestError from '../useRequestError';
import { PasswordLoginContext } from '../../contexts/passwordLoginContext';
import { AppContext } from '../../contexts/appContext';

export interface IPasswordVerifyAction extends IBaseAction {
  type: 'verify_password';

  data: {
    passwordInputId: string;
    verifyEndpoint: string;
  };
}

export interface IPasswordVerifyResponse {
  deviceId: string;
  sessionId: string;
}

const useVerifyPasswordAction = () => {
  const { saveSession } = useContext(AppContext);
  const { phone, smsCode } = useContext(PasswordLoginContext);
  const screenContext = useContext(ScreenContext);

  const handleRequestError = useRequestError();

  const handleVerifyPasswordAction = async (action: IPasswordVerifyAction) => {
    try {
      const response = await request<IPasswordVerifyResponse>(
        action.data.verifyEndpoint,
        {
          method: 'POST',
          body: {
            country_code: phone.countryCode,
            phone_number: phone.phoneNumber,
            code: smsCode,
            password:
              screenContext.inputStates[action.data.passwordInputId]?.value,
          },
        }
      );

      // Save session and reinitialize app.
      saveSession(response.sessionId, response.deviceId, true);
    } catch (e) {
      handleRequestError(e);
    }
  };

  return handleVerifyPasswordAction;
};

export default useVerifyPasswordAction;
