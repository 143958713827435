/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';

import { IBasePart } from '../part';
import useTheme from '../../../hooks/useTheme';

export interface IGapPart extends IBasePart {
  type: 'gap';

  height: number;
  lineHeight: number;
  lineColor: string;
  leadingMargin: 0;
  trailingMargin: 0;
  color: string;
}

const GapPart = (data: IGapPart) => {
  const { resolveColor } = useTheme();

  const STYLE: CSSObject = {
    height: data.height - data.lineHeight * 2,
    paddingLeft: data.leadingMargin || 20,
    paddingRight: data.trailingMargin || 20,
    backgroundColor: data.color,
    borderTop: `${data.lineHeight}px solid ${resolveColor(data.lineColor)}`,
    borderBottom: `${data.lineHeight}px solid ${resolveColor(data.lineColor)}`,
  };

  return <div css={STYLE} />;
};

export default GapPart;
