/** @jsx jsx */
import React, { Fragment } from 'react';
import { jsx } from '@emotion/core';
import { decamelizeKeys } from 'humps';

import { IBasePart } from '../part';
import RemoteImage, { IRemoteImage } from '../../../components/RemoteImage';
import ImageUploader from '../../../components/imageUploader';
import AttributedText from '../../../components/attributedText';
import Loader from '../../../components/loader';
import useTheme from '../../../hooks/useTheme';
import useStatefulInput, {
  IInputValidator,
} from '../../../hooks/useStatefulInput';
import useStatefulPart from '../../../hooks/useStatefulPart';
import useOverlay from '../../../hooks/useOverlay';
import LocalImage from '../../../components/localImage';
import InputValidationError from '../components/inputValidationError';

interface IUploadButtonTracker {
  action: string;
  category: string;
  label?: string;
  property?: null;
  value?: null;
}

export interface IImageUploadInputPart extends IBasePart {
  type: 'image_upload_input';

  inputId: string;
  images: Array<{ image: IRemoteImage }>;
  maxUploads: number;

  margins: {
    top: number;
    bottom: number;
    leading: number;
    trailing: number;
  };

  cellProperty: {
    cornerRadius: number;
    spacing: number;
    size: {
      width: number;
      height: number;
    };
    padding: {
      top: number;
      bottom: number;
      leading: number;
      trailing: number;
    };
  };

  uploadButton?: {
    clearable?: boolean;
    backgroundColor?: string;
    borderColor: string;
    uploadIcon: IRemoteImage;
    uploadIconSize: {
      width: number;
      height: number;
    };
    uploadRetryText: string;
    uploadText: string;
    uploadingFailedText: string;
    uploadingText: string;
    uploadCancelledTracker: IUploadButtonTracker;
    uploadClickedTracker: IUploadButtonTracker;
    uploadDoneTracker: IUploadButtonTracker;
    uploadFailedTracker: IUploadButtonTracker;
    uploadInitiatedTracker: IUploadButtonTracker;
    uploadRetryClickedTracker: IUploadButtonTracker;

    endpoint: {
      body: object;
      url: string;
    };
  };

  validators?: Array<IInputValidator>;
}

interface IImageUploadResponse {
  previewUrl?: string;
  response: object;
}

const ImageUploadInputPart = (data: IImageUploadInputPart) => {
  const {
    inputValue,
    isValid,
    isDirty,
    isTouched,
    errorMessage,
    setInputValue,
    setIsTouched,
  } = useStatefulInput<Array<object>>({
    inputId: data.inputId,
    initialValue: [],
    validators: data.validators,
  });

  // Keeps state of images thumbnails without polluting the input state.
  const [partState, setPartState] = useStatefulPart<
    Array<{ imgUri?: string; previewUrl?: string; preloaded?: boolean }>
  >(
    data.inputId,
    data.images.map((image) => ({ imgUri: image.image.url, preloaded: true }))
  );

  const { color, resolveColor } = useTheme();
  const overlay = useOverlay();

  const shouldShowErrorState = !isValid && isDirty && isTouched;

  const COMMON_UPLOADER_PROPS = {
    height: data.cellProperty.size.width,
    width: data.cellProperty.size.width,
    marginBottom: data.cellProperty.padding.trailing,
    marginRight: data.cellProperty.padding.trailing,
    borderColor: resolveColor(data.uploadButton?.borderColor),
    borderRadius: data.cellProperty.cornerRadius,
  };

  const handlePreviewImage = (url?: string) => {
    if (url) {
      overlay.presentImagePreview({
        // The url is absolute needs to be relative
        // in order for the reverse proxy to do its job.
        url: new URL(url).pathname,
      });
    }
  };

  return (
    <div
      css={{
        marginTop: data.margins.top,
        marginRight: data.margins.trailing - data.cellProperty.padding.trailing,
        marginBottom: data.margins.bottom - data.cellProperty.padding.trailing,
        marginLeft: data.margins.leading,
      }}
    >
      <div
        css={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        {/* Upload another image */}
        {partState.length < data.maxUploads && data.uploadButton && (
          <ImageUploader<IImageUploadResponse>
            key={partState.length}
            endpointUrl={data.uploadButton?.endpoint.url}
            endpointBody={data.uploadButton?.endpoint.body}
            // Uploader default state
            infoElementDefault={
              <Fragment>
                {/* Set the tint of the upload icon to always be primary
               until we get proper theming support and the tintColor would be set
               from the VL
             */}
                <RemoteImage
                  {...data.uploadButton.uploadIcon}
                  css={{
                    width: data.uploadButton.uploadIconSize.width,
                    height: data.uploadButton.uploadIconSize.height,
                    marginBottom: 8,
                  }}
                  tintColor={color.PRIMARY}
                />
                <AttributedText text={data.uploadButton.uploadText} />
              </Fragment>
            }
            // Uploader in progress state
            infoElementProgress={
              <Fragment>
                <div
                  css={{
                    position: 'relative',
                    width: data.uploadButton.uploadIconSize.width,
                    height: data.uploadButton.uploadIconSize.height,
                    margin: 5,
                  }}
                >
                  <Loader
                    show
                    size={data.uploadButton.uploadIconSize.width}
                    stroke={3}
                    strokeColor={color.LOCAL_WHITE}
                    transparent
                  />
                </div>
                <AttributedText text={data.uploadButton.uploadingText} />
              </Fragment>
            }
            // Uploader error state
            infoElementError={
              <Fragment>
                <LocalImage
                  src='APP_IMAGE_WARNING_CIRCLE'
                  alt=''
                  css={{ width: 16, height: 16, marginBottom: 8 }}
                />
                <AttributedText text={data.uploadButton.uploadingFailedText} />
                <AttributedText text={data.uploadButton.uploadRetryText} />
              </Fragment>
            }
            onImageUploaded={(res, thumbnail) => {
              setPartState([
                { imgUri: thumbnail, previewUrl: res.previewUrl },
                ...partState,
              ]);
              setInputValue([decamelizeKeys(res.response), ...inputValue]);
            }}
            {...COMMON_UPLOADER_PROPS}
          />
        )}

        {/* Uploaded images */}
        {partState?.map((image, i) => {
          return (
            <ImageUploader
              key={i}
              presetThumbnail={image.imgUri}
              hideClearButton={!data.uploadButton?.clearable || image.preloaded}
              onImageCleared={() => {
                const newInputState = [...(inputValue || [])];
                const newPartState = [...(partState || [])];
                newInputState.splice(i, 1);
                newPartState.splice(i, 1);

                setInputValue(newInputState);
                setPartState(newPartState);
              }}
              onPreviewRequested={
                partState[i].previewUrl && !image.preloaded
                  ? () => {
                      handlePreviewImage(partState[i].previewUrl);
                    }
                  : undefined
              }
              onBlur={() => {
                setIsTouched();
              }}
              {...COMMON_UPLOADER_PROPS}
            />
          );
        })}
      </div>

      {/* Validation error message */}
      {shouldShowErrorState && (
        <InputValidationError inputId={data.inputId} message={errorMessage} />
      )}
    </div>
  );
};

export default ImageUploadInputPart;
