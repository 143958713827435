import {
  newTracker,
  trackStructEvent,
  StructuredEvent,
  SelfDescribingJson,
  BrowserTracker,
} from '@snowplow/browser-tracker';
import { useContext, useEffect, useRef } from 'react';
import { AppConfigContext } from '../contexts/appConfigContext';
import { MeetingContext } from '../contexts/meetingContext';
import environment from '../utils/environment';

export type IStructuredSnowplowEvent = StructuredEvent;

const DEBUG_ENDPOINT = 'analytics.kry.pet';
const PRODUCTION_ENDPOINT = 'snowplowcollector.kry.pet';

const USER_CONTEXT_SCHEMA_URL = 'iglu:se.kry/user/jsonschema/1-0-0';
const MEETING_CONTEXT_SCHEMA_URL = 'iglu:se.kry/meeting/jsonschema/1-0-0';

const event = {
  // Core funnel events.
  APP_OPEN: {
    category: 'core-funnel',
    action: 'app-open',
  },
  USER_ACCOUNT_CREATED: {
    category: 'core-funnel',
    action: 'user-account-created',
  },
  MEETING_START: {
    category: 'core-funnel',
    action: 'meeting-start',
  },
  BANNER_CLOSE: {
    category: 'core-funnel',
    action: 'banner-close',
  },
  BANNER_REDIRECT: {
    category: 'core-funnel',
    action: 'banner-redirect',
  },

  // Meeting room events
  MEETING_ROOM_MICROPHONE_ENABLE: {
    category: 'meeting-room',
    action: 'microphone-enable',
  },
  MEETING_ROOM_MICROPHONE_DISABLE: {
    category: 'meeting-room',
    action: 'microphone-disable',
  },
  MEETING_ROOM_CAMERA_ENABLE: {
    category: 'meeting-room',
    action: 'camera-enable',
  },
  MEETING_ROOM_CAMERA_DISABLE: {
    category: 'meeting-room',
    action: 'camera-disable',
  },

  // Async messaging events
  ATTACHMENT_UPLOAD_STARTED: {
    category: 'async_messaging',
    action: 'attachment-upload-started',
  },
  ATTACHMENT_UPLOAD_DELETED: {
    category: 'async_messaging',
    action: 'attachment-upload-deleted',
  },
  ATTACHMENT_UPLOAD_COMPLETED: {
    category: 'async_messaging',
    action: 'attachment-upload-completed',
  },
};

let tracker: BrowserTracker;

// The BrowserTracker API doesn't allow pausing and resuming tracking so we handle it manually.
let isEnabled = false;

const useSnowplowTracker = () => {
  const { userId } = useContext(AppConfigContext);
  const { meetingInfo } = useContext(MeetingContext);

  const userIdRef = useRef(userId);
  const meetingInfoRef = useRef(meetingInfo);

  const getUserContext = (): SelfDescribingJson => {
    return {
      schema: USER_CONTEXT_SCHEMA_URL,
      data: {
        market: environment.COUNTRY,
      },
    };
  };

  const getMeetingContext = (): SelfDescribingJson => {
    return {
      schema: MEETING_CONTEXT_SCHEMA_URL,
      data: {
        user_id: userIdRef.current || 'n/a',
        meeting_id: meetingInfoRef.current?.meetingId || 'n/a',
        clinician_id: meetingInfoRef.current?.doctor?.id || 'n/a',
      },
    };
  };

  const getCustomContexts = (): Array<SelfDescribingJson> => {
    return [getUserContext(), getMeetingContext()];
  };

  const initialize = () => {
    const collectorUrl = environment.IS_PROD
      ? PRODUCTION_ENDPOINT
      : DEBUG_ENDPOINT;

    if (!tracker && isEnabled) {
      tracker = newTracker('sp', collectorUrl, {
        appId: 'patient-web',
      });
    }
  };

  const enableTracking = () => {
    isEnabled = true;
    initialize();
  };

  const disableTracking = () => {
    isEnabled = false;
  };

  const trackEvent = (snowplowEvent: IStructuredSnowplowEvent) => {
    if (isEnabled) {
      trackStructEvent({
        ...snowplowEvent,
        context: getCustomContexts(),
      });
    }
  };

  // Update mutable refs.
  useEffect(() => {
    userIdRef.current = userId;
    meetingInfoRef.current = meetingInfo;
  }, [userId, meetingInfo]);

  return useRef({
    event,

    initialize,
    enableTracking,
    disableTracking,
    trackEvent,
  }).current;
};

export default useSnowplowTracker;
