/** @jsx jsx */
import React, {
  createContext,
  ReactNode,
  useState,
  useEffect,
  useRef,
} from 'react';
import { jsx } from '@emotion/core';
import { useLocation } from 'react-router-dom';
import Overlay from '../overlays/overlay';
import AlertOverlay, { IAlertOverlay } from '../overlays/alertOverlay';
import IFrameOverlay, { IIFrameOverlay } from '../overlays/iFrameOverlay';
import SplashOverlay, { ISplashOverlay } from '../overlays/splashOverlay';
import StripeCheckoutOverlay, {
  IStripeCheckoutOverlay,
} from '../overlays/stripeCheckoutOverlay';
import BankIdOverlay, { IBankIdOverlay } from '../overlays/bankIdOverlay';
import CallingScreenOverlay, {
  ICallingScreenOverlay,
} from '../overlays/callingScreenOverlay';
import ActionListOverlay, {
  IActionListOverlay,
} from '../overlays/actionListOverlay';
import IconAlertOverlay, {
  IIconAlertOverlay,
} from '../overlays/iconAlertOverlay';
import ImagePreviewOverlay, {
  IImagePreviewOverlay,
} from '../overlays/imagePreviewOverlay';
import PdfPreviewOverlay, {
  IPdfPreviewOverlay,
} from '../overlays/pdfPreviewOverlay';
import VideoOverlay, { IVideoOverlay } from '../overlays/videoOverlay';

export interface IBaseOverlay {
  persistent?: boolean;
  dismissible?: boolean;
  noWidthConstrain?: boolean;
  fullscreenOnMobile?: boolean;
  onDismiss?: () => void;
}

interface IAlert {
  type: 'ALERT';
  data: IAlertOverlay;
}

interface IIconAlert {
  type: 'ICON_ALERT';
  data: IIconAlertOverlay;
}

interface IActionList {
  type: 'ACTION_LIST';
  data: IActionListOverlay;
}

interface IIFrame {
  type: 'IFRAME';
  data: IIFrameOverlay;
}

interface ISplash {
  type: 'SPLASH';
  data: ISplashOverlay;
}

interface IStripeCheckout {
  type: 'STRIPE_CHECKOUT';
  data: IStripeCheckoutOverlay;
}

interface IBankId {
  type: 'BANK_ID';
  data: IBankIdOverlay;
}

interface ICallingScreen {
  type: 'CALLING_SCREEN';
  data: ICallingScreenOverlay;
}

interface IImagePreview {
  type: 'IMAGE_PREVIEW';
  data: IImagePreviewOverlay;
}

interface IPdfPreview {
  type: 'PDF_PREVIEW';
  data: IPdfPreviewOverlay;
}

interface IVideo {
  type: 'VIDEO';
  data: IVideoOverlay;
}

export type IOverlay =
  | IAlert
  | IIconAlert
  | IActionList
  | ISplash
  | IIFrame
  | IStripeCheckout
  | IBankId
  | ICallingScreen
  | IImagePreview
  | IPdfPreview
  | IVideo;

export interface IOverlayContext {
  presentOverlay: (modalData: IOverlay) => void;
  dismissOverlay: () => void;
  getCurrentOverlay: () => IOverlay | undefined;
}

export const OverlayContext = createContext({} as IOverlayContext);

interface IOverlayContextProvider {
  children: ReactNode;
}

export const OverlayContextProvider = ({
  children,
}: IOverlayContextProvider) => {
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  const [overlay, setOverlay] = useState<IOverlay>();
  const overlayRef = useRef<IOverlay | undefined>(overlay);

  const presentOverlay = (overlayArg: IOverlay) => {
    setOverlay(overlayArg);
    setIsOpen(true);
  };

  const dismissOverlay = () => {
    setIsOpen(false);

    if (overlayRef.current?.data.onDismiss) {
      overlayRef.current.data.onDismiss();
    }
  };

  const getCurrentOverlay = () => {
    return isOpen ? overlay : undefined;
  };

  const getOverlayComponent = () => {
    switch (overlay?.type) {
      case 'ALERT':
        return <AlertOverlay {...overlay.data} />;
      case 'ICON_ALERT':
        return <IconAlertOverlay {...overlay.data} />;
      case 'ACTION_LIST':
        return <ActionListOverlay {...overlay.data} />;
      case 'IFRAME':
        return <IFrameOverlay {...overlay.data} />;
      case 'SPLASH':
        return <SplashOverlay {...overlay.data} />;
      case 'STRIPE_CHECKOUT':
        return <StripeCheckoutOverlay {...overlay.data} />;
      case 'BANK_ID':
        return <BankIdOverlay {...overlay.data} />;
      case 'CALLING_SCREEN':
        return <CallingScreenOverlay {...overlay.data} />;
      case 'IMAGE_PREVIEW':
        return <ImagePreviewOverlay {...overlay.data} />;
      case 'PDF_PREVIEW':
        return <PdfPreviewOverlay {...overlay.data} />;
      case 'VIDEO':
        return <VideoOverlay {...overlay.data} />;
      default:
        break;
    }
  };

  useEffect(() => {
    overlayRef.current = overlay;
  }, [overlay]);

  // Dismiss the overlay on route changes unless
  // set as persistent.
  useEffect(() => {
    if (overlayRef?.current && !overlayRef?.current?.data.persistent) {
      dismissOverlay();
    }
  }, [location]);

  return (
    <OverlayContext.Provider
      value={{
        presentOverlay,
        dismissOverlay,
        getCurrentOverlay,
      }}
    >
      {children}
      <Overlay
        open={isOpen}
        dismissible={overlay?.data.dismissible}
        fullscreenOnMobile={overlay?.data.fullscreenOnMobile}
        constrainWidth={!overlay?.data.noWidthConstrain}
        onRequestClose={() => {
          setIsOpen(false);
        }}
      >
        {getOverlayComponent()}
      </Overlay>
    </OverlayContext.Provider>
  );
};
