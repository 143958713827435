// Parses a hex color value (supported formats are #fff, #ffffff, fff and ffffff)
// and returns an array with the three rgb values, as well as the alpha.
export const hexToRgba = (hex: string) => {
  // Strip the hashtag prefix.
  hex = hex.startsWith('#') ? hex.slice(1) : hex;

  // Get the length of each color (given it can differ depending if it's a short-hand value or not)
  // and split it up into an array.
  const chunkSize = Math.floor(hex.length / 3);
  const hexArray = hex.match(new RegExp(`.{${chunkSize}}`, 'g')) || [];

  const [r, g, b, a] = hexArray.map((component) =>
    parseInt(component.repeat(2 / component.length), 16)
  );

  // If the alpha value is missing, default to 1.
  return [r, g, b, a ? a / 255 : 1];
};

// Same function as above, but excludes the alpha.
export const hexToRgb = (hex: string) => {
  const rgba = hexToRgba(hex);

  return [rgba[0], rgba[1], rgba[2]];
};
