import React, { Fragment } from 'react';
import { IView } from '../../views/view';
import useNavigation from '../../hooks/useNavigation';

interface IRedirectToView {
  view: IView;
}

const RedirectToView = ({ view }: IRedirectToView) => {
  const navigation = useNavigation();
  navigation.replace(view);

  return <Fragment />;
};

export default RedirectToView;
