/** @jsx jsx */
import React, { useCallback, useState, Fragment, useRef } from 'react';
import { jsx, CSSObject } from '@emotion/core';

import VisibilitySensor from 'react-visibility-sensor';

import { IBasePart } from '../part';
import RemoteImage, { IRemoteImage } from '../../../components/RemoteImage';
import AttributedText from '../../../components/attributedText';
import Clickable from '../../../components/clickable';
import useTheme from '../../../hooks/useTheme';
import useSnowplowTracker, {
  IStructuredSnowplowEvent,
} from '../../../hooks/useSnowplowTracker';
import { IVideoAction } from '../../../hooks/actions/useVideoAction';
import layout from '../../../constants/layout';
import VideoPlayer from '../../../components/videoPlayer';
import YouTubePlayer from '../../../components/youtubePlayer';

export interface IVideoPart extends IBasePart {
  type: 'video';

  title: string;
  duration: string;
  thumbnail: IRemoteImage;
  thumbnailHeight: string;
  playButtonImage: IRemoteImage;
  playButtonImageHeight: number;
  leadingMargin: number;
  trailingMargin: number;
  topMargin: number;
  bottomMargin: number;

  action: IVideoAction;

  thumbnailAppearedTracker?: IStructuredSnowplowEvent;
}

const VideoPart = (data: IVideoPart) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const startPlaybackRef = useRef<any>();
  const { color } = useTheme();

  const {
    action: { data: actionData },
  } = data;

  const snowplowTracker = useSnowplowTracker();

  const videoType = data.action.data.type;

  // If the provided action is a video action,
  // handle it locally by playing the video inline,
  // else handle it the useActionHook (handled by Clickable)
  const videoId =
    data.action.type === 'video' ? data.action.data.videoId : undefined;

  const videoSource = {
    sources: data.action.data.sources?.map(({ url, mimeType }) => ({
      src: url,
      type: mimeType,
    })),
  };

  const WRAPPER_STYLE: CSSObject = {
    marginLeft: data.leadingMargin,
    marginRight: data.trailingMargin,
    marginTop: data.topMargin,
    marginBottom: data.bottomMargin,
    backgroundColor: 'transparent',
  };

  const THUMBNAIL_WRAPPER_STYLE: CSSObject = {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    height: data.thumbnailHeight,
    zIndex: isPlaying ? 'unset' : layout.BELOW_ALL_ZINDEX,
  };

  const THUMBNAIL_STYLE: CSSObject = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  };

  const PLAY_BUTTON_STYLE: CSSObject = {
    height: data.playButtonImageHeight,
    zIndex: isPlaying ? 'unset' : layout.MODAL_ZINDEX,
  };

  const DURATION_STYLE: CSSObject = {
    position: 'absolute',
    top: 8,
    right: 8,
    color: color.LOCAL_WHITE,
    fontSize: '14px',
    backgroundColor: 'rgba(0, 0, 0, 0.55)',
    borderRadius: 7,
    padding: '6px 10px',
  };

  const PLAYER_STYLE: CSSObject = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    visibility: isPlaying ? 'visible' : 'hidden',
    opacity: isPlaying ? 1 : 0,
    transition: 'opacity 0.3s ease-in-out',
  };

  const VIDEO_FILE_PLAYER_STYLE: CSSObject = {
    ...PLAYER_STYLE,
    height: data.thumbnailHeight,
  };

  const trackStartVideo = useCallback(
    (currentTime: number) => {
      if (actionData.snowplowTrackers?.videoStartedTracker) {
        snowplowTracker.trackEvent({
          ...actionData.snowplowTrackers.videoStartedTracker,
          ...(currentTime && { value: Math.round(currentTime) }),
        });
      }
    },
    [actionData.snowplowTrackers, snowplowTracker]
  );

  const trackStopVideo = useCallback(
    (currentTime: number) => {
      if (actionData.snowplowTrackers?.videoStoppedTracker) {
        snowplowTracker.trackEvent({
          ...actionData.snowplowTrackers.videoStoppedTracker,
          ...(currentTime && { value: Math.round(currentTime) }),
        });
      }
    },
    [actionData.snowplowTrackers, snowplowTracker]
  );

  const trackVideoEnded = useCallback(
    (currentTime: number) => {
      if (actionData.snowplowTrackers?.videoCompletedTracker) {
        snowplowTracker.trackEvent({
          ...actionData.snowplowTrackers.videoCompletedTracker,
          ...(currentTime && { value: Math.round(currentTime) }),
        });
      }
    },
    [actionData.snowplowTrackers, snowplowTracker]
  );

  const trackThumbnailVisible = (isVisible: boolean) => {
    if (isVisible && data.thumbnail.url && data.thumbnailAppearedTracker) {
      snowplowTracker.trackEvent(data.thumbnailAppearedTracker);
    }
  };

  return (
    <Clickable
      styleAs='card'
      css={WRAPPER_STYLE}
      // Pass the action to Clickable only if it's not a video action or
      // the type of video is a file
      action={videoType === 'file_url' || videoId ? undefined : data.action}
      onClick={
        isPlaying
          ? undefined
          : () => {
              if (startPlaybackRef.current && !isPlaying) {
                startPlaybackRef.current();
                setIsPlaying(true);
              }
            }
      }
    >
      <VisibilitySensor partialVisibility onChange={trackThumbnailVisible}>
        <Fragment>
          {/* Thumbnail, play button and player container */}
          <div css={THUMBNAIL_WRAPPER_STYLE}>
            {/* Thumbnail image */}
            <RemoteImage {...data.thumbnail} css={THUMBNAIL_STYLE} />

            {/* Duration label */}
            <div css={DURATION_STYLE}>{data.duration}</div>

            {/* Play button */}
            <RemoteImage {...data.playButtonImage} css={PLAY_BUTTON_STYLE} />

            {/* Video player for files */}
            {videoType === 'file_url' && (
              <div css={VIDEO_FILE_PLAYER_STYLE}>
                <VideoPlayer
                  options={videoSource}
                  onReady={(startPlayback) =>
                    (startPlaybackRef.current = startPlayback)
                  }
                  onPlay={trackStartVideo}
                  onPause={trackStopVideo}
                  onEnd={trackVideoEnded}
                  onDisposeWhenPlaying={trackStopVideo}
                />
              </div>
            )}

            {/* Video iframe for youtube url */}
            {videoId && videoType === 'youtube' && (
              <YouTubePlayer
                videoId={videoId}
                styles={PLAYER_STYLE}
                onReady={(startPlayback) =>
                  (startPlaybackRef.current = startPlayback)
                }
                onPlay={trackStartVideo}
                onPause={trackStopVideo}
                onEnd={trackVideoEnded}
                onDisposeWhenPlaying={trackStopVideo}
              />
            )}
          </div>

          {/* Video title */}
          {data.title && (
            <AttributedText css={{ padding: '14px 20px' }} text={data.title} />
          )}
        </Fragment>
      </VisibilitySensor>
    </Clickable>
  );
};

export default VideoPart;
