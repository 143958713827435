/** @jsx jsx */
import React, { useCallback } from 'react';
import { jsx, CSSObject } from '@emotion/core';
import useOverlay from '../hooks/useOverlay';
import { IBaseOverlay } from '../contexts/overlayContext';
import Clickable from '../components/clickable';
import useLanguage from '../hooks/useLanguage';
import layout from '../constants/layout';
import LocalImage from '../components/localImage';
import { IVideoSource, IVideoTracking } from '../hooks/actions/useVideoAction';
import VideoPlayer from '../components/videoPlayer';
import useSnowplowTracker from '../hooks/useSnowplowTracker';
import useTheme from '../hooks/useTheme';

export interface IVideoOverlay extends IBaseOverlay {
  sources?: IVideoSource[];
  snowplowTrackers?: IVideoTracking;
}

const VideoOverlay = ({ sources, snowplowTrackers }: IVideoOverlay) => {
  const overlay = useOverlay();
  const language = useLanguage();
  const snowplowTracker = useSnowplowTracker();
  const { color } = useTheme();

  const BASE_STYLE: CSSObject = {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    backgroundColor: '#000',
    overflow: 'hidden',

    [`@media (min-width: ${layout.BREAKPOINT_MEDIUM}px)`]: {
      borderRadius: 7,
    },
  };

  const CLOSE_BUTTON_STYLE: CSSObject = {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 20,
    right: 20,
    width: 44,
    height: 44,
    backgroundColor: color.ACTION_FLOATING_SOFT,
    borderRadius: '100%',

    img: {
      width: 14,
      height: 14,
    },
  };

  const videoOptions = {
    autoplay: true,
    sources: sources?.map(({ url, mimeType }) => ({
      src: url,
      type: mimeType,
    })),
  };

  const trackStartVideo = useCallback(
    (currentTime: number) => {
      if (snowplowTrackers?.videoStartedTracker) {
        snowplowTracker.trackEvent({
          ...snowplowTrackers.videoStartedTracker,
          ...(currentTime && { value: Math.round(currentTime) }),
        });
      }
    },
    [snowplowTrackers, snowplowTracker]
  );

  const trackStopVideo = useCallback(
    (currentTime: number) => {
      if (snowplowTrackers?.videoStoppedTracker) {
        snowplowTracker.trackEvent({
          ...snowplowTrackers.videoStoppedTracker,
          ...(currentTime && { value: Math.round(currentTime) }),
        });
      }
    },
    [snowplowTrackers, snowplowTracker]
  );

  const trackVideoEnded = useCallback(
    (currentTime: number) => {
      if (snowplowTrackers?.videoCompletedTracker) {
        snowplowTracker.trackEvent({
          ...snowplowTrackers.videoCompletedTracker,
          ...(currentTime && { value: Math.round(currentTime) }),
        });
      }
    },
    [snowplowTrackers, snowplowTracker]
  );

  return (
    <div css={BASE_STYLE}>
      <VideoPlayer
        options={videoOptions}
        onPlay={trackStartVideo}
        onPause={trackStopVideo}
        onEnd={trackVideoEnded}
        onDisposeWhenPlaying={trackStopVideo}
      />

      {/* Close button */}
      <Clickable
        scale
        onClick={() => {
          overlay.dismiss();
        }}
        css={CLOSE_BUTTON_STYLE}
      >
        <LocalImage
          src='APP_IMAGE_REMOVE_PHOTO'
          tint='ICON_INVERSE'
          alt={language.get('close')}
        />
      </Clickable>
    </div>
  );
};

export default VideoOverlay;
