import useFlexNavigation, { IFlexNavigation } from '../flex/useFlexNavigation';
import { IBaseAction } from '../useAction';

export interface IFlexNavigateAction extends IBaseAction {
  type: 'flex_navigate';
  data: IFlexNavigation;
}

const useFlexNavigateAction = () => {
  const handleFlexNavigation = useFlexNavigation();

  const handleFlexNavigateAction = (action: IFlexNavigateAction) => {
    handleFlexNavigation(action.data);
  };

  return handleFlexNavigateAction;
};

export default useFlexNavigateAction;
