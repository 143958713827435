import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  createContext,
  ReactNode,
} from 'react';

import { decamelizeKeys } from 'humps';
import Ajv from 'ajv';
import { useHistory } from 'react-router-dom';
import request, { IRequestOptions } from '../utils/request';

import RequestError from '../utils/requestError';
import {
  ISurvey,
  ISurveyEdge,
  ISurveySubmissionResponse,
} from '../views/flex/flexNodes/surveyFlexNode/components/survey';
import {
  ISurveyNode,
  ISurveyNodeAnswer,
} from '../views/flex/flexNodes/surveyFlexNode/components/surveyNode';
import useSessionStorage from '../hooks/useSessionStorage';
import useRequestError from '../hooks/useRequestError';
import { ISurveyFlexNode } from '../views/flex/flexNodes/surveyFlexNode/surveyFlexNode';
import mapJSONToSurvey from '../utils/surveyMapper';
import useFlexNavigation from '../hooks/flex/useFlexNavigation';

export interface ISurveyState {
  answers?: Array<ISurveyNodeAnswer>;
}

export interface ISurveyContext {
  nodeStack?: Array<ISurveyNode>;
  flexNode: ISurveyFlexNode;

  registerAnswer: (answer: ISurveyNodeAnswer) => void;
  nodeHasValidAnswer: (node: ISurveyNode) => boolean;
  nodeHasValidApiAnswer: (node: ISurveyNode) => Promise<void>;
  getNextEdgeFrom: (node: ISurveyNode) => ISurveyEdge | undefined;
  getNextNodeFrom: (node: ISurveyNode) => ISurveyNode | undefined;
  getAnswerByPath: (path?: Array<string>) => ISurveyNodeAnswer | undefined;
  approximateProgressFromNode: (node: ISurveyNode) => number;
  submitSurvey: (gotoNode: ISurveyNode) => void;
}

export interface ISurveySource {
  flexNode: ISurveyFlexNode;
  url: string;
  requestOptions?: IRequestOptions;
  toNode?: string;
  path?: Array<string>;
}

export const SurveyContext = createContext({} as ISurveyContext);

interface ISurveyContextProvider {
  routeSource: ISurveySource;
  children: ReactNode;
}
export const SurveyContextProvider = ({
  children,
  routeSource,
}: ISurveyContextProvider) => {
  const history = useHistory();
  const [getCache, setCache] = useSessionStorage<ISurveyState>(
    `SURVEY_CONTEXT_${routeSource.flexNode.id}`
  );

  const [survey, setSurvey] = useState<ISurvey>();
  const [nodeStack, setNodeStack] = useState<Array<ISurveyNode>>();
  const [answers, setAnswers] = useState<Array<ISurveyNodeAnswer> | undefined>(
    () => {
      // Only use the cache when the context was NOT manually navigated to,
      // i.e. on reload page and on browser back/forward buttons.
      // All of these result in a POP action.
      if (history.action === 'POP') {
        return getCache()?.answers;
      }
    }
  );

  const handleFlexNavigation = useFlexNavigation();

  const setCacheRef = useRef(setCache);
  const handleRequestErrorRef = useRef(useRequestError());

  const getAnswerByPath = useCallback(
    (path?: Array<string>) => {
      return (
        answers &&
        answers.find((answer) => answer.path?.join() === path?.join())
      );
    },
    [answers]
  );

  const getAnswerValues = (answer?: ISurveyNodeAnswer) => {
    if (answer?.allowedAnswers !== undefined) {
      // AllowedAnswers array
      return {
        answer: answer.allowedAnswers.map(
          (allowedAnswer) => allowedAnswer.answerId
        ),
      };
    }
    if (answer?.text !== undefined) {
      // Text
      return { answer: answer.text };
    }
    if (answer?.photos !== undefined) {
      // Photos array
      return { answer: answer.photos?.map((photo) => photo.id) };
    }
    if (answer?.taggablePhotos !== undefined) {
      // Taggable photos
      const photos: { [answerId: string]: Array<string> } = {};
      answer.taggablePhotos?.forEach((photo) => {
        photos[photo.answerId] = [photo.id];
      });
      return photos;
    }
    if (answer?.componentAllowedAnswers !== undefined) {
      // componentAllowedAnswers array
      const components: { [answerId: string]: Array<string> } = {};
      answer.componentAllowedAnswers?.forEach((component) => {
        components[component.answerId] = [component.value];
      });
      return components;
    }
    if (answer?.observations !== undefined) {
      // Observations
      return { answer: answer.observations };
    }
    return null;
  };

  const nodeHasValidAnswer = useCallback(
    (node: ISurveyNode) => {
      const validator = new Ajv();
      const answer = getAnswerByPath(node.path);

      if (node?.input?.validation) {
        return !!validator.validate(
          node.input.validation,
          getAnswerValues(answer)
        );

        // contextable_prescription_records_consent node requires special treatment
        // as it doesn't have any validation, yet still has a chackbox that needs to be checked.
      }
      if (node.nodeType === 'contextable_prescription_records_consent') {
        return !!(answer?.observations && answer?.observations?.length > 0);
      }
      return true;
    },
    [getAnswerByPath]
  );

  const nodeHasValidApiAnswer = useCallback(
    (node: ISurveyNode): Promise<void> => {
      const answer = getAnswerByPath(node.path);
      const answerValue = getAnswerValues(answer);

      return new Promise((resolve, reject) => {
        if (node.input?.validationEndpoint) {
          request(node.input?.validationEndpoint, {
            method: 'POST',
            body: {
              input: answerValue?.answer,
            },
          })
            .then(() => resolve())
            .catch(() => reject());
        } else {
          resolve();
        }
      });
    },
    [getAnswerByPath]
  );

  const getPropertyValuesForNodePath = useCallback(
    (node: ISurveyNode) => {
      const values: { [property: string]: unknown } = {};

      const answerStack = node.path?.map((path, i) => {
        const subPath = node.path?.slice(0, i + 1);
        const answer = getAnswerByPath(subPath);
        return answer || null;
      });

      answerStack?.forEach((answer) => {
        if (answer?.allowedAnswers !== undefined && answer.property) {
          // AllowedAnswers array
          values[answer.property] = answer?.allowedAnswers?.map(
            (allowedAnswer) => allowedAnswer.samplePointName
          );
        } else if (answer?.text !== undefined && answer.property) {
          // Text
          values[answer.property] = answer.text;
        } else {
          return false;
        }
      });

      return values;
    },
    [getAnswerByPath]
  );

  const getNextEdgeFrom = useCallback(
    (node: ISurveyNode) => {
      const propertyValues = getPropertyValuesForNodePath(node);
      const validator = new Ajv();

      return survey?.graph.structure
        .find((neighborhood) => neighborhood.fromNode === node?.name)
        ?.toEdges.find((edge) => {
          const valid = validator.validate(
            edge.clientCondition,
            propertyValues
          );
          return !!valid;
        });
    },
    [survey, getPropertyValuesForNodePath]
  );

  const getNextNodeFrom = (node: ISurveyNode) => {
    const nextEdge = getNextEdgeFrom(node);
    return survey?.graph.nodes.find(
      (graphNode) => graphNode.name === nextEdge?.toNode
    );
  };

  const registerAnswer = useCallback((answer: ISurveyNodeAnswer) => {
    setAnswers((prevAnswers) => {
      const newAnswers = [...(prevAnswers || [])];
      const prevAnswerIndex = newAnswers.findIndex(
        (prevAnswer) => prevAnswer.path?.join() === answer.path?.join()
      );

      if (prevAnswerIndex > -1) {
        newAnswers[prevAnswerIndex] = answer;
      } else {
        newAnswers.push(answer);
      }

      return newAnswers;
    });
  }, []);

  const submitSurvey = (gotoNode: ISurveyNode) => {
    interface IVisit {
      visited_at: number;
      node: string;
      answer: object | Array<any> | string | null;
      answered_at: number;
    }

    if (nodeStack && survey?.saveCall) {
      const visits: Array<IVisit> = [];
      const finalPath = nodeStack[nodeStack.length - 1].path;

      finalPath?.forEach((path, i) => {
        const subPath = finalPath.slice(0, i + 1);
        const answer = getAnswerByPath(subPath);

        if (answer) {
          const answerValues = getAnswerValues(answer);
          visits.push({
            visited_at: answer.visitedAt,
            node: answer.nodeName,
            answer:
              answerValues?.answer !== undefined
                ? answerValues.answer
                : answerValues,
            answered_at: answer.answeredAt,
          });
        }
      });

      // Append the goto node.
      visits.push({
        visited_at: Date.now(),
        node: gotoNode.name,
        answer: null,
        answered_at: Date.now(),
      });

      request<ISurveySubmissionResponse>(survey.saveCall.url, {
        method: survey.saveCall.method,
        body: {
          primary_path: visits,

          // @TODO: history should contain an absolute path of visits.
          history: visits,
          meta: survey.graph.meta,

          ...survey.saveCall.body,
          ...(typeof routeSource.flexNode.memory === 'object' && {
            ...decamelizeKeys(routeSource.flexNode.memory),
          }),
        },
        retryable: survey.saveCall.retryable,
      })
        .then((res) => {
          handleFlexNavigation(res.navigation);
        })
        .catch((error: RequestError) => handleRequestErrorRef.current(error));
    }
  };

  const getInitialNode = (surveyArg: ISurvey) => {
    const initialNodeName = surveyArg?.graph.structure.find(
      (neighborhood) => neighborhood.fromNode === null
    )?.toEdges[0].toNode;

    return surveyArg?.graph.nodes.find((node) => node.name === initialNodeName);
  };

  const getNodeByName = (surveyArg: ISurvey, name?: string) => {
    return surveyArg?.graph.nodes.find((node) => node.name === name);
  };

  // @TODO: This is extremely naive, rewrite.
  const approximateProgressFromNode = (node: ISurveyNode) => {
    return (node.path?.length || 1) / (survey?.graph.structure.length || 1);
  };

  const setPeakAheadStackFromNode = useCallback(() => {
    // Currently only support subnodes if the top node is a binary choice
    // and the potential subnode is any type of text input.
    setNodeStack((nodes) => {
      if (survey && nodes) {
        const firstNode = nodes[0];

        // If the first node is not a binary choice, bail.
        if (firstNode.nodeType !== 'a_xor_b') {
          return nodes;
        }

        const lastNode = nodes[nodes.length - 1];
        const nextEdge = getNextEdgeFrom(firstNode);
        if (nodeHasValidAnswer(firstNode) && nextEdge && !nextEdge.newView) {
          const nextNode = getNodeByName(survey, nextEdge.toNode);
          const isAcceptableSubNode =
            nextNode?.nodeType === 'text' ||
            nextNode?.nodeType === 'textarea' ||
            nextNode?.nodeType === 'int_pad' ||
            nextNode?.nodeType === 'api_validated_text' ||
            nextNode?.nodeType === 'api_validated_textarea' ||
            nextNode?.nodeType === 'api_validated_int_pad';

          if (
            nextNode &&
            isAcceptableSubNode &&
            nextNode.name !== lastNode.name
          ) {
            return [
              ...nodes,
              { ...nextNode, path: [...(firstNode.path || []), nextNode.name] },
            ];
          }
          return nodes;
        }
      }
      return nodes && [nodes[0]];
    });
  }, [survey, getNextEdgeFrom, nodeHasValidAnswer]);

  useEffect(() => {
    setCacheRef.current = setCache;
  }, [setCache]);

  useEffect(() => {
    if (survey?.graph) {
      const node =
        getNodeByName(survey, routeSource.toNode) || getInitialNode(survey);
      if (node) {
        setNodeStack([{ ...node, path: routeSource.path || [node.name] }]);
      }
    } else {
      request<{ graph: object; save_call: object }>(routeSource.url, {
        ...routeSource.requestOptions,
        noCamelization: true,
      })
        .then((surveyData) => {
          if (surveyData.graph && surveyData.save_call) {
            setSurvey(mapJSONToSurvey(surveyData));
          }
        })
        .catch((error: RequestError) => handleRequestErrorRef.current(error));
    }
  }, [routeSource, survey]);

  useEffect(() => {
    setPeakAheadStackFromNode();
    setCacheRef.current({ answers });
  }, [answers, setPeakAheadStackFromNode]);

  return (
    <SurveyContext.Provider
      value={{
        nodeStack,
        flexNode: routeSource.flexNode,
        registerAnswer,
        nodeHasValidAnswer,
        nodeHasValidApiAnswer,
        getNextEdgeFrom,
        getNextNodeFrom,
        getAnswerByPath,
        approximateProgressFromNode,
        submitSurvey,
      }}
    >
      {children}
    </SurveyContext.Provider>
  );
};
