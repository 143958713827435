/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';
import useOverlay from '../hooks/useOverlay';
import Clickable from '../components/clickable';
import { IBaseOverlay } from '../contexts/overlayContext';
import useTheme from '../hooks/useTheme';

export interface IAlertOverlay extends IBaseOverlay {
  title?: string;
  message?: string;
  messageElement?: JSX.Element;

  verticalOrientation?: 'horizontal' | 'vertical';

  items: Array<{
    title: string;
    isCancel?: boolean;
    isDestructive?: boolean;
    onClick: () => void;
  }>;
}

const AlertOverlay = ({
  title,
  message,
  messageElement,
  verticalOrientation,
  items,
}: IAlertOverlay) => {
  const overlay = useOverlay();
  const { font, color } = useTheme();

  const DIALOG_STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    textAlign: 'center',
    backgroundColor: color.LOCAL_WHITE,
    padding: '30px 20px 20px',
    borderRadius: 12,

    h2: {
      fontFamily: font.FAMILY_BOLD,
      fontSize: 24,
      marginTop: 10,
    },

    '[role="doc-subtitle"]': {
      fontSize: 17,
      marginTop: 10,
      overflowWrap: 'break-word',
    },
  };

  const DEFAULT_BUTTON_STYLE: CSSObject = {
    flex: 1,
    margin: 7,
    height: 44,
    minHeight: 44,
    textAlign: 'center',
    color: color.LOCAL_WHITE,
    backgroundColor: color.PRIMARY,
    '&[active]': {
      backgroundColor: color.PRIMARY_PRESS,
    },
  };

  const CANCEL_BUTTON_STYLE: CSSObject = {
    color: color.ALERT,
    backgroundColor: color.ALERT_SECONDARY,
    '&[active]': {
      backgroundColor: color.ALERT_SECONDARY_PRESS,
    },
  };

  const DESTRUCTIVE_BUTTON_STYLE: CSSObject = {
    color: color.ALERT,
    backgroundColor: color.ALERT_SECONDARY,
    '&[active]': {
      backgroundColor: color.ALERT_SECONDARY_PRESS,
    },
  };

  return (
    <div css={DIALOG_STYLE}>
      {/* Title and message */}
      <h2>{title}</h2>
      {(!!messageElement || !!message) && (
        <div role='doc-subtitle'>{messageElement || message}</div>
      )}

      {/* Buttons */}
      <div
        css={{
          display: 'flex',
          margin: '34px -7px -7px',
          flexDirection: verticalOrientation === 'vertical' ? 'column' : 'row',
        }}
      >
        {items.map((item, i) => (
          <Clickable
            styleAs='button'
            key={i}
            autoFocus={i === 0}
            onClick={() => {
              overlay.dismiss();
              item.onClick();
            }}
            css={{
              ...DEFAULT_BUTTON_STYLE,
              ...(item.isCancel && CANCEL_BUTTON_STYLE),
              ...(item.isDestructive && DESTRUCTIVE_BUTTON_STYLE),
            }}
          >
            {item.title}
          </Clickable>
        ))}
      </div>
    </div>
  );
};

export default AlertOverlay;
