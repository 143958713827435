/** @jsx jsx */
import React, { Fragment } from 'react';
import { jsx, CSSObject, keyframes } from '@emotion/core';
import useTheme from '../../../hooks/useTheme';

interface IInputValidationError
  extends React.InputHTMLAttributes<HTMLDivElement> {
  inputId: string;
  message?: string;
}

const InputValidationError = ({
  inputId,
  message,
  ...props
}: IInputValidationError) => {
  const { color } = useTheme();

  const fadeInAnimation = keyframes`
    0% {
      opacity: 0;
      max-height: 0;
    }
    100% {
      opacity: 1;
      max-height: 100px;
    }
  `;

  const STYLE: CSSObject = {
    color: color.ALERT,
    marginTop: 3,
    fontSize: '13px',
    animation: `${fadeInAnimation} 0.3s forwards`,
  };

  return (
    <Fragment>
      {message && (
        <span id={`${inputId}-validation-error`} css={STYLE} {...props}>
          {message}
        </span>
      )}
    </Fragment>
  );
};

export default InputValidationError;
