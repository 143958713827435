/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';
import useTheme from '../hooks/useTheme';
import LocalImage from './localImage';

interface IRadioButton extends React.InputHTMLAttributes<HTMLInputElement> {
  isInvalid?: boolean;
}

const RadioButton = ({ isInvalid, ...props }: IRadioButton) => {
  const { color } = useTheme();

  const STYLE: CSSObject = {
    position: 'relative',

    width: 26,
    height: 26,
    margin: 3,
    flexShrink: 0,
    input: {
      position: 'absolute',
      opacity: 0,
      width: 0,
      height: 0,
      ':checked + span': {
        backgroundColor: color.PRIMARY,
        border: `2px solid ${isInvalid ? color.ALERT : color.PRIMARY}`,
        borderRadius: '100%',
        img: {
          display: 'block',
        },
      },
    },
    span: {
      content: `''`,
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      backgroundColor: color.LOCAL_WHITE,
      border: `2px solid ${isInvalid ? color.ALERT : color.UI_IDLE}`,
      borderRadius: '100%',
      boxSizing: 'border-box',
      img: {
        display: 'none',
      },
    },
  };

  return (
    <div css={STYLE}>
      <input type='radio' {...props} tabIndex={-1} />
      <span>
        <LocalImage src='APP_IMAGE_CHECKMARK' tint='LOCAL_WHITE' alt='' />
      </span>
    </div>
  );
};

export default RadioButton;
