/** @jsx jsx */
import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react';
import { jsx } from '@emotion/core';

import { ISurveyNode, IAllowedAnswer } from '../surveyNode';
import Title from '../../../../../../components/title';
import Select from '../../../../../../components/select';
import { SurveyContext } from '../../../../../../contexts/surveyContext';
import useTheme from '../../../../../../hooks/useTheme';

interface IPickerSurveyNode {
  node: ISurveyNode;
  isSubNode?: boolean;
}

const PickerSurveyNode = ({ node, isSubNode }: IPickerSurveyNode) => {
  const { color } = useTheme();
  const nodeVisistedAt = useRef(Date.now());
  const { registerAnswer, getAnswerByPath } = useContext(SurveyContext);
  const [state, setState] = useState<Array<IAllowedAnswer>>(
    getAnswerByPath(node.path)?.componentAllowedAnswers || []
  );

  useEffect(() => {
    registerAnswer({
      nodeName: node.name,
      path: node.path,
      property: node.property,
      componentAllowedAnswers: state,
      answeredAt: Date.now(),
      visitedAt: nodeVisistedAt.current,
    });
  }, [node, state, registerAnswer]);

  return (
    <Fragment>
      <Title
        title={node.title}
        subtitle={node.subtitle}
        subdued={isSubNode}
        css={{
          padding: '0 20px',
          marginBottom: isSubNode ? 12 : 20,
        }}
      />

      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          padding: '20px 0',
        }}
      >
        {node.input?.options?.map((option, i) => {
          return (
            <Fragment key={i}>
              {/* Center split */}
              {i > 0 && (
                <div
                  css={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    fontSize: 30,
                    color: color.UI_IDLE,
                  }}
                >
                  /
                </div>
              )}

              {/* Picker group */}
              <div css={{ flex: 1 }}>
                {/* Picker title */}
                <div
                  css={{
                    fontSize: 12,
                    paddingLeft: 20,
                    marginBottom: 8,
                    textTransform: 'uppercase',
                  }}
                >
                  {option.title}
                </div>

                {/* Picker */}
                <div
                  css={{
                    width: '100%',
                    padding: '0 20px',
                    boxSizing: 'border-box',
                  }}
                >
                  <Select
                    noArrow
                    value={
                      state.find((value) => value.answerId === option.id)
                        ?.value || option.defaultValue
                    }
                    onChange={(e) => {
                      const answers = [...(state || [])];
                      const index = answers.findIndex(
                        (answer) => answer.answerId === option.id
                      );
                      const value = option.values?.find(
                        (currentValue) =>
                          currentValue.answerId === e.currentTarget.value
                      );

                      const newAnswer = {
                        answerId: option.id,
                        property: option.property,
                        samplePointName: value?.samplePointName || '',
                        value: value?.answerId || '',
                      };

                      if (index > -1) {
                        answers[index] = newAnswer;
                      } else {
                        answers.push(newAnswer);
                      }

                      setState(answers);
                    }}
                    aria-label={option.title}
                  >
                    {option.values?.map((value, index: number) => (
                      <option key={index} value={value.answerId}>
                        {value.value}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            </Fragment>
          );
        })}
      </div>
    </Fragment>
  );
};

export default PickerSurveyNode;
