import { logWarning } from '../../utils/remoteLogger';
import { IBaseAction } from '../useAction';
import useHttpCall, { IHttpCall } from '../useHttpCall';
import useOverlay from '../useOverlay';
import useRequestError from '../useRequestError';

export interface IOpenPdfAction extends IBaseAction {
  type: 'open_pdf';

  data: {
    title: string;
    httpCall: IHttpCall;
  };
}

const useOpenPdfAction = () => {
  const handleHttpCall = useHttpCall();
  const handleRequestError = useRequestError();

  const overlay = useOverlay();

  const handleOpenPdfAction = (action: IOpenPdfAction) => {
    handleHttpCall<BlobPart>(action.data.httpCall, {
      assumeBlob: true,
    })
      .then((blob) => {
        // Present the pdf preview overlay and pass it a new blob by appending a content type.
        // We use application/octet-stream rather than application/pdf
        // in order to trigger an actual download when user clicks the download button,
        // rather than opening the pdf file in the browser.
        overlay.presentPdfPreview({
          file: new Blob([blob], {
            type: 'application/octet-stream',
          }),
          title: action.data.title,
        });
      })
      .catch((error) => {
        handleRequestError(error);
        logWarning(
          'useOpenPdfAction',
          `Failed to load document ${action.data.httpCall.url}`,
          error
        );
      });
  };

  return handleOpenPdfAction;
};

export default useOpenPdfAction;
