/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';
import useOverlay from '../hooks/useOverlay';
import Clickable from '../components/clickable';
import { IBaseOverlay } from '../contexts/overlayContext';
import useTheme from '../hooks/useTheme';
import useLanguage from '../hooks/useLanguage';

export interface IActionListOverlay extends IBaseOverlay {
  title?: string;
  message?: string;
  cancelLabel?: string;

  items: Array<{
    title: string;
    isDestructive?: boolean;
    onClick: () => void;
  }>;
}

const ActionListOverlay = ({
  title,
  message,
  cancelLabel,
  items,
}: IActionListOverlay) => {
  const overlay = useOverlay();
  const language = useLanguage();
  const { font, color } = useTheme();

  const DIALOG_STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    textAlign: 'center',
    backgroundColor: color.LOCAL_WHITE,
    padding: '30px 20px 20px',
    borderRadius: 12,

    h2: {
      fontFamily: font.FAMILY_BOLD,
      fontSize: 24,
      marginTop: 10,
    },

    '[role="doc-subtitle"]': {
      fontSize: 17,
      marginTop: 10,
      overflowWrap: 'break-word',
    },
  };

  const DEFAULT_BUTTON_STYLE: CSSObject = {
    justifyContent: 'center',
    minHeight: 56,
    fontFamily: font.FAMILY_BOLD,
    color: color.PRIMARY,
  };

  const DESTRUCTIVE_BUTTON_STYLE: CSSObject = {
    color: color.ALERT,
  };

  const CLOSE_BUTTON_STYLE: CSSObject = {
    backgroundColor: color.LOCAL_WHITE,
    color: color.PRIMARY,
    '&[active]': {
      backgroundColor: color.LOCAL_WHITE,
    },
  };

  return (
    <div css={DIALOG_STYLE}>
      {/* Title and message */}
      <h2>{title}</h2>
      {!!message && <div role='doc-subtitle'>{message}</div>}

      {/* Dynamic buttons */}
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          margin: '40px -20px 20px',
          borderTop: `1px solid ${color.DIVIDER_LINE}`,
          borderBottom: `1px solid ${color.DIVIDER_LINE}`,
          backgroundColor: color.SEARCH_FIELD_BG,
        }}
      >
        {items.map((item, i) => (
          <Clickable
            styleAs='listItem'
            darken
            key={i}
            autoFocus={i === 0}
            onClick={() => {
              overlay.dismiss();
              item.onClick();
            }}
            css={{
              ...DEFAULT_BUTTON_STYLE,
              ...(item.isDestructive && DESTRUCTIVE_BUTTON_STYLE),
            }}
          >
            {item.title}
          </Clickable>
        ))}
      </div>

      {/* Close button */}
      <Clickable
        styleAs='button'
        onClick={overlay.dismiss}
        css={CLOSE_BUTTON_STYLE}
      >
        {cancelLabel || language.get('close')}
      </Clickable>
    </div>
  );
};

export default ActionListOverlay;
