import React, { useState, useEffect, useCallback, useRef } from 'react';
import PhoneEntryView from '../views/login/password/phoneInputView';
import SmsCodeEntryView from '../views/login/password/smsCodeInputView';
import UserDetailsView from '../views/login/password/userDetailsFormView';
import { ScreenContextProvider } from './screenContext';
import Navbar from '../components/navbar';
import Screen from '../views/screen/screen';
import useSessionStorage from '../hooks/useSessionStorage';
import request from '../utils/request';
import useRequestError from '../hooks/useRequestError';

export interface IUserDetailsForm {
  title: string;
  fields: Array<{
    id: string;
    type: string;
    placeholder: string;
    value: string;
    required: boolean;
    selected: boolean;
  }>;
}

export interface IDeviceRegistrationForm {
  defaultCountryCode: string;
  userDetailsForm: IUserDetailsForm;
}

interface IPasswordLoginContext {
  phone: {
    countryCode: string;
    phoneNumber: string;
  };
  smsCode: string;
  deviceRegistrationForm?: IDeviceRegistrationForm;
  setPhone: (countryCode: string, phoneNumber: string) => void;
  setSmsCode: (smsCode: string) => void;
}

export interface IPasswordLoginSource {
  viewType?:
    | 'PHONE_ENTRY'
    | 'SMS_CODE_ENTRY'
    | 'PASSWORD_ENTRY'
    | 'USER_DETAILS';
  screenResource?: string;
}

interface ILoginState {
  phone: {
    countryCode: string;
    phoneNumber: string;
  };
  smsCode: string;
  deviceRegistrationForm?: IDeviceRegistrationForm;
}

export const PasswordLoginContext = React.createContext(
  {} as IPasswordLoginContext
);

interface IPasswordLoginContextProvider {
  routeSource?: IPasswordLoginSource;
}

export const PasswordLoginContextProvider = ({
  routeSource,
}: IPasswordLoginContextProvider) => {
  const [getCache, setCache] = useSessionStorage<ILoginState>(
    'PASSWORD_LOGIN_CONTEXT'
  );
  const setCacheRef = useRef(setCache);
  const getCacheRef = useRef(getCache);

  const handleRequestErrorRef = useRef(useRequestError());

  const [state, setState] = useState<ILoginState>(
    getCacheRef.current() || {
      phone: {
        countryCode: '',
        phoneNumber: '',
      },
      smsCode: '',
    }
  );

  useEffect(() => {
    // This request fetches the current market country calling code
    // and form config used to fill user details later in login flow
    request<IDeviceRegistrationForm>('/api/view/device_registration_form')
      .then((res) => {
        setState((prevState) => {
          return {
            ...prevState,
            phone: {
              ...prevState.phone,
              countryCode:
                prevState.phone.countryCode || res.defaultCountryCode,
            },
            deviceRegistrationForm: res,
          };
        });
      })
      .catch((e) => {
        handleRequestErrorRef.current(e);
      });
  }, []);

  useEffect(() => {
    if (state) {
      setCacheRef.current({
        phone: state.phone,
        smsCode: state.smsCode,
      });
    }
  }, [state]);

  const setPhone = useCallback((countryCode: string, phoneNumber: string) => {
    setState((prevState) => {
      return {
        ...prevState,
        phone: {
          countryCode,
          phoneNumber,
        },
      };
    });
  }, []);

  const setSmsCode = useCallback((smsCode: string) => {
    setState((prevState) => {
      return {
        ...prevState,
        smsCode,
      };
    });
  }, []);

  const getView = () => {
    switch (routeSource?.viewType) {
      case 'SMS_CODE_ENTRY':
        return <SmsCodeEntryView />;
      case 'USER_DETAILS':
        return <UserDetailsView />;
      case 'PASSWORD_ENTRY':
        // we get VL screen url to set and verify password
        return (
          <ScreenContextProvider
            routeSource={{ url: routeSource.screenResource }}
          >
            {/* Navbar */}
            <Navbar />

            <Screen />
          </ScreenContextProvider>
        );
      // Password login default screen
      case 'PHONE_ENTRY':
      default:
        return <PhoneEntryView />;
    }
  };

  return (
    <PasswordLoginContext.Provider
      value={{
        ...state,
        setPhone,
        setSmsCode,
      }}
    >
      {getView()}
    </PasswordLoginContext.Provider>
  );
};
