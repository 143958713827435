/** @jsx jsx */
import React, { useContext, useEffect, useRef } from 'react';
import { jsx } from '@emotion/core';

import { ISurveyNode } from '../surveyNode';
import { ScreenContextProvider } from '../../../../../../contexts/screenContext';
import Screen from '../../../../../screen/screen';
import { SurveyContext } from '../../../../../../contexts/surveyContext';

interface IInfoScreenSurveyNode {
  node: ISurveyNode;
}

const InfoScreenSurveyNode = ({ node }: IInfoScreenSurveyNode) => {
  const nodeVisistedAt = useRef(Date.now());
  const { registerAnswer } = useContext(SurveyContext);

  useEffect(() => {
    registerAnswer({
      nodeName: node.name,
      path: node.path,
      property: node.property,
      answeredAt: Date.now(),
      visitedAt: nodeVisistedAt.current,
    });
  }, [node, registerAnswer]);

  return (
    <ScreenContextProvider routeSource={{ screen: node.screen }}>
      <Screen />
    </ScreenContextProvider>
  );
};

export default InfoScreenSurveyNode;
