/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';
import useTheme from '../hooks/useTheme';

interface ITitle {
  title?: string;
  subtitle?: string;

  large?: boolean;
  subdued?: boolean;
}

const Title = ({ title, subtitle, large, subdued, ...props }: ITitle) => {
  const { font, color } = useTheme();

  const TITLE_STYLE: CSSObject = {
    position: 'relative',
    fontSize: subdued ? 18 : large ? 26 : 20,
    fontFamily: subdued ? font.FAMILY_MEDIUM : font.FAMILY_BOLD,

    ...(!subdued && {
      '::after': {
        content: `''`,
        display: 'block',
        width: 36,
        height: 3,
        backgroundColor: color.DIVIDER_LINE,
        margin: '12px 0 0 0',
      },
    }),
  };

  const SUBTITLE_STYLE: CSSObject = {
    marginTop: 12,
  };

  return (
    <div {...props}>
      <h2 css={TITLE_STYLE}>{title}</h2>
      {!subdued && subtitle && <div css={SUBTITLE_STYLE}>{subtitle}</div>}
    </div>
  );
};

export default Title;
