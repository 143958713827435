/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';

import { IBasePart } from '../part';
import useTheme from '../../../hooks/useTheme';

export interface IShapePart extends IBasePart {
  type: 'shape';

  height: number;
  color: string;
  fill: boolean;
  quadY: number;
}

const ShapePart = (data: IShapePart) => {
  const { resolveColor } = useTheme();

  const baseY = data.quadY >= 0 ? 0 : data.height;
  const controlY = data.quadY >= 0 ? data.quadY : data.height + data.quadY;

  return (
    <div
      css={{
        height: data.height,
      }}
    >
      <svg
        viewBox={`0 0 100 ${data.height}`}
        preserveAspectRatio='none'
        css={{
          height: '100%',
          width: '100%',
          fill: resolveColor(data.color),
        }}
      >
        <path d={`M0,${baseY} Q50,${controlY} 100,${baseY} Z`} />
      </svg>
    </div>
  );
};

export default ShapePart;
