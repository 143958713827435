import useFlexHttpCall, { IFlexHttpCall } from '../../useFlexHttpCall';
import { IStructuredSnowplowEvent } from '../../useSnowplowTracker';
import { IFlexNavigation } from '../useFlexNavigation';

export interface IFlexNavigateReference {
  type: 'reference';
  call: IFlexHttpCall;
  snowplowEvent?: IStructuredSnowplowEvent;
}

const useFlexNavigateReference = () => {
  const handleFlexHttpCall = useFlexHttpCall();

  const handleFlexNavigateReference = (
    flexNavigate: IFlexNavigateReference
  ): Promise<IFlexNavigation> => {
    return handleFlexHttpCall<IFlexNavigation>(flexNavigate.call);
  };

  return handleFlexNavigateReference;
};

export default useFlexNavigateReference;
