/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';

import { IBasePart } from '../part';
import useTheme from '../../../hooks/useTheme';

export interface IImageInfoPart extends IBasePart {
  type: 'image_info';

  title: string;
  subtitle: string;
  imageName: string;
  imageUrl: string;
}

const ImageInfoPart = (data: IImageInfoPart) => {
  const { color } = useTheme();

  const STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  return (
    <div css={STYLE}>
      {data.imageUrl && (
        <img
          css={{
            marginBottom: 12,
          }}
          src={data.imageUrl}
          alt=''
        />
      )}
      <div
        css={{
          fontSize: '20px',
          marginBottom: 12,
        }}
      >
        {data.title}
      </div>
      <div
        css={{
          fontSize: '15px',
          color: color.TEXT_SOFT,
        }}
      >
        {data.subtitle}
      </div>
    </div>
  );
};

export default ImageInfoPart;
