import { camelizeKeys } from 'humps';
import { useEffect, useRef, useState } from 'react';
import useReactWebSocket from 'react-use-websocket';
import environment from '../utils/environment';
import { getCustomHeaders } from '../utils/request';
import { logError } from '../utils/remoteLogger';

interface IWebSocketOptions {
  // Connection options.
  appendCustomHeaders?: boolean;
  shareConnection?: boolean;

  // Content options.
  noCamelization?: boolean;
}

// As opposed to the fetch API, the websocket API needs an absolute url.
// Construct an absolute one using the current origin as a base, or if we're running in France,
// the direct API url, as traffic must not pass Cloudfront.
const getAbsoluteWebSocketUrl = (
  path: string,
  appendCustomHeaders?: boolean
): string => {
  const url = new URL(
    environment.COUNTRY === 'FR' && environment.IS_PROD
      ? environment.getApiUrlFrance(path)
      : `${document.location.origin}${path}`
  );

  // Change the protocol of the url from https to wss.
  url.protocol = 'wss';

  // Append custom http headers as a query string to the url.
  if (appendCustomHeaders) {
    url.searchParams.append(
      'custom-headers',
      JSON.stringify(getCustomHeaders())
    );
  }

  return url.href;
};

// Multiple instances of the hook can exist simultaneously.
// This stores the timestamp of the last heartbeat for a given socket url,
// preventing other instances to send unnecessary heartbeats.
const previousHeartbeats: Record<string, number> = {};

// Store the timestamp of the current error, as to avoid having multiple
// hook instances report the same error.
let previousErrorTimestamp = 0;

const useWebSocket = <T>(
  url?: string,
  options?: IWebSocketOptions
): T | undefined => {
  const [message, setMessage] = useState<T>();

  // Set to true once the hook unmounts, used in order to to not attempt
  // reconnects if the client purposefully closed the connection.
  const didUnmountRef = useRef(false);

  // Stores the heartbeat interval.
  const heartbeatIntervalRef = useRef<number>();

  // When we receive a new message, parse it, optionally camelize the json
  // and finally set it to state.
  const handleOnMessage = (event: WebSocketEventMap['message']) => {
    if (event.data) {
      const messageDataJson = JSON.parse(event.data);

      setMessage(
        options?.noCamelization
          ? messageDataJson
          : camelizeKeys(messageDataJson)
      );
    }
  };

  // Returns whether or not the hook should attempt to reconnect to the socket url
  // upon a disconnect.
  const handleShouldReconnect = () => {
    return !didUnmountRef.current;
  };

  // Reports potentially failed connections.
  const handleOnReconnectStop = () => {
    if (Date.now() > previousErrorTimestamp) {
      logError('useWebSocket', `WebSocket connection failed: ${url}`);
      previousErrorTimestamp = Date.now();
    }
  };

  // Instantiate useReactWebSocket.
  const { sendMessage, readyState } = useReactWebSocket(
    url ? getAbsoluteWebSocketUrl(url, options?.appendCustomHeaders) : null,
    {
      share: options?.shareConnection,
      reconnectAttempts: 6,
      reconnectInterval: 1000,

      onMessage: handleOnMessage,
      shouldReconnect: handleShouldReconnect,
      onReconnectStop: handleOnReconnectStop,
    },
    !!url
  );

  // As we want the message to be momentary, remove it from the state as soon as it's been set.
  useEffect(() => {
    setMessage(undefined);
  }, [message]);

  // Sends a periodical heartbeat message through the websocket connection.
  useEffect(() => {
    if (readyState === 1) {
      heartbeatIntervalRef.current = window.setInterval(() => {
        if (url) {
          const lastHeartbeat = previousHeartbeats[url];
          const deltaFromNow = (Date.now() - lastHeartbeat) / 1000;

          // Send a heartbeat message if it hasn't already been sent within the last 10 seconds.
          if (!lastHeartbeat || deltaFromNow > 10) {
            // Send the heartbeat message and update the heartbeat history.
            sendMessage('');
            previousHeartbeats[url] = Date.now();
          }
        }
      }, 2000);
    }

    return () => {
      clearInterval(heartbeatIntervalRef.current);
    };
  }, [url, readyState, sendMessage]);

  // Update didUnmountRef which keeps track of whether or not the hook has unmounted.
  useEffect(() => {
    return () => {
      didUnmountRef.current = true;
    };
  }, []);

  return message;
};

export default useWebSocket;
