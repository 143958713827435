const apiKeys = {
  // This doesn't really make sense for the web client but is needed in order for the
  // backend to not register a new device for every session.
  KRY_PUB:
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAmUr0ftbFFTvXCzqa3NNTt7yWvb2Rm5+QeoXytN0B1LnzfIsS9SbpHIEZgQKny7jTvsmpVlKs5DnGOM5fKab0KV3DrWu4OyCb2SbWTKCoKuug2zvusaQtCh4zl9VqYc7uhP9LyrBT9Cq1tulW1KbUDv/pH6En8tfAv4RCRPwTvbpjD6ga5BV6QaJSkx6ozhsV4bMaqznHlRzNOgzXVCr3uPBE5v7scZsgroNau5bw9bp4Th78SwSz3D+M4uOgQT7X9+b1ewdxdhACUOgijnNRJ80rvnLOQjmwcRMBCBWoMtiy0/YTl6TIhhV6F3bOoj4sBPFe4ikOawSfXPVjWS58QwIDAQAB',

  // This API key is limited to requests from our public domains and will not work locally.
  GOOGLE_STATIC_MAPS: 'AIzaSyAUo5-9zvN1FK0ZAFtO5-aDTM6YPpZ0EGw',

  // This API key is limited to requests from our public domains and will not work locally.
  GOOGLE_MAPS: 'AIzaSyD_X3fcaZHxGc3-BcSqvuQufLScyJzMCLg',
};

export default apiKeys;
