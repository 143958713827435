/** @jsx jsx */
import { useEffect, useState } from 'react';

interface IIdleDetector {
  enabled: boolean;
  timeToIdle: number;
}

const ACTIVITY_EVENTS = ['load', 'click', 'keydown', 'scroll'];
let idleTimeout: number;

const useIdleDetector = ({ enabled, timeToIdle }: IIdleDetector) => {
  const [userIsIdle, setUserIsIdle] = useState(false);

  // Setup user activity event listeners.
  useEffect(() => {
    // On user activity, reset the current timeout and set a new one.
    const onUserActivity = () => {
      setUserIsIdle(false);
      clearTimeout(idleTimeout);

      if (enabled) {
        idleTimeout = window.setTimeout(() => {
          setUserIsIdle(true);
        }, timeToIdle * 1000);
      }
    };

    ACTIVITY_EVENTS.forEach((event) => {
      window.addEventListener(event, onUserActivity, true);
    });

    onUserActivity();

    // Clear timeout and remove event listeners.
    return () => {
      clearTimeout(idleTimeout);

      ACTIVITY_EVENTS.forEach((event) => {
        window.removeEventListener(event, onUserActivity, true);
      });
    };
  }, [enabled, timeToIdle]);

  return {
    userIsIdle,
  };
};

export default useIdleDetector;
