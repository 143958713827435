import useLanguage from './useLanguage';
import { IScreen } from '../views/screen/screen';
import { images } from '../constants/assets';
import useTheme from './useTheme';
import appStoreLinks from '../constants/appStoreLinks';

const useLocalScreen = () => {
  const { color } = useTheme();
  const language = useLanguage();

  const notSupportedScreen: IScreen = {
    screenName: 'local_not_supported_screen',
    center: true,
    parts: [
      {
        type: 'title',
        title: language.get('patient_web.unsupported_feature.title'),
        subtitle: language.get('patient_web.unsupported_feature.message'),
        topMargin: 0,
        id: 'local_not_supported_screen_title_0',
      },
      {
        type: 'space',
        height: 30,
        id: 'local_not_supported_screen_space_2',
      },
      {
        type: 'button',
        bgColor: color.LOCAL_WHITE,
        text: language.get('patient_web.unsupported_feature.download_ios'),
        textColor: color.PRIMARY || '',
        textColorActive: color.PRIMARY_PRESS || '',
        buttonColor: color.SECONDARY || '',
        buttonColorActive: color.SECONDARY_PRESS || '',
        action: {
          type: 'url',
          data: { value: appStoreLinks.IOS },
        },
        enabled: true,
        buttonHeight: 56,
        hasArrow: false,
        leadingMargin: 20,
        trailingMargin: 20,
        id: 'local_not_supported_screen_button_1',
      },
      {
        type: 'button',
        bgColor: color.LOCAL_WHITE,
        text: language.get('patient_web.unsupported_feature.download_android'),
        textColor: color.PRIMARY || '',
        textColorActive: color.PRIMARY_PRESS || '',
        buttonColor: color.SECONDARY || '',
        buttonColorActive: color.SECONDARY_PRESS || '',
        action: {
          type: 'url',
          data: { value: appStoreLinks.ANDROID },
        },
        enabled: true,
        buttonHeight: 56,
        hasArrow: false,
        leadingMargin: 20,
        trailingMargin: 20,
        id: 'local_not_supported_screen_button_2',
      },
    ],
  };

  const errorScreen: IScreen = {
    screenName: 'local_error_screen',
    center: true,
    parts: [
      {
        type: 'image',
        height: 96,
        name: 'Error',
        url: images.ERROR_SAD_FACE,
        contentMode: 'aspect_fit',
        leadingMargin: 40,
        trailingMargin: 40,
        ratio: 1,
        id: 'local_error_screen_image_0',
      },
      {
        type: 'title',
        title: language.get('error'),
        subtitle: language.get('failed_loading_data'),
        topMargin: 20,
        id: 'local_error_screen_title_0',
      },
      {
        type: 'space',
        height: 30,
        id: 'local_error_screen_space_2',
      },
      {
        type: 'button',
        bgColor: color.LOCAL_WHITE,
        text: language.get('try_again'),
        textColor: color.PRIMARY || '',
        textColorActive: color.PRIMARY_PRESS || '',
        buttonColor: color.SECONDARY || '',
        buttonColorActive: color.SECONDARY_PRESS || '',
        action: {
          type: 'reload',
        },
        enabled: true,
        buttonHeight: 56,
        hasArrow: false,
        leadingMargin: 20,
        trailingMargin: 20,
        id: 'local_error_screen_button_1',
      },
    ],
  };

  const fourOhFourScreen: IScreen = {
    screenName: '404_screen',
    center: true,
    parts: [
      {
        type: 'image',
        height: 175,
        name: 'Error',
        url: images.ERROR_SAD_FACE,
        contentMode: 'aspect_fit',
        leadingMargin: 20,
        trailingMargin: 20,
        ratio: 1.0,
        id: 'local_error_screen_image_0',
      },
    ],
  };

  return { notSupportedScreen, errorScreen, fourOhFourScreen };
};

export default useLocalScreen;
