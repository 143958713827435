/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';

import { IBasePart } from '../part';

export interface ISpacePart extends IBasePart {
  type: 'space';
  height: number;
}

const SpacePart = (data: ISpacePart) => {
  const STYLE: CSSObject = {
    height: data.height,
  };

  return <div css={STYLE} />;
};

export default SpacePart;
