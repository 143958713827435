// we use this to share info between app and service worker
// we prefer to use IndexedBD because sw <-> app messaging is not reliable
import { DBSchema, openDB } from 'idb';

type Context = { deviceId: string; sessionId: string; appConfig: object }; // cannot import IAppConfig

interface ContextSchema extends DBSchema {
  'shared-app-context': {
    value: Context;
    key: string;
  };
}

class ContextStore {
  private static DB_NAME = 'kry-idb';

  private static STORE_NAME = 'shared-app-context' as const;

  private static CONTEXT_KEY = 'current-context' as const;

  private static db = () =>
    openDB<ContextSchema>(ContextStore.DB_NAME, 1, {
      upgrade(db) {
        db.createObjectStore(ContextStore.STORE_NAME);
      },
    });

  public static getContext = async () =>
    (await ContextStore.db()).get(
      ContextStore.STORE_NAME,
      ContextStore.CONTEXT_KEY
    );

  public static setContext = async (newContext: Context) =>
    (await ContextStore.db()).put(
      ContextStore.STORE_NAME,
      newContext,
      ContextStore.CONTEXT_KEY
    );

  public static clearContext = async () =>
    (await ContextStore.db()).delete(
      ContextStore.STORE_NAME,
      ContextStore.CONTEXT_KEY
    );
}

export default ContextStore;
