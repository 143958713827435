/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';

import { IBasePart } from '../part';
import useTheme from '../../../hooks/useTheme';

export interface ISeparatorPart extends IBasePart {
  type: 'separator';

  height: number;
  text: string;
}

const SeparatorPart = (data: ISeparatorPart) => {
  const { color } = useTheme();

  const WRAPPER_STYLE: CSSObject = {
    display: 'flex',
    alignItems: 'center',
    height: data.height,
    marginLeft: 20,
    marginRight: 20,
  };

  const TEXT_STYLE: CSSObject = {
    fontSize: '12px',
    color: color.TEXT_SOFT,
    padding: '0 8px',
  };

  const LINE_STYLE: CSSObject = {
    height: 1,
    backgroundColor: color.DIVIDER_LINE,
    flex: 1,
  };

  return (
    <div css={WRAPPER_STYLE}>
      <div css={LINE_STYLE} />
      <div css={TEXT_STYLE}>{data.text}</div>
      <div css={LINE_STYLE} />
    </div>
  );
};

export default SeparatorPart;
