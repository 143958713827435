import { useRef } from 'react';
import device from '../utils/device';
import environment from '../utils/environment';

// A few notes on browser compatibility:
//
// We're considering local notifications to be a desktop-only feature.
// They are supported on all semi-modern desktop browsers.
//
// iOS does not support notifications at all, while mobile Android
// only supports then when invoked by a service worker.

const useNotifications = () => {
  const browserSupportsNotifications =
    !!window.Notification && !device.IS_MOBILE;
  const permissionGranted =
    browserSupportsNotifications && Notification.permission === 'granted';

  const requestPermission = () => {
    if (browserSupportsNotifications && !permissionGranted) {
      Notification.requestPermission();
    }
  };

  const presentNotification = (options: {
    title: string;
    message: string;
    onClick?: () => void;
  }) => {
    if (permissionGranted) {
      const icon = `${window.location.origin}/${
        environment.IS_KRY ? 'favicon_kry.ico' : 'favicon_livi.ico'
      }`;

      const notification = new Notification(options.title, {
        icon,
        body: options.message,
        requireInteraction: true,
      });

      notification.onclick = () => {
        // 1. Attempt to switch focus to the browser and our own tab
        // if the user happens to be in another one, or in another application altogether.
        // Applies to desktop only.
        window.parent.parent.focus();

        // 2. Close the clicked notification.
        notification.close();

        // 3. Invoke a potentially provided click handler.
        if (options.onClick) {
          options.onClick();
        }
      };

      return notification;
    }
  };

  return useRef({
    browserSupportsNotifications,
    permissionGranted,

    requestPermission,
    presentNotification,
  }).current;
};

export default useNotifications;
