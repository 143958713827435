import { IAction, IBaseAction } from '../useAction';
import useFlexHttpCall, { IFlexHttpCall } from '../useFlexHttpCall';

export interface IFlexHttpAction extends IBaseAction {
  type: 'flex_http_call';
  data: IFlexHttpCall;
}

const useFlexHttpAction = () => {
  const handleFlexHttpCall = useFlexHttpCall();

  const handleFlexHttpAction = (action: IFlexHttpAction): Promise<IAction> => {
    return new Promise((resolve, reject) => {
      handleFlexHttpCall<{ completionAction?: IAction }>(action.data)
        .then((data) => {
          if (data?.completionAction) {
            resolve(data?.completionAction);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return handleFlexHttpAction;
};

export default useFlexHttpAction;
