/** @jsx jsx */
import { jsx } from '@emotion/core';
import { motion } from 'framer-motion';
import useTheme from '../../../../../hooks/useTheme';
import useLanguage from '../../../../../hooks/useLanguage';
import { images } from '../../../../../constants/assets';
import Clickable from '../../../../../components/clickable';
import LocalImage from '../../../../../components/localImage';
import layout from '../../../../../constants/layout';

interface IVideoMeetingInfo {
  title?: string;
  subtitle?: string;

  onUploaderClick: () => void;
}

const VideoMeetingInfo = ({
  title,
  subtitle,
  onUploaderClick,
}: IVideoMeetingInfo) => {
  const { color, font } = useTheme();
  const language = useLanguage();

  return (
    <motion.div
      initial={{ opacity: 0, y: '-100%' }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: '-100%' }}
      transition={{ duration: 0.3 }}
      css={{
        position: 'absolute',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        left: 0,
        top: 0,
        width: '100%',
        padding: 20,
        background:
          'linear-gradient(180deg, rgba(0, 0, 0, 0.6) 7.29%, rgba(0, 0, 0, 0.28) 66.67%, rgba(0, 0, 0, 0) 100%)',
        zIndex: layout.BASIC_ZINDEX,
        boxSizing: 'border-box',
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          css={{
            backgroundColor: color.LOCAL_WHITE,
            width: 32,
            height: 32,
            marginRight: 7,
            borderRadius: '50%',
            backgroundImage: `url(${images.LOGO})`,
            backgroundSize: '70%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        />
        <div>
          <h1 css={{ fontFamily: font.FAMILY_BOLD }}>{title}</h1>
          <h2>{subtitle}</h2>
        </div>
      </div>

      <Clickable
        scale
        aria-label={language.get('meeting_room_photo_upload_add_photo')}
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 64,
          height: 64,
          borderRadius: '50%',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',

          boxShadow: '0px 12px 19px rgba(21, 61, 87, 0.2)',
          ':focus': {
            boxShadow: `0px 12px 19px rgba(21, 61, 87, 0.2), inset 0px 0px 0px 3px ${color.LOCAL_FOCUS_BORDER}`,
          },
          '&[active], :focus:not([data-focus-visible-added]):not([active])': {
            boxShadow: '0px 12px 19px rgba(21, 61, 87, 0.2)',
          },
        }}
        onClick={onUploaderClick}
      >
        <LocalImage src='APP_IMAGE_MEETING_IMAGE_UPLOAD' />
      </Clickable>
    </motion.div>
  );
};

export default VideoMeetingInfo;
