/** @jsx jsx */
import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react';
import { jsx } from '@emotion/core';

import { ISurveyNode, ISurveyNodeAnswer } from '../surveyNode';
import Title from '../../../../../../components/title';
import { SurveyContext } from '../../../../../../contexts/surveyContext';
import SurveyImageUploader from '../shared/surveyImageUploader';
import useOverlay from '../../../../../../hooks/useOverlay';

interface IPhotoSurveyNode {
  node: ISurveyNode;
  isSubNode?: boolean;
}

const PhotoSurveyNode = ({ node, isSubNode }: IPhotoSurveyNode) => {
  const nodeVisistedAt = useRef(Date.now());
  const { registerAnswer, getAnswerByPath } = useContext(SurveyContext);
  const [state, setState] = useState<ISurveyNodeAnswer['photos']>(
    getAnswerByPath(node.path)?.photos || []
  );

  const overlay = useOverlay();

  useEffect(() => {
    registerAnswer({
      nodeName: node.name,
      path: node.path,
      property: node.property,
      photos: state,
      answeredAt: Date.now(),
      visitedAt: nodeVisistedAt.current,
    });
  }, [node, state, registerAnswer]);

  return (
    <Fragment>
      <Title
        title={node.title}
        subtitle={node.subtitle}
        subdued={isSubNode}
        css={{
          padding: '0 20px',
          marginBottom: isSubNode ? 12 : 20,
        }}
      />

      <div
        css={{
          display: 'flex',
          flexWrap: 'wrap',
          padding: '0 0 0 20px',
        }}
      >
        {/* Already uploaded images */}
        {state?.map((img, i) => {
          return (
            <SurveyImageUploader
              key={i}
              presetThumbnail={img.thumbnail}
              onImageCleared={() => {
                setState((prevState) => {
                  const newState = [...(prevState || [])];
                  newState.splice(i, 1);
                  return newState;
                });
              }}
              onPreviewRequested={() => {
                overlay.presentImagePreview({
                  url: `/api/meeting/patientsymptoms/image/${state[i].id}`,
                });
              }}
            />
          );
        })}

        {/* Upload another image */}
        <SurveyImageUploader
          key={state?.length}
          onImageUploaded={(res, thumbnail) => {
            setState((prevState) => {
              return (
                (prevState && [...prevState, { id: res, thumbnail }]) || [
                  { id: res, thumbnail },
                ]
              );
            });
          }}
        />
      </div>
    </Fragment>
  );
};

export default PhotoSurveyNode;
