/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';
import { IBasePart } from '../part';
import AttributedText from '../../../components/attributedText';
import Clickable from '../../../components/clickable';
import { IAction } from '../../../hooks/useAction';
import useTheme from '../../../hooks/useTheme';
import RemoteImage, { IRemoteImage } from '../../../components/RemoteImage';

export interface ISearchBarPart extends IBasePart {
  type: 'search_bar';

  icon: IRemoteImage;
  attributedText: string;
  style: 'elevated' | 'plain';
  searchBarHeight: number;
  leadingMargin: number;
  trailingMargin: number;
  topMargin: number;
  bottomMargin: number;

  action: IAction;
}

const SearchBarPart = (data: ISearchBarPart) => {
  const { color } = useTheme();

  const WRAPPER_STYLE: CSSObject = {
    display: 'flex',
    position: 'relative',
    marginLeft: data.leadingMargin,
    marginRight: data.trailingMargin,
    marginTop: data.topMargin,
    marginBottom: data.bottomMargin,
  };

  const FAKE_INPUT_STYLE: CSSObject = {
    display: 'flex',
    flex: 1,
    alignItems: 'center',

    height: data.searchBarHeight,
    boxSizing: 'border-box',

    ...(data.style === 'plain' && {
      backgroundColor: color.SEARCH_FIELD_BG,
      borderRadius: 8,
    }),
  };

  const ICON_STYLE: CSSObject = {
    position: 'relative',
    width: 24,
    top: -1,
    margin: '0 10px',
  };

  return (
    <div css={WRAPPER_STYLE}>
      <Clickable
        scale
        styleAs={data.style === 'elevated' ? 'card' : undefined}
        action={data.action}
        css={FAKE_INPUT_STYLE}
      >
        {/* Search icon */}
        <RemoteImage {...data.icon} css={ICON_STYLE} />

        {/* Placeholer text */}
        <AttributedText text={data.attributedText} />
      </Clickable>
    </div>
  );
};

export default SearchBarPart;
