/** @jsx jsx */
import React from 'react';
import { CSSObject, jsx } from '@emotion/core';
import { IAction } from '../../../../../hooks/useAction';
import Clickable from '../../../../../components/clickable';
import RemoteImage, {
  IRemoteImage,
} from '../../../../../components/RemoteImage';
import NotificationBadge, {
  INotificationBadge,
} from '../../../../../components/notificationBadge';

type IContentMode = 'aspect_fit' | 'aspect_fill' | 'center' | 'left' | 'right';

export interface IFiveColumnImageCell {
  type: 'image';
  image: IRemoteImage;
  contentMode?: IContentMode;
  cornerRadius?: number;

  size: {
    height: number;
    width: number;
  };

  badge: INotificationBadge;

  action: IAction;
}

interface IFiveColumnImageCellProps {
  data: IFiveColumnImageCell;
}

const FiveColumnImageCell = ({ data }: IFiveColumnImageCellProps) => {
  const STYLE: CSSObject = {
    position: 'relative',
    width: data.size.width || '100%',
    height: data.size.height || '100%',
    textAlign: 'center',
    borderRadius: data.cornerRadius,
    overflow: 'hidden',

    // The image should be centered unless specifically aligned
    ...(data.contentMode !== 'left' &&
      data.contentMode !== 'right' && {
        margin: '0 auto',
      }),
  };

  const NOTIFICATION_BADGE_STYLE: CSSObject = {
    position: 'absolute',
    top: -4,
    right: -8,
  };

  const getImageStyle = (contentMode?: IContentMode) => {
    const baseStyle: CSSObject = {
      display: 'block',
      width: '100%',
      height: '100%',
    };

    switch (contentMode) {
      case 'aspect_fit':
        baseStyle.objectFit = 'contain';
        break;
      case 'aspect_fill':
        baseStyle.objectFit = 'cover';
        break;
      case 'center':
        break;
      case 'left':
        baseStyle.position = 'absolute';
        baseStyle.left = 0;
        baseStyle.width = 'unset';
        baseStyle.height = 'unset';
        baseStyle.top = '50%';
        baseStyle.transform = 'translateY(-50%)';
        break;
      case 'right':
        baseStyle.position = 'absolute';
        baseStyle.right = 0;
        baseStyle.width = 'unset';
        baseStyle.height = 'unset';
        baseStyle.top = '50%';
        baseStyle.transform = 'translateY(-50%)';
        break;
      default:
        break;
    }

    return baseStyle;
  };

  return (
    <Clickable scale action={data.action}>
      <div css={STYLE}>
        <div
          css={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
          }}
        >
          <RemoteImage {...data.image} css={getImageStyle(data.contentMode)} />
        </div>
      </div>
      {data.badge && (
        <NotificationBadge css={NOTIFICATION_BADGE_STYLE} {...data.badge} />
      )}
    </Clickable>
  );
};

export default FiveColumnImageCell;
