import useEventCallback from '../useEventCallback';
import useRequestError from '../useRequestError';
import useSnowplowTracker from '../useSnowplowTracker';

import useFlexNavigateForward, {
  IFlexNavigateForward,
} from './flexNavigation/useFlexNavigateForward';
import useFlexNavigateReference, {
  IFlexNavigateReference,
} from './flexNavigation/useFlexNavigateReference';
import useFlexNavigateModal, {
  IFlexNavigateModal,
} from './flexNavigation/useFlexNavigateModal';
import useFlexNavigateOverlay, {
  IFlexNavigateOverlay,
} from './flexNavigation/useFlexNavigateOverlay';
import useFlexNavigateUnwind, {
  IFlexNavigateUnwind,
} from './flexNavigation/useFlexNavigateUnwind';
import useFlexNavigateFinish, {
  IFlexNavigateFinish,
} from './flexNavigation/useFlexNavigateFinish';

export type IFlexNavigation =
  | IFlexNavigateForward
  | IFlexNavigateReference
  | IFlexNavigateModal
  | IFlexNavigateOverlay
  | IFlexNavigateUnwind
  | IFlexNavigateFinish;

const useFlexNavigation = () => {
  const handleFlexNavigateForward = useFlexNavigateForward();
  const handleFlexNavigateReference = useFlexNavigateReference();
  const handleFlexNavigateModal = useFlexNavigateModal();
  const handleFlexNavigateOverlay = useFlexNavigateOverlay();
  const handleFlexNavigateUnwind = useFlexNavigateUnwind();
  const handleFlexNavigateFinish = useFlexNavigateFinish();

  const snowplowTracker = useSnowplowTracker();
  const handleRequestError = useRequestError();

  const handleFlexNavigation = (navigation: IFlexNavigation) => {
    switch (navigation.type) {
      case 'forward':
        handleFlexNavigateForward(navigation);
        break;
      case 'reference':
        handleFlexNavigateReference(navigation)
          .then(handleFlexNavigation)
          .catch(handleRequestError);
        break;
      case 'modal':
        handleFlexNavigateModal(navigation)
          .then(handleFlexNavigation)
          .catch(handleRequestError);
        break;
      case 'overlay':
        handleFlexNavigateOverlay(navigation)
          .then(handleFlexNavigation)
          .catch(handleRequestError);
        break;
      case 'unwind':
        handleFlexNavigateUnwind(navigation);
        break;
      case 'finish':
        handleFlexNavigateFinish(navigation);
        break;
    }

    // Snowplow tracking.
    if (navigation.snowplowEvent) {
      snowplowTracker.trackEvent(navigation.snowplowEvent);
    }
  };

  return useEventCallback(handleFlexNavigation) as (
    navigation: IFlexNavigation
  ) => void;
};

export default useFlexNavigation;
