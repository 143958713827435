import { decamelizeKeys } from 'humps';
import {
  IAppConfigContextState,
  IAppStartResponse,
} from '../contexts/appConfigContext';

interface ISetAppStartDataDispatch {
  type: 'SET_APP_START_DATA';
  payload: {
    appStartData: IAppStartResponse;
    initialLaunch: boolean;
  };
}

interface ISetAppStatusDispatch {
  type: 'SET_STATUS';
  payload: IAppConfigContextState['status'];
}

export function appConfigContextReducer(
  state: IAppConfigContextState,
  dispatch: ISetAppStartDataDispatch | ISetAppStatusDispatch
): IAppConfigContextState {
  switch (dispatch.type) {
    case 'SET_APP_START_DATA':
      return {
        initialLaunch: dispatch.payload.initialLaunch,
        status: 'OK',

        ...dispatch.payload.appStartData,

        // appLanguage is an object containing localization keys.
        // The keys need to remain in snake case for lookup.
        appLanguage: decamelizeKeys(
          dispatch.payload.appStartData.appLanguage
        ) as IAppStartResponse['appLanguage'],
      };

    case 'SET_STATUS':
      return {
        ...state,
        status: dispatch.payload,
      };
    default:
      return state;
  }
}
