import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { decamelizeKeys } from 'humps';
import { IOpenTokSession } from '../views/flex/flexNodes/meetingRoomFlexNode/components/rtcProviders/opentok';
import device from '../utils/device';
import useLanguage from '../hooks/useLanguage';
import useNavigation from '../hooks/useNavigation';
import useOverlay from '../hooks/useOverlay';
import { logError, logWarning } from '../utils/remoteLogger';
import request from '../utils/request';
import { AppConfigContext } from './appConfigContext';
import { AppContext } from './appContext';
import useWsPushNotification from '../hooks/useWsPushNotification';
import RequestError from '../utils/requestError';

export interface IMeetingInfo {
  meetingId?: string;
  callerTitle?: string;
  callerSubtitle?: string;
  callingTitle?: string;
  videoTitle?: string;
  videoSubtitle?: string;
  careType?: ICareType;
  ringtone?: unknown;
  symptomId?: string;
  type?: unknown;
  userId?: unknown;

  doctor?: {
    id?: string;
    name?: string;
    doctorImage?: string;
    title?: string;
    careType?: unknown;
    facility?: unknown;
    facilityLocation?: unknown;
    poolId?: unknown;
    profession?: unknown;
    questions?: Array<unknown>;
  };
}

export enum ICareType {
  PRIMARY = 1,
  REGULAR_CARE = 1,
  NURSE = 2,
  PSYCHOLOGY = 3,
}

export enum IMeetingTimelineEvent {
  VIDEO_PATIENT_CALL_SCREEN_PRESENTED = 'VIDEO_PATIENT_CALL_SCREEN_PRESENTED',
  VIDEO_PATIENT_CALL_ANSWERED = 'VIDEO_PATIENT_CALL_ANSWERED',
  VIDEO_PATIENT_CONNECTING = 'VIDEO_PATIENT_CONNECTING',
  VIDEO_PATIENT_CALL_DECLINED = 'VIDEO_PATIENT_CALL_DECLINED',
  VIDEO_PATIENT_CALL_HUNG_UP = 'VIDEO_PATIENT_CALL_HUNG_UP',
  VIDEO_PATIENT_SWITCH_CAMERA = 'VIDEO_PATIENT_SWITCH_CAMERA',
  VIDEO_PATIENT_JOINED = 'VIDEO_PATIENT_JOINED',
  VIDEO_PATIENT_REMOTE_STREAM_CONNECTED = 'VIDEO_PATIENT_REMOTE_STREAM_CONNECTED',
  VIDEO_PATIENT_MIC_MUTED = 'VIDEO_PATIENT_MIC_MUTED',
  VIDEO_PATIENT_MIC_UNMUTED = 'VIDEO_PATIENT_MIC_UNMUTED',
  VIDEO_PATIENT_CAMERA_DISABLED = 'VIDEO_PATIENT_CAMERA_DISABLED',
  VIDEO_PATIENT_CAMERA_ENABLED = 'VIDEO_PATIENT_CAMERA_ENABLED',
  CLIENT_AUTH_ERROR = 'CLIENT_AUTH_ERROR',
  CLIENT_CONNECTION_ERROR = 'CLIENT_CONNECTION_ERROR',
  CLIENT_LIFECYCLE_ERROR = 'CLIENT_LIFECYCLE_ERROR',
}

interface IMeetingSession {
  opentokSession?: IOpenTokSession;
}

interface IUpcomingMeeting {
  needsVideoAudioPermission: boolean;
  shouldDisableIdleTimer: boolean;
}

interface IMeetingContext {
  meetingInfo?: IMeetingInfo;
  meetingSession?: IMeetingSession;
  callAccepted?: boolean;
  upcomingMeeting?: IUpcomingMeeting;
  setMeetingRoomId: (meetingId?: string) => void;
  getMeetingSession: (meetingId: string) => void;
  endCall: (meetingId: string) => void;
  trackTimelineEvent: (
    meetingId: string,
    eventType: IMeetingTimelineEvent,
    eventDetails?: string
  ) => void;
  reportConnection: (sessionId: string, connectionId?: string) => void;

  showSessionError: () => void;
  showStreamError: () => void;
  resetMeetingSession: () => void;
}

export const MeetingContext = React.createContext({} as IMeetingContext);

interface IMeetingContextProvider {
  children: ReactNode;
}

export const MeetingContextProvider = React.memo(
  ({ children }: IMeetingContextProvider) => {
    const [upcomingMeeting, setUpcomingMeeting] = useState<IUpcomingMeeting>();
    const [meetingSession, setMeetingSession] = useState<IMeetingSession>();
    const [meetingInfo, setMeetingInfo] = useState<IMeetingInfo>();
    const [callAccepted, setCallAccepted] = useState<boolean>();

    const { userId, appConfig } = useContext(AppConfigContext);
    const { enableIdleDetection } = useContext(AppContext);

    const navigation = useNavigation();
    const language = useLanguage();
    const overlay = useOverlay();

    const missedCallNotification = useWsPushNotification('missed_call');
    const startVideoMeetingNotification = useWsPushNotification(
      'start_video_meeting'
    );

    const pollMeetingsTimeoutRef = useRef<number>();
    const meetingRoomIdRef = useRef<string>();

    // Used to keep track of the current meeting info for the VIDEO_PATIENT_CALL_SCREEN_PRESENTED event
    const prevMeetingInfoRef = useRef<IMeetingInfo>();

    // Resets states, which in turn resumes polling.
    const resetMeetingSession = useCallback(() => {
      setMeetingInfo(undefined);
      setMeetingSession(undefined);
      setCallAccepted(false);
      prevMeetingInfoRef.current = undefined;
    }, []);

    // Called when entering or existing the meetingRoom flex node.
    // Used to track whether we already are in the correct meeting room nor not.
    const setMeetingRoomId = useCallback((meetingId?: string) => {
      meetingRoomIdRef.current = meetingId;
    }, []);

    const showSessionError = useCallback(() => {
      overlay.presentBasicAlert({
        title: language.get('meeting_ended_title'),
        message: language.get('meeting_ended_info'),
      });
    }, [overlay, language]);

    const showStreamError = useCallback(() => {
      overlay.presentBasicAlert({
        title: language.get('stream_error_title'),
        message: language.get('stream_error_msg'),
      });
    }, [overlay, language]);

    const trackTimelineEvent = useCallback(
      (
        meetingId: string,
        event: IMeetingTimelineEvent,
        eventDetails?: string
      ) => {
        if (meetingId) {
          request(appConfig.meetingVideoEndpoints.trackTimelineEvent, {
            method: 'POST',
            body: decamelizeKeys({
              meetingId,
              timestamp: Date.now(),
              event,
              type: 'VIDEO',
              userType: 'PATIENT',
              source: 'CLIENT',
              ...(eventDetails && { eventDetails }),
            }),
            silent: true,
            retryable: true,
            persistent: true,
          }).catch((error: RequestError) => {
            logError(
              'MeetingContext',
              `Failed posting timeline event: ${event}`,
              error
            );
          });
        }
      },
      [appConfig]
    );

    // Reporting connection ID for potential kry-meeting force disconnection from clinician side
    const reportConnection = useCallback(
      (sessionId: string, connectionId?: string) => {
        if (connectionId) {
          request(appConfig.meetingVideoEndpoints.reportConnection, {
            method: 'POST',
            body: decamelizeKeys({
              sessionId,
              connectionId,
            }),
            silent: true,
            persistent: true,
          }).catch((error: RequestError) => {
            logError(
              'MeetingContext',
              `Failed posting connection report`,
              error
            );
          });
        }
      },
      [appConfig]
    );

    const declineCall = useCallback(
      (meetingId: string) => {
        trackTimelineEvent(
          meetingId,
          IMeetingTimelineEvent.VIDEO_PATIENT_CALL_DECLINED
        );
        request(appConfig.meetingVideoEndpoints.decline, {
          method: 'POST',
          body: {
            meeting_id: meetingId,
            timestamp: Date.now(),
            signature: '',
          },
        })
          .then(() => {
            resetMeetingSession();
          })
          .catch((error: RequestError) => {
            resetMeetingSession();
            logError('MeetingContext', `Failed declining the call`, error);
          });
      },
      [appConfig, resetMeetingSession, trackTimelineEvent]
    );

    const acceptCall = useCallback(
      (meetingInfoArg: IMeetingInfo) => {
        setCallAccepted(true);

        if (meetingInfoArg.meetingId) {
          trackTimelineEvent(
            meetingInfoArg.meetingId,
            IMeetingTimelineEvent.VIDEO_PATIENT_CALL_ANSWERED
          );
        }

        // If we are not already are in the meeting room flex flow for this particular meeting,
        // start the flex flow.
        if (meetingRoomIdRef.current !== meetingInfoArg.meetingId) {
          navigation.push({
            type: 'FLEX',
            source: {
              call: {
                url: `${appConfig.meetingRoomUrl}?meeting_id=${meetingInfoArg.meetingId}&is_video_call_ongoing=true`,
                method: 'POST',
              },
            },
          });
        }
      },
      [appConfig, navigation, trackTimelineEvent]
    );

    const getMeetingInfo = useCallback((meetingId: string) => {
      return request<IMeetingInfo>(`/api/view/meeting/booked/${meetingId}`, {
        silent: true,
        persistent: true,
      });
    }, []);

    const getMeetingSession = useCallback(
      (meetingId: string) => {
        request<IMeetingSession>(
          `${appConfig.meetingVideoEndpoints.getVideoToken}/${meetingId}`,
          {
            persistent: true,
          }
        )
          .then((res) => {
            // NOTE: We're currently not providing any UI for browsers that don't support webRTC
            // and will just show the generic session error.
            if (res.opentokSession && device.IS_WEB_RTC_CAPABLE) {
              setMeetingSession(res);
            } else {
              // If we lack video tokens or the browser doesn't support webRTC, decline the call.
              declineCall(meetingId);
              showSessionError();

              if (device.IS_WEB_RTC_CAPABLE) {
                logError('MeetingContext', `Unexpected video token response`);
              } else {
                logError('MeetingContext', `Browser is not webRTC capable`);
              }
            }
          })
          .catch((error: RequestError) => {
            showSessionError();
            logError('MeetingContext', `Failed getting video token`, error);
          });
      },
      [appConfig, declineCall, showSessionError]
    );

    const endCall = useCallback(
      (meetingId: string) => {
        trackTimelineEvent(
          meetingId,
          IMeetingTimelineEvent.VIDEO_PATIENT_CALL_HUNG_UP
        );
        request(appConfig.meetingVideoEndpoints.hangup, {
          method: 'POST',
          body: {
            meeting_id: meetingId,
          },
        }).catch((error: RequestError) => {
          resetMeetingSession();
          logError('MeetingContext', `Failed ending the call`, error);
        });
      },
      [appConfig, resetMeetingSession, trackTimelineEvent]
    );

    const showCallingScreen = useCallback(
      (meetingInfoArg: IMeetingInfo) => {
        // Making sure that we dont track duplicate events or present the overlay
        // screen multiple times every time the poller runs
        if (
          prevMeetingInfoRef.current?.meetingId !== meetingInfoArg.meetingId &&
          meetingInfoArg.meetingId
        ) {
          setCallAccepted(false);

          trackTimelineEvent(
            meetingInfoArg.meetingId,
            IMeetingTimelineEvent.VIDEO_PATIENT_CALL_SCREEN_PRESENTED
          );

          overlay.presentCallingScreen({
            meetingInfo: meetingInfoArg,
            persistent: true,

            onCallAccepted: () => {
              if (meetingInfoArg.meetingId) {
                acceptCall(meetingInfoArg);
              }
            },
            onCallDeclined: () => {
              if (meetingInfoArg.meetingId) {
                declineCall(meetingInfoArg.meetingId);
              }
            },
          });

          prevMeetingInfoRef.current = meetingInfoArg;
        }
      },
      [acceptCall, declineCall, overlay, trackTimelineEvent]
    );

    useEffect(() => {
      // Trigger and dismiss the calling screen.
      if (meetingInfo?.meetingId && !meetingSession) {
        showCallingScreen(meetingInfo);
      } else if (
        // If the clinician ends the call before the user accepts,
        // dismiss the calling screen.
        !meetingInfo &&
        overlay.getCurrentOverlayType() === 'CALLING_SCREEN'
      ) {
        overlay.dismiss();
      }

      // Present the camera and microphone permission prompt.
      // NOTE: This is just a placeholder for now, as this functionality
      // is not uet implemented.
      if (upcomingMeeting?.needsVideoAudioPermission) {
        // Check whether the permission already has been granted. If not, ask.
      }

      // Disable auto logout due to user inactivity if we have an upcoming meeting
      // of a type that requires us to do so, or during an actually ongoing meeting.
      enableIdleDetection(
        !upcomingMeeting?.shouldDisableIdleTimer && !meetingInfo
      );
    }, [
      upcomingMeeting?.needsVideoAudioPermission,
      upcomingMeeting?.shouldDisableIdleTimer,
      meetingInfo,
      meetingSession,
      overlay,
      enableIdleDetection,
      showCallingScreen,
    ]);

    // Poll for upcoming and ongoing meetings.
    useEffect(() => {
      // Get upcoming meetings.
      // This doesn't actually return an a list of upcoming meetings,
      // but rather instructs the client to e.g. disable the idle detection.
      const getUpcomingMeeting = () => {
        return request<IUpcomingMeeting>(
          appConfig.meetingVideoEndpoints.getUpcoming,
          {
            silent: true,
            persistent: true,
          }
        )
          .then((upcoming) => {
            setUpcomingMeeting(upcoming);
          })
          .catch((error: RequestError) => {
            logWarning(
              'MeetingContext',
              `Failed getting upcoming meetings`,
              error
            );
          });
      };

      // Get a potential ongoing meeting.
      const getOngoingMeeting = () => {
        return request<{ meetingId?: string }>(
          appConfig.meetingVideoEndpoints.getOnGoing,
          {
            silent: true,
            persistent: true,
          }
        ).then((ongoingMeeting) => {
          // If there is an ongoing meeting, fetch its details.
          if (ongoingMeeting.meetingId) {
            getMeetingInfo(ongoingMeeting.meetingId)
              .then((meetingInfoArg) => {
                setMeetingInfo(meetingInfoArg);

                if (!meetingInfoArg.meetingId) {
                  logError(
                    'MeetingContext',
                    `Got unexpected meeting info data`
                  );
                }
              })
              .catch((error: RequestError) => {
                // Report error and fail silently. The request will be retried on the next poll.
                logWarning(
                  'MeetingContext',
                  `Failed getting ongoing meetings`,
                  error
                );
              });
          } else {
            resetMeetingSession();
          }
        });
      };

      const pollMeetings = () => {
        Promise.all([getUpcomingMeeting(), getOngoingMeeting()])
          .catch(() => {
            // Fail silently. Errors are tracked in the two individual functions.
          })
          .finally(() => {});
      };

      // Start polling for upcoming and ongoing meetings as soon as the user logs in,
      // pause while the meeting is taking place and stop if the user logs out.
      // Repetitively check for upcoming and ongoing meetings.
      // The polling continues up until we receive an actual meeting/video session.
      if (userId && !meetingSession) {
        setTimeout(() => {
          // Fetch once in case there is already call screen to show,
          // without waiting for the ongoingMeetingPollInterval;
          pollMeetings();
        }, 1000);
        // Start polling after potentially stopping previous interval.
        clearInterval(pollMeetingsTimeoutRef.current);
        pollMeetingsTimeoutRef.current = window.setInterval(() => {
          pollMeetings();
        }, (appConfig.ongoingMeetingPollInterval || 15) * 1000);
      } else {
        clearInterval(pollMeetingsTimeoutRef.current);
      }

      return () => {
        clearInterval(pollMeetingsTimeoutRef.current);
      };
    }, [
      userId,
      appConfig,
      meetingSession,
      getMeetingInfo,
      resetMeetingSession,
    ]);

    // Handle the "start-video-meeting" push notification.
    // Its payload contains a meetingId which we'll use to fetch the meeting details.
    // Once the details have been set to state, the calling screen will be presented.
    useEffect(() => {
      if (startVideoMeetingNotification) {
        getMeetingInfo(startVideoMeetingNotification.payload.meetingId)
          .then((meetingInfoArg) => {
            setMeetingInfo(meetingInfoArg);
          })
          .catch((error: RequestError) => {
            // Report error and fail silently. The request will be retried on the next poll.
            logError(
              'MeetingContext',
              `Failed getting meeting info after receiving the VOIP push`,
              error
            );
          });
      }
    }, [startVideoMeetingNotification, getMeetingInfo]);

    // Handle the "missed-call" push notification.
    // It's received if the clinician cancels before the user picks up,
    // as well as when the user joins the call, in order to dismiss any ringing on other devices.
    // Clearing the meetingInfo state will also dismiss the calling screen.
    useEffect(() => {
      if (missedCallNotification && !callAccepted) {
        resetMeetingSession();
      }
    }, [missedCallNotification, callAccepted, resetMeetingSession]);

    return (
      <MeetingContext.Provider
        value={{
          meetingInfo,
          meetingSession,
          callAccepted,
          upcomingMeeting,
          setMeetingRoomId,
          getMeetingSession,
          endCall,

          showSessionError,
          showStreamError,

          trackTimelineEvent,
          reportConnection,
          resetMeetingSession,
        }}
      >
        {children}
      </MeetingContext.Provider>
    );
  }
);
