/** @jsx jsx */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { CSSObject, jsx } from '@emotion/core';
import GoogleMapReact from 'google-map-react';
import Supercluster from 'supercluster';
import { IBaseFlexNode } from '../../flexNode';
import MAP_STYLES from '../../../../constants/googleMapsStyles';
import Navbar from '../../../../components/navbar';
import MapPinDetailsCard from './components/mapPinDetailsCard';
import MapSearchBar from './components/mapSearchBar';
import usePersistentState from '../../../../hooks/usePersistentState';
import Clickable from '../../../../components/clickable';
import useFlexNavigation, {
  IFlexNavigation,
} from '../../../../hooks/flex/useFlexNavigation';
import RemoteImage from '../../../../components/RemoteImage';
import useTheme from '../../../../hooks/useTheme';
import Map, {
  getMapBoundsToFitPins,
  IGeoJsonProperties,
  IMapCircularButton,
  IMapCoordinates,
  IMapInitialPosition,
  IMapPin,
} from '../../../../components/interactiveMap/map';

export interface IMapFlexNode extends IBaseFlexNode {
  type: 'map';

  initialPosition: IMapInitialPosition;

  pins: Array<IMapPin>;

  search?: {
    enabled: boolean;
    placeholder: string;
    navigation: IFlexNavigation;
  };

  button?: IMapCircularButton;
}

interface IMapState {
  selectedPinIndex: number;
  centerCoordinates: IMapCoordinates;
  zoomLevel: number;
  userCoordinates?: IMapCoordinates;
}

// Create a singleton instance of Supercluster.
// The radius is set in pixels, set to 80 to have the clustering happen
// before the pins have a chance to overlap.
const supercluster = new Supercluster({ radius: 80 });

interface IMapFlexNodeProps {
  data: IMapFlexNode;
}

const MapFlexNode = React.memo(({ data }: IMapFlexNodeProps) => {
  const [mapState, setMapState] = usePersistentState<IMapState>(
    'MAP_FLEX_NODE',
    {
      // An index of -1 means no pin is currently selected.
      selectedPinIndex: -1,
      centerCoordinates:
        data.initialPosition.type === 'centered_with_zoom_level'
          ? data.initialPosition.coordinates
          : data.initialPosition.coordinates[0],
      zoomLevel:
        data.initialPosition.type === 'centered_with_zoom_level'
          ? data.initialPosition.zoomLevel
          : 1, // The zoom level will be altered based on the bounds on the pins, so this zoom level is only the initial level
    },
    {
      locationAware: true,
    }
  );
  const [mapPoints, setMapPoints] = useState<
    Array<Supercluster.PointFeature<IGeoJsonProperties>>
  >([]);

  const handleFlexNavigation = useFlexNavigation();
  const { color, resolveColor } = useTheme();

  // We need a reference to the initial center coordinates and zoom level,
  // as the Google Maps API throws warnings if they change during runtime.
  const centerCoordinatesRef = useRef(mapState.centerCoordinates);
  const zoomLevelRef = useRef(mapState.zoomLevel);

  // Sets the index and the coords of the pin to the state,
  // which will center the map to that location.
  const setSelectedPinIndex = (index: number) => {
    setMapState((prevState) => {
      return {
        ...prevState,
        centerCoordinates:
          data.pins[index]?.coordinates || prevState.centerCoordinates,
        selectedPinIndex: index,
      };
    });
  };

  // Request the users position using the geolocation api
  // and center the map to that location if successful.
  const centerOnUserLocation = () => {
    navigator.geolocation.getCurrentPosition(
      // Success callback.
      (position) => {
        setMapState((prevState) => {
          return {
            ...prevState,
            userCoordinates: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
            centerCoordinates: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          };
        });
      },
      // Error callback.
      () => {
        // Fail silently for now.
      }
    );
  };

  const handleMapBoundsChange = (event: GoogleMapReact.ChangeEventValue) => {
    setMapState((prevState) => {
      return {
        ...prevState,
        centerCoordinates: event.center,
        zoomLevel: event.zoom,
      };
    });

    // Get map points for the current map bounds.
    setMapPoints(
      supercluster.getClusters(
        [
          event.bounds.nw.lng,
          event.bounds.se.lat,
          event.bounds.se.lng,
          event.bounds.nw.lat,
        ],
        event.zoom
      )
    );
  };

  useEffect(() => {
    // Supercluster works with data in the GeoJSON Feature format:
    // https://tools.ietf.org/html/rfc7946#section-3.2
    // Convert our own pin array into the appropriate format and feed it to Supercluster.
    supercluster.load(
      data.pins.map((pin, index) => ({
        type: 'Feature',
        id: index,
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: [pin.coordinates.lng, pin.coordinates.lat],
        },
      }))
    );
  }, [data.pins]);

  // Set mapPins and fit map to its bounds after the api is loaded
  const apiIsLoaded = (mapsArg: any) => {
    const { maps, map } = mapsArg;

    setMapPoints(
      data.pins.map((pin, index) => ({
        type: 'Feature',
        id: index,
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: [pin.coordinates.lng, pin.coordinates.lat],
        },
      }))
    );

    // Change bounds of map to fit all pin coordinates as tightly as possible
    if (data.initialPosition.type === 'fit_to_coordinates') {
      const bounds = getMapBoundsToFitPins(maps, data.pins);
      map.fitBounds(bounds);
    }
  };

  const CENTER_LOCATION_BUTTON: CSSObject = {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: data.button?.diameter,
    height: data.button?.diameter,
    right: 20,
    top: -76,
    borderRadius: '50%',
    backgroundColor:
      resolveColor(data.button?.backgroundColor) || color.SURFACE_BG_MAIN,
  };

  return (
    <Fragment>
      {/* Navbar */}
      {data.visibleNavbar && <Navbar />}

      <div
        css={{
          position: 'relative',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        {/* Search bar */}
        {data.search?.enabled && (
          <MapSearchBar
            placeholder={data.search.placeholder}
            onClick={() => {
              if (data.search?.navigation) {
                handleFlexNavigation(data.search?.navigation);
              }
            }}
          />
        )}

        {/* Map */}
        <Map
          config={{
            defaultCenter: centerCoordinatesRef.current,
            defaultZoom: zoomLevelRef.current,
            center: mapState.centerCoordinates,
            zoom: mapState.zoomLevel,
            options: {
              zoomControl: false,
              fullscreenControl: false,
              clickableIcons: false,
              gestureHandling: 'greedy',
              styles: MAP_STYLES,
            },
            onChange: handleMapBoundsChange,
            onClick: () => setSelectedPinIndex(-1),
            onGoogleApiLoaded: apiIsLoaded,
          }}
          content={{
            userCoordinates: mapState.userCoordinates,
            mapPoints,
            pins: data.pins,
            selectedPinIndex: mapState.selectedPinIndex,
            setSelectedPinIndex,
          }}
        />

        {/* Center location button and location details card. */}
        <div
          css={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            padding: '0 20px 30px',
            boxSizing: 'border-box',
            transform: `translateY(${
              mapState.selectedPinIndex >= 0 ? 0 : 100
            }%)`,
            transition: 'transform 0.3s ease',
          }}
        >
          {/* Center location button */}
          {data.button && (
            <Clickable
              scale
              css={CENTER_LOCATION_BUTTON}
              onClick={centerOnUserLocation}
            >
              <RemoteImage
                {...data.button.icon}
                css={{
                  height: data.button.iconSize.height,
                  width: data.button.iconSize.width,
                }}
              />
            </Clickable>
          )}

          {/* Location details card */}
          <MapPinDetailsCard
            index={mapState.selectedPinIndex}
            mapPin={data.pins[mapState.selectedPinIndex]}
            onClick={(flexNavigation) => {
              if (flexNavigation) {
                handleFlexNavigation(flexNavigation);
              }
            }}
          />
        </div>
      </div>
    </Fragment>
  );
});

export default MapFlexNode;
