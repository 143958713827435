import useNavigation from '../useNavigation';

import { IBaseAction } from '../useAction';
import { IView } from '../../views/view';

export interface ISpringDetailsAction extends IBaseAction {
  type: 'spring_details';

  data: {
    value: string;
  };
}

const useSpringDetailsAction = () => {
  const navigation = useNavigation();

  const handleSpringDetailsAction = (action: ISpringDetailsAction) => {
    const newView: IView = {
      type: 'SCREEN',
      isSpringDetails: true,
      source: {
        url: action.data.value,
      },
    };

    navigation.present(newView);
  };

  return handleSpringDetailsAction;
};

export default useSpringDetailsAction;
