/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';

interface IMapUserLocationPin {
  lat: number;
  lng: number;
}

const MapUserLocationPin: React.FC<IMapUserLocationPin> = () => {
  return (
    <div
      css={{
        width: 20,
        height: 20,
        transform: 'translate(-50%, -50%)',
        border: '2px solid white',
        borderRadius: '50%',
        backgroundColor: '#03A3F4',
        boxShadow: '0px 0px 0px 4px rgb(3, 163, 244, 0.3)',
      }}
    />
  );
};

export default MapUserLocationPin;
