import { useContext } from 'react';
import { IMenuItemBadgeTextInfo } from '../../contexts/appConfigContext';
import { AppContext } from '../../contexts/appContext';

import { IBaseAction } from '../useAction';

export interface ITabBadgeUpdateAction extends IBaseAction {
  type: 'tab_badge_update';

  data: {
    id: string;
    textInfo: IMenuItemBadgeTextInfo;
  };
}

const useTabBadgeUpdateAction = () => {
  const { updateMenuItemBadge } = useContext(AppContext);

  const handleTabBadgeUpdateAction = (action: ITabBadgeUpdateAction) => {
    updateMenuItemBadge(action.data.id, action.data.textInfo);
  };

  return handleTabBadgeUpdateAction;
};

export default useTabBadgeUpdateAction;
