/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import NotificationBadge, {
  INotificationBadge,
} from '../../../../../components/notificationBadge';

export interface IFiveColumnBadgeCell extends INotificationBadge {
  type: 'badge';
}

interface IFiveColumnBadeCellProps {
  data: IFiveColumnBadgeCell;
}

const FiveColumnBadgeCell = ({ data }: IFiveColumnBadeCellProps) => {
  return <NotificationBadge {...data} />;
};

export default FiveColumnBadgeCell;
