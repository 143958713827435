/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';
import { IAction } from '../../../hooks/useAction';

import { IBasePart, IMargins } from '../part';
import Clickable from '../../../components/clickable';
import useStatefulInput, {
  IInputValidator,
} from '../../../hooks/useStatefulInput';
import RemoteImage, { IRemoteImage } from '../../../components/RemoteImage';
import InputValidationError from '../components/inputValidationError';

export interface IRatingInputPart extends IBasePart {
  type: 'rating_input';

  inputId: string;
  margins: IMargins;

  items: [
    {
      itemId: string;
      idleImage: IRemoteImage;
      activeImage: IRemoteImage;
      margins: IMargins;

      size: {
        height: number;
        width: number;
      };

      action: IAction;
    }
  ];

  selectedItemId?: string;
  readOnly: boolean;
  isExclusive: boolean;
  validators?: Array<IInputValidator>;

  action: IAction;
}

const RatingInputPart = (data: IRatingInputPart) => {
  const {
    inputValue,
    isValid,
    isDirty,
    isTouched,
    errorMessage,
    setInputValue,
    setIsTouched,
  } = useStatefulInput<string | null>({
    inputId: data.inputId,
    initialValue: data.selectedItemId || null,
    validators: data.validators,
  });

  const selectedIndex = data.items.findIndex(
    (item) => item.itemId === inputValue
  );
  const shouldShowErrorState = !isValid && isDirty && isTouched;

  const OUTER_WRAPPER_STYLE: CSSObject = {
    marginTop: data.margins.top,
    marginRight: data.margins.trailing,
    marginBottom: data.margins.bottom,
    marginLeft: data.margins.leading,
  };

  const INNER_WRAPPER_STYLE: CSSObject = {
    display: 'flex',
    justifyContent: 'center',
  };

  return (
    <div css={OUTER_WRAPPER_STYLE}>
      <ul css={INNER_WRAPPER_STYLE}>
        {data.items.map((item, i) => (
          <li
            key={i}
            css={{
              listStyleType: 'none',
              marginTop: item.margins.top,
              marginRight: item.margins.trailing,
              marginBottom: item.margins.bottom,
              marginLeft: item.margins.leading,
            }}
          >
            <Clickable
              scale
              css={{ width: 'auto' }}
              action={item.action}
              onClick={() => {
                setInputValue(item.itemId);
              }}
              onBlur={() => {
                setIsTouched();
              }}
              aria-pressed={item.itemId === inputValue}
              aria-invalid={shouldShowErrorState}
              aria-errormessage={`${data.inputId}-validation-error`}
            >
              {/* When 'isExclusive' is set, only the item which has been chosen should be
                be set as active. Else all the items up to and including the chosen item
                should be set as active. Other items should be set as idle.
            */}
              <RemoteImage
                {...(data.isExclusive
                  ? i === selectedIndex
                    ? { ...item.activeImage }
                    : { ...item.idleImage }
                  : i <= selectedIndex
                  ? { ...item.activeImage }
                  : { ...item.idleImage })}
                css={{
                  width: item.size.width,
                  height: item.size.height,
                }}
              />
            </Clickable>
          </li>
        ))}
      </ul>

      {/* Validation error message */}
      {shouldShowErrorState && (
        <InputValidationError inputId={data.inputId} message={errorMessage} />
      )}
    </div>
  );
};

export default RatingInputPart;
