import { useContext, useEffect, useRef } from 'react';
import Adjust from '@adjustcom/adjust-web-sdk';
import environment from '../utils/environment';
import { AppConfigContext } from '../contexts/appConfigContext';

export interface IAdjustEvent {
  eventToken: string;
}

const appToken = {
  KRY: '32eju87tz85c',
  LIVI: 'z8btc2oioohs',
};

const localEvents = {
  kry: {
    REGISTRATION: { eventToken: 'h09w47' },
  },

  livi: {
    REGISTRATION: { eventToken: 'ca7r7f' },
  },
};

// Keep track of Adjust internal states as the SDK doesn't seem to do that,
// and throws errors if the same state is set twice.
let isEnabled = false;
let isOffline = false;

const useAdjustTracker = () => {
  const { appConfig, userId } = useContext(AppConfigContext);

  const appConfigRef = useRef(appConfig);
  const userIdRef = useRef(userId);

  const event = environment.IS_KRY ? localEvents.kry : localEvents.livi;

  const initialize = () => {
    Adjust.initSdk({
      appToken: environment.IS_KRY ? appToken.KRY : appToken.LIVI,
      environment: environment.IS_DEV ? 'sandbox' : 'production',
    });

    isEnabled = true;
  };

  const enableTracking = () => {
    if (!isEnabled) {
      Adjust.restart();
      isEnabled = true;
    }
  };

  const disableTracking = () => {
    if (isEnabled) {
      Adjust.stop();
      isEnabled = false;
    }
  };

  const enableOfflineMode = () => {
    if (!isOffline) {
      Adjust.switchToOfflineMode();
      isOffline = true;
    }
  };

  const disableOfflineMode = () => {
    if (isOffline) {
      Adjust.switchBackToOnlineMode();
      isOffline = false;
    }
  };

  const trackEvent = (adjustEvent: IAdjustEvent, explicitUserId?: string) => {
    Adjust.trackEvent({
      eventToken: adjustEvent.eventToken,
      callbackParams: [
        { key: 'app_country', value: environment.COUNTRY },

        // Conditionally set the "userId" value if a userId is available.
        ...(explicitUserId || userIdRef.current
          ? [{ key: 'userId', value: explicitUserId || userIdRef.current }]
          : []),
      ],
    });
  };

  // Update mutable refs.
  useEffect(() => {
    appConfigRef.current = appConfig;
    userIdRef.current = userId;
  }, [appConfig, userId]);

  return useRef({
    event,

    initialize,
    enableTracking,
    disableTracking,
    enableOfflineMode,
    disableOfflineMode,
    trackEvent,
  }).current;
};

export default useAdjustTracker;
