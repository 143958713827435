import { useContext } from 'react';
import useNavigation from '../../useNavigation';
import useFlexHttpCall, { IFlexHttpCall } from '../../useFlexHttpCall';
import { FlexContext } from '../../../contexts/flexContext';
import {
  IFlexTopRightButton,
  IFlexProgressMap,
} from '../../../views/flex/flex';
import { IView } from '../../../views/view';
import { IFlexNode } from '../../../views/flex/flexNode';
import { IFlexNavigation } from '../useFlexNavigation';
import { IStructuredSnowplowEvent } from '../../useSnowplowTracker';

interface IFlexDeferredModal {
  node: IFlexNode;
  progressMap?: IFlexProgressMap;
  topRightButton?: IFlexTopRightButton;
}

export interface IFlexNavigateModal {
  type: 'modal';
  node?: IFlexNode;
  deferCall?: IFlexHttpCall;
  progressMap?: IFlexProgressMap;
  topRightButton?: IFlexTopRightButton;
  snowplowEvent?: IStructuredSnowplowEvent;
}

const useFlexNavigateModal = () => {
  const flexContext = useContext(FlexContext);
  const navigation = useNavigation();

  const handleFlexHttpCall = useFlexHttpCall();

  const handleFlexNavigateModal = (
    flexNavigate: IFlexNavigateModal
  ): Promise<IFlexNavigation> => {
    return new Promise((resolve, reject) => {
      const navigateToView = (view: IView) => {
        // If this is the initial navigation, part of actually starting the flow,
        // we want to just replace the empty flex route, rather than navigating to a new one.
        if (!flexContext.node) {
          navigation.replaceWithModal(view);
        } else {
          navigation.present(view);
        }
      };

      if (flexNavigate.node) {
        navigateToView({
          type: 'FLEX',
          source: {
            node: {
              ...flexNavigate.node,
              topRightButton:
                flexNavigate.node.topRightButton || flexNavigate.topRightButton,
              memory: flexNavigate.node.memory || flexContext.node?.memory,
            },
          },
        });

        return;
      }

      if (flexNavigate.deferCall) {
        const call = flexNavigate.deferCall;
        handleFlexHttpCall<IFlexDeferredModal>(call)
          .then((resolvedModal) => {
            navigateToView({
              type: 'FLEX',
              source: {
                node: {
                  ...resolvedModal.node,
                  topRightButton:
                    resolvedModal.node.topRightButton ||
                    flexNavigate.topRightButton,
                  memory: resolvedModal.node.memory || flexContext.node?.memory,
                },
              },
            });
          })
          .catch((error) => reject(error));
      }
    });
  };

  return handleFlexNavigateModal;
};

export default useFlexNavigateModal;
