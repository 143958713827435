/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';
import useTheme from '../hooks/useTheme';
import device from '../utils/device';
import appStoreLinks from '../constants/appStoreLinks';
import useLanguage from '../hooks/useLanguage';
import LocalImage from './localImage';
import BottomSticky from './bottomSticky';

const UnsupportedBrowser = () => {
  const language = useLanguage();
  const { color, font } = useTheme();

  const STYLE: CSSObject = {
    display: 'flex',
    alignItems: 'center',
    order: 9, // Make sure the warning is rendered last in the footer stack.
    backgroundColor: color.STATUS_BG_NOTICE,
    padding: 20,
    fontSize: 12,

    img: {
      width: 20,
      height: 20,
      marginRight: 8,
    },

    a: {
      color: color.PRIMARY,
      fontFamily: font.FAMILY_MEDIUM,
    },
  };

  return (
    <BottomSticky>
      <div css={STYLE}>
        {/* Info icon */}
        <LocalImage src='ICON32_CIRCLE_INFO' tint='TEXT' alt='' />

        {/* Message and links */}
        <div>
          <span>
            {language.get('patient_web.unsupported_browser.pre_link_text')}{' '}
          </span>
          {/* Mobile, device specific link */}
          {device.IS_MOBILE && (
            <a href={appStoreLinks.THIS_DEVICE}>
              {language.get('patient_web.unsupported_browser.link_text')}
            </a>
          )}
          {/* Desktop */}
          {!device.IS_MOBILE && (
            <span>
              {language.get('patient_web.unsupported_browser.link_text')}
            </span>
          )}
        </div>
      </div>
    </BottomSticky>
  );
};

export default UnsupportedBrowser;
