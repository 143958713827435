/** @jsx jsx */
import { Fragment, useContext } from 'react';
import ReactDOM from 'react-dom';
import { jsx, CSSObject } from '@emotion/core';

import { ScreenContext } from '../contexts/screenContext';
import { FlexContext } from '../contexts/flexContext';
import Clickable from './clickable';
import { IAction } from '../hooks/useAction';
import useNavigation from '../hooks/useNavigation';
import layout from '../constants/layout';
import useTheme from '../hooks/useTheme';
import RemoteImage, { IRemoteImage } from './RemoteImage';
import LocalImage from './localImage';
import useLanguage from '../hooks/useLanguage';
import useFlexNavigation, {
  IFlexNavigation,
} from '../hooks/flex/useFlexNavigation';
import { ViewContext } from '../contexts/viewContext';

interface ITopRightButton {
  title?: string;
  image?: IRemoteImage;
  action?: IAction;
  flexNavigation?: IFlexNavigation;
}

const Navbar = () => {
  const { headerElement } = useContext(ViewContext);
  const screenContext = useContext(ScreenContext);
  const flexContext = useContext(FlexContext);

  const { color } = useTheme();
  const language = useLanguage();

  const navigation = useNavigation();
  const currentView = navigation.getCurrentView();

  const handleFlexNavigation = useFlexNavigation();

  const isTransparent =
    currentView.isSpringDetails || screenContext.screen?.topBar?.transparent;

  const shouldShowBackButton = !currentView.preventBack && navigation.canPop();

  const screenTopRightButton = screenContext.screen?.topRightAction;
  const flexTopRightButton =
    flexContext.node?.topRightButton || flexContext.topRightButton;

  // Map Screen.topRightAction and Flex.topRightButton to a common format.
  const topRightButton: ITopRightButton | null = screenContext.screen
    ?.topRightAction
    ? {
        title: screenTopRightButton?.text,
        ...(screenTopRightButton?.image && {
          image: {
            name: screenTopRightButton?.image,
          },
        }),
        action: screenTopRightButton?.action,
      }
    : flexTopRightButton
    ? {
        title: flexTopRightButton?.title,
        image: flexTopRightButton?.image,
        flexNavigation: flexTopRightButton?.navigation,
      }
    : currentView.isSpringDetails
    ? {
        image: {
          name: 'remove',
        },
        action: {
          type: 'dismiss',
        },
      }
    : null;

  const title =
    screenContext.screen?.title ||
    flexContext.node?.title ||
    navigation.getCurrentView().title;

  const { progress } = flexContext;

  const STYLE: CSSObject = {
    display: 'flex',
    position: 'relative',
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 6px',
    height: layout.APP_NAVBAR_HEIGHT,

    // When the navbar is supposed to be transparent,
    // set its color to transparent and add a negative bottom margin,
    // corresponding its height, pushing the content below it underneath.
    backgroundColor: isTransparent ? 'transparent' : color.LOCAL_WHITE,
    marginBottom: isTransparent ? layout.APP_NAVBAR_HEIGHT * -1 : 0,
  };

  const TITLE_STYLE: CSSObject = {
    flex: 1,
    textAlign: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  };

  const TOP_RIGHT_BUTTON_STYLE: CSSObject = {
    display: 'flex',
    alignItems: 'center',
    height: 38,
    width: 'auto',
    maxWidth: '100%',
    float: 'right',
    padding: '0 7px',
    color: color.PRIMARY,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontSize: 16,

    img: {
      height: 32,
    },
  };

  const PROGRESS_STYLE: CSSObject = {
    position: 'absolute',
    width: '100%',
    height: 3,
    left: 0,
    bottom: 0,
    backgroundColor: color.UI_IDLE,
    '::after': {
      content: `''`,
      display: 'inline-block',
      position: 'absolute',
      height: 3,
      left: 0,
      top: 0,
      width: `${(progress || 0) * 100}%`,
      backgroundColor: color.PRIMARY,
    },
  };

  const TOP_LEFT_CONTAINER_STYLE: CSSObject = {
    display: 'flex',
    alignItems: 'center',
    height: 38,
    width: 110,
  };

  const TOP_LEFT_BUTTON_CONTAINER_STYLE: CSSObject = {
    maxWidth: 110,
  };

  const TOP_LEFT_BUTTON_STYLE: CSSObject = { paddingRight: 10, height: 38 };

  const TOP_RIGHT_BUTTON_CONTAINER_STYLE: CSSObject = {
    width: 110,
    height: 38,
    textAlign: 'right',
  };

  const BACK_NAVIGATION_STYLE: CSSObject = {
    display: 'flex',
    alignItems: 'center',
    color: color.PRIMARY,
    fontSize: 16,
  };

  const TRUNCATE_TEXT_STYLE: CSSObject = {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  };

  const componentOutput = (
    <div css={STYLE}>
      {/* Left button */}
      <div css={TOP_LEFT_CONTAINER_STYLE}>
        {shouldShowBackButton && (
          <div css={TOP_LEFT_BUTTON_CONTAINER_STYLE}>
            <Clickable
              css={TOP_LEFT_BUTTON_STYLE}
              dim
              onClick={() => {
                navigation.pop({ type: 'back' });
              }}
            >
              <div css={BACK_NAVIGATION_STYLE}>
                <LocalImage
                  css={{ minWidth: '32px' }}
                  src='CHEVRON_LEFT'
                  tint='PRIMARY'
                  alt=''
                />
                <span css={TRUNCATE_TEXT_STYLE}>{language.get('back')}</span>
              </div>
            </Clickable>
          </div>
        )}
      </div>

      {/* Title */}
      <h1 css={TITLE_STYLE}>{title}</h1>

      {/* Right button */}
      <div css={TOP_RIGHT_BUTTON_CONTAINER_STYLE}>
        {topRightButton && (
          <Clickable
            css={TOP_RIGHT_BUTTON_STYLE}
            dim
            onClick={() => {
              if (topRightButton.flexNavigation) {
                handleFlexNavigation(topRightButton.flexNavigation);
              }
            }}
            action={topRightButton.action}
          >
            {/* Right button text */}
            {!topRightButton?.image && (
              <span css={TRUNCATE_TEXT_STYLE}>{topRightButton.title}</span>
            )}

            {/* Right button image */}
            {topRightButton?.image && (
              <Fragment>
                {/* Remote image */}
                {topRightButton?.image?.url && (
                  <RemoteImage {...topRightButton?.image} />
                )}

                {/* Local image - if we don't have an img url and the view is not SpringDetails, render the local close button asset. */}
                {!currentView.isSpringDetails &&
                  !topRightButton?.image?.url && (
                    <LocalImage src='ICON32_CROSS' tint='PRIMARY' />
                  )}

                {/* Local image - SpringDetails views have a hardcoded, round close button. */}
                {currentView.isSpringDetails && (
                  <LocalImage src='CLOSE_BUTTON_ROUND' />
                )}
              </Fragment>
            )}
          </Clickable>
        )}
      </div>

      {/* Progress bar */}
      {progress !== undefined && <div css={PROGRESS_STYLE} />}
    </div>
  );

  return headerElement && ReactDOM.createPortal(componentOutput, headerElement);
};

export default Navbar;
