/** @jsx jsx */
import React, { useContext } from 'react';
import { jsx, CSSObject } from '@emotion/core';

import BlockItem, { IBlockBaseItem, IBlockItem } from '../blockItem';

import { IAction } from '../../../../../hooks/useAction';
import useTheme from '../../../../../hooks/useTheme';
import { hexToRgb } from '../../../../../utils/hexToRgba';
import Clickable from '../../../../../components/clickable';
import { ScreenContext } from '../../../../../contexts/screenContext';

export type IBlockContainerOrientation = 'vertical' | 'horizontal';

export interface IBlockStackContainerItem extends IBlockBaseItem {
  type: 'stack_container';

  items: Array<IBlockItem>;
  orientation: IBlockContainerOrientation;
  highlightColor?: string;

  shadow?: {
    offset: number;
    color: string;
    opacity: number;
    radius: number;
  };

  action?: IAction;
}

interface IBlockStackContainerItemProps {
  data: IBlockStackContainerItem;
}

const BlockStackContainerItem = ({
  data,
  ...props
}: IBlockStackContainerItemProps) => {
  const { hiddenIds } = useContext(ScreenContext);
  const { resolveColor } = useTheme();

  const { items, orientation, highlightColor, action, shadow } = data;

  // Check whether the container has input items that do not handle
  // their own focus state. In those cases, the entire container is rendered
  // as a label, is clickable and renders a focus border.
  const hasNonFocusableInputItem = () => {
    return !!items.find((item) => {
      switch (item.type) {
        case 'checkbox':
        case 'radio_button':
        case 'toggle':
          return true;
        default:
          return false;
      }
    });
  };

  // A crude way of making accordions more accessible.
  // If the container has a "modify" action with a toggles array, assume it's an accordion.
  // Cross-reference the first part id in the array with the current state of screen hiddenIds array
  // to guesstimate whether the accordion is expanded or collapsed.
  const getPotentialAccordionState = () => {
    if (action?.type === 'modify' && action.data.toggles?.length > 0) {
      return !hiddenIds.includes(action.data.toggles[0].id);
    }
  };

  const CONTAINER_STYLE: CSSObject = {
    display: 'flex',
    boxSizing: 'border-box',

    flexDirection: orientation === 'horizontal' ? 'row' : 'column',

    ...(shadow && {
      boxShadow: `0px ${shadow.offset}px
      ${2 * shadow.radius}px
      rgba(${hexToRgb(resolveColor(shadow.color) || '')},${shadow.opacity})`,
    }),

    ...(highlightColor && {
      '&[active]': {
        backgroundColor: resolveColor(highlightColor),
      },
    }),
  };

  return (
    <Clickable
      renderAs={hasNonFocusableInputItem() ? 'label' : undefined}
      defaultHandler={hasNonFocusableInputItem()}
      action={!hasNonFocusableInputItem() ? action : undefined}
      {...props}
      css={CONTAINER_STYLE}
      aria-expanded={getPotentialAccordionState()}
    >
      {items.map((item, index) => {
        return (
          <BlockItem
            key={index}
            {...{ ...item, parentOrientation: orientation }}
          />
        );
      })}
    </Clickable>
  );
};

export default BlockStackContainerItem;
