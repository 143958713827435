/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';

import { IBlockBaseItem } from '../blockItem';

export interface IBlockShapeItem extends IBlockBaseItem {
  type: 'shape';
}

interface IBlockShapeItemProps {
  data: IBlockShapeItem;
}

const BlockShapeItem = ({ data, ...props }: IBlockShapeItemProps) => {
  return <div {...props} />;
};

export default BlockShapeItem;
