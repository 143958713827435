import useOverlay from '../../../useOverlay';
import useFlexHttpCall, { IFlexHttpCall } from '../../../useFlexHttpCall';
import { attemptToLaunchBankId } from '../../../../utils/bankid';
import { IFlexNavigation } from '../../useFlexNavigation';

export interface IFlexOverlayBankidSigning {
  type: 'bankid_signing';
  startCall: IFlexHttpCall;
}

interface IBankIdSignStartSuccess {
  startUrl: string;
  pollCall: IFlexHttpCall;
}

interface IPollingResponse {
  status: 'pending' | 'success';
  completionNavigation?: IFlexNavigation;
}

// A singleton keeping track of the BankID poll timeout.
let bankIdPollingTimeoutId: number;

const useFlexOverlayBankidSigning = () => {
  const handleFlexHttpCall = useFlexHttpCall();
  const overlay = useOverlay();

  const handleFlexOverlayBankidSigning = (
    flexOverlay: IFlexOverlayBankidSigning
  ): Promise<IFlexNavigation> => {
    // Polls the BankID sign status.
    const pollBankIdSign = (
      pollCall: IFlexHttpCall
    ): Promise<IFlexNavigation | undefined> => {
      return new Promise((resolve, reject) => {
        const pollStatus = () => {
          handleFlexHttpCall<IPollingResponse>(pollCall, { silent: true })
            .then((res) => {
              if (res.status === 'success') {
                resolve(res.completionNavigation);
              } else {
                bankIdPollingTimeoutId = window.setTimeout(() => {
                  pollStatus();
                }, 2000);
              }
            })
            .catch((error) => reject(error));
        };
        pollStatus();
      });
    };

    // Initiates the BankID sign process and attempts to launch
    // the BankID app if on supported platform.
    const initiateBankIdSigning = (
      startCall: IFlexHttpCall
    ): Promise<IBankIdSignStartSuccess> => {
      return new Promise((resolve, reject) => {
        handleFlexHttpCall<IBankIdSignStartSuccess>(startCall, { silent: true })
          .then((res) => {
            attemptToLaunchBankId(res.startUrl);
            resolve(res);
          })
          .catch((error) => reject(error));
      });
    };

    return new Promise((resolve, reject) => {
      // Present the sign overlay, which just visualizes the process.
      overlay.presentBankId({
        role: 'sign',
        onDismiss: () => {
          clearTimeout(bankIdPollingTimeoutId);
        },
      });

      // Initiate the BankID sign process and start polling.
      initiateBankIdSigning(flexOverlay.startCall)
        .then((data) => pollBankIdSign(data.pollCall))
        .then((completionNavigation) => {
          overlay.dismiss();
          if (completionNavigation) {
            resolve(completionNavigation);
          }
        })
        .catch((error) => {
          overlay.dismiss();
          reject(error);
        });
    });
  };

  return handleFlexOverlayBankidSigning;
};

export default useFlexOverlayBankidSigning;
