/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';

import { IBasePart, IMargins } from '../part';
import IFrame from '../../../components/iframe';
import useInternalUrl from '../../../hooks/useInternalUrl';

export interface IHtmlPart extends IBasePart {
  type: 'html';

  body: string;
  margins: IMargins;
}

const HtmlPart = (data: IHtmlPart) => {
  const handleInternalUrl = useInternalUrl();

  const WRAPPER_STYLE: CSSObject = {
    marginLeft: data.margins.leading,
    marginRight: data.margins.trailing,
    marginTop: data.margins.top,
    marginBottom: data.margins.bottom,
  };

  const IFRAME_STYLE: CSSObject = {
    width: '100%',
    height: '0',
  };

  return (
    <div css={WRAPPER_STYLE}>
      <IFrame
        css={IFRAME_STYLE}
        HTMLSource={data.body}
        fitContent
        injectFontFaces
        frameBorder='0'
        scrolling='no'
        onLinkClick={handleInternalUrl}
      />
    </div>
  );
};

export default HtmlPart;
