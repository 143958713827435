/* eslint-disable no-console */
import { useCallback, useContext } from 'react';
import * as logger from '../../utils/remoteLogger';

import { AppConfigContext } from '../../contexts/appConfigContext';
import { IBaseAction } from '../useAction';
import WebPushService from '../../services/WebPushService';
import useOverlay from '../useOverlay';
import useLanguage from '../useLanguage';
import sessionStorage from '../../utils/sessionStorage';

export interface IWebPushNotificationAction extends IBaseAction {
  type: 'notifications_request_permission';
  data: {};
}

const useAlreadyPrompted = () => {
  // could not use useSessionStorage cause actions are interfering
  const key = `web_push_permission_prompt`;
  const get = useCallback(() => sessionStorage.get(key), [key]);
  const set = useCallback((state) => sessionStorage.set(key, state), [key]);
  return [() => !!get(), () => set(Date.now().valueOf())] as const;
};

const useWebPushNotificationAction = () => {
  const { appConfig } = useContext(AppConfigContext);
  const overlay = useOverlay();
  const language = useLanguage();
  const pushState = WebPushService.getState();
  const [isAlreadyPrompted, setAlreadyPrompted] = useAlreadyPrompted();

  const handleWebPushNotificationAction = async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _: IWebPushNotificationAction
  ) => {
    logger.logDebug(
      'webPush',
      `WebPushNotificationAction handler, current state is ${pushState}, already presented: ${isAlreadyPrompted()}`
    );
    switch (pushState) {
      case 'disabled':
      case 'unknown':
        // means not supported or previously disabled, do nothing for the moment
        break;
      case 'not_determined':
        if (!isAlreadyPrompted()) {
          overlay.presentIconAlert({
            image: { url: 'https://appresources.kry.se/icons/Bell-Icon.png' },
            orientation: 'vertical',
            title: language.get('web_browser_push_permission_prompt_title'),
            message: language.get('web_browser_push_permission_prompt_body'),
            fullscreenOnMobile: true,
            items: [
              {
                title: language.get('web_browser_push_permission_prompt_cta'), // 'Turn on Notifications',
                style: 'normal',
                onClick: async () => {
                  await WebPushService.initialise(
                    appConfig.webPushVapidKeyPublic
                  );
                },
              },
              {
                title: language.get('web_browser_push_permission_prompt_skip'),
                style: 'cancel',
                onClick: () => {
                  setAlreadyPrompted();
                },
              },
            ],
          });
        }
        break;
      /* case 'disabled':
        overlay.presentBasicAlert({
          title: language.get('web_browser_push_permission_disabled_title'),
          message: language.get('web_browser_push_permission_disabled_body'),
          fullscreenOnMobile: true,
        });
        break; */
      case 'enabled': {
        await WebPushService.initialise(appConfig.webPushVapidKeyPublic);
        break;
      }
    }
  };

  return handleWebPushNotificationAction;
};

export default useWebPushNotificationAction;
