import useNavigation from '../useNavigation';

import { IBaseAction } from '../useAction';
import { IView } from '../../views/view';

export interface ISearchAction extends IBaseAction {
  type: 'search';

  data: {
    context?: string;
    endpoint: string;
    searchPlaceholder?: string;
  };
}

const useSearchAction = () => {
  const navigation = useNavigation();

  const handleSearchAction = (action: ISearchAction) => {
    const newView: IView = {
      type: 'SEARCH_SCREEN',
      source: {
        url: action.data.endpoint,
        requestOptions: {
          method: 'POST',
        },
        searchPlaceholder: action.data.searchPlaceholder,
        context: action.data.context,
      },
    };

    navigation.present(newView);
  };

  return handleSearchAction;
};

export default useSearchAction;
