/** @jsx jsx */
import React, { Fragment } from 'react';
import { jsx } from '@emotion/core';
import { decamelizeKeys } from 'humps';
import Screen from '../../screen/screen';
import { IBaseFlexNode } from '../flexNode';
import { ScreenContextProvider } from '../../../contexts/screenContext';
import {
  SearchContextProvider,
  ISearchSource,
} from '../../../contexts/searchContext';
import { IFlexHttpCall } from '../../../hooks/useFlexHttpCall';

export interface ISearchFlexNode extends IBaseFlexNode {
  type: 'search';
  call: IFlexHttpCall;
  placeholder?: string;
}

interface ISearchFlexNodeProps {
  data: ISearchFlexNode;
}

const SearchFlexNode = React.memo(({ data }: ISearchFlexNodeProps) => {
  const generateSearchSource = (): ISearchSource => {
    return {
      url: data.call?.url || '',
      requestOptions: {
        method: data.call.method,
        body: {
          ...(typeof data.call.body === 'object' && {
            ...decamelizeKeys(data.call.body),
          }),
          ...(typeof data.memory === 'object' && {
            ...decamelizeKeys(data.memory),
          }),
        },
        retryable: data.call.retryable,
      },
      searchPlaceholder: data.placeholder,
    };
  };

  return (
    <Fragment>
      <ScreenContextProvider>
        <SearchContextProvider routeSource={generateSearchSource()} />
        <Screen />
      </ScreenContextProvider>
    </Fragment>
  );
});

export default SearchFlexNode;
