/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';
import useStatefulInput, {
  IInputValidator,
} from '../../../hooks/useStatefulInput';

import { IBasePart, IMargins } from '../part';
import useAction, { IAction } from '../../../hooks/useAction';
import useTheme from '../../../hooks/useTheme';
import AttributedText from '../../../components/attributedText';
import InputValidationError from '../components/inputValidationError';

export interface ITextAreaInputPart extends IBasePart {
  type: 'text_area_input';

  inputId: string;
  text: string;
  placeholder: string;
  lineCount: number;
  borderCornerRadius: number;
  autoResize: boolean;

  minHeight: number;
  borderColor: string;
  inactiveBorderColor: string;
  activeBorderColor: string;

  returnKeyType?: string;
  returnKeyAction?: IAction;

  label?: string;
  validators?: Array<IInputValidator>;

  margins: IMargins;
}

const TextAreaInputPart = (data: ITextAreaInputPart) => {
  const {
    inputValue,
    isValid,
    isDirty,
    isTouched,
    errorMessage,
    setInputValue,
    setIsTouched,
  } = useStatefulInput<string>({
    inputId: data.inputId,
    initialValue: data.text,
    validators: data.validators,
  });

  const { color, resolveColor } = useTheme();
  const handleAction = useAction();

  const shouldShowErrorState = !isValid && isDirty && isTouched;

  const TEXTAREA_STYLE: CSSObject = {
    fontSize: 17,
    border: `2px solid ${
      shouldShowErrorState
        ? color.ALERT_SECONDARY_PRESS
        : resolveColor(data.inactiveBorderColor)
    }`,
    borderRadius: data.borderCornerRadius,
    padding: '10px 16px',
    marginTop: '6px',

    ':focus': {
      border: `2px solid ${
        shouldShowErrorState
          ? color.ALERT
          : resolveColor(data.activeBorderColor)
      }`,
    },
    '::placeholder': {
      color: color.INPUT_PLACEHOLDER,
    },

    resize: 'vertical',
  };

  const LABEL_STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: data.margins.leading,
    marginRight: data.margins.trailing,
    marginTop: data.margins.top,
    marginBottom: data.margins.bottom,
  };

  return (
    <label css={LABEL_STYLE}>
      <AttributedText text={data.label} />
      <textarea
        rows={data.lineCount}
        value={inputValue}
        placeholder={data.placeholder}
        css={TEXTAREA_STYLE}
        onChange={(e) => {
          e.stopPropagation();
          setInputValue(e.currentTarget.value);
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleAction(data.returnKeyAction);
          }
        }}
        onBlur={() => {
          setIsTouched();
        }}
        aria-invalid={shouldShowErrorState}
        aria-errormessage={`${data.inputId}-validation-error`}
        // @TODO: enterKeyHint is not supported in React 16, force the property until we update.
        {...{
          enterkeyhint: data.returnKeyType,
        }}
      />

      {/* Validation error message */}
      {shouldShowErrorState && (
        <InputValidationError inputId={data.inputId} message={errorMessage} />
      )}
    </label>
  );
};

export default TextAreaInputPart;
