/** @jsx jsx */
import React, { useContext, Fragment } from 'react';
import { jsx } from '@emotion/core';

// Supported card items.
import FiveColumnCardItem from './items/fiveColumnCardItem';
import SpaceCardItem from './items/spaceCardItem';
import MapCardItem from './items/mapCardItem';
import LineCardItem from './items/lineCardItem';

import { ScreenContext } from '../../../../contexts/screenContext';
import useTheme from '../../../../hooks/useTheme';

const cardItemComponents: Record<
  string,
  ({ data }: ICardItem<any>) => JSX.Element
> = {
  five_column: FiveColumnCardItem,
  line: LineCardItem,
  map: MapCardItem,
  space: SpaceCardItem,
};

export type ICardItem<T> = {
  id: string;
  type: string;
  bgColor: string;
} & T;

interface ICardItemProps {
  data: ICardItem<any>;
  hidden?: boolean;
}

const CardItem = ({ data, hidden }: ICardItemProps) => {
  const { resolveColor } = useTheme();
  const screenContext = useContext(ScreenContext);
  const CardItemComponent = cardItemComponents[data.type];

  return (
    <Fragment>
      {CardItemComponent && (
        <div
          css={{
            display:
              hidden || screenContext.hiddenIds.includes(data.id)
                ? 'none'
                : 'block',
            backgroundColor: resolveColor(data.bgColor),
          }}
        >
          <CardItemComponent data={data} />
        </div>
      )}
    </Fragment>
  );
};

export default CardItem;
