/** @jsx jsx */
import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  Fragment,
} from 'react';
import { jsx } from '@emotion/core';
import { decamelizeKeys } from 'humps';
import useOverlay from '../../../../../../hooks/useOverlay';

import { ISurveyNode, ISurveyNodeAnswer } from '../surveyNode';
import { SurveyContext } from '../../../../../../contexts/surveyContext';

import Title from '../../../../../../components/title';
import CheckBox from '../../../../../../components/checkBox';
import Clickable from '../../../../../../components/clickable';
import useTheme from '../../../../../../hooks/useTheme';

interface IContextablePrescriptionRecordsConsentSurveyNode {
  node: ISurveyNode;
  isSubNode?: boolean;
}

const ContextablePrescriptionRecordsConsentSurveyNode = ({
  node,
  isSubNode,
}: IContextablePrescriptionRecordsConsentSurveyNode) => {
  const { color } = useTheme();
  const { registerAnswer, getAnswerByPath } = useContext(SurveyContext);
  const [state, setState] = useState<ISurveyNodeAnswer['observations']>(
    getAnswerByPath(node.path)?.observations
  );
  const overlay = useOverlay();
  const nodeVisistedAt = useRef(Date.now());

  useEffect(() => {
    registerAnswer({
      nodeName: node.name,
      path: node.path,
      property: node.property,
      observations: state && (decamelizeKeys(state) as Array<object>),
      answeredAt: Date.now(),
      visitedAt: nodeVisistedAt.current,
    });
  }, [node, state, registerAnswer]);

  return (
    <Fragment>
      <Title
        title={node.title}
        subtitle={node.subtitle}
        subdued={isSubNode}
        css={{
          padding: '0 20px',
          marginBottom: isSubNode ? 12 : 20,
        }}
      />

      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          padding: '10px 0',
        }}
      >
        {node.body?.content?.map((content, i) => {
          switch (content.type) {
            case 'checkbox':
              return (
                <Clickable
                  renderAs='label'
                  styleAs='listItem'
                  defaultHandler
                  key={i}
                  css={{
                    justifyContent: 'unset',
                  }}
                >
                  <CheckBox
                    checked={!!state}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setState(node.body?.meta?.observations);
                      } else {
                        setState(undefined);
                      }
                    }}
                  />
                  <div css={{ marginLeft: 6, maxWidth: 240 }}>
                    {content.labelTitle}
                  </div>
                </Clickable>
              );

            case 'prescription_consent_info':
              return (
                <Clickable
                  styleAs='listItem'
                  key={i}
                  css={{ color: color.PRIMARY }}
                  onClick={() => {
                    overlay.presentIFrame({
                      HTMLSource: content.copy,
                    });
                  }}
                >
                  {content.labelTitle}
                </Clickable>
              );

            case 'prescription_consent_group':
              return (
                <Clickable
                  styleAs='listItem'
                  key={i}
                  css={{ color: color.PRIMARY }}
                  onClick={() => {
                    overlay.presentIFrame({
                      HTMLSource: content.copy,
                    });
                  }}
                >
                  {content.labelTitle}
                </Clickable>
              );
            default:
              return null;
          }
        })}
      </div>
    </Fragment>
  );
};

export default ContextablePrescriptionRecordsConsentSurveyNode;
