/** @jsx jsx */
import React, { useState, useEffect, useRef, useContext } from 'react';
import { jsx, CSSObject } from '@emotion/core';
import CodeInput from '../../../components/codeInput';
import Title from '../../../components/title';
import request from '../../../utils/request';
import apiKeys from '../../../constants/apiKeys';
import useNavigation from '../../../hooks/useNavigation';
import useRequestError from '../../../hooks/useRequestError';
import useLanguage from '../../../hooks/useLanguage';
import Clickable from '../../../components/clickable';
import useTheme from '../../../hooks/useTheme';
import { PasswordLoginContext } from '../../../contexts/passwordLoginContext';
import useOverlay from '../../../hooks/useOverlay';
import { AppContext } from '../../../contexts/appContext';

interface ICodeVerifyResponse {
  state: 'NEW' | 'PASSWORD_REQUIRED' | 'EXISTING' | 'VERIFICATION_REQUIRED';
  deviceId: string;
  sessionId: string;
  enterPasswordScreenResource: string;
}

const SmsCodeEntryView = () => {
  const { saveSession } = useContext(AppContext);
  const { phone, smsCode, setSmsCode } = useContext(PasswordLoginContext);
  const navigation = useNavigation();
  const handleRequestError = useRequestError();
  const overlay = useOverlay();
  const language = useLanguage();
  const { color, font } = useTheme();

  // Time remaining to allow user to resend code.
  const [timeToResend, setTimeToResend] = useState(60);

  // Resend sms code countdown timer.
  // It will make resend button appear after 60 secs by decrementing timeToResend.
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (timeToResend <= 0) {
        clearTimeout(timeoutId);
        return;
      }

      setTimeToResend(timeToResend - 1);
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [timeToResend]);

  const verifyCode = useRef(
    async (code: string, countryCode: string, phoneNumber: string) => {
      try {
        const verifyResponse = await request<ICodeVerifyResponse>(
          '/api/view/register/device/verify',
          {
            method: 'POST',
            body: {
              code,
              country_code: countryCode,
              phone_number: phoneNumber,
              pub_key: apiKeys.KRY_PUB,
              timestamp: Date.now(),
            },
          }
        );

        switch (verifyResponse.state) {
          case 'NEW':
            navigation.push({
              type: 'LOGIN',
              source: {
                loginMethod: 'PASSWORD_LOGIN',
                viewType: 'USER_DETAILS',
              },
            });
            break;

          // Save the session and reinitialize the app.
          case 'EXISTING':
            saveSession(
              verifyResponse.sessionId,
              verifyResponse.deviceId,
              true
            );
            break;

          // Save the session and push the supplied password screen.
          case 'PASSWORD_REQUIRED':
            saveSession(verifyResponse.sessionId, verifyResponse.deviceId);

            navigation.push({
              type: 'LOGIN',
              source: {
                loginMethod: 'PASSWORD_LOGIN',
                viewType: 'PASSWORD_ENTRY',
                screenResource: verifyResponse.enterPasswordScreenResource,
              },
            });
            break;
          case 'VERIFICATION_REQUIRED':
            // The web client currently doesn't support Onfido. The dialog will instruct the user to open Livi app and create new password.
            overlay.presentAlert({
              title: language.get('pin_code_setup_title_signin'),
              message: language.get('create_new_password_message'),
              items: [
                {
                  title: language.get('ok'),
                  onClick: () => {
                    navigation.reinitializeApp();
                  },
                },
              ],
            });
            break;
          default:
            break;
        }
      } catch (e) {
        handleRequestError(e);
        setSmsCode('');
      }
    }
  );

  useEffect(() => {
    if (smsCode.length === 4) {
      verifyCode.current(smsCode, phone.countryCode, phone.phoneNumber);
    }
  }, [smsCode, phone]);

  const resendSmsCode = async () => {
    try {
      await request('/api/view/register/device/sms', {
        method: 'POST',
        body: {
          phone_number: phone.phoneNumber,
          country_code: phone.countryCode,
        },
      });

      // Reset timer after resending code.
      setTimeToResend(60);
    } catch (e) {
      handleRequestError(e);
    }
  };

  const WRAPPER_STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    height: 'auto',
  };

  const CONTAINER_STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    maxWidth: 300,
    marginTop: 44,
  };

  return (
    <div css={WRAPPER_STYLE}>
      <Title
        title={language.get('enter_sms_code_title')}
        subtitle={`${language.get('enter_sms_code_message')}`}
        large
      />

      <div css={CONTAINER_STYLE}>
        {/* Info label sms sent to */}
        <label css={{ textAlign: 'center', marginBottom: 12 }}>
          {`${language.get('sent_to')} +${phone.countryCode}\xa0${
            phone.phoneNumber
          }`}
        </label>

        {/* Sms code input */}
        <CodeInput
          maxLength={4}
          value={smsCode}
          label={language.get('enter_sms_code_title')}
          onChange={(e) => {
            if (smsCode.length === 4) {
              return;
            }
            setSmsCode(e.currentTarget.value);
          }}
        />

        {/* Resend sms code action */}
        <div
          css={{
            marginTop: 44,
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          {timeToResend > 0 ? (
            <label>
              {`${language.get('resend_code_description')} ${timeToResend}`}
            </label>
          ) : (
            <Clickable
              scale
              css={{
                backgroundColor: color.LOCAL_WHITE,
                color: color.PRIMARY,
                fontFamily: font.FAMILY_BOLD,
                padding: 8,
              }}
              onClick={resendSmsCode}
            >
              {language.get('resend_sms_code')}
            </Clickable>
          )}
        </div>
      </div>
    </div>
  );
};

export default SmsCodeEntryView;
