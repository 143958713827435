/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { jsx, CSSObject } from '@emotion/core';

import {
  PaymentMethod,
  StripeError,
  Stripe,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import useOverlay from '../hooks/useOverlay';
import useLanguage from '../hooks/useLanguage';
import { IBaseOverlay } from '../contexts/overlayContext';
import Clickable from '../components/clickable';
import useTheme from '../hooks/useTheme';
// import CheckBox from '../../../common/checkBox';

export interface IStripeCheckoutOverlay extends IBaseOverlay {
  stripe: Stripe;
  onPaymentMethod: (
    paymentMethod: PaymentMethod,
    shouldSaveCard: boolean
  ) => void;
  onError: (error: StripeError) => void;
}

interface ICheckoutForm {
  onPaymentMethod: (
    paymentMethod: PaymentMethod,
    shouldSaveCard: boolean
  ) => void;
  onError: (error: StripeError) => void;
}

const CheckoutForm = ({ onPaymentMethod, onError }: ICheckoutForm) => {
  const [stripeBusy, setStripeBusy] = useState(false);
  const [stripeValid, setStripeValid] = useState(false);
  const [shouldSaveCard /* setShouldSaveCard */] = useState(false);

  const { font, color } = useTheme();
  const overlay = useOverlay();
  const language = useLanguage();
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setStripeBusy(true);
    const cardElement = elements?.getElement(CardElement);

    if (stripe && cardElement) {
      stripe
        .createPaymentMethod({
          type: 'card',
          card: cardElement,
        })
        .then((res) => {
          if (res.paymentMethod) {
            onPaymentMethod(res.paymentMethod, shouldSaveCard);
          } else if (res.error) {
            onError(res.error);
          }
        })
        .catch(() => {
          // createPaymentMethod doesn't seem to reject on error.
        });
    }
  };

  useEffect(() => {
    const cardElement = elements?.getElement(CardElement);

    cardElement?.on('change', function (event) {
      setStripeValid(event.complete);
    });
  }, [elements]);

  const FORM_STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    backgroundColor: color.LOCAL_WHITE,
    padding: '30px 20px 10px',
    borderRadius: 7,

    '.StripeElement': {
      boxSizing: 'border-box',
      padding: '20px 0',
      marginBottom: 28,
      borderTop: `1px solid ${color.DIVIDER_LINE}`,
      borderBottom: `1px solid ${color.DIVIDER_LINE}`,
    },
  };

  const HEADER_STYLE: CSSObject = {
    fontSize: 17,
    fontFamily: font.FAMILY_BOLD,
    lineHeight: '21px',
    marginBottom: 20,
  };

  // As we don't support removing and modifying saved cards for now,
  // we'll remove the "Save card" functionality.
  // Uncomment when needed.
  // const SAVE_CARD_STYLE: CSSObject = {
  //   margin: '30px -20px 10px -20px',
  //   '::after': {
  //     display: 'none',
  //   },
  // };

  const CANCEL_BUTTON_STYLE: CSSObject = {
    backgroundColor: color.LOCAL_WHITE,
    color: color.PRIMARY,
    '&[active], :disabled': {
      backgroundColor: color.LOCAL_WHITE,
    },
  };

  return (
    <form onSubmit={handleSubmit} css={FORM_STYLE}>
      {/* Dialog header */}
      <h2 css={HEADER_STYLE}>{language.get('pay_with_card')}</h2>

      {/* Stripe Elements form */}
      <CardElement
        options={{
          style: {
            base: {
              fontSize: '16px',
              lineHeight: '1.3',
              color: color.TEXT,
              iconColor: color.UI_IDLE,
              '::placeholder': {
                color: color.TEXT_SOFT,
              },
            },
            invalid: {
              color: color.ALERT,
              iconColor: color.ALERT,
            },
          },
        }}
      />

      {/* Save card checkbox */}
      {/* As we don't support removing and modifying saved cards for now,
      we'll remove the "Save card" functionality.
      Uncomment when needed. */}
      {/* <Clickable
        as='ListRow'
        defaultHandler
        css={SAVE_CARD_STYLE}
      >
        <CheckBox
          checked={shouldSaveCard}
          disabled={stripeBusy}
          onChange={(e) => {
            setShouldSaveCard(e.target.checked);
          }}
        />
        <div css={{ width: '100%', marginLeft: 4 }}>
          {language.get('save_card_details')}
        </div>
      </Clickable> */}

      {/* Pay button */}
      <Clickable
        styleAs='button'
        defaultHandler
        disabled={stripeBusy || !stripeValid}
      >
        {language.get('pay')}
      </Clickable>

      {/* Cancel button */}
      <Clickable
        styleAs='button'
        disabled={stripeBusy}
        css={CANCEL_BUTTON_STYLE}
        onClick={() => {
          overlay.dismiss();
        }}
      >
        {language.get('cancel')}
      </Clickable>
    </form>
  );
};

const StripeCheckoutOverlay = ({
  stripe,
  onPaymentMethod,
  onError,
}: IStripeCheckoutOverlay) => {
  const overlay = useOverlay();
  const { getCurrentLanguage } = useLanguage();

  const locale = getCurrentLanguage() as StripeElementsOptions['locale'];

  return (
    <div>
      <Elements stripe={stripe} options={{ locale }}>
        <CheckoutForm
          onPaymentMethod={(paymentMethod, shouldSaveCard) => {
            overlay.dismiss();
            onPaymentMethod(paymentMethod, shouldSaveCard);
          }}
          onError={(error) => {
            overlay.dismiss();
            onError(error);
          }}
        />
      </Elements>
    </div>
  );
};

export default StripeCheckoutOverlay;
