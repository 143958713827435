/** @jsx jsx */
import React, { createRef, useEffect } from 'react';
import { CSSObject, jsx } from '@emotion/core';
import layout from '../constants/layout';

// NOTES ON THIS COMPONENT:
// The need for this is explained fairly well here:
// https://nicolas-hoizey.com/articles/2015/02/18/viewport-height-is-taller-than-the-visible-part-of-the-document-in-some-mobile-browsers/
//
// OUR CUSTOM USE-CASE:
// Since the app renders within a scrollable frame on desktop,
// we want the height of the div to always be full height, no exceptions.
// On mobile, however, we're scrolling the actual document as usual,
// hence the div needs to be full height at the minimum, but should grow beyond that if needed.

interface IFullHeightDiv extends React.HTMLAttributes<HTMLElement> {
  canExceedOnMobile?: boolean;
}

const FullHeightDiv = ({
  canExceedOnMobile,
  children,
  ...props
}: IFullHeightDiv) => {
  const elementRef = createRef<HTMLDivElement>();

  const STYLE: CSSObject = {
    // The initial height of the element.
    height: `${window.innerHeight}px`,
    minHeight: `${window.innerHeight}px`,

    // Override the height property on mobile if it needs to exceed full view height.
    ...(canExceedOnMobile && {
      [`@media (max-width: ${layout.BREAKPOINT_MEDIUM}px)`]: {
        height: 'auto !important',
      },
    }),
  };

  useEffect(() => {
    const updateElementHeight = () => {
      if (elementRef.current) {
        const viewportHeight = window.innerHeight;

        elementRef.current.style.height = `${viewportHeight}px`;
        elementRef.current.style.minHeight = `${viewportHeight}px`;
      }
    };

    window.addEventListener('resize', updateElementHeight);
    return () => window.removeEventListener('resize', updateElementHeight);
  }, [elementRef]);

  return (
    <div css={STYLE} {...props} ref={elementRef}>
      {children}
    </div>
  );
};

export default FullHeightDiv;
