import { camelizeKeys } from 'humps';
import {
  ISurvey,
  ISurveyNeighborhood,
} from '../views/flex/flexNodes/surveyFlexNode/components/survey';
import { ISurveyNode } from '../views/flex/flexNodes/surveyFlexNode/components/surveyNode';

const mapJSONToSurvey = (data: any): ISurvey => {
  const survey: ISurvey = {
    graph: {
      nodes: camelizeKeys(data.graph.nodes) as Array<ISurveyNode>,
      structure: camelizeKeys(
        data.graph.structure
      ) as Array<ISurveyNeighborhood>,
      meta: data.graph.meta,
    },

    saveCall: data.save_call,
  };

  // Replace clientCondition in each neighborhood with the raw, uncamelized object.
  // Since clientConditions contains json schemas, they are the only blobs that cannot safely be decamelized.
  survey.graph.structure = survey.graph.structure.map(
    (structure, structureIndex) => {
      return {
        ...structure,
        toEdges: structure.toEdges.map((edge, edgeIndex) => {
          return {
            ...edge,
            clientCondition:
              data.graph.structure[structureIndex].to_edges[edgeIndex]
                .client_condition,
          };
        }),
      };
    }
  );

  return survey;
};

export default mapJSONToSurvey;
