/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';
import useStatefulInput from '../../../../../hooks/useStatefulInput';
import useAction, { IAction } from '../../../../../hooks/useAction';
import useTheme from '../../../../../hooks/useTheme';
import RemoteImage, {
  IRemoteImage,
} from '../../../../../components/RemoteImage';
import AttributedText from '../../../../../components/attributedText';

export interface IFiveColumnPickerCell {
  type: 'picker';
  inputId: string;

  label?: string;

  placeholder: string;
  inactiveLineColor: string;
  activeLineColor: string;

  items: [
    {
      itemId: string;
      title: string;
    }
  ];
  selectedItemId: string;
  defaultSelectedItemId: string;

  expandIcon: IRemoteImage;

  action: IAction;
}

interface IFiveColumnPickerCellProps {
  data: IFiveColumnPickerCell;
}

const FiveColumnPickerCell = ({ data }: IFiveColumnPickerCellProps) => {
  const { inputValue, setInputValue } = useStatefulInput<string>({
    inputId: data.inputId,
    initialValue: data.selectedItemId,
  });

  const handleAction = useAction();
  const { color, resolveColor } = useTheme();

  const SELECT_STYLE: CSSObject = {
    appearance: 'none',
    MozAppearance: 'none',
    fontSize: 17,
    lineHeight: '26px',
    padding: '6px 0 8px',
    backgroundColor: color.LOCAL_WHITE,
    borderStyle: 'none none solid',
    borderRadius: 0,
    borderBottom: `2px solid ${resolveColor(data.inactiveLineColor)}`,
    ':focus': {
      borderBottom: `2px solid ${resolveColor(data.activeLineColor)}`,
    },
    '::placeholder': {
      color: color.INPUT_PLACEHOLDER,
    },

    ':invalid': {
      color: color.TEXT_SOFT,
    },
    color: 'inherit',
  };

  const LABEL_STYLE: CSSObject = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  };

  const EXPAND_ICON_STYLE: CSSObject = {
    position: 'absolute',
    right: 0,
    bottom: 4,
    width: 38,
    height: 38,
    pointerEvents: 'none',
  };

  return (
    <label css={LABEL_STYLE}>
      {/* Input label text. */}
      <AttributedText text={data.label} />

      {/* The select input itself. */}
      <select
        required
        css={SELECT_STYLE}
        value={inputValue || ''}
        onChange={(e) => {
          e.stopPropagation();
          handleAction(data.action);
          setInputValue(e.target.value);
        }}
      >
        {/* The hidden placeholder item. */}
        <option disabled hidden value=''>
          {data.placeholder}
        </option>

        {/* Dynamic items provided by the model. */}
        {data.items.map((item, i: number) => (
          <option key={i} value={item.itemId}>
            {item.title}
          </option>
        ))}
      </select>

      {/* Toggle/expand arrow icon */}
      <RemoteImage {...data.expandIcon} css={EXPAND_ICON_STYLE} />
    </label>
  );
};

export default FiveColumnPickerCell;
