import { useContext, useEffect } from 'react';
import {
  AppConfigContext,
  IMenuItemBadgeTextInfo,
} from '../contexts/appConfigContext';
import request from '../utils/request';
import useWebSocket from './useWebSocket';

interface IUpdateTabBadgeMessageContent {
  action: 'update_tab_badge';
  payload: {
    tabBadgeUpdate: {
      id: string;
      textInfo: IMenuItemBadgeTextInfo;
    };
  };
}

interface IStartVideoMeetingMessageContent {
  action: 'start_video_meeting';
  payload: {
    callerId: string;
    callerSubtitle: string;
    callerTitle: string;
    meetingId: string;
  };
}

interface IMissedCallMessageContent {
  action: 'missed_call';
  payload: null;
}

type IPushNotificationMessageContent =
  | IUpdateTabBadgeMessageContent
  | IStartVideoMeetingMessageContent
  | IMissedCallMessageContent;

interface IRegisterTokenWebSocketMessage {
  type: 'register_token';
  content: {
    token: string;
  };
}

interface IPushNotificationWebSocketMessage {
  type: 'push_notification';
  content: IPushNotificationMessageContent;
}

type IWebSocketMessage =
  | IRegisterTokenWebSocketMessage
  | IPushNotificationWebSocketMessage;

type IWsPushNotification<T> = T extends 'update_tab_badge'
  ? IUpdateTabBadgeMessageContent
  : T extends 'start_video_meeting'
  ? IStartVideoMeetingMessageContent
  : T extends 'missed_call'
  ? IMissedCallMessageContent
  : unknown;

// Keeps track of whether or not the PNS token has been registered,
// as we might have multiple instances of the hook when the token message arrives.
let pnsTokenHandled = false;

const useWsPushNotification = <
  T extends IPushNotificationMessageContent['action']
>(
  action: T
): IWsPushNotification<T> | undefined => {
  const { userId } = useContext(AppConfigContext);

  // Connect to the WebSocket service once the user is logged in.
  // The connection is shared and any additional instances of this hook will share the same connection.
  // Once all the instances have been destroyed, the socket is closed.
  const message = useWebSocket<IWebSocketMessage>(
    userId ? '/api/websocket' : undefined,
    { shareConnection: true }
  );

  // Register the received token with the push notification service.
  useEffect(() => {
    if (message && !pnsTokenHandled) {
      if (message.type === 'register_token') {
        pnsTokenHandled = true;

        request('/api/push-notification/user/token', {
          method: 'POST',
          body: {
            token: message.content.token,
          },
          silent: true,
          persistent: true,
          retryable: true,
        });
      }
    }
  }, [message]);

  // Return the push notification message only if the content matches the requested type.
  return message &&
    message.type === 'push_notification' &&
    message.content.action === action
    ? (message.content as IWsPushNotification<T>)
    : undefined;
};

export default useWsPushNotification;
