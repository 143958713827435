/** @jsx jsx */
import React, { Fragment, useContext, useEffect, useRef } from 'react';
import { jsx } from '@emotion/core';
import { ISurveyNode } from '../surveyNode';
import { SurveyContext } from '../../../../../../contexts/surveyContext';
import useTheme from '../../../../../../hooks/useTheme';

interface IInfoboxesSurveyNode {
  node: ISurveyNode;
}

const InfoboxesSurveyNode = ({ node }: IInfoboxesSurveyNode) => {
  const { color } = useTheme();
  const nodeVisistedAt = useRef(Date.now());
  const { registerAnswer } = useContext(SurveyContext);

  useEffect(() => {
    registerAnswer({
      nodeName: node.name,
      path: node.path,
      property: node.property,
      answeredAt: Date.now(),
      visitedAt: nodeVisistedAt.current,
    });
  }, [node, registerAnswer]);

  return (
    <Fragment>
      {node.infoBoxList?.map((infoBox, i) => {
        return (
          <div
            key={i}
            css={{
              backgroundColor: color.STATUS_BG_NOTICE,
              padding: 20,
              margin: 20,
              borderRadius: 4,
            }}
          >
            {/* Infobox title */}
            {infoBox.title && (
              <div
                css={{
                  position: 'relative',
                  fontSize: '20px',
                  marginBottom: 20,
                }}
              >
                {infoBox.title}
              </div>
            )}

            {/* Infobox text */}
            {infoBox.text && (
              <div
                css={{
                  fontSize: '14px',
                  lineHeight: '22px',
                  whiteSpace: 'break-spaces',
                }}
              >
                {infoBox.text}
              </div>
            )}
          </div>
        );
      })}
    </Fragment>
  );
};

export default InfoboxesSurveyNode;
