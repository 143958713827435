/** @jsx jsx */
import React, { Fragment, useState } from 'react';
import { jsx } from '@emotion/core';
import platform from 'platform';
import Clickable from '../../components/clickable';
import useOverlay from '../../hooks/useOverlay';
import useNotifications from '../../hooks/useNotifications';
import Title from '../../components/title';
import VideoMeeting from '../flex/flexNodes/meetingRoomFlexNode/components/videoMeeting';
import VideoMeetingContainer from '../flex/flexNodes/meetingRoomFlexNode/components/videoMeetingContainer';
import { logError, logWarning } from '../../utils/remoteLogger';
import request from '../../utils/request';
import RequestError from '../../utils/requestError';
import useMediaDevicePermission from '../../hooks/useMediaDevicePermission';
import Navbar from '../../components/navbar';

const DebugView = () => {
  const [showingBrokenDiv, setShowingBrokenDiv] = useState(false);
  const [showingVideoOverlay, setShowingVideoOverlay] = useState(false);
  const overlay = useOverlay();
  const notifications = useNotifications();

  const { getMediaDevicePermission } = useMediaDevicePermission();

  const purposefullyEmptyObject = {} as any;

  return (
    <Fragment>
      <Navbar />

      <div
        css={{
          padding: '0 20px 20px 20px',

          section: {
            marginBottom: 40,
          },
          h2: {
            marginBottom: 20,
          },
          button: {
            marginBottom: 10,
          },
        }}
      >
        {/* Overlays */}
        <section>
          <Title title='Overlays' />

          {/* Present calling screen */}
          <Clickable
            styleAs='button'
            onClick={() => {
              overlay.presentCallingScreen({
                meetingInfo: {
                  callingTitle: 'Your doctor is calling',
                  callerTitle: 'Doctor Doctorsson',
                  callerSubtitle: 'Licensed physician',
                  doctor: {
                    doctorImage:
                      'https://images.ctfassets.net/h8qzhh7m9m8u/QDvqle0OAwKMs9vjVXLaz/1362ce659db0e37b0f37e7c4ae5357e1/doctor-circle.png',
                  },
                },
                onCallAccepted: () => {
                  setShowingVideoOverlay(true);
                },
                onCallDeclined: () => {},
              });
            }}
          >
            Present calling screen
          </Clickable>

          {/* Present video meeting */}
          <Clickable
            styleAs='button'
            onClick={() => {
              setShowingVideoOverlay(true);
            }}
          >
            Present video meeting
          </Clickable>

          {/* Present image preview */}
          <Clickable
            styleAs='button'
            onClick={() => {
              overlay.presentImagePreview({
                url: '/test/image_sample.jpg',
              });
            }}
          >
            Present image preview
          </Clickable>

          {/* Present pdf preview */}
          <Clickable
            styleAs='button'
            onClick={() => {
              request<BlobPart>('/test/pdf_sample.pdf', {
                assumeBlob: true,
              }).then((blob) => {
                overlay.presentPdfPreview({
                  file: new Blob([blob], {
                    type: 'application/octet-stream',
                  }),
                  title: 'Sample PDF document',
                });
              });
            }}
          >
            Present pdf preview
          </Clickable>
        </section>

        {/* Permission requests */}
        <section>
          <Title title='Permissions' />

          {/* Request notification permission */}
          <Clickable
            styleAs='button'
            onClick={() => {
              overlay.presentAlert({
                title: `Don't miss the call from your clinician`,
                message:
                  'Enable notifications to get notified when your clinician calls you, even if you are living your best life while waiting.',
                verticalOrientation: 'vertical',
                items: [
                  {
                    title: 'Enable notifications',
                    onClick: () => {
                      notifications.requestPermission();
                    },
                  },
                  {
                    title: 'Not now',
                    isCancel: true,
                    onClick: () => {},
                  },
                ],
              });
            }}
          >
            Request notification permission
          </Clickable>

          {/* Ask for media device permission */}
          <Clickable
            styleAs='button'
            onClick={() => {
              getMediaDevicePermission({ audio: true, video: true }).then(
                () => {
                  overlay.presentBasicAlert({
                    title: 'Permission granted',
                    message: 'Media device permission have been granted',
                  });
                }
              );
            }}
          >
            Request media device permission
          </Clickable>
        </section>

        {/* Log test errors */}
        <section>
          <Title title='Logging' />

          <Clickable
            styleAs='button'
            onClick={() => {
              logError(
                'DebugView',
                'This is a test error with custom attributes',
                {
                  foo: 'bar',
                  fizz: '123',
                }
              );
            }}
          >
            Log error with custom attributes
          </Clickable>

          <Clickable
            styleAs='button'
            onClick={() => {
              logError(
                'DebugView',
                'This is a test error with a throwable',
                new Error('foo')
              );
            }}
          >
            Log a throwable error
          </Clickable>
          <Clickable
            styleAs='button'
            onClick={() => {
              request('https://app.kry.se/api/user/heartbeat').catch(
                (error: RequestError) => {
                  logError('DebugView', 'This is a test request error', error);
                }
              );
            }}
          >
            Log a request error
          </Clickable>
          <Clickable
            styleAs='button'
            onClick={() => {
              request('https://app.kry.se/api/user/heartbeat').catch(() => {
                logWarning('DebugView', 'This is a test warning', {
                  foo: 'bar',
                  fizz: '123',
                });
              });
            }}
          >
            Log a warning
          </Clickable>
        </section>

        <section>
          <Title title='Other' />

          <Clickable
            styleAs='button'
            onClick={() => {
              setShowingBrokenDiv(true);
            }}
          >
            Provoke error boundary
          </Clickable>

          {/* Render a div reading from a purposefully empty object, provoking an error and rendering the error boundary. */}
          {showingBrokenDiv && <div>{purposefullyEmptyObject.foo.bar}</div>}
        </section>

        {/* Device/Platform info */}
        <section
          css={{
            marginTop: 20,
            span: {
              fontWeight: 700,
              marginRight: 5,
            },
          }}
        >
          <Title title='Device/Platform info' />

          {/* Display */}
          <div>
            <span>Pixel ratio:</span>
            {window.devicePixelRatio}
          </div>

          {/* Browser info */}
          <div css={{ marginTop: 8 }}>
            <span>Browser:</span>
            {platform.name}
          </div>
          <div>
            <span>Version:</span>
            {platform.version}
          </div>
          <div>
            <span>Browser language:</span>
            {navigator.language}
          </div>
          <div css={{ marginTop: 8 }}>
            <span>Description:</span>
            {platform.description}
          </div>
          <div css={{ marginTop: 8 }}>
            <span>UA:</span>
            {platform.ua}
          </div>

          {/* OS info */}
          <div css={{ marginTop: 8 }}>
            <span>OS architecture:</span>
            {platform.os?.architecture}
          </div>
          <div>
            <span>OS family:</span>
            {platform.os?.family}
          </div>
          <div>
            <span>OS version:</span>
            {platform.os?.version}
          </div>

          {/* Device info */}
          <div css={{ marginTop: 8 }}>
            <span>Manufacturer:</span>
            {platform.manufacturer}
          </div>
          <div>
            <span>Product:</span>
            {platform.product}
          </div>

          <div css={{ marginTop: 8 }}>
            <span>Commit hash:</span>
            {process.env.REACT_APP_COMMIT_HASH}
          </div>
        </section>

        {/* Video meeting overlay */}
        <section>
          {showingVideoOverlay && (
            <VideoMeetingContainer>
              <VideoMeeting
                meetingInfo={{
                  videoTitle: 'Doctor Doctorrson',
                  videoSubtitle: 'Licensed physician',
                }}
                // opentokSession={meetingSession.opentokSession}
                photoUploadScreenSource={{
                  // Random screen to test scrolling behavior.
                  url: '/api/app/contentful/5GTfQZtqFy0u5rvl8bNfi?cache_client=false&cache_server=true',
                }}
                onHangupClicked={() => {
                  setShowingVideoOverlay(false);
                }}
                onVideoDisconnected={() => {}}
                onStreamError={() => {}}
                onConnecting={() => {}}
                onRemoteStreamConnected={() => {}}
                reportConnection={() => {}}
              />
            </VideoMeetingContainer>
          )}
        </section>
      </div>
    </Fragment>
  );
};

export default DebugView;
