/** @jsx jsx */
import React, { Fragment, useRef } from 'react';
import { jsx, CSSObject } from '@emotion/core';

import { format as formatDate } from 'date-fns';
import { enGB, sv, fr, nb } from 'date-fns/locale';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import useTheme from '../../hooks/useTheme';
import useLanguage from '../../hooks/useLanguage';
import RemoteImage, { IRemoteImage } from '../RemoteImage';
import AttributedText from '../attributedText';
import layout from '../../constants/layout';
import LocalImage from '../localImage';
import device from '../../utils/device';
import CalendarHeader from './components/calendarHeader';

registerLocale('en', enGB);
registerLocale('sv', sv);
registerLocale('fr', fr);
registerLocale('nb', nb);

interface IDateTimePickerProps {
  type: 'date' | 'time' | 'datetime';
  inputStyle?: 'os_default' | 'calendar' | 'numbers';
  label?: string;
  placeholder?: string;
  format?: string;

  value?: Date;
  defaultValue?: Date;
  minDate?: Date;
  maxDate?: Date;

  inactiveLineColor?: string;
  activeLineColor?: string;
  expandIcon?: IRemoteImage;

  className?: string;

  onChange: (date: Date | null) => void;
  onBlur?: () => void;
}

const DateTimePickerInput = ({
  type,
  inputStyle,
  label,
  placeholder,
  format,
  value,
  defaultValue,
  minDate,
  maxDate,
  inactiveLineColor,
  activeLineColor,
  expandIcon,
  className,
  onChange,
  onBlur,
  ...props
}: IDateTimePickerProps) => {
  const { font, color, resolveColor } = useTheme();
  const language = useLanguage();

  const pickerRef = useRef<DatePicker<never, undefined> | null>();

  const dateToString = (date?: Date) => {
    if (!date) {
      return '';
    }

    switch (type) {
      case 'date':
        return formatDate(date, 'yyyy-MM-dd');
      case 'datetime':
        return `${formatDate(date, 'yyyy-MM-dd')}T${formatDate(
          date,
          'HH:mm:ss'
        )}`;
      case 'time':
        return formatDate(date, 'HH:mm:ss');
    }
  };

  const stringToDate = (dateString: string) => {
    switch (type) {
      case 'date':
        return new Date(dateString);
      case 'datetime':
        return new Date(dateString);
      case 'time':
        return new Date(`01/01/1970 ${dateString}`);
    }
  };

  const LABEL_STYLE: CSSObject = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  };

  const INPUT_STYLE: CSSObject = {
    appearance: 'none',
    MozAppearance: 'none',
    width: 'inherit',
    fontSize: 17,
    padding: '6px 0 8px',
    height: '26px',
    backgroundColor: color.LOCAL_WHITE,
    borderStyle: 'none none solid',
    borderRadius: 0,
    borderBottom: `2px solid ${
      resolveColor(inactiveLineColor) || color.UI_IDLE
    }`,
    ':focus': {
      borderBottom: `2px solid ${
        resolveColor(activeLineColor) || color.PRIMARY
      }`,
    },
    '::placeholder': {
      color: color.INPUT_PLACEHOLDER,
    },
    '::-webkit-calendar-picker-indicator': {
      position: 'absolute',
      top: '-150%',
      left: '-150%',
      width: '300%',
      height: '300%',
      zIndex: layout.BASIC_ZINDEX,
    },
    color: 'inherit',
  };

  const CUSTOM_PLACEHOLDER_STYLE: CSSObject = {
    position: 'absolute',
    width: '100%',
    bottom: 2,
    padding: '6px 0 10px',
    fontSize: 17,
    color: color.INPUT_PLACEHOLDER,
    backgroundColor: 'white',
    pointerEvents: 'none',
  };

  const EXPAND_ICON_STYLE: CSSObject = {
    position: 'absolute',
    right: 0,
    bottom: 4,
    width: 38,
    height: 38,
    pointerEvents: 'none',
  };

  const CALENDAR_STYLE: CSSObject = {
    // Main wrapper
    '.react-datepicker': {
      fontFamily: font.FAMILY_REGULAR,
      backgroundColor: color.LOCAL_WHITE,
      border: `1px ${color.UI_IDLE} solid`,
      borderRadius: 12,
      boxShadow: layout.DEFAULT_SHADOW_LARGE,
      overflow: 'hidden',
    },

    // All headers header
    '.react-datepicker__header ': {
      backgroundColor: color.SURFACE_BG_SECONDARY,
      borderBottom: `1px ${color.UI_IDLE} dashed`,
      fontFamily: font.FAMILY_REGULAR,
      fontWeight: 'normal',
    },

    '.react-datepicker-time__header': {
      fontFamily: font.FAMILY_REGULAR,
      fontWeight: 'normal',
    },

    // Time picker header
    '.react-datepicker__header--time': {
      paddingTop: 12,
    },

    // Time picker container
    '.react-datepicker__time-container, .react-datepicker__time-box': {
      width: '110px !important',
    },

    // Time picker item
    '.react-datepicker__time-list-item': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '24px !important',
    },

    // Cells
    '.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name':
      {
        width: 32,
        height: 32,
        lineHeight: '33px',
      },

    // Cell states.
    '.react-datepicker__day--outside-month': {
      color: color.TEXT_SOFT,
    },
    '.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range, .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected':
      {
        backgroundColor: color.PRIMARY,
        fontFamily: font.FAMILY_BOLD,
      },
    '.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range':
      {
        borderRadius: '100%',
      },

    // Month and year selector for past dates
    '.react-datepicker__year-dropdown-container--select, .react-datepicker__month-dropdown-container--select, .react-datepicker__month-year-dropdown-container--select, .react-datepicker__year-dropdown-container--scroll, .react-datepicker__month-dropdown-container--scroll, .react-datepicker__month-year-dropdown-container--scroll':
      {
        margin: '2px 15px',
      },
  };

  const commonDatePickerProps = {
    ref: (ref: DatePicker | null) => {
      pickerRef.current = ref;
    },
    selected: value,
    openToDate: value || defaultValue,
    dateFormat: format,
    locale: language.getCurrentLanguage(),
    calendarStartDay: 1,
    minDate,
    maxDate,
    showTimeSelect: type === 'time' || type === 'datetime',
    showTimeSelectOnly: type === 'time',
    placeholderText: placeholder,
    shouldCloseOnSelect: false,
    showPopperArrow: false,
    timeCaption: language.get('time_and_date'),
    required: true,
    fixedHeight: true,
    css: INPUT_STYLE,
    onChange: (date: Date) => {
      onChange(date);
      setTimeout(() => {
        pickerRef.current?.setOpen(false);
      }, 300);
    },
    onBlur,
    ...props,
  };

  return (
    <label css={[LABEL_STYLE, CALENDAR_STYLE]} className={className}>
      <AttributedText text={label} />

      {/* On mobile browsers, use the browser-native DateTime picker. */}
      {device.IS_MOBILE && (
        <Fragment>
          {/* Native datetime inpputs don't support placeholders, render a custom replacements. */}
          {!value && <div css={CUSTOM_PLACEHOLDER_STYLE}>{placeholder}</div>}

          {/* Native datetime input. */}
          <input
            type={type === 'datetime' ? 'datetime-local' : type}
            value={dateToString(value || defaultValue)}
            min={dateToString(minDate)}
            max={dateToString(maxDate)}
            required
            css={INPUT_STYLE}
            placeholder={placeholder}
            onChange={(e) => {
              onChange(stringToDate(e.target.value));
            }}
            onBlur={onBlur}
            {...props}
          />
        </Fragment>
      )}

      {/* On desktop browsers, where DatePicker support is spotty at best, use the custom one. 
       When the "date_input_style" is "numbers", the native apps show numeric fields.
       This is still the DatePicker component but is a different version with month and year selectors, 
       which make for a better way to go back further in time for past dates such as a birthdate.
      */}
      {!device.IS_MOBILE &&
        (inputStyle !== 'numbers' ? (
          <DatePicker
            {...commonDatePickerProps}
            renderCustomHeader={(headerProps) => (
              <CalendarHeader {...headerProps} />
            )}
          />
        ) : (
          <DatePicker
            {...commonDatePickerProps}
            showMonthDropdown
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={80}
          />
        ))}

      {/* Toggle/expand arrow icon. */}
      {expandIcon ? (
        <RemoteImage {...expandIcon} css={EXPAND_ICON_STYLE} />
      ) : (
        <LocalImage
          src='CHEVRON_DOWN'
          tint='TEXT'
          alt=''
          css={EXPAND_ICON_STYLE}
        />
      )}
    </label>
  );
};

export default DateTimePickerInput;
