/** @jsx jsx */
import React from 'react';
import { CSSObject, jsx } from '@emotion/core';

import { images } from './constants/assets';
import { logError } from './utils/remoteLogger';
import environment from './utils/environment';

interface IErrorBoundary {
  children: React.ReactNode;
}

interface IErrorBoundaryState {
  hasError: boolean;
}

// We cannot use remote translations here as the error might have occurred before they are available.
const errorMessages = {
  en: 'An error occurred. Try refreshing the window, and contact support if the problem persists.',
  se: 'Ett fel uppstod. Försök ladda om sidan, och kontakta support om problemet kvarstår.',
  fr: "Oups, une erreur s'est produite. Veuillez réessayer, et contactez le support si le problème persiste.",
  no: 'Noe gikk visst galt. Prøv igjen, og kontakt teknisk støtte hvis feilen vedvarer.',
};

export class ErrorBoundary extends React.Component<
  IErrorBoundary,
  IErrorBoundaryState
> {
  constructor(props: IErrorBoundary) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    logError('ErrorBoundary', 'Unhandled error', error);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      const getMarketSpecificErrorMessage = () => {
        switch (environment.COUNTRY) {
          case 'SE':
            return errorMessages.se;
          case 'FR':
            return errorMessages.fr;
          case 'NO':
            return errorMessages.no;
          case 'GB':
            return errorMessages.en;
        }
      };

      const STYLE: CSSObject = {
        position: 'fixed',
        left: '50%',
        top: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        width: '100%',
        maxWidth: 500,
        padding: 20,
        boxSizing: 'border-box',

        fontFamily: 'sans-serif',
        fontSize: '17px',
        color: '#0A161FFF',
        textAlign: 'center',

        img: {
          height: 175,
        },

        div: {
          marginTop: 30,
          fontWeight: 'bold',
        },

        'div + div': {
          marginTop: 20,
          fontWeight: 'normal',
          fontSize: '14px',
          color: '#0A161F96',
        },
      };

      return (
        <div css={STYLE}>
          <img src={images.ERROR_SAD_FACE} alt='Error' />
          <div>{getMarketSpecificErrorMessage()}</div>

          {/* The error message is always rendered in english as well, for non-UK markets. */}
          {environment.COUNTRY !== 'GB' && <div>{errorMessages.en}</div>}
        </div>
      );
    }

    return children;
  }
}
