/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';

import AttributedText from '../../../../../components/attributedText';
import useTheme from '../../../../../hooks/useTheme';
import RemoteImage, {
  IRemoteImage,
} from '../../../../../components/RemoteImage';

export interface IFiveColumnUserPortraitCell {
  type: 'user_portrait';
  circleColor?: string;
  text?: string;
  image?: IRemoteImage;

  cornerRadius: number;
  size: {
    height: number;
    width: number;
  };
}

interface IFiveColumnUserPortraitCellProps {
  data: IFiveColumnUserPortraitCell;
}

const FiveColumnUserPortraitCell = ({
  data,
}: IFiveColumnUserPortraitCellProps) => {
  const { resolveColor } = useTheme();

  const STYLE: CSSObject = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: data.size?.width,
    height: data.size?.height,
    backgroundColor: resolveColor(data.circleColor),
    borderRadius: data.cornerRadius,
    overflow: 'hidden',
  };

  return (
    <div css={STYLE} aria-hidden='true'>
      {/* Image */}
      {data.image && <RemoteImage {...data.image} />}

      {/* Attributed text */}
      {data.text && <AttributedText text={data.text} />}
    </div>
  );
};

export default FiveColumnUserPortraitCell;
