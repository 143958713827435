/** @jsx jsx */
import React, { ReactNode, useState } from 'react';
import { jsx } from '@emotion/core';
import useTheme from '../hooks/useTheme';

interface IDropTarget {
  active?: boolean;
  onFileDropped: (file: File) => void;
  children: ReactNode;
}

const DropTarget = ({
  active,
  onFileDropped,
  children,
  ...props
}: IDropTarget) => {
  // This value, rather than being a boolean, gets incremented and decrement
  // as the dragEnter and dragLeave events get interrupted by the target containing children.
  const [dropTargetEntered, setDropTargetEntered] = useState(0);

  const { color } = useTheme();

  const handleTargetOnDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDropTargetEntered((state) => state - 1);

    const file = e.dataTransfer.files[0];

    // Only accept image files.
    // @TODO: Make "accept type" a prop in the future when needed.
    if (file.type.match(/image.*/)) {
      onFileDropped(file);
    }
  };

  const handleTargetDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDropTargetEntered((state) => state + 1);
  };

  const handleTargetDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDropTargetEntered((state) => state - 1);
  };

  const handleTargetDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return (
    <div
      onDrop={active ? handleTargetOnDrop : undefined}
      onDragOver={active ? handleTargetDragOver : undefined}
      onDragEnter={active ? handleTargetDragEnter : undefined}
      onDragLeave={active ? handleTargetDragLeave : undefined}
      css={{
        backgroundColor: dropTargetEntered > 0 ? color.DIVIDER_LINE : 'unset',
        transform: dropTargetEntered > 0 ? 'scale(1.05)' : 'unset',
        transition: 'all 0.15s ease-in-out',
      }}
      {...props}
    >
      {children}
    </div>
  );
};

export default DropTarget;
