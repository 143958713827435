import device from '../utils/device';
import environment from '../utils/environment';

const KRY_UNIVERSAL_LINK_TO_OPEN_APP = 'https://app.kry.se/app-link/app/home';
// Market NO
const NO_KRY_UNIVERSAL_LINK_TO_OPEN_APP =
  'https://app.kry.no/app-link/app/home';

const LIVI_UNIVERSAL_LINK_TO_OPEN_APP = 'https://app.livi.fr/app-link/app/home';

const KRY_ADJUST_DEEP_LINK_TO_OPEN_APP =
  'https://3km5.adj.st/home?adjust_campaign=source&adjust_adgroup=_menuCTA&adjust_engagement_type=fallback_click&adjust_t=cs7fn8e&adjust_deeplink=kry%3A%2F%2Fhome';

const LIVI_ADJUST_DEEP_LINK_TO_OPEN_APP =
  'https://fpsb.adj.st/home?adjust_campaign=source&adjust_adgroup=_menuCTA&adjust_engagement_type=fallback_click&adjust_t=9l3aul2&adjust_deeplink=livi%3A%2F%2Fhome';

// Market NO
const NO_ADJUST_DEEP_LINK_TO_OPEN_APP =
  'https://app.kry.no/login?redirect=%2Fhome%3Fadjust_redirect_market%3Dno%26adjust_referrer%3Dadjust_reftag%253DcR8VplC7JHCnu';

const links = {
  kry: {
    IOS: 'https://itunes.apple.com/se/app/id968052278',
    ANDROID: 'https://play.google.com/store/apps/details?id=se.kry.android',
  },

  livi: {
    IOS: 'https://itunes.apple.com/us/app/id1433906974',
    ANDROID:
      'https://play.google.com/store/apps/details?id=health.livi.android',
  },
};

const appStoreLinks = {
  IOS: links[environment.IS_KRY ? 'kry' : 'livi'].IOS,
  ANDROID: links[environment.IS_KRY ? 'kry' : 'livi'].ANDROID,
  THIS_DEVICE:
    links[environment.IS_KRY ? 'kry' : 'livi'][
      device.IS_ANDROID ? 'ANDROID' : 'IOS'
    ],
  KRY_UNIVERSAL_LINK_TO_OPEN_APP,
  LIVI_UNIVERSAL_LINK_TO_OPEN_APP,
  KRY_ADJUST_DEEP_LINK_TO_OPEN_APP,
  LIVI_ADJUST_DEEP_LINK_TO_OPEN_APP,
  NO_KRY_UNIVERSAL_LINK_TO_OPEN_APP,
  NO_ADJUST_DEEP_LINK_TO_OPEN_APP,
};

export default appStoreLinks;
