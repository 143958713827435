/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';
import useStatefulPart from '../../../hooks/useStatefulPart';
import Clickable from '../../../components/clickable';
import { IBasePart } from '../part';
import { IAction } from '../../../hooks/useAction';
import useTheme from '../../../hooks/useTheme';
import NotificationBadge, {
  INotificationBadge,
} from '../../../components/notificationBadge';

export interface ITabsPart extends IBasePart {
  type: 'tabs';

  selectedIndex: number;

  tabs: Array<{
    title: string;
    action: IAction;
    badge: INotificationBadge;
  }>;

  iosProperties: {
    margins: {
      top: number;
      bottom: number;
      leading: number;
      trailing: number;
    };

    segmentedControlHeight: number;
    selectedStyle: ITabsStyle;
    deselectedStyle: ITabsStyle;
  };
}

interface ITabsStyle {
  font: {
    name: string;
    weight: number;
  };
  fontSize: number;
  textColor: string;
  color: string;
}

const TabsPart = (data: ITabsPart) => {
  const { resolveColor } = useTheme();

  const [state, setState] = useStatefulPart<number>(
    data.id,
    data.selectedIndex
  );

  const TABS_STYLE: CSSObject = {
    display: 'flex',
    listStyleType: 'none',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: data.iosProperties.margins.top,
    marginRight: data.iosProperties.margins.trailing,
    marginBottom: data.iosProperties.margins.bottom,
    marginLeft: data.iosProperties.margins.leading,
    backgroundColor: resolveColor(data.iosProperties.deselectedStyle.color),
    height: data.iosProperties.segmentedControlHeight,
    borderRadius: 10,
    padding: 2,
    overflow: 'hidden',
  };

  const TAB_STYLE: CSSObject = {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    borderRadius: 10,
    color: resolveColor(data.iosProperties.deselectedStyle.textColor),
    backgroundColor: resolveColor(data.iosProperties.deselectedStyle.color),
    fontSize: data.iosProperties.deselectedStyle.fontSize,
    fontWeight: data.iosProperties.deselectedStyle.font.weight,
  };

  const TAB_SELECTED_STYLE: CSSObject = {
    color: resolveColor(data.iosProperties.selectedStyle.textColor),
    backgroundColor: resolveColor(data.iosProperties.selectedStyle.color),
    fontSize: data.iosProperties.selectedStyle.fontSize,
    fontWeight: data.iosProperties.selectedStyle.font.weight,
  };

  const BADGE_STYLE: CSSObject = {
    marginLeft: 6,
  };

  return (
    <ul css={TABS_STYLE}>
      {data.tabs.map((tab, i) => (
        <li key={i} css={TAB_STYLE}>
          <Clickable
            css={
              i === state ? { ...TAB_STYLE, ...TAB_SELECTED_STYLE } : TAB_STYLE
            }
            action={tab.action}
            onClick={() => {
              setState(i);
            }}
            aria-pressed={i === state}
          >
            {tab.title}
            {tab.badge && (
              <NotificationBadge css={BADGE_STYLE} {...tab.badge} />
            )}
          </Clickable>
        </li>
      ))}
    </ul>
  );
};

export default TabsPart;
