/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import useLanguage from '../hooks/useLanguage';
import useTheme from '../hooks/useTheme';
import { colorFilter } from '../utils/colorFilter';

export interface IRemoteImage {
  name?: string;
  url?: string;
  tintColor?: string;
  forceCircular?: boolean;

  accessibilityLabel?: string;
  accessibilityLabelKey?: string;
}

const RemoteImage = ({
  name,
  url,
  tintColor,
  forceCircular,
  accessibilityLabel,
  accessibilityLabelKey,
  ...props
}: IRemoteImage) => {
  const { resolveColor } = useTheme();
  const language = useLanguage();

  const altLabel =
    accessibilityLabel ||
    (accessibilityLabelKey ? language.get(accessibilityLabelKey) : '');

  // Using direct React styles to override any styles received from the parent component.
  // Emotion transfers 'css' properties to 'classNames' property, which takes precedence over
  // local styling (which is opposite of what we wish to achieve in this case).
  // https://emotion.sh/docs/css-prop#style-precedence
  const STYLES: React.CSSProperties = {
    ...(tintColor && {
      filter: colorFilter(resolveColor(tintColor)),
    }),
    ...(forceCircular && {
      width: 'auto',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '50%',
      aspectRatio: '1/1',
      margin: '0 auto',
    }),
  };

  return url ? (
    <img style={STYLES} src={url} {...props} alt={altLabel} />
  ) : null;
};

export default RemoteImage;
