import { IBaseAction } from '../useAction';
import useNavigation from '../useNavigation';

export interface IDismissAction extends IBaseAction {
  type: 'dismiss';
}

const useDismissAction = () => {
  const navigation = useNavigation();

  const handleDismissAction = () => {
    navigation.dismiss();
  };

  return handleDismissAction;
};

export default useDismissAction;
