/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';

import { IBasePart } from '../part';
import useTheme from '../../../hooks/useTheme';

export interface ILinePart extends IBasePart {
  type: 'line';

  height: number;
  color: string;

  topMargin?: number;
  bottomMargin?: number;
  leadingMargin?: number;
  trailingMargin?: number;
}

const LinePart = (data: ILinePart) => {
  const { resolveColor } = useTheme();

  const STYLE: CSSObject = {
    height: data.height || 1,
    backgroundColor: resolveColor(data.color),

    marginLeft: data.leadingMargin,
    marginRight: data.trailingMargin,
    marginTop: data.topMargin,
    marginBottom: data.bottomMargin,
  };

  return <div css={STYLE} />;
};

export default LinePart;
