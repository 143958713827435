import React, { Fragment } from 'react';
import useInternalUrl from '../../hooks/useInternalUrl';

interface IRedirectToInternalUrl {
  url: string;
}

const RedirectToInternalUrl = ({ url }: IRedirectToInternalUrl) => {
  const handleInternalUrl = useInternalUrl();

  handleInternalUrl(url);

  return <Fragment />;
};

export default RedirectToInternalUrl;
