const localStorage = {
  get: <T>(key: string) => {
    const data = window.localStorage.getItem(key);

    try {
      return data && (JSON.parse(data) as T);
    } catch {}
  },

  set: (key: string, value: any) => {
    window.localStorage.setItem(key, JSON.stringify(value));
  },

  remove: (key: string) => {
    window.localStorage.removeItem(key);
  },

  clear: () => {
    window.localStorage.clear();
  },
};

export default localStorage;
