import { useContext } from 'react';

import { ScreenContext } from '../../contexts/screenContext';
import { IBaseAction } from '../useAction';

export interface IModifyAction extends IBaseAction {
  type: 'modify';

  data: {
    toggles: Array<{ id: string }>;
    shows: Array<{ id: string }>;
    hides: Array<{ id: string }>;
  };
}

const useModifyAction = () => {
  const screenContext = useContext(ScreenContext);

  const handleModifyAction = (action: IModifyAction) => {
    let state = [...screenContext.hiddenIds];

    // Toggles
    action.data.toggles?.forEach((part) => {
      if (state.includes(part.id)) {
        state = state.filter((id) => id !== part.id);
      } else {
        state.push(part.id);
      }
    });

    // Shows
    action.data.shows?.forEach((part) => {
      state = state.filter((id) => id !== part.id);
    });

    // Hides
    action.data.hides?.forEach((part) => {
      if (!state.includes(part.id)) {
        state.push(part.id);
      }
    });

    screenContext.setHiddenIds(state);
  };

  return handleModifyAction;
};

export default useModifyAction;
