import React, {
  useEffect,
  useRef,
  useCallback,
  useContext,
  createContext,
} from 'react';
import usePersistentState from '../hooks/usePersistentState';
import request, { cancelAllRequests, IRequestOptions } from '../utils/request';
import RequestError from '../utils/requestError';

import { IScreen } from '../views/screen/screen';
import { ScreenContext } from './screenContext';
import SearchBar from '../views/screen/components/searchBar';
import useLocalScreen from '../hooks/useLocalScreen';

export interface ISearchContext {
  searchPlaceholder?: string;
  query?: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
}

export interface ISearchSource {
  url: string;
  requestOptions?: IRequestOptions;
  query?: string;
  searchPlaceholder?: string;
  context?: string;
}

interface ISearchResultData {
  screen: IScreen;
  searchSessionId: string;
}

export const SearchContext = createContext({} as ISearchContext);

interface ISearchContextProvider {
  routeSource: ISearchSource;
}

export const SearchContextProvider = ({
  routeSource,
}: ISearchContextProvider) => {
  const [query, setQuery] = usePersistentState('SEARCH_CONTEXT', '', {
    locationAware: true,
  });
  const { updateScreenSource } = useContext(ScreenContext);

  const updateScreenRef = useRef(updateScreenSource);
  const busy = useRef(false);
  const nextQuery = useRef<string | null>(null);
  const searchSessionIdRef = useRef<string | null>();
  const initialRenderRef = useRef(true);
  const localScreenRef = useRef(useLocalScreen());

  const loadSearchResults = useCallback(() => {
    const currentQuery = nextQuery.current;
    if (busy.current || currentQuery === null) {
      return;
    }

    // @TODO: Look into tagging requests to be able to only cancel the ones
    // that it might apply to, in this case async screen actions.
    cancelAllRequests();

    busy.current = true;
    request<ISearchResultData>(routeSource.url, {
      method: routeSource.requestOptions?.method,
      body: {
        ...routeSource.requestOptions?.body,
        query: currentQuery,
        searchSessionId: searchSessionIdRef.current,
        context: routeSource.context,
      },
      silent: true,
    })
      .then((result) => {
        if (result.screen) {
          updateScreenRef.current({
            screen: result.screen,
          });
          searchSessionIdRef.current = result.searchSessionId;
        }
      })
      .catch((error: RequestError) => {
        // If the request failed for whatever reason other than being cancelled,
        // show a local error screen.
        if (error.status !== 20) {
          updateScreenRef.current({
            screen: localScreenRef.current.errorScreen,
          });
        }
      })
      .finally(() => {
        busy.current = false;
        loadSearchResults();
      });

    nextQuery.current = null;
  }, [routeSource]);

  useEffect(() => {
    nextQuery.current = query || '';

    // On initial render, if there is no previous query
    // (e.g. if we're navigating back to the search view),
    // perform an "empty" search.
    if (initialRenderRef.current) {
      if (!query) {
        loadSearchResults();
      }
      initialRenderRef.current = false;
      return;
    }

    loadSearchResults();
  }, [query, loadSearchResults]);

  return (
    <SearchContext.Provider
      value={{
        searchPlaceholder: routeSource.searchPlaceholder,
        query,
        setQuery,
      }}
    >
      <SearchBar />
    </SearchContext.Provider>
  );
};
