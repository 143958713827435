import { IRemoteImage } from '../../components/RemoteImage';
import { IAction, IBaseAction } from '../useAction';
import useOverlay from '../useOverlay';

export interface IIconAlertAction extends IBaseAction {
  type: 'icon_alert';

  data: {
    title?: string;
    message?: string;
    image?: IRemoteImage;

    orientation: 'horizontal' | 'vertical';

    items: Array<{
      title: string;
      style: 'normal' | 'close' | 'link' | 'cancel';
      action: IAction;
    }>;
  };
}

const useIconAlertAction = () => {
  const overlay = useOverlay();

  const handleIconAlertAction = (
    action: IIconAlertAction
  ): Promise<IAction> => {
    return new Promise((resolve) => {
      overlay.presentIconAlert({
        title: action.data.title,
        message: action.data.message,
        image: action.data.image,
        orientation: action.data.orientation,
        items: action.data.items.map((item) => {
          return {
            title: item.title,
            style: item.style,
            onClick: () => {
              resolve(item.action);
            },
          };
        }),
      });
    });
  };

  return handleIconAlertAction;
};

export default useIconAlertAction;
