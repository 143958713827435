import { decamelizeKeys } from 'humps';
import { useCallback } from 'react';
import request, { IRequestOptions } from '../utils/request';

export interface IHttpCall {
  method: IRequestOptions['method'];
  url: string;
  body?: object;
  retryable?: boolean;
}

const useHttpCall = () => {
  const handleHttpCall = useCallback(
    <T>(call: IHttpCall, options?: IRequestOptions): Promise<T> => {
      return request<T>(call.url, {
        ...options,

        method: call.method,
        retryable: call.retryable,
        body: {
          ...(typeof call.body === 'object' && {
            ...decamelizeKeys(call.body),
          }),

          // Merge call body and the optionally provided body.
          ...options?.body,
        },
      });
    },
    []
  );

  return handleHttpCall;
};

export default useHttpCall;
