/** @jsx jsx */
import React from 'react';
import { CSSObject, jsx } from '@emotion/core';

import AttributedText from '../../../components/attributedText';
import { IBasePart } from '../part';

export interface IAttributedLabelPart extends IBasePart {
  type: 'attributed_label';

  attributedText: string;
  topMargin?: number;
  bottomMargin?: number;
  leadingMargin?: number;
  trailingMargin?: number;
}

const headerLevels: Array<keyof JSX.IntrinsicElements> = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
];

const AttributedLabelPart = (data: IAttributedLabelPart) => {
  const STYLE: CSSObject = {
    paddingLeft: data.leadingMargin,
    paddingRight: data.trailingMargin,
    paddingTop: data.topMargin,
    paddingBottom: data.bottomMargin,
  };

  // Default to level 1 (h2), if not header level has been specified.
  const level = data.accessibilityInfo?.headerLevel
    ? data.accessibilityInfo?.headerLevel - 1
    : 1;

  // Wrap with header, if present else default to div.
  const Wrapper = data.accessibilityInfo?.header ? headerLevels[level] : 'div';

  return (
    <Wrapper css={STYLE}>
      <AttributedText text={data.attributedText} />
    </Wrapper>
  );
};

export default AttributedLabelPart;
