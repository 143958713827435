import useOverlay from '../../../useOverlay';
import { IFlexNavigation } from '../../useFlexNavigation';

export interface IFlexOverlayAlert {
  type: 'alert';
  title: string;
  message?: string;
  style: 'alert' | 'sheet';

  actions: Array<{
    title: string;
    style: 'normal' | 'destructive' | 'cancel';
    navigation?: IFlexNavigation;
  }>;
}

const useFlexOverlayAlert = () => {
  const overlay = useOverlay();

  const handleFlexOverlayAlert = (
    flexOverlay: IFlexOverlayAlert
  ): Promise<IFlexNavigation> => {
    return new Promise((resolve) => {
      switch (flexOverlay.style) {
        case 'alert':
          overlay.presentAlert({
            title: flexOverlay.title,
            message: flexOverlay.message,
            items: flexOverlay.actions.map((action) => {
              return {
                title: action.title,
                isCancel: action.style === 'cancel',
                isDestructive: action.style === 'destructive',
                onClick: () => {
                  if (action.navigation) {
                    resolve(action.navigation);
                  }
                },
              };
            }),
          });
          break;

        case 'sheet':
          overlay.presentActionList({
            title: flexOverlay.title,
            message: flexOverlay.message,
            cancelLabel: flexOverlay.actions.find(
              (action) => action.style === 'cancel'
            )?.title,
            items: flexOverlay.actions
              .filter((action) => action.style !== 'cancel')
              .map((action) => {
                return {
                  title: action.title,
                  isDestructive: action.style === 'destructive',
                  onClick: () => {
                    if (action.navigation) {
                      resolve(action.navigation);
                    }
                  },
                };
              }),
          });
          break;
      }
    });
  };

  return handleFlexOverlayAlert;
};

export default useFlexOverlayAlert;
