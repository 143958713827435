/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';
import React, { useRef, useEffect } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

export type IVideoPlayerPlaybackEvent = (currentTime: number) => void;

interface IVideoPlayer {
  onPlay: IVideoPlayerPlaybackEvent;
  onPause: IVideoPlayerPlaybackEvent;
  onEnd: IVideoPlayerPlaybackEvent;
  onReady?: (startPlayback: () => void) => void;
  onDisposeWhenPlaying: IVideoPlayerPlaybackEvent;
  options: videojs.PlayerOptions;
}

const initialOptions: videojs.PlayerOptions = {
  autoplay: false,
  controls: true,
  responsive: true,
  fill: true,
};

const STYLES: CSSObject = {
  // Fixing time indicator for video control for custom video player
  '.vjs-time-tooltip': {
    whiteSpace: 'nowrap',
  },
  '.vjs-volume-tooltip': {
    whiteSpace: 'nowrap',
  },
};

export const VideoPlayer = ({
  options,
  onPause,
  onEnd,
  onPlay,
  onReady,
  onDisposeWhenPlaying,
}: IVideoPlayer) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const playerRef = useRef<videojs.Player>();

  useEffect(() => {
    // Make sure the player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      // Initialize the player
      const player = (playerRef.current = videojs(videoElement, {
        ...initialOptions,
        ...options,
      }));

      // Enable tracking functions for various player events
      player.on('dispose', () => {
        // Trigger the onDisposeWhenPlaying function only if the player is playing
        if (!player.paused()) {
          onDisposeWhenPlaying(player.currentTime());
        }
      });

      player.on('play', () => {
        player.play();
        onPlay(player.currentTime());
      });

      player.on('pause', () => {
        player.pause();

        // The pause event get triggered when the video ends, so
        // the pause callback function should only be triggered
        // when the video has not been completed
        if (player.currentTime() !== player.duration()) {
          onPause(player.currentTime());
        }
      });

      player.on('ended', () => {
        onEnd(player.currentTime());
      });

      if (onReady) {
        player.on('ready', () => {
          onReady(() => {
            player.play();
          });
        });
      }
    }
  }, [
    options,
    videoRef,
    onReady,
    onDisposeWhenPlaying,
    onPlay,
    onPause,
    onEnd,
  ]);

  // Dispose the player on unmount
  useEffect(() => {
    return () => {
      playerRef.current?.dispose();
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        css={STYLES}
        ref={videoRef}
        className='video-js vjs-big-play-centered'
      />
    </div>
  );
};

export default VideoPlayer;
