import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import useEventCallback from './useEventCallback';
import useNavigation from './useNavigation';
import parseUri, { IParsedUri } from '../utils/parseUri';
import { IView } from '../views/view';
import useLanguage from './useLanguage';
import useOverlay from './useOverlay';
import { AppContext } from '../contexts/appContext';
import { logError } from '../utils/remoteLogger';
import { IFlexHttpCall } from './useFlexHttpCall';

const useInternalUrl = () => {
  const { reinitializeApp, logout } = useContext(AppContext);
  const navigation = useNavigation();
  const history = useHistory();
  const language = useLanguage();
  const overlay = useOverlay();

  const handleInternalUrl = (url: string) => {
    const parsedUrl = parseUri(url);

    const handleViewUrl = (parsedUrlArg: IParsedUri) => {
      const presentationMode = parsedUrlArg.query.get('presentation_mode');

      const view: IView = {
        type: 'SCREEN',
        source: {
          url: parsedUrlArg.relative,
        },
      };

      if (presentationMode === 'present') {
        navigation.present(view);
      } else {
        navigation.push(view);
      }
    };

    const handleLogoutUrl = () => {
      logout(true);
    };

    const handleRelaunchUrl = () => {
      reinitializeApp();
    };

    const handleBookUrl = (parsedUrlArg: IParsedUri) => {
      const view: IView = {
        type: 'FLEX',
        source: {
          call: {
            url: `/api/view/flex-booking/start?${parsedUrlArg.query}`,
            method: 'POST',
          },
        },
      };

      navigation.present(view);
    };

    const handleEmailSupportUrl = () => {
      document.location.href = `mailto:${language.get(
        'support_email'
      )}?Subject=${language.get('support_subject')}`;
    };

    const handleTabUrl = (parsedUrlArg: IParsedUri) => {
      // Hardcoded legacy values.
      switch (parsedUrlArg.path) {
        case '/home':
        case '/book':
          history.push('/');
          break;
        case '/pharmacy':
        case '/test':
          history.push('/pharmacy');
          break;
        case '/more':
          history.push('/profile');
          break;
        default:
          // Attempt to navigate to whatever tab was provided.
          // If it doesn't exist, the router will fallback to /home.
          history.push(parsedUrlArg.path);
      }
    };

    const handleFlexUrl = (parsedUrlArg: IParsedUri) => {
      const encodedCall = parsedUrlArg.query.get('call');

      if (encodedCall) {
        try {
          const decodedCall = JSON.parse(atob(encodedCall)) as IFlexHttpCall;

          const view: IView = {
            type: 'FLEX',
            source: {
              call: decodedCall,
            },
          };

          navigation.present(view);
        } catch (e) {
          logError(
            'useInternalUrl',
            `Failed to parse flex url action: ${parsedUrlArg.source}`
          );
        }
      }
    };

    const handleCopyUrl = (parsedUrlArg: IParsedUri) => {
      const link = parsedUrlArg.query.get('link');

      if (link) {
        navigator.clipboard.writeText(link).then(() => {
          overlay.presentBasicAlert({
            title: language.get('link_copied'),
          });
        });
      }
    };

    if (parsedUrl.protocol === 'kry' || parsedUrl.protocol === 'livi') {
      switch (parsedUrl.host) {
        case 'view':
          handleViewUrl(parsedUrl);
          break;
        case 'logout':
          handleLogoutUrl();
          break;
        case 'relaunch':
          handleRelaunchUrl();
          break;
        case 'book':
          handleBookUrl(parsedUrl);
          break;
        case 'email_support':
          handleEmailSupportUrl();
          break;
        case 'tab':
          handleTabUrl(parsedUrl);
          break;
        case 'flex':
          handleFlexUrl(parsedUrl);
          break;
        case 'copy':
          handleCopyUrl(parsedUrl);
          break;
      }
    } else if (parsedUrl.protocol === 'tel') {
      document.location.href = `tel:${parsedUrl.host}`;
    } else if (parsedUrl.protocol === 'mailto') {
      document.location.href = `mailto:${parsedUrl.host}`;
    } else {
      // Assuming an external link.
      window.open(parsedUrl.source);
    }
  };

  return useEventCallback(handleInternalUrl);
};

export default useInternalUrl;
