/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import useTheme from '../../../../../hooks/useTheme';
import useLanguage from '../../../../../hooks/useLanguage';
import LocalImage from '../../../../../components/localImage';
import AttributedText from '../../../../../components/attributedText';

export const InputError = () => {
  const { color } = useTheme();
  const language = useLanguage();

  return (
    <div
      css={{
        color: color.ALERT,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        padding: '0px 20px',
      }}
    >
      <LocalImage
        src='APP_IMAGE_ASYNC_MESSAGING_ERROR'
        tint='ALERT'
        css={{
          width: 16,
          height: 16,
        }}
        alt=''
      />
      <AttributedText
        aria-live='polite'
        css={{ flex: 1, padding: '0 5px' }}
        text={language.get('async_messaging_compose_error')}
      />
    </div>
  );
};
