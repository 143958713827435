/** @jsx jsx */
import React from 'react';
import { jsx, keyframes } from '@emotion/core';
import { IScheduleDay, IScheduleSlot } from './schedule';
import Clickable from '../../../../../components/clickable';
import useTheme from '../../../../../hooks/useTheme';
import useFlexNavigation from '../../../../../hooks/flex/useFlexNavigation';

interface IScheduleRowProps {
  title: string;
  slots: Array<IScheduleSlot>;
  slotDay: IScheduleDay;

  onSlotSelect?: (slot: IScheduleSlot) => void;
}

const ScheduleRow = ({
  title,
  slots,
  slotDay,

  onSlotSelect,
}: IScheduleRowProps) => {
  const { font, color } = useTheme();
  const handleFlexNavigation = useFlexNavigation();

  const fadeInAnimation = keyframes`
    0% {opacity: 0}
    100% {opacity: 1}
  `;

  const renderHourSlots = () => {
    return slots.map((slot) => {
      const slotKey = `${slotDay.title}_${slot.text}`;
      const ariaSlot = `${slotDay.title} ${slot.text}`;

      return (
        <Clickable
          key={slotKey}
          scale
          darken
          css={{
            border: `${color.DIVIDER_LINE} solid 2px`,
            borderRadius: 29,
            fontSize: 15,
            backgroundColor: color.LOCAL_WHITE,
            color: color.TEXT,
            width: 'calc(25% - 8px)',
            textAlign: 'center',
            padding: '10px 0',
            margin: '6px 4px',
          }}
          onClick={() => {
            if (onSlotSelect) {
              onSlotSelect(slot);
            }

            handleFlexNavigation(slot.navigation);
          }}
          aria-label={ariaSlot}
        >
          {slot.text}
        </Clickable>
      );
    });
  };

  const ariaTitle = `${slotDay.title} ${title}`;

  return (
    <div css={{ animation: `${fadeInAnimation} 0.3s` }}>
      <h3
        css={{
          fontFamily: font.FAMILY_MEDIUM,
          fontSize: 12,
          margin: '0 0 6px 4px',
        }}
        aria-label={ariaTitle}
      >
        {title}
      </h3>
      <div
        css={{
          display: 'flex',
          flexWrap: 'wrap',
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          marginBottom: 20,
        }}
      >
        {renderHourSlots()}
      </div>
    </div>
  );
};

export default ScheduleRow;
