/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';

import { IBasePart } from '../part';
import AttributedText from '../../../components/attributedText';
import Clickable from '../../../components/clickable';
import { IAction } from '../../../hooks/useAction';
import useTheme from '../../../hooks/useTheme';

export interface IDataNavigationRowLeftLabelPart extends IBasePart {
  type: 'data_navigation_row_left_label';

  topMargin: number;
  bottomMargin: number;
  leadingMargin: number;
  trailingMargin: number;
  minimumHeight: number;

  attributedText: string;

  highlightColor: string;
  imageHeight: number;
  iconName: string;
  iconUrl: string;
  iconHeight: number;
  imageName: string;
  imageUrl: string;

  action: IAction;
}

const DataNavigationRowLeftLabelPart = (
  data: IDataNavigationRowLeftLabelPart
) => {
  const { resolveColor } = useTheme();

  const WRAPPER_STYLE: CSSObject = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: data.leadingMargin || 20,
    paddingRight: data.trailingMargin || 20,
    // paddingTop: data.topMargin || 20,
    // paddingBottom: data.bottomMargin || 20,
    boxSizing: 'border-box',
    minHeight: data.minimumHeight,
    '::after': {
      content: data.iconUrl ? `''` : 'unset',
      display: 'inline-block',
      width: 28,
      height: 28,
      margin: '-8px 0 -8px',
      backgroundColor: 'pink',
      backgroundImage: data.iconUrl ? `url(${data.iconUrl})` : 'unset',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right center',
      backgroundSize: data.iconHeight,
    },
    '&[active]': {
      backgroundColor: resolveColor(data.highlightColor),
    },
  };

  const LABEL_STYLE: CSSObject = {
    flex: 1,
    paddingTop: data.topMargin || 20,
    paddingBottom: data.bottomMargin || 20,
  };

  const IMAGE_STYLE: CSSObject = {
    width: 'auto',
    height: data.imageHeight,
    marginRight: 8,
    borderRadius: '50%',
  };

  return (
    <Clickable action={data.action} css={WRAPPER_STYLE}>
      {data.imageUrl && <img css={IMAGE_STYLE} src={data.imageUrl} alt='' />}
      <AttributedText css={LABEL_STYLE} text={data.attributedText} />
    </Clickable>
  );
};

export default DataNavigationRowLeftLabelPart;
