/** @jsx jsx */
import { useContext, useEffect, useRef } from 'react';
import { ViewContext } from '../contexts/viewContext';
import useSessionStorage from '../hooks/useSessionStorage';

const ScrollManager = () => {
  const { mainElement } = useContext(ViewContext);

  const [getCache] = useSessionStorage<number>('SCROLL_POSITION', {
    locationAware: true,
  });
  const getCacheRef = useRef(getCache);

  // Update hook references.
  useEffect(() => {
    getCacheRef.current = getCache;
  }, [getCache]);

  // Restore scroll position or scroll to top if we have navigated to a new route.
  useEffect(() => {
    window.requestAnimationFrame(() => {
      if (mainElement) {
        const cachedPos = getCacheRef.current();

        window.scrollTo(0, cachedPos || 0);
        mainElement.scrollTop = cachedPos || 0;
      }
    });
  }, [mainElement]);

  return null;
};

export default ScrollManager;
