/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';
import useTheme from '../hooks/useTheme';
import useInternalUrl from '../hooks/useInternalUrl';

interface IElement {
  node: ChildNode;
}

const Element = ({ node }: IElement) => {
  const handleInternalUrl = useInternalUrl();
  const { resolveColor, resolveFont } = useTheme();

  const handleLinkClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    const href = e.currentTarget.getAttribute('href');

    if (href) {
      handleInternalUrl(href);
    }
  };

  const handleLinkKeyPress = (e: React.KeyboardEvent<HTMLLIElement>) => {
    const href = e.currentTarget.getAttribute('href');
    const key = e.charCode || e.keyCode;

    // Only trigger click on return or space.
    if ((key === 13 || key === 32) && href) {
      e.preventDefault();
      e.stopPropagation();
      handleInternalUrl(href);
    }
  };

  const attributesToReactAttributes = (attributes: NamedNodeMap) => {
    const reactAttributes: Record<string, React.ReactNode> = {};

    for (let i = 0; i < attributes.length; i++) {
      const attrName = attributes[i].name;
      const attrValue = attributes[i].value;

      switch (attrName.toLowerCase()) {
        case 'url':
          reactAttributes.href = attrValue;
          reactAttributes.onClick = handleLinkClick;
          reactAttributes.onKeyDown = handleLinkKeyPress;
          break;
      }
    }

    return reactAttributes;
  };

  const attributesToStyle = (tagName: string, attributes: NamedNodeMap) => {
    const style: CSSObject = {};

    if (tagName === 'u') {
      style.textDecoration = 'underline';
    }

    if (tagName === 'action') {
      style.color = 'inherit';
      style.textDecoration = 'underline';
      style.padding = '3px 6px';
      style.margin = '-3px -6px';
    }

    for (let i = 0; i < attributes.length; i++) {
      const attrName = attributes[i].name;
      const attrValue = attributes[i].value;

      switch (attrName.toLowerCase()) {
        case 'color':
          style.color = resolveColor(attrValue);
          break;
        case 'font':
          style.fontFamily = resolveFont(attrValue);
          break;
        case 'size':
          style.fontSize = `${attrValue}px`;
          break;
        case 'line-height':
          style.lineHeight = attrValue;
          break;
        case 'align':
          style.textAlign = attrValue as 'left' | 'right' | 'center';
          if (attrValue === 'center' || attrValue === 'right') {
            style.display = 'block';
          }
          break;
      }
    }

    return { css: style };
  };

  const xmlTagToHtmlTag = (tagName: string) => {
    switch (tagName) {
      case 'br':
        return 'br';
      case 'action':
        return 'a';
      default:
        return 'span';
    }
  };

  const xmlTagName = node.nodeName;
  const xmlAttributes = (node as HTMLElement).attributes || [];

  const htmlTagName = xmlTagToHtmlTag(xmlTagName);
  const reactAttributes = attributesToReactAttributes(xmlAttributes);
  const style = attributesToStyle(node.nodeName, xmlAttributes);

  const nodes = [];
  for (let i = 0; i < node.childNodes.length; i++) {
    nodes.push(node.childNodes[i]);
  }

  // <br> is the only empty element, treat it separately.
  return htmlTagName === 'br'
    ? jsx('br', {})
    : jsx(
        htmlTagName,
        {
          ...style,
          ...reactAttributes,
        },
        xmlTagName === '#text' ? node.textContent : undefined,
        nodes.map((currentNode, i) => <Element key={i} node={currentNode} />)
      );
};

interface IAttributedText {
  text?: string;
  renderAs?: keyof JSX.IntrinsicElements;
}

const AttributedText = ({ text, renderAs, ...props }: IAttributedText) => {
  // If the provided text doesn't seem to be expected xml attributed text,
  // just render it as is.
  if (!text?.startsWith('<text')) {
    return <div {...props}>{text}</div>;
  }

  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(text, 'text/xml');

  const WrapperElement = renderAs || 'div';

  return (
    <WrapperElement {...props}>
      <Element node={xmlDoc.documentElement} />
    </WrapperElement>
  );
};

export default AttributedText;
