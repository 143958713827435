import React, {
  useEffect,
  useRef,
  useReducer,
  createContext,
  ReactNode,
} from 'react';
import { dequal } from 'dequal';
import request, { IRequestOptions } from '../utils/request';
import RequestError from '../utils/requestError';

import { screenContextReducer } from '../reducers/screenContextReducer';

import { IScreen } from '../views/screen/screen';
import { IAction } from '../hooks/useAction';
import useSessionStorage from '../hooks/useSessionStorage';
import useLocalScreen from '../hooks/useLocalScreen';
import useWebSocket from '../hooks/useWebSocket';
import { scrollElementToViewportTop } from '../utils/smoothScroll';

interface IInputState {
  value: unknown;
  isValid: boolean;
  isDirty: boolean;
  isTouched: boolean;
  errorMessage?: string;
}

export interface IScreenState {
  source?: IScreenSource;
  screen?: IScreen;

  onLoadActions?: Array<IAction>;
  hiddenIds: Array<string>;

  partStates: {
    [partId: string]: unknown;
  };
  inputStates: {
    [inputId: string]: IInputState;
  };
}

export interface IScreenContext extends IScreenState {
  onAction: (callback: (action: IAction) => void) => void;
  setHiddenIds: (hiddenIds: Array<string>) => void;
  setPartState: (partStates: { [partIdId: string]: unknown }) => void;
  setInputState: (
    partStates: {
      [partIdId: string]: IInputState;
    },
    keepLastOnNull?: boolean
  ) => void;
  reloadScreen: () => void;
  updateScreenSource: (source: IScreenSource) => void;
  getPartIdFromInputId: (inputId: string) => string;
  scrollPartIntoView: (partId: string) => void;
}

export interface IScreenSource {
  url?: string;
  requestOptions?: IRequestOptions;
  screen?: IScreen;

  externalOnLoadAction?: IAction;
  sourceUpdatedAt?: number;

  // @TODO: Remove this when all screens in need of auto-reloading
  // use the new reloadConfig.autoReloadIntervalMillis property.
  autoRefreshInterval?: number;
}

interface IScreenContextProvider {
  routeSource?: IScreenSource;
  children: ReactNode;
}

interface IWebSocketMessage {
  type: 'replace';
  screen: IScreen;
}

export const ScreenContext = createContext({} as IScreenContext);

export const ScreenContextProvider = ({
  children,
  routeSource,
}: IScreenContextProvider) => {
  const [getCache, setCache] = useSessionStorage<IScreenState>(
    'SCREEN_CONTEXT',
    { locationAware: true }
  );
  const [state, dispatch] = useReducer(screenContextReducer, {
    source: routeSource,
    hiddenIds: [],
    partStates: {},
    inputStates: {},
  });

  const setCacheRef = useRef(setCache);
  const getCacheRef = useRef(getCache);
  const actionHandlerRef = useRef<(action: IAction) => void>();
  const localScreenRef = useRef(useLocalScreen());
  const autoReloadIntervalRef = useRef<number>();

  const webSocketMessage = useWebSocket<IWebSocketMessage>(
    state.screen?.webSocketUrl,
    {
      appendCustomHeaders: true,
    }
  );

  const onAction = (callback: (action: IAction) => void) => {
    actionHandlerRef.current = callback;
  };

  const setHiddenIds = (hiddenIds: Array<string>) => {
    dispatch({
      type: 'SET_HIDDEN_IDS',
      payload: hiddenIds,
    });
  };

  const setPartState = (partState: { [partId: string]: unknown }) => {
    dispatch({
      type: 'SET_PART_STATE',
      payload: partState,
    });
  };

  const setInputState = (
    inputState: {
      [partId: string]: IInputState;
    },
    keepLastOnNull?: boolean
  ) => {
    dispatch({
      type: 'SET_INPUT_STATE',
      payload: {
        inputState,
        keepLastOnNull,
      },
    });
  };

  const loadScreen = (
    source?: IScreenSource,
    options?: {
      change?: boolean;
      reload?: boolean;
      silent?: boolean;
    }
  ) => {
    const setScreen = (screen?: IScreen) => {
      if (source) {
        const onLoadActions = [
          source.externalOnLoadAction,
          screen?.onLoadAction,
        ].filter((action): action is IAction => !!action);

        dispatch({
          type: 'UPDATE_SCREEN_STATE',
          payload: {
            screen,
            source,
            onLoadActions,
          },
        });
      }
    };

    // Remove the current screen while loading the new one.
    if (!options?.reload && !options?.change) {
      setScreen();
    }

    // Screens can be provided inline by e.g. flex nodes.
    // If the screen is being reloaded, ignore a potential inline screen.
    if (source?.screen && !options?.reload) {
      setScreen(source.screen);
      return;
    }

    // If no screen data is provided but we have an url, fetch it.
    if (source?.url) {
      request<IScreen>(source.url, {
        method: source.requestOptions?.method || 'GET',
        body: source.requestOptions?.body,
        silent: options?.silent || source.requestOptions?.silent,
        retryable: source.requestOptions?.retryable,
        assumeJson: true,
      })
        .then((screenData) => {
          // Assert that we got an actual screen.
          setScreen(
            screenData.parts ? screenData : localScreenRef.current.errorScreen
          );
        })
        .catch((error: RequestError) => {
          // If the request failed for whatever reason other than being cancelled,
          // show a local error screen.
          if (error.status !== 20 && !options?.reload && !options?.change) {
            setScreen(localScreenRef.current.errorScreen);
          }
        });
    }
  };

  const reloadScreen = () => {
    loadScreen(state.source, {
      reload: true,
      silent: state.screen?.reloadConfig?.silent,
    });
  };

  const updateScreenSource = (source: IScreenSource) => {
    loadScreen(source, {
      change: true,
      silent: state.screen?.reloadConfig?.silent,
    });
  };

  const getPartIdFromInputId = (inputId: string) => {
    // A hacky method to find the part containing a specific input.
    // Not performance-dependent so let's not make a fuss.
    return (
      state.screen?.parts.find(
        (part) => JSON.stringify(part).indexOf(`"inputId":"${inputId}"`) > -1
      )?.id || ''
    );
  };

  const scrollPartIntoView = (partId: string) => {
    const partElement = document.getElementById(`screen-part-${partId}`);

    if (partElement) {
      scrollElementToViewportTop(partElement, true);
    }
  };

  useEffect(() => {
    getCacheRef.current = getCache;
    setCacheRef.current = setCache;
  }, [getCache, setCache]);

  useEffect(() => {
    const cachedState = getCacheRef.current();
    const cachedReloadConfig = cachedState?.screen?.reloadConfig;
    const cachePredatesSource =
      (routeSource?.sourceUpdatedAt || 0) >
      (cachedState?.source?.sourceUpdatedAt || 0);

    // If we've navigated backwards, set the cached state.
    if (cachedState) {
      dispatch({
        type: 'SET_STATE',
        payload: {
          ...cachedState,
          onLoadActions: [],
          source: {
            ...cachedState.source,
            sourceUpdatedAt: routeSource?.sourceUpdatedAt,
          },
        },
      });

      // If we have a cached state but have received a new screen source,
      // e.g. if the screen was unwound to and flex memory has been updated,
      // evaluate the new source.
      // The source could either contain a new onLoadAction or a whole new call.
      if (cachePredatesSource) {
        // @NOTE: The screen call will shortly get refactored into a single HttpRequest,
        // which will streamline this comparison.
        const oldUrl = cachedState.source?.url;
        const oldRequestOptions = cachedState.source?.requestOptions;
        const newUrl = routeSource?.url;
        const newRequestOptions = routeSource?.requestOptions;

        // If the call has changed or we have inline screen data,
        // reload the screen with the new source.
        // This will also apply a potential new OnLoadAction.
        if (
          routeSource?.screen ||
          oldUrl !== newUrl ||
          !dequal(oldRequestOptions, newRequestOptions)
        ) {
          loadScreen(routeSource, {
            change: true,
            silent: cachedReloadConfig?.silent,
          });

          return;
        }

        // If the call hasn't changed but we've received a new OnLoadAction,
        // apply it without reloading the screen.
        if (routeSource?.externalOnLoadAction) {
          dispatch({
            type: 'SET_EXTERNAL_ACTION',
            payload: routeSource?.externalOnLoadAction,
          });
        }
      }

      // If the screen has a reloadConfig with shouldReloadOnShow set to true,
      // reload it.
      if (cachedReloadConfig?.shouldReloadOnShow) {
        loadScreen(cachedState.source, {
          reload: true,
          silent: cachedReloadConfig?.silent,
        });
      }
    } else {
      loadScreen(routeSource);
    }
  }, [routeSource]);

  // Listen for webSocket messages.
  useEffect(() => {
    // Replace screen.
    if (webSocketMessage && webSocketMessage.type === 'replace') {
      loadScreen(
        {
          ...state.source,
          screen: webSocketMessage.screen,
          sourceUpdatedAt: Date.now(),
        },
        { change: true }
      );
    }
  }, [webSocketMessage, state.source]);

  // Store current screen state to cache.
  useEffect(() => {
    if (state.screen) {
      // Update cache ref with current state
      setCacheRef.current(state);
    }
  }, [state]);

  // Handle the on-load actions.
  useEffect(() => {
    state.onLoadActions?.forEach((onLoadAction) => {
      // Do not reload the screen multiple times if it happens to both have a reload on-load action
      // and a reloadConfig telling it to reload on show.
      if (
        onLoadAction.type === 'reload' &&
        state.screen?.reloadConfig?.shouldReloadOnShow
      ) {
        return;
      }

      if (actionHandlerRef.current) {
        actionHandlerRef.current(onLoadAction);
      }
    });

    // Clear the actions from the state once they have been handled.
    dispatch({ type: 'CLEAR_ONLOAD_ACTIONS' });
  }, [state.screen, state.onLoadActions]);

  // Automatically reload the screen if a reloadConfig.autoReloadIntervalMillis property has been provided.
  //
  // @TODO: Remove listening to routeSource.autoRefreshInterval when all screens in need of auto-reloading
  // use the new reloadConfig property.
  useEffect(() => {
    const autoReloadInterval =
      state.screen?.reloadConfig?.autoReloadIntervalMillis ||
      routeSource?.autoRefreshInterval;

    if (autoReloadInterval) {
      clearInterval(autoReloadIntervalRef.current);
      autoReloadIntervalRef.current = window.setInterval(() => {
        loadScreen(state.source, {
          reload: true,
          silent: state.screen?.reloadConfig?.silent,
        });
      }, autoReloadInterval);
    }

    return () => {
      clearInterval(autoReloadIntervalRef.current);
    };
  }, [
    state,
    state.screen?.reloadConfig?.autoReloadIntervalMillis,
    routeSource?.autoRefreshInterval,
  ]);

  return (
    <ScreenContext.Provider
      value={{
        source: state.source,
        screen: state.screen,
        hiddenIds: state.hiddenIds,
        partStates: state.partStates,
        inputStates: state.inputStates,

        onAction,
        setHiddenIds,
        setPartState,
        setInputState,
        reloadScreen,
        updateScreenSource,
        getPartIdFromInputId,
        scrollPartIntoView,
      }}
    >
      {children}
    </ScreenContext.Provider>
  );
};
