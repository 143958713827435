/** @jsx jsx */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { jsx } from '@emotion/core';
import useTheme from '../../../../../hooks/useTheme';
import useFlexHttpCall, {
  IFlexHttpCall,
} from '../../../../../hooks/useFlexHttpCall';
import useLanguage from '../../../../../hooks/useLanguage';
import useAction, { IAction } from '../../../../../hooks/useAction';
import BottomSticky from '../../../../../components/bottomSticky';
import { dataURItoBlob, resizeImage } from '../../../../../utils/imageUtils';
import useOverlay from '../../../../../hooks/useOverlay';
import {
  IAsyncMessagingInteraction,
  IAsyncMessagingUpload,
} from '../asyncMessagingFlexNode';
import { InputError } from './inputError';
import { SubmitButton } from './submitButton';
import { UploadButton } from './uploadButton';
import { MessageInput } from './messageInput';
import { Attachments } from './attachments';
import { logError } from '../../../../../utils/remoteLogger';
import useSnowplowTracker from '../../../../../hooks/useSnowplowTracker';
import { useDebounce } from '../../../../../hooks/useDebounce';

interface IConversationInput {
  sendMessageCall: IFlexHttpCall;
  uploadAttachmentSettings?: IAsyncMessagingUpload;
  conversationId: string;
  messageUpdateConfig?: IAsyncMessagingInteraction;
}

export interface IMessageAttachment {
  referenceId: string;
  referenceType: string;
  thumbnail: string;
}

export type IConversationInputStatus = 'INITIAL' | 'PROGRESS' | 'ERROR';

const ConversationInput = ({
  sendMessageCall,
  uploadAttachmentSettings,
  conversationId,
  messageUpdateConfig,
}: IConversationInput) => {
  const { color } = useTheme();
  const handleFlexHttpCall = useFlexHttpCall();
  const handleAction = useAction();
  const overlay = useOverlay();
  const language = useLanguage();
  const snowplowTracker = useSnowplowTracker();

  const [message, setMessage] = useState('');
  const [status, setStatus] = useState<IConversationInputStatus>('INITIAL');
  const [attachments, setAttachments] = useState<Array<IMessageAttachment>>([]);
  const messageUpdate = useDebounce<string>(
    message,
    messageUpdateConfig?.debounceTimeInMillis || 0
  );

  const submitMessage = useCallback(() => {
    setStatus('PROGRESS');

    handleFlexHttpCall<{ completionAction?: IAction }>(sendMessageCall, {
      body: {
        message,
        attachments: attachments.map((attachment) => ({
          reference_type: attachment.referenceType,
          reference_id: attachment.referenceId,
        })),
      },
    })
      .then((res) => {
        handleAction(res.completionAction);
        setMessage('');
        setAttachments([]);
        setStatus('INITIAL');
      })
      .catch(() => {
        setStatus('ERROR');
      });
  }, [attachments, handleAction, handleFlexHttpCall, message, sendMessageCall]);

  const handleOnKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (
        (event.key === 'Enter' && event.ctrlKey) ||
        (event.key === 'Enter' && event.metaKey)
      ) {
        event.preventDefault();
        submitMessage();
      }
    },
    [submitMessage]
  );

  const isButtonDisabled = useMemo(() => {
    return message.trim().length === 0 && attachments.length === 0;
  }, [message, attachments]);

  const uploadAttachment = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      attachments.length === uploadAttachmentSettings?.maxNumberOfAttachments
    ) {
      overlay.presentBasicAlert({
        title: language.get('messaging_photo_limit_error_title'),
        message: language.get('messaging_photo_limit_error_body'),
      });

      return;
    }

    const file = e.target?.files?.[0];

    if (
      file?.size &&
      uploadAttachmentSettings?.maxFileSize &&
      Math.floor(file?.size / 1000) > uploadAttachmentSettings?.maxFileSize
    ) {
      overlay.presentBasicAlert({
        title: language.get('messaging_photo_too_big_error_title'),
        message: language
          .get('messaging_photo_too_big_error_body')
          .replace(
            '[file size]',
            `${uploadAttachmentSettings.maxFileSize / 1000} MB`
          ),
      });

      return;
    }

    if (file && uploadAttachmentSettings?.call) {
      snowplowTracker.trackEvent({
        ...snowplowTracker.event.ATTACHMENT_UPLOAD_STARTED,
        label: 'photo',
        property: conversationId,
      });

      setStatus('PROGRESS');

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        if (reader.result) {
          const imgData = reader.result as string;
          // Generate a thumbnail.
          resizeImage(imgData, 100).then((thumbnailURI) => {
            // Generate the scaled-down version of the image and attempt upload.
            resizeImage(imgData, 2880).then((finalImageURI) => {
              const formData = new FormData();
              formData.append('file', dataURItoBlob(finalImageURI), file.name);
              formData.append('Content-Type', 'image/jpeg');

              handleFlexHttpCall<{ id: string }>(
                uploadAttachmentSettings.call,
                {
                  formData,
                  assumeBlob: false,
                  method: 'POST',
                }
              )
                .then((res) => {
                  setAttachments((oldAttachments) => {
                    oldAttachments.push({
                      referenceId: res.id,
                      referenceType: uploadAttachmentSettings.type,
                      thumbnail: thumbnailURI,
                    });

                    return oldAttachments;
                  });

                  setStatus('INITIAL');

                  snowplowTracker.trackEvent({
                    ...snowplowTracker.event.ATTACHMENT_UPLOAD_COMPLETED,
                    label: 'photo',
                    property: conversationId,
                  });
                })
                .catch(() => {
                  overlay.presentBasicAlert({
                    title: language.get(
                      'messaging_photo_failed_add_photo_title'
                    ),
                    message: language.get(
                      'messaging_photo_failed_add_photo_body'
                    ),
                  });
                });
            });
          });
        }
      };
    }
  };

  const sendMessageUpdate = useCallback(() => {
    if (!messageUpdateConfig || message.length === 0) {
      return;
    }
    handleFlexHttpCall<{ completionAction?: IAction }>(
      messageUpdateConfig.call,
      {
        body: {
          message,
        },
        silent: true,
      }
    )
      .then((res) => {
        handleAction(res.completionAction);
      })
      .catch(() => {
        logError(
          'ConversationInput',
          `Failed to send typing activity for conversation ${conversationId}`
        );
      });
  }, [
    messageUpdateConfig,
    handleFlexHttpCall,
    message,
    handleAction,
    conversationId,
  ]);

  useEffect(() => {
    sendMessageUpdate();
  }, [messageUpdate, sendMessageUpdate]);

  const clearAttachment = (referenceId: string) => {
    setAttachments((oldAttachments) =>
      oldAttachments.filter(
        (attachment) => attachment.referenceId !== referenceId
      )
    );

    snowplowTracker.trackEvent({
      ...snowplowTracker.event.ATTACHMENT_UPLOAD_DELETED,
      label: 'photo',
      property: conversationId,
    });
  };

  return (
    <BottomSticky>
      <form
        css={{
          backgroundColor: color.LOCAL_WHITE,
          borderTop: `1px solid ${color.DIVIDER_LINE}`,
          padding: '10px 0',
          flexDirection: 'column',
          display: 'flex',
          flexWrap: 'wrap',
        }}
        onSubmit={(e) => {
          e.preventDefault();
          submitMessage();
        }}
      >
        {attachments.length > 0 && (
          <Attachments attachments={attachments} onClear={clearAttachment} />
        )}

        {/* Error badge */}
        {status === 'ERROR' && <InputError />}

        <div
          css={{
            height: 70,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {uploadAttachmentSettings && (
            <UploadButton onChange={uploadAttachment} />
          )}

          {/* Text area */}
          <MessageInput
            status={status}
            message={message}
            onChange={setMessage}
            onKeyDown={handleOnKeyDown}
            withAttachments={uploadAttachmentSettings != null}
          />

          {/* Submit button. Disabled only if
            - The submission is in progress
            - The message is empty with no attachments
          */}
          <SubmitButton status={status} disabled={isButtonDisabled} />
        </div>
      </form>
    </BottomSticky>
  );
};

export default ConversationInput;
