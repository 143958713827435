import { IBaseAction } from '../useAction';
import useOverlay from '../useOverlay';

export interface IViewImageAction extends IBaseAction {
  type: 'view_image';

  data: {
    url: string;
    accessibilityLabel?: string;
  };
}

const useViewImageAction = () => {
  const overlay = useOverlay();

  const handleViewImageAction = (action: IViewImageAction) => {
    overlay.presentImagePreview({
      url: action.data.url,
      accessibilityLabel: action.data.accessibilityLabel,
    });
  };

  return handleViewImageAction;
};

export default useViewImageAction;
