import { IBaseAction } from '../useAction';
import { IRequestOptions } from '../../utils/request';
import useNavigation from '../useNavigation';
import { IView } from '../../views/view';

export interface IFlexStartAction extends IBaseAction {
  type: 'flex_start';

  data: {
    method: IRequestOptions['method'];
    url: string;
    body?: object;
    retryable?: boolean;
  };
}

const useFlexStartAction = () => {
  const navigation = useNavigation();

  const handleFlexStartAction = (action: IFlexStartAction) => {
    const newView: IView = {
      type: 'FLEX',
      source: {
        call: {
          url: action.data.url,
          method: action.data.method,
          body: action.data.body,
          retryable: action.data.retryable,
        },
      },
    };

    navigation.present(newView);
  };

  return handleFlexStartAction;
};

export default useFlexStartAction;
