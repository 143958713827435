/** @jsx jsx */
import React from 'react';
import { CSSObject, jsx } from '@emotion/core';

import AttributedText from '../../../../../components/attributedText';
import { IAccessibilityInfo } from '../../../part';

export interface IFiveColumnTextCell {
  type: 'text';
  text: string;
  fixedWidth?: number;
  accessibilityInfo?: IAccessibilityInfo;
}

const headerLevels: Array<keyof JSX.IntrinsicElements> = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
];

interface IFiveColumnTextCellProps {
  data: IFiveColumnTextCell;
}

const FiveColumnTextCell = ({ data }: IFiveColumnTextCellProps) => {
  const STYLE: CSSObject = {
    width: data.fixedWidth ? data.fixedWidth : 'unset',
  };

  // Default to level 1 (h2), if not header level has been specified.
  const level = data.accessibilityInfo?.headerLevel
    ? data.accessibilityInfo?.headerLevel - 1
    : 1;

  // Wrap with header, if present else default to div.
  const Wrapper = data.accessibilityInfo?.header ? headerLevels[level] : 'div';

  return (
    <Wrapper>
      <AttributedText text={data.text} css={STYLE} />
    </Wrapper>
  );
};

export default FiveColumnTextCell;
