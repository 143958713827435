/** @jsx jsx */
import React, { useContext, Fragment } from 'react';
import { jsx } from '@emotion/core';

// Supported parts
import ActionableImageAttributedLabelPart, {
  IActionableImageAttributedLabelPart,
} from './parts/actionableImageAttributedLabelPart';
import ArcScoreBarPart, { IArcScoreBarPart } from './parts/arcScoreBarPart';
import AttributedButton, { IAttributedButton } from './parts/attributedButton';
import AttributedLabelPart, {
  IAttributedLabelPart,
} from './parts/attributedLabelPart';
import BillboardPart, { IBillboardPart } from './parts/billboardPart';
import BinaryButtonsPart, {
  IBinaryButtonsPart,
} from './parts/binaryButtonsPart';
import BoxCarouselPart, { IBoxCarouselPart } from './parts/boxCarouselPart';
import BoxPart, { IBoxPart } from './parts/boxPart';
import ButtonPart, { IButtonPart } from './parts/buttonPart';
import CardPart, { ICardPart } from './parts/cardPart';
import ChatBubblePart, { IChatBubblePart } from './parts/chatBubblePart';
import DataNavigationRowLeftLabelPart, {
  IDataNavigationRowLeftLabelPart,
} from './parts/dataNavigationRowLeftLabelPart';
import DataNavigationRowRightLabelPart, {
  IDataNavigationRowRightLabelPart,
} from './parts/dataNavigationRowRightLabelPart';
import DataTextRowBoxPart, {
  IDataTextRowBoxPart,
} from './parts/dataTextRowBoxPart';
import DataTextRowLeftLabelPart, {
  IDataTextRowLeftLabelPart,
} from './parts/dataTextRowLeftLabelPart';
import DataTextRowRightLabelPart, {
  IDataTextRowRightLabelPart,
} from './parts/dataTextRowRightLabelPart';
import FiveColumnPart, { IFiveColumnPart } from './parts/fiveColumnPart';
import GapPart, { IGapPart } from './parts/gapPart';
import HeaderPart, { IHeaderPart } from './parts/headerPart';
import HtmlPart, { IHtmlPart } from './parts/htmlPart';
import ImageCarouselPart, {
  IImageCarouselPart,
} from './parts/imageCarouselPart';
import ImageInfoBannerPart, {
  IImageInfoBannerPart,
} from './parts/imageInfoBannerPart';
import ImageInfoPart, { IImageInfoPart } from './parts/imageInfoPart';
import ImagePart, { IImagePart } from './parts/imagePart';
import ImageUploadInputPart, {
  IImageUploadInputPart,
} from './parts/imageUploadInputPart';
import LinePart, { ILinePart } from './parts/linePart';
import MapSnapshotPart, { IMapSnapshotPart } from './parts/mapSnapshotPart';
import MicCheckPart, { IMicCheckPart } from './parts/micCheckPart';
import PhoneInputPart, { IPhoneInputPart } from './parts/phoneInputPart';
import PromoCardPart, { IPromoCardPart } from './parts/promoCardPart';
import RatingInputPart, { IRatingInputPart } from './parts/ratingInputPart';
import SearchBarPart, { ISearchBarPart } from './parts/searchBarPart';
import SeparatorPart, { ISeparatorPart } from './parts/separatorPart';
import ShadowEdgePart, { IShadowEdgePart } from './parts/shadowEdgePart';
import ShapePart, { IShapePart } from './parts/shapePart';
import SpacePart, { ISpacePart } from './parts/spacePart';
import TabsPart, { ITabsPart } from './parts/tabsPart';
import TextAreaInputPart, {
  ITextAreaInputPart,
} from './parts/textAreaInputPart';
import TextInputPart, { ITextInputPart } from './parts/textInputPart';
import TitlePart, { ITitlePart } from './parts/titlePart';
import ToggleAttributedLabelPart, {
  IToggleAttributedLabelPart,
} from './parts/toggleAttributedLabelPart';
import VideoPart, { IVideoPart } from './parts/videoPart';

import { ScreenContext } from '../../contexts/screenContext';
import useTheme from '../../hooks/useTheme';
import CamCheckPart, { ICamCheckPart } from './parts/camCheckPart';
import EmbeddedMapPart, { IEmbeddedMapPart } from './parts/embeddedMapPart';
import BlocksPart, { IBlocksPart } from './parts/blocksPart';
import WaitTimeHeaderPart, {
  IWaitTimeHeaderPart,
} from './parts/waitTimeHeaderPart';

export interface IMargins {
  top: number;
  bottom: number;
  leading: number;
  trailing: number;
}

export interface IAccessibilityInfo {
  header?: boolean;
  headerLevel?: number;
}

export interface IBasePart {
  type: string;
  id: string;
  bgColor?: string;
  accessibilityInfo?: IAccessibilityInfo;
}

export type IPart =
  | IActionableImageAttributedLabelPart
  | IArcScoreBarPart
  | IAttributedButton
  | IAttributedLabelPart
  | IBillboardPart
  | IBinaryButtonsPart
  | IBlocksPart
  | IBoxCarouselPart
  | IBoxPart
  | IButtonPart
  | ICamCheckPart
  | ICardPart
  | IChatBubblePart
  | IDataNavigationRowLeftLabelPart
  | IDataNavigationRowRightLabelPart
  | IDataTextRowBoxPart
  | IDataTextRowLeftLabelPart
  | IDataTextRowRightLabelPart
  | IEmbeddedMapPart
  | IFiveColumnPart
  | IGapPart
  | IHeaderPart
  | IHtmlPart
  | IImageCarouselPart
  | IImageInfoBannerPart
  | IImageInfoPart
  | IImagePart
  | IImageUploadInputPart
  | ILinePart
  | IMapSnapshotPart
  | IMicCheckPart
  | IPhoneInputPart
  | IPromoCardPart
  | IRatingInputPart
  | ISearchBarPart
  | ISeparatorPart
  | IShadowEdgePart
  | IShapePart
  | ISpacePart
  | ITabsPart
  | ITextAreaInputPart
  | ITextInputPart
  | ITitlePart
  | IToggleAttributedLabelPart
  | IVideoPart
  | IWaitTimeHeaderPart;

const partComponents: Record<IPart['type'], (data: any) => JSX.Element> = {
  actionable_image_attributed_label: ActionableImageAttributedLabelPart,
  arc_score_bar: ArcScoreBarPart,
  attributed_button: AttributedButton,
  attributed_label: AttributedLabelPart,
  billboard: BillboardPart,
  binary_buttons: BinaryButtonsPart,
  blocks: BlocksPart,
  box_carousel: BoxCarouselPart,
  box: BoxPart,
  button: ButtonPart,
  cam_check: CamCheckPart,
  card: CardPart,
  chat_bubble: ChatBubblePart,
  data_navigation_row_left_label: DataNavigationRowLeftLabelPart,
  data_navigation_row_right_label: DataNavigationRowRightLabelPart,
  data_text_row_box: DataTextRowBoxPart,
  data_text_row_left_label: DataTextRowLeftLabelPart,
  data_text_row_right_label: DataTextRowRightLabelPart,
  embedded_map: EmbeddedMapPart,
  five_column: FiveColumnPart,
  gap: GapPart,
  header: HeaderPart,
  html: HtmlPart,
  image_carousel: ImageCarouselPart,
  image_info_banner: ImageInfoBannerPart,
  image_info: ImageInfoPart,
  image: ImagePart,
  image_upload_input: ImageUploadInputPart,
  line: LinePart,
  map_snapshot: MapSnapshotPart,
  mic_check: MicCheckPart,
  phone_input: PhoneInputPart,
  promo_card: PromoCardPart,
  rating_input: RatingInputPart,
  search_bar: SearchBarPart,
  separator: SeparatorPart,
  shadow_edge: ShadowEdgePart,
  shape: ShapePart,
  space: SpacePart,
  tabs: TabsPart,
  text_area_input: TextAreaInputPart,
  text_input: TextInputPart,
  title: TitlePart,
  toggle_attributed_label: ToggleAttributedLabelPart,
  video: VideoPart,
  wait_time_header: WaitTimeHeaderPart,
};

const Part = ({ data, hidden }: { data: IPart; hidden?: boolean }) => {
  const { resolveColor } = useTheme();
  const screenContext = useContext(ScreenContext);
  const PartComponent = partComponents[data.type];

  return (
    <Fragment>
      {PartComponent && (
        <div
          id={`screen-part-${data.id}`}
          css={{
            display:
              hidden || screenContext.hiddenIds.includes(data.id)
                ? 'none'
                : 'block',
            backgroundColor: resolveColor(data.bgColor),
          }}
        >
          <PartComponent {...data} />
        </div>
      )}
    </Fragment>
  );
};

export default Part;
