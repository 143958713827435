/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';

import { IBasePart } from '../part';
import AttributedText from '../../../components/attributedText';
import Clickable from '../../../components/clickable';
import { IAction } from '../../../hooks/useAction';

export interface IDataTextRowRightLabelPart extends IBasePart {
  type: 'data_text_row_right_label';

  topMargin: number;
  bottomMargin: number;
  leadingMargin: number;
  trailingMargin: number;
  minimumHeight: number;

  attributedText: string;
  rightAttributedText: string;

  imageName: string;
  imageUrl: string;
  imageHeight: number;

  action: IAction;
}

const DataTextRowRightLabelPart = (data: IDataTextRowRightLabelPart) => {
  const WRAPPER_STYLE: CSSObject = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: data.leadingMargin || 20,
    paddingRight: data.trailingMargin || 20,
    paddingTop: data.topMargin,
    paddingBottom: data.bottomMargin,
    boxSizing: 'border-box',
    minHeight: data.minimumHeight || 60,
  };

  const IMAGE_STYLE: CSSObject = {
    width: 'auto',
    height: data.imageHeight,
    marginRight: 8,
    borderRadius: '50%',
  };

  return (
    <Clickable action={data.action} css={WRAPPER_STYLE}>
      <div css={{ width: '50%' }}>
        {data.imageUrl && <img css={IMAGE_STYLE} src={data.imageUrl} alt='' />}
        <AttributedText text={data.attributedText} />
      </div>
      <AttributedText css={{ width: '50%' }} text={data.rightAttributedText} />
    </Clickable>
  );
};

export default DataTextRowRightLabelPart;
