/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { createContext, ReactNode, useCallback, useState } from 'react';

export interface IViewContextState {
  headerElement: HTMLElement | null;
  mainElement: HTMLElement | null;
  footerElement: HTMLElement | null;
}

interface IViewContext extends IViewContextState {
  setHeaderElement: (element: HTMLElement) => void;
  setMainElement: (element: HTMLElement) => void;
  setFooterElement: (element: HTMLElement) => void;
}

export const ViewContext = createContext({} as IViewContext);

const initialState: IViewContextState = {
  headerElement: null,
  mainElement: null,
  footerElement: null,
};

interface IViewContextProvider {
  children: ReactNode;
}

export const ViewContextProvider = ({ children }: IViewContextProvider) => {
  const [elements, setElements] = useState<IViewContextState>(initialState);

  const setHeaderElement = useCallback((element: HTMLElement) => {
    setElements((prevElements) => {
      return {
        ...prevElements,
        headerElement: element,
      };
    });
  }, []);

  const setMainElement = useCallback((element: HTMLElement) => {
    setElements((prevElements) => {
      return {
        ...prevElements,
        mainElement: element,
      };
    });
  }, []);

  const setFooterElement = useCallback((element: HTMLElement) => {
    setElements((prevElements) => {
      return {
        ...prevElements,
        footerElement: element,
      };
    });
  }, []);

  return (
    <ViewContext.Provider
      value={{
        ...elements,

        setHeaderElement,
        setMainElement,
        setFooterElement,
      }}
    >
      {children}
    </ViewContext.Provider>
  );
};
