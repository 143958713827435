import { IBaseAction } from '../useAction';
import useInternalUrl from '../useInternalUrl';

export interface IUrlAction extends IBaseAction {
  type: 'url';

  data: {
    value: string;
  };
}

const useUrlAction = () => {
  const handleInternalUrl = useInternalUrl();

  const handleUrlAction = (action: IUrlAction) => {
    handleInternalUrl(action.data.value);
  };

  return handleUrlAction;
};

export default useUrlAction;
