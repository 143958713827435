/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';

import AttributedText from '../../../components/attributedText';
import { IBasePart } from '../part';
import useTheme from '../../../hooks/useTheme';
import { IAction } from '../../../hooks/useAction';

import Clickable from '../../../components/clickable';

export interface IChatBubblePart extends IBasePart {
  type: 'chat_bubble';

  bubbleColor: 'chat_bubble_incoming' | 'chat_bubble_outgoing';
  bubbleMaxWidth: number;
  bubblePadding: {
    top: number;
    bottom: number;
    leading: number;
    trailing: number;
  };
  cornerRadius: number;
  horizontalAlignment: 'leading' | 'trailing';
  margins: {
    top: number;
    bottom: number;
    leading: number;
    trailing: number;
  };
  text: string;

  tapAction: IAction;
}

const ChatBubblePart = (data: IChatBubblePart) => {
  const { color } = useTheme();
  const STYLE: CSSObject = {
    backgroundColor:
      data.bubbleColor === 'chat_bubble_incoming'
        ? color.CHAT_BUBBLE_INCOMING
        : color.CHAT_BUBBLE_OUTGOING,
    borderRadius: data.cornerRadius,
    display: 'inline-block',
    float: data.horizontalAlignment === 'leading' ? 'left' : 'right',
    marginLeft: data.margins.leading,
    marginRight: data.margins.trailing,
    marginTop: data.margins.top,
    marginBottom: data.margins.bottom,
    maxWidth: data.bubbleMaxWidth,
    paddingLeft: data.bubblePadding.leading,
    paddingRight: data.bubblePadding.trailing,
    paddingTop: data.bubblePadding.top,
    paddingBottom: data.bubblePadding.bottom,
  };
  return (
    <Clickable action={data.tapAction}>
      <div css={STYLE}>
        <AttributedText text={data.text} />
      </div>
    </Clickable>
  );
};

export default ChatBubblePart;
