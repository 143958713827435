/** @jsx jsx */
import React, { ReactNode, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { jsx, CSSObject, keyframes } from '@emotion/core';
import layout from '../../../../../constants/layout';
import FullHeightDiv from '../../../../../components/fullHeightDiv';
import useMediaDevicePermission from '../../../../../hooks/useMediaDevicePermission';

const fadeInContentAnimation = keyframes`
0% {transform: translateY(30px)}
100% {transform: translateY(0)}
`;

const MODAL_CONTENT_STYLE: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: '0 auto',
  boxSizing: 'border-box',
  padding: 0,
  maxWidth: 'unset',
  animation: `${fadeInContentAnimation} 0.2s`,

  [`@media (min-width: ${layout.BREAKPOINT_MEDIUM}px)`]: {
    padding: layout.GUTTER_SIZE,
    maxWidth: 'unset',
    borderRadius: 7,
  },

  '> div': {
    pointerEvents: 'auto',
  },
};

interface IVideoMeetingContainer {
  children: ReactNode;
}

const VideoMeetingContainer = ({ children }: IVideoMeetingContainer) => {
  const [permissionGranted, setPermissionGranted] = useState(false);
  const { getMediaDevicePermission } = useMediaDevicePermission();

  useEffect(() => {
    getMediaDevicePermission({ audio: true, video: true }).then(() =>
      setPermissionGranted(true)
    );
  }, [getMediaDevicePermission]);

  return (
    <Modal
      className='modal-content'
      style={{
        overlay: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.9)',
          animationDuration: '0.15s',
          zIndex: layout.VIDEO_MEETING_ZINDEX,
        },
        content: {
          pointerEvents: 'none',
        },
      }}
      isOpen
    >
      <FullHeightDiv css={MODAL_CONTENT_STYLE}>
        {permissionGranted && children}
      </FullHeightDiv>
    </Modal>
  );
};

export default VideoMeetingContainer;
