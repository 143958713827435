import layout from '../constants/layout';

const getViewportElement = () => {
  return window.innerWidth < layout.BREAKPOINT_MEDIUM
    ? window.document.scrollingElement
    : document.getElementsByTagName('main')[0];
};

export const scrollElementToViewportTop = (
  element: HTMLElement,
  focus?: boolean
) => {
  const viewportElement = getViewportElement();

  if (viewportElement) {
    setTimeout(() => {
      viewportElement.scroll({
        left: 0,
        top: 0,
        behavior: 'smooth',
      });
    }, 0);

    if (focus) {
      element.focus();
    }
  }
};

export const scrollToViewportBottom = () => {
  const viewportElement = getViewportElement();
  if (viewportElement) {
    setTimeout(() => {
      viewportElement.scroll({
        left: 0,
        top: viewportElement.scrollHeight,
        behavior: 'smooth',
      });
    }, 0);
  }
};
