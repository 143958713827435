import { useContext } from 'react';
import { IBaseAction } from '../useAction';
import useOverlay from '../useOverlay';
import { IStructuredSnowplowEvent } from '../useSnowplowTracker';
import { logError } from '../../utils/remoteLogger';
import { ScreenContext } from '../../contexts/screenContext';

export interface IVideoTracking {
  videoCompletedTracker?: IStructuredSnowplowEvent;
  videoStartedTracker?: IStructuredSnowplowEvent;
  videoStoppedTracker?: IStructuredSnowplowEvent;
}

export interface IVideoSource {
  url: string;
  mimeType: string;
}

export interface IVideoAction extends IBaseAction {
  type: 'video';

  data: {
    type: 'youtube' | 'file_url' | unknown;
    url: string;
    sources?: IVideoSource[];
    videoId: string;
    aspectRatio: number;
    snowplowTrackers?: IVideoTracking;
  };
}

const useVideoAction = () => {
  const overlay = useOverlay();
  const { reloadScreen } = useContext(ScreenContext);

  const handleVideoAction = (action: IVideoAction) => {
    switch (action.data.type) {
      case 'youtube':
        window.open(action.data.url);
        break;
      case 'file_url':
        overlay.presentVideo({
          sources: action.data.sources,
          snowplowTrackers: action.data.snowplowTrackers,
          // Reload current screen after overlay is closed
          // to align with mobile clients
          onDismiss: reloadScreen,
        });
        break;
      default:
        logError(
          'useVideoAction',
          `Unsupported video action: ${action.data.type}`
        );
    }
  };

  return handleVideoAction;
};

export default useVideoAction;
