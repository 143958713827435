/** @jsx jsx */
import React, { ReactNode, useContext, useEffect, useState } from 'react';

import { jsx, CSSObject } from '@emotion/core';
import { usePromiseTracker } from 'react-promise-tracker';
import layout from '../constants/layout';
import Loader from './loader';
import AppMenu from './appMenu';
import { AppConfigContext } from '../contexts/appConfigContext';
import { AppContext } from '../contexts/appContext';
import useNavigation from '../hooks/useNavigation';
import UnsupportedBrowser from './unsupportedBrowser';
import device from '../utils/device';
import environment from '../utils/environment';
import PartnerHeader from './partnerHeader';
import FullHeightDiv from './fullHeightDiv';
import useViewport from '../hooks/useViewport';
import currentPartner from '../constants/partners';
import useTheme from '../hooks/useTheme';
import SmartBanner from './smartBanner';
import { MeetingContext } from '../contexts/meetingContext';

interface IAppFrame {
  children: ReactNode;
}

const AppFrame = ({ children }: IAppFrame) => {
  const [componentHasRendered, setComponentHasRendered] = useState(false);

  const { flexStartCall } = useContext(AppConfigContext);
  const { userLoggedIn } = useContext(AppContext);

  const { promiseInProgress } = usePromiseTracker();
  const { color } = useTheme();
  const viewport = useViewport();
  const navigation = useNavigation();
  const currentView = navigation.getCurrentView();
  const { upcomingMeeting } = useContext(MeetingContext);

  const isPartnerBranded = !!currentPartner;

  const desktopMenuVisible =
    viewport === 'desktop' &&
    userLoggedIn &&
    !flexStartCall &&
    !currentView?.hideAppMenu;

  const mobileMenuVisible =
    viewport !== 'desktop' &&
    userLoggedIn &&
    !flexStartCall &&
    !currentView?.hideAppMenu &&
    !currentView?.isModalRoot &&
    !currentView?.isModalDescendant;

  const OUTER_WRAPPER_STYLE: CSSObject = {
    padding: isPartnerBranded
      ? `${40 + layout.PARTNER_HEADER_HEIGHT}px 0 40px`
      : '40px 0',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    [`@media (max-width: ${layout.BREAKPOINT_MEDIUM}px)`]: {
      padding: isPartnerBranded ? `${layout.PARTNER_HEADER_HEIGHT}px 0 0` : 0,
    },
  };

  const INNER_WRAPPER_STYLE: CSSObject = {
    display: 'flex',
    flex: 1,
    position: 'relative',

    [`@media (min-width: ${layout.BREAKPOINT_MEDIUM}px)`]: {
      flex: currentView?.autoHeight ? 'unset' : 1,
      maxHeight: currentView?.constrainHeight
        ? currentView?.constrainHeight
        : 'unset',

      margin: '0 auto',
      overflow: 'hidden',
      borderRadius: 12,
      boxShadow: layout.DEFAULT_SHADOW_MEDIUM,
    },
  };

  const CONTENT_PORTAL_WRAPPER_STYLE: CSSObject = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    maxWidth: '100%',
    backgroundColor: color.LOCAL_WHITE,

    [`@media (min-width: ${layout.BREAKPOINT_MEDIUM}px)`]: {
      width: layout.APP_MAX_WIDTH,
      maxWidth: layout.APP_MAX_WIDTH,
      boxShadow: layout.DEFAULT_SHADOW_MEDIUM,
    },
  };
  // Delay rendering the children until after the frame has rendered,
  // as content is using the header and footer elements.
  useEffect(() => setComponentHasRendered(true), []);
  return (
    <FullHeightDiv canExceedOnMobile css={OUTER_WRAPPER_STYLE}>
      {/* Partner header */}
      {isPartnerBranded && <PartnerHeader />}

      <div css={INNER_WRAPPER_STYLE}>
        {/* Desktop app menu */}
        {desktopMenuVisible && <AppMenu />}

        {/* Main view wrapper */}
        <div css={CONTENT_PORTAL_WRAPPER_STYLE}>
          {/* Main view */}

          {/* Smart Banner */}
          {userLoggedIn && upcomingMeeting?.needsVideoAudioPermission && (
            <SmartBanner />
          )}
          {componentHasRendered && children}
          {/* Mobile app menu */}
          {currentView && mobileMenuVisible && <AppMenu />}
          {/* Show a warning on browsers that do not support video meetings.
            Disabled for dev since Safari on iOS disables WebRTC if the app isn't served over https. */}
          {!environment.IS_DEV && !device.IS_WEB_RTC_CAPABLE && (
            <UnsupportedBrowser />
          )}
          {/* Global loading indicator. */}
          <Loader
            css={{
              position: 'fixed',
              zIndex: layout.GLOBAL_LOADING_INDICATOR_AND_TOGGLE_ZINDEX,
              [`@media (min-width: ${layout.BREAKPOINT_MEDIUM}px)`]: {
                position: 'absolute',
              },
            }}
            show={promiseInProgress}
          />
        </div>
      </div>
    </FullHeightDiv>
  );
};

export default AppFrame;
