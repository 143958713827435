import { useContext } from 'react';
import { decamelizeKeys } from 'humps';
import { IBaseAction } from '../useAction';
import request from '../../utils/request';
import useOverlay from '../useOverlay';
import useLanguage from '../useLanguage';
import { FlexContext } from '../../contexts/flexContext';
import useFlexNavigation from '../flex/useFlexNavigation';

export interface IPaymentRelayAction extends IBaseAction {
  type: 'payment_relay';

  data: {
    saveUrl: string;
    payload: {
      meetingId: string;
      priceCategory: string;
      paymentType: string;

      voucherCode?: string;
      voucherAmount?: string;

      freeCard: object;
    };
  };
}

const usePaymentRelayAction = () => {
  const flexContext = useContext(FlexContext);
  const overlay = useOverlay();
  const language = useLanguage();

  const handleFlexNavigation = useFlexNavigation();

  const handlePaymentRelayAction = (action: IPaymentRelayAction) => {
    const { payload } = action.data;

    request<{ transactionId: string }>(action.data.saveUrl, {
      method: 'POST',
      body: {
        meeting_id: payload.meetingId,
        voucher_code: payload.voucherCode,
        voucher_amount: payload.voucherAmount,

        price_category: payload.priceCategory,
        paymentType: payload.paymentType,

        free_card: payload.freeCard
          ? decamelizeKeys(payload.freeCard)
          : undefined,
      },
    })
      .then(() => {
        if (flexContext.node?.type === 'payment') {
          handleFlexNavigation(flexContext.node.completionNavigation);
        }
      })
      .catch(() => {
        overlay.presentBasicAlert({
          title: language.get('transaction_aborted'),
          message: language.get('transaction_error'),
        });
      });
  };

  return handlePaymentRelayAction;
};

export default usePaymentRelayAction;
