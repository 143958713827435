/** @jsx jsx */
import React, { useRef } from 'react';
import { jsx, CSSObject } from '@emotion/core';
import useOverlay from '../hooks/useOverlay';
import useLanguage from '../hooks/useLanguage';
import IFrame from '../components/iframe';
import Clickable from '../components/clickable';
import { IBaseOverlay } from '../contexts/overlayContext';
import useTheme from '../hooks/useTheme';
import useInternalUrl from '../hooks/useInternalUrl';

export interface IIFrameOverlay extends IBaseOverlay {
  urlSource?: string;
  HTMLSource?: string;

  closeButtonLabel?: string;
}

const IFrameOverlay = ({
  urlSource,
  HTMLSource,
  closeButtonLabel,
}: IIFrameOverlay) => {
  const { color } = useTheme();
  const language = useLanguage();
  const overlay = useOverlay();
  const handleInternalUrl = useInternalUrl();

  const initialHistoryLengthRef = useRef(window.history.length);

  const STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    backgroundColor: color.LOCAL_WHITE,
    padding: '20px 0',
    borderRadius: 7,
    height: '70vh',
  };

  const CLOSE_BUTTON_STYLE: CSSObject = {
    backgroundColor: 'transparent',
    color: color.LOCAL_WHITE,
    padding: '10px 20px',
    marginTop: 8,
  };

  const handleDismissOverlay = () => {
    const iframeHistoryLength =
      window.history.length - initialHistoryLengthRef.current;

    if (iframeHistoryLength > 0) {
      window.history.go(iframeHistoryLength * -1);
      setTimeout(() => {
        overlay.dismiss();
      }, 300);
    } else {
      overlay.dismiss();
    }
  };

  return (
    <div css={{ textAlign: 'center' }}>
      <div css={STYLE}>
        <IFrame
          id='iframe_overlay'
          title='iframe overlay'
          urlSource={urlSource}
          HTMLSource={HTMLSource}
          css={{ height: '100%' }}
          onLinkClick={handleInternalUrl}
        />
      </div>
      <Clickable scale css={CLOSE_BUTTON_STYLE} onClick={handleDismissOverlay}>
        {closeButtonLabel || language.get('close')}
      </Clickable>
    </div>
  );
};

export default IFrameOverlay;
