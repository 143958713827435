/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';

import AttributedText from '../../../../../components/attributedText';
import useTheme from '../../../../../hooks/useTheme';

export interface IFiveColumnTagCell {
  type: 'tag';
  text: string;
  color: string;

  padding: {
    top: number;
    bottom: number;
    leading: number;
    trailing: number;
  };
}

interface IFiveColumnTagCellProps {
  data: IFiveColumnTagCell;
}

const FiveColumnTagCell = ({ data }: IFiveColumnTagCellProps) => {
  const { resolveColor } = useTheme();

  const STYLE: CSSObject = {
    backgroundColor: resolveColor(data.color),
    paddingTop: data.padding.top,
    paddingBottom: data.padding.bottom,
    paddingLeft: data.padding.leading,
    paddingRight: data.padding.trailing,
    borderRadius: 14,
  };

  return (
    <div css={STYLE}>
      <AttributedText text={data.text} />
    </div>
  );
};

export default FiveColumnTagCell;
