/** @jsx jsx */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { jsx, CSSObject } from '@emotion/core';

import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { Document, Page, pdfjs } from 'react-pdf';

import { IBaseOverlay } from '../contexts/overlayContext';
import layout from '../constants/layout';
import useOverlay from '../hooks/useOverlay';
import useLanguage from '../hooks/useLanguage';
import useTheme from '../hooks/useTheme';
import Clickable from '../components/clickable';
import LocalImage from '../components/localImage';

export interface IPdfPreviewOverlay extends IBaseOverlay {
  file: Blob;
  title: string;
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const MAX_ZOOM = 6;
const PAGE_MARGIN = 20;

const PdfPreviewOverlay = ({ file, title }: IPdfPreviewOverlay) => {
  const [container, setContainer] = useState<HTMLDivElement>();
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const [documentWidth, setDocumentWidth] = useState<number>(0);
  const [documentHeight, setDocumentHeight] = useState<number>(0);

  const [pageCount, setPageCount] = useState(0);

  const widestPageWidthRef = useRef(0);
  const totalPageHeightRef = useRef(0);

  const overlay = useOverlay();
  const language = useLanguage();
  const { color } = useTheme();

  const documentScale = useMemo(() => {
    if (
      !containerWidth ||
      !containerHeight ||
      !documentWidth ||
      !documentHeight
    ) {
      return 0;
    }

    return Math.min(
      containerWidth / documentWidth,
      containerHeight / documentHeight
    );
  }, [containerWidth, containerHeight, documentWidth, documentHeight]);

  const handleOnDownloadClick = () => {
    const link = document.createElement('a');

    link.href = window.URL.createObjectURL(file);
    link.download = `${title}.pdf`;
    link.click();

    // For Firefox it is necessary to delay revoking the ObjectURL.
    setTimeout(function () {
      window.URL.revokeObjectURL(window.URL.createObjectURL(file));
    }, 100);
  };

  useEffect(() => {
    const handleResize = () => {
      if (container) {
        const rect = container.getBoundingClientRect();
        setContainerWidth(rect.width);
        setContainerHeight(rect.height);
      } else {
        setContainerWidth(0);
        setContainerHeight(0);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [container]);

  const BASE_STYLE: CSSObject = {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    backgroundColor: '#000',
    overflow: 'hidden',

    [`@media (min-width: ${layout.BREAKPOINT_MEDIUM}px)`]: {
      borderRadius: 7,
    },

    '.react-transform-wrapper': {
      cursor: 'grab',
    },

    canvas: {
      margin: PAGE_MARGIN,
      borderRadius: 7,
    },
  };

  const BUTTON_STYLE: CSSObject = {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 44,
    height: 44,
    backgroundColor: color.ACTION_FLOATING_SOFT,
    borderRadius: '100%',
  };

  const CLOSE_BUTTON_STYLE: CSSObject = {
    ...BUTTON_STYLE,

    top: 20,
    right: 20,

    img: {
      width: 14,
      height: 14,
    },
  };

  const DOWNLOAD_BUTTON_STYLE: CSSObject = {
    ...BUTTON_STYLE,

    top: 20,
    right: 74,

    img: {
      width: 24,
      height: 24,
    },
  };

  return (
    <div
      css={BASE_STYLE}
      ref={(element) => {
        if (element) {
          setContainer(element);
        }
      }}
    >
      <TransformWrapper
        key={`${documentScale}`}
        initialScale={documentScale}
        minScale={documentScale}
        maxScale={documentScale * MAX_ZOOM}
        centerOnInit
      >
        <TransformComponent
          wrapperStyle={{
            width: '100%',
            height: '100%',
          }}
        >
          <div
            css={{
              width: documentWidth,
              height: documentHeight,
            }}
          >
            <Document
              file={file}
              onLoadSuccess={(doc) => {
                setPageCount(doc.numPages);
              }}
            >
              {pageCount &&
                [...Array(pageCount)].map((e, i) => {
                  return (
                    <Page
                      key={i}
                      pageNumber={i + 1}
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                      scale={Math.max(1, 3 - window.devicePixelRatio)}
                      onRenderSuccess={(page) => {
                        // Let the widest page of the document dictates the total width.
                        widestPageWidthRef.current = Math.max(
                          widestPageWidthRef.current,
                          page.width
                        );

                        // Accumulate the height of all the pages.
                        totalPageHeightRef.current =
                          i === 0
                            ? page.height
                            : totalPageHeightRef.current + page.height;

                        // Calculate the total margins.
                        const horizontalMargin = PAGE_MARGIN * 2;
                        const verticalMargin = (pageCount + 1) * PAGE_MARGIN;

                        // Once the final page renders, update document dimension states.
                        if (i + 1 === pageCount) {
                          setDocumentWidth(
                            widestPageWidthRef.current + horizontalMargin
                          );
                          setDocumentHeight(
                            totalPageHeightRef.current + verticalMargin
                          );
                        }
                      }}
                    />
                  );
                })}
            </Document>
          </div>
        </TransformComponent>
      </TransformWrapper>

      {/* Download button */}
      <Clickable
        scale
        onClick={handleOnDownloadClick}
        css={DOWNLOAD_BUTTON_STYLE}
      >
        <LocalImage src='ICON32_DOWNLOAD' tint='ICON_INVERSE' />
      </Clickable>

      {/* Close button */}
      <Clickable
        scale
        onClick={() => {
          overlay.dismiss();
        }}
        css={CLOSE_BUTTON_STYLE}
      >
        <LocalImage
          src='APP_IMAGE_REMOVE_PHOTO'
          tint='ICON_INVERSE'
          alt={language.get('close')}
        />
      </Clickable>
    </div>
  );
};

export default PdfPreviewOverlay;
