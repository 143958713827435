/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';

import { IBasePart } from '../part';
import AttributedText from '../../../components/attributedText';
import useTheme from '../../../hooks/useTheme';
import useLanguage from '../../../hooks/useLanguage';
import plainText from '../../../utils/plainText';

export interface IArcScoreBarPart extends IBasePart {
  type: 'arc_score_bar';

  minScore: number;
  maxScore: number;
  score: number;

  attributedTextScore: string;
  attributedTextMinScore: string;
  attributedTextMaxScore: string;
  attributedTextScoreCopy: string;

  secondaryColor: string;
  progressColor: string;

  height: number;

  leadingMargin: number;
  trailingMargin: number;

  strokeWidth: number;
}

const ArcScoreBarPart = (data: IArcScoreBarPart) => {
  const { resolveColor } = useTheme();
  const language = useLanguage();
  const size = data.height;
  const radius = size / 2 - data.strokeWidth / 2;
  const arc = 0.75;
  const score = data.score / (data.maxScore - data.minScore);

  const SVG_STYLE: CSSObject = {
    display: 'block',
    margin: `0 auto ${Math.ceil(radius * (1 - arc)) * -1 + 8}px`,
    strokeLinecap: 'round',
    transform: 'rotate(-225deg)',
  };

  const getAriaLabel = () => {
    const scoreRound = Math.round(data.score);
    const scoreCopy = plainText(data.attributedTextScoreCopy);
    const scoreA11yCopy = language.get('accessibility_label_meter');
    return `${scoreRound} ${scoreCopy} ${scoreA11yCopy} ${data.maxScore}`;
  };

  return (
    <div
      role='img'
      aria-label={getAriaLabel()}
      css={{
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        css={SVG_STYLE}
      >
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill='none'
          stroke={resolveColor(data.secondaryColor)}
          strokeWidth={data.strokeWidth}
          strokeDasharray={2 * Math.PI * radius}
          strokeDashoffset={2 * Math.PI * radius * (1 - arc)}
        />
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill='none'
          stroke={resolveColor(data.progressColor)}
          strokeWidth={data.strokeWidth}
          strokeDasharray={2 * Math.PI * radius}
          strokeDashoffset={2 * Math.PI * radius * (1 - arc * score)}
        />
      </svg>

      {/* Text inside the arc */}
      <div
        css={{
          position: 'absolute',
          top: '50%',
          width: '100%',
          transform: 'translateY(-50%)',
        }}
      >
        <AttributedText text={data.attributedTextScore} />
        <AttributedText text={data.attributedTextScoreCopy} />
      </div>

      {/* Min/max indicator texts */}
      <AttributedText
        css={{
          position: 'absolute',
          bottom: 0,
          left: '50%',
          transform: 'translateX(-100%)',
          marginLeft: ((size * arc) / 2) * -1 - 8,
        }}
        text={data.attributedTextMinScore}
      />
      <AttributedText
        css={{
          position: 'absolute',
          bottom: 0,
          left: '50%',
          marginLeft: (size * arc) / 2 + 8,
        }}
        text={data.attributedTextMaxScore}
      />
    </div>
  );
};

export default ArcScoreBarPart;
