/** @jsx jsx */
import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { CSSObject, jsx } from '@emotion/core';

import { Document, Page, pdfjs } from 'react-pdf';

import { IBlockBaseItem } from '../blockItem';
import useAction, { IAction } from '../../../../../hooks/useAction';
import Clickable from '../../../../../components/clickable';
import request from '../../../../../utils/request';
import { logWarning } from '../../../../../utils/remoteLogger';
import useTheme from '../../../../../hooks/useTheme';

export interface IBlockPdfItem extends IBlockBaseItem {
  type: 'pdf';

  relativeUrl: string;
  title: string;

  action?: IAction;
  onLoadAction?: IAction;
  onLoadFailedAction?: IAction;
}

interface IBlockPdfItemProps {
  data: IBlockPdfItem;
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const BlockPdfItem = ({ data, ...props }: IBlockPdfItemProps) => {
  const [document, setDocument] = useState<Blob>();
  const [pageCount, setPageCount] = useState(0);
  const [lastPageRendered, setLastPageRendered] = useState(false);

  const [containerWidth, setContainerWidth] = useState(0);

  const { color } = useTheme();
  const handleAction = useAction();

  const dataRef = useRef(data);
  const containerRef = createRef<HTMLDivElement>();

  // react-pdf doesn't export the PDFDocumentProxy type.
  const handleOnLoadSuccess = useCallback(
    (doc: any) => {
      setPageCount(doc.numPages);
      handleAction(dataRef.current.onLoadAction);
    },
    [handleAction]
  );

  const handleOnLoadError = useCallback(
    (error: Error) => {
      handleAction(dataRef.current.onLoadFailedAction);
      logWarning(
        'blockPdfItem',
        `Failed to load document ${dataRef.current.relativeUrl}`,
        error
      );
    },
    [handleAction]
  );

  // Download the pdf document.
  useEffect(() => {
    if (document) {
      return;
    }

    request<BlobPart>(dataRef.current.relativeUrl, {
      method: 'GET',
      assumeBlob: true,
    })
      .then((blob) => {
        setDocument(
          new Blob([blob], {
            type: 'application/octet-stream',
          })
        );
      })
      .catch(handleOnLoadError);
  }, [handleOnLoadError, document]);

  // react-pdf doesn't support percentage widths.
  // At the initial render and screen resize, measure the width of the container and update the state,
  // re-rendering the pdf document to fill the container width.
  useEffect(() => {
    const updateContainerWidth = () => {
      setContainerWidth(containerRef.current?.clientWidth || 0);
    };

    updateContainerWidth();
    window.addEventListener('resize', updateContainerWidth);

    return () => {
      window.removeEventListener('resize', updateContainerWidth);
    };
  }, [containerRef]);

  const BASE_STYLE: CSSObject = {
    '.react-pdf__Document': {
      visibility: lastPageRendered ? 'visible' : 'hidden',
    },

    '.react-pdf__Page:not(.last-page)': {
      borderBottom: `1px solid ${color.DIVIDER_LINE}`,
    },
  };

  return (
    <Clickable dim css={BASE_STYLE} action={dataRef.current.action} {...props}>
      <div ref={containerRef}>
        {!!containerWidth && !!document && (
          <Document
            file={document}
            onLoadSuccess={handleOnLoadSuccess}
            onLoadError={handleOnLoadError}
          >
            {pageCount &&
              [...Array(pageCount)].map((e, i) => {
                return (
                  <Page
                    key={i}
                    pageNumber={i + 1}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    width={containerWidth}
                    className={i + 1 === pageCount ? 'last-page' : undefined}
                    onRenderSuccess={() => {
                      // Once the final page has successfully rendered, update the state.
                      // The document is kept hidden until then to avoid black flashing.
                      if (i + 1 === pageCount) {
                        setLastPageRendered(true);
                      }
                    }}
                  />
                );
              })}
          </Document>
        )}
      </div>
    </Clickable>
  );
};

export default BlockPdfItem;
