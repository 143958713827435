/** @jsx jsx */
import React, { useContext, Fragment } from 'react';

import { CSSObject, jsx } from '@emotion/core';

import Clickable from '../../../components/clickable';
import useTheme from '../../../hooks/useTheme';
import LoginViewTopSection from '../shared/loginViewTopSection';
import { SwedishBankIdLoginContext } from '../../../contexts/swedishBankIdLoginContext';
import { AppConfigContext } from '../../../contexts/appConfigContext';
import RemoteImage from '../../../components/RemoteImage';
import AttributedText from '../../../components/attributedText';
import device from '../../../utils/device';
import useLanguage from '../../../hooks/useLanguage';
import parseUri from '../../../utils/parseUri';

const SwedishBankIdLogin = () => {
  const { login, demoAccountLogin } = useContext(SwedishBankIdLoginContext);
  const { loginViewContent } = useContext(AppConfigContext);

  const language = useLanguage();
  const { color } = useTheme();

  const shouldUseDemoAccount = parseUri(window.location.href)
    .query.get('redirect')
    ?.includes('demo');

  const SECONDARY_BUTTON_STYLE: CSSObject = {
    color: color.PRIMARY,
    backgroundColor: color.LOCAL_WHITE,

    '&[active]': {
      backgroundColor: color.LIST_PRESS,
    },
  };

  return (
    <Fragment>
      {/* The shared view top section, containing contextual content, such as the header image,
          language picker, market-appropriate logo and the main header/subheader. */}
      <LoginViewTopSection />

      {/* The rest of the view - the login method itself, CTA and disclaimer. */}
      <div css={{ padding: '0 20px 20px' }}>
        {/* The normal login buttons - not shown if the login screen is in demo account mode. */}
        {!shouldUseDemoAccount && (
          <span>
            {/* Primary login button */}
            <Clickable
              styleAs='button'
              onClick={() => {
                login(device.IS_MOBILE);
              }}
            >
              {/* Button image */}
              {loginViewContent.buttonImage && (
                <RemoteImage
                  {...loginViewContent.buttonImage}
                  css={{ height: 24, marginRight: 14 }}
                />
              )}

              {/* Button text */}
              {loginViewContent.buttonTitle}
            </Clickable>

            {/* Use BankId on another device - only shown on mobile */}
            {device.IS_MOBILE && (
              <Clickable
                styleAs='button'
                css={SECONDARY_BUTTON_STYLE}
                onClick={() => {
                  login(false);
                }}
              >
                {language.get(
                  'patient_web.bankid_login.mobile_bankid_on_another_device'
                )}
              </Clickable>
            )}

            {/* Use BankId on this device - only shown on desktop */}
            {!device.IS_MOBILE && (
              <Clickable
                styleAs='button'
                css={SECONDARY_BUTTON_STYLE}
                onClick={() => {
                  login(true);
                }}
              >
                {language.get(
                  'patient_web.bankid_login.bankid_on_this_desktop_device'
                )}
              </Clickable>
            )}
          </span>
        )}

        {/* Demo account login button */}
        {shouldUseDemoAccount && (
          <Clickable
            styleAs='button'
            onClick={() => {
              demoAccountLogin();
            }}
          >
            Login with a demo account
          </Clickable>
        )}

        {/* Disclaimer text */}
        {loginViewContent.disclaimerText && (
          <AttributedText
            text={loginViewContent.disclaimerText}
            css={{
              marginTop: 15,
            }}
          />
        )}
      </div>
    </Fragment>
  );
};

export default SwedishBankIdLogin;
