import { useContext } from 'react';
import { IBaseAction } from '../useAction';
import { AppContext } from '../../contexts/appContext';

export interface IUpdateSessionAction extends IBaseAction {
  type: 'update_session';

  data: {
    id: string;
    deviceId: string;
    relaunch: boolean;
  };
}

const useUpdateSessionAction = () => {
  const { saveSession } = useContext(AppContext);

  const handleUpdateSessionAction = (action: IUpdateSessionAction) => {
    const { id, deviceId, relaunch } = action.data;

    // Update session and optionally reinitialize app.
    saveSession(id, deviceId, relaunch);
  };

  return handleUpdateSessionAction;
};

export default useUpdateSessionAction;
