/** @jsx jsx */
import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react';
import { jsx, CSSObject } from '@emotion/core';

import { SurveyContext } from '../../../../../../contexts/surveyContext';
import { ISurveyNode } from '../surveyNode';
import Title from '../../../../../../components/title';
import Input from '../../../../../../components/input';
import useTheme from '../../../../../../hooks/useTheme';

interface ICommonInputSurveyNode {
  node: ISurveyNode;
  isSubNode?: boolean;

  inputType: 'text' | 'number';
  textArea?: boolean;
}

const CommonInputSurveyNode = ({
  node,
  isSubNode,
  inputType,
  textArea,
}: ICommonInputSurveyNode) => {
  const { color } = useTheme();
  const nodeVisistedAt = useRef(Date.now());
  const { registerAnswer, getAnswerByPath } = useContext(SurveyContext);
  const [state, setState] = useState<string>(
    getAnswerByPath(node.path)?.text || ''
  );

  const TEXTAREA_STYLE: CSSObject = {
    fontSize: 17,
    border: `2px solid ${color.UI_IDLE}`,
    padding: '10px 16px',
    margin: '0 20px',
    ':focus': {
      border: `2px solid ${color.PRIMARY}`,
    },

    resize: 'vertical',
  };

  useEffect(() => {
    registerAnswer({
      nodeName: node.name,
      path: node.path,
      property: node.property,
      text: state,
      answeredAt: Date.now(),
      visitedAt: nodeVisistedAt.current,
    });
  }, [node, state, registerAnswer]);

  return (
    <Fragment>
      <Title
        title={node.title}
        subtitle={node.subtitle}
        subdued={isSubNode}
        css={{
          padding: '0 20px',
          marginBottom: isSubNode ? 12 : 20,
        }}
      />

      {textArea && (
        <textarea
          rows={3}
          value={state}
          css={TEXTAREA_STYLE}
          aria-label={node.subtitle || node.title}
          onChange={(e) => {
            setState(e.currentTarget.value);
          }}
        />
      )}

      {!textArea && (
        <Input
          type={inputType}
          css={{ margin: '0 20px' }}
          value={state}
          aria-label={node.subtitle || node.title}
          onChange={(e) => {
            setState(e.currentTarget.value);
          }}
        />
      )}
    </Fragment>
  );
};

export default CommonInputSurveyNode;
