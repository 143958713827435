/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { IBaseFlexNode } from '../../flexNode';
import { ScheduleContextProvider } from '../../../../contexts/scheduleContext';
import Navbar from '../../../../components/navbar';
import Schedule from './components/schedule';
import { IFlexHttpCall } from '../../../../hooks/useFlexHttpCall';

export interface IScheduleFlexNode extends IBaseFlexNode {
  type: 'schedule';
  call: IFlexHttpCall;
}

interface IScheduleFlexNodeProps {
  data: IScheduleFlexNode;
}

const ScheduleFlexNode = React.memo(({ data }: IScheduleFlexNodeProps) => {
  return (
    <ScheduleContextProvider routeSource={{ call: data.call }}>
      {/* Navbar */}
      {data.visibleNavbar && <Navbar />}

      <Schedule />
    </ScheduleContextProvider>
  );
});

export default ScheduleFlexNode;
