/** @jsx jsx */
import React, { useRef, useState } from 'react';
import { jsx, CSSObject } from '@emotion/core';
import useTheme from '../hooks/useTheme';

interface ICodeInput extends React.HTMLProps<HTMLInputElement> {
  foo?: string;
}

const CodeInput = ({
  label,
  maxLength = 4,

  onFocus,
  onBlur,
  ...props
}: ICodeInput) => {
  const selectRef = useRef<HTMLSelectElement | null>();
  const [isFocused, setIsFocused] = useState(false);
  const { color, font } = useTheme();

  const LABEL_STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12px',
    textTransform: 'uppercase',
    color: color.TEXT_SOFT,
    fontFamily: font.FAMILY_MEDIUM,
  };

  const SINGLE_INPUT_DISPLAY_STYLE: CSSObject = {
    fontSize: 17,
    padding: '10px 0',
    borderRadius: 0,
    minWidth: 30,
    maxWidth: 30,
    marginRight: 4,
    marginLeft: 4,
    minHeight: 23,
    textAlign: 'center',
    fontFamily: font.FAMILY_REGULAR,
    color: color.TEXT,
    background: color.LOCAL_WHITE,
  };

  const DISPLAY_CONTAINER_STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  };

  const INPUT_STYLE: CSSObject = {
    padding: '10px 0',
    minHeight: 23,
    color: 'transparent',
    letterSpacing: -6,
    backgroundColor: 'transparent',
    caretColor: 'transparent',
    width: `calc(${maxLength} * 38px)`,
    margin: 0,
  };

  const getValueAtIndex = (index: number) => {
    if (props.value) {
      return `${props.value}`[index] || '';
    }

    return '';
  };

  return (
    <label css={LABEL_STYLE}>
      {/* Code input display */}
      <div
        css={{ position: 'absolute', cursor: 'text' }}
        onClick={() => selectRef.current?.focus()}
      >
        <div css={DISPLAY_CONTAINER_STYLE}>
          {[...Array(maxLength)].map((_, index) => {
            return (
              <div
                key={index}
                css={{
                  ...SINGLE_INPUT_DISPLAY_STYLE,
                  borderBottom: `2px solid ${
                    isFocused && index === `${props.value}`.length
                      ? color.PRIMARY
                      : color.UI_IDLE
                  }`,
                }}
              >
                {getValueAtIndex(index)}
              </div>
            );
          })}
        </div>
      </div>

      {/* Code input behind the scenes */}
      <input
        autoFocus
        aria-label={label}
        autoComplete='off'
        min={0}
        max={Math.pow(10, maxLength) - 1}
        name='otp'
        maxLength={maxLength}
        type='tel'
        onKeyDown={(e) => {
          const inputValue = `${props.value || ''}`;
          e.currentTarget.selectionStart = inputValue.length;
          e.currentTarget.selectionEnd = inputValue.length;
        }}
        onFocus={(e) => {
          setIsFocused(true);
          if (onFocus) {
            onFocus(e);
          }
        }}
        onBlur={(e) => {
          setIsFocused(false);
          if (onBlur) {
            onBlur(e);
          }
        }}
        css={INPUT_STYLE}
        {...props}
      />
    </label>
  );
};

export default CodeInput;
