/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';

import { format as formatDate } from 'date-fns';
import { enGB, sv, fr, nb } from 'date-fns/locale';

import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import useTheme from '../../../hooks/useTheme';
import useLanguage from '../../../hooks/useLanguage';
import Clickable from '../../clickable';

type ICalendarHeader = ReactDatePickerCustomHeaderProps;

const CalendarHeader = ({
  date,
  decreaseYear,
  decreaseMonth,
  increaseYear,
  increaseMonth,
}: ICalendarHeader) => {
  const { font, color } = useTheme();
  const language = useLanguage();

  const dateToMonthAndYear = () => {
    let locale = enGB;

    switch (language.getCurrentLanguage()) {
      case 'sv':
        locale = sv;
        break;
      case 'fr':
        locale = fr;
        break;
      case 'nb':
        locale = nb;
        break;
    }

    return formatDate(date, 'MMMM yyyy', { locale });
  };

  const WRAPPER_STYLE: CSSObject = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 10px',
  };

  const BUTTON_GROUP_STYLE: CSSObject = {
    display: 'flex',
  };

  const BUTTON_STYLE: CSSObject = {
    width: 24,
    height: 36,
    color: color.PRIMARY,
    fontSize: 22,
    fontFamily: font.FAMILY_MEDIUM,
  };

  const DATE_STYLE: CSSObject = {
    marginTop: 4,
    fontSize: 15,
  };

  return (
    <div css={WRAPPER_STYLE}>
      {/* Left buttons. */}
      <div css={BUTTON_GROUP_STYLE}>
        <Clickable dim css={BUTTON_STYLE} onClick={decreaseYear}>
          «
        </Clickable>
        <Clickable dim css={BUTTON_STYLE} onClick={decreaseMonth}>
          ‹
        </Clickable>
      </div>

      {/* Date. */}
      <div css={DATE_STYLE}>{dateToMonthAndYear()}</div>

      {/* Right buttons. */}
      <div css={BUTTON_GROUP_STYLE}>
        <Clickable dim css={BUTTON_STYLE} onClick={increaseMonth}>
          ›
        </Clickable>
        <Clickable dim css={BUTTON_STYLE} onClick={increaseYear}>
          »
        </Clickable>
      </div>
    </div>
  );
};

export default CalendarHeader;
