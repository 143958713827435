import React, {
  useReducer,
  useEffect,
  useRef,
  createContext,
  ReactNode,
} from 'react';

import { scheduleContextReducer } from '../reducers/scheduleContextReducer';
import useRequestError from '../hooks/useRequestError';
import useFlexHttpCall, { IFlexHttpCall } from '../hooks/useFlexHttpCall';
import {
  ISchedule,
  IScheduleDay,
  IScheduleSlot,
} from '../views/flex/flexNodes/scheduleFlexNode/components/schedule';
import useFlexNavigation, {
  IFlexNavigation,
} from '../hooks/flex/useFlexNavigation';

interface IScheduleBottomButton {
  title: string;
  call: IFlexHttpCall;
}

export interface IScheduleResponse {
  bottomButton: IScheduleBottomButton;
  emptyScheduleNavigation: IFlexNavigation;
  schedule: ISchedule;
}

interface ISlotSelection {
  day: IScheduleDay;
  slot: IScheduleSlot;
}

export interface IScheduleState {
  scheduleData?: IScheduleResponse;
  selectedDay?: IScheduleDay;
  selectedSlot?: ISlotSelection;
}

export interface IScheduleContext {
  schedule?: ISchedule;
  bottomButton?: IScheduleBottomButton;
  selectedDay?: IScheduleDay;
  selectedSlot?: ISlotSelection;
  setSelectedDay: (day: IScheduleDay) => void;
  setSelectedSlot: (newSlot: ISlotSelection) => void;
  populateSchedule: (call: IFlexHttpCall) => Promise<void>;
}

export interface IScheduleSource {
  call: IFlexHttpCall;
}

export const ScheduleContext = createContext({} as IScheduleContext);

interface IScheduleContextProviderProps {
  routeSource: IScheduleSource;
  children: ReactNode;
}

export const ScheduleContextProvider = ({
  routeSource,
  children,
}: IScheduleContextProviderProps) => {
  const handleFlexHttpCall = useFlexHttpCall();
  const handleRequestErrorRef = useRef(useRequestError());
  const [state, dispatch] = useReducer(scheduleContextReducer, {});
  const isMounted = useRef(false);

  const handleFlexNavigation = useFlexNavigation();

  const populateSchedule = async (call: IFlexHttpCall) => {
    try {
      const response = await handleFlexHttpCall<IScheduleResponse>(call);
      const { emptyScheduleNavigation } = response;

      if (!response.schedule && emptyScheduleNavigation) {
        handleFlexNavigation(emptyScheduleNavigation);
      }

      if (isMounted.current) {
        dispatch({ type: 'SET_SCHEDULE_DATA', payload: response });
      }
    } catch (e) {
      handleRequestErrorRef.current(e);
    }
  };

  const populateScheduleRef = useRef(populateSchedule);

  useEffect(() => {
    isMounted.current = true;
    populateScheduleRef.current(routeSource.call);
    return () => {
      isMounted.current = false;
    };
  }, [routeSource]);

  const setSelectedDay = (day: IScheduleDay) => {
    dispatch({
      type: 'SET_SELECTED_DAY',
      payload: day,
    });
  };

  const setSelectedSlot = (selectedSlot: ISlotSelection) => {
    dispatch({
      type: 'SET_SELECTED_SLOT',
      payload: selectedSlot,
    });
  };

  return (
    <ScheduleContext.Provider
      value={{
        schedule: state.scheduleData?.schedule,
        bottomButton: state.scheduleData?.bottomButton,
        selectedDay: state.selectedDay,
        selectedSlot: state.selectedSlot,

        populateSchedule: populateScheduleRef.current,
        setSelectedDay,
        setSelectedSlot,
      }}
    >
      {children}
    </ScheduleContext.Provider>
  );
};
