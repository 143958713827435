/** @jsx jsx */
import React from 'react';
import { jsx, keyframes } from '@emotion/core';
import { IScreen } from '../../../../screen/screen';
import { IRequestOptions } from '../../../../../utils/request';
import ScrollManager from '../../../../../components/scrollManager';
import Clickable from '../../../../../components/clickable';
import useTheme from '../../../../../hooks/useTheme';

import InfotextSurveyNode from './surveyNodes/infotextSurveyNode';
import InfoboxesSurveyNode from './surveyNodes/infoboxesSurveyNode';
import RadioButtonListSurveyNode from './surveyNodes/radioButtonListSurveyNode';
import CommonInputSurveyNode from './surveyNodes/commonInputSurveyNode';
import CheckboxesSurveyNode from './surveyNodes/checkboxesSurveyNode';
import AxorBSurveyNode from './surveyNodes/aXorBSurveyNode';
import InfoScreenSurveyNode from './surveyNodes/infoScreenSurveyNode';
import PhotoSurveyNode from './surveyNodes/photoSurveyNode';
import TaggablePhotosSurveyNode from './surveyNodes/taggablePhotosSurveyNode';
import PickerSurveyNode from './surveyNodes/pickerSurveyNode';
import ContextableObjectSurveyNode from './surveyNodes/contextableObjectSurveyNode';
import ContextablePrescriptionRecordsConsentSurveyNode from './surveyNodes/contextablePrescriptionRecordsConsentSurveyNode';
import useFlexNavigation, {
  IFlexNavigation,
} from '../../../../../hooks/flex/useFlexNavigation';

export interface ISurveyNode {
  nodeType:
    | 'infoboxes'
    | 'infotext'
    | 'info_screen'
    | 'checkboxes'
    | 'radio_button_list'
    | 'a_xor_b'
    | 'text'
    | 'textarea'
    | 'int_pad'
    | 'api_validated_text'
    | 'api_validated_textarea'
    | 'api_validated_int_pad'
    | 'picker'
    | 'photo'
    | 'taggable_photos'
    | 'goto'
    | 'action'
    | 'contextable_object'
    | 'contextable_prescription_records_consent';
  name: string;

  input?: IInput;
  property?: string;
  action?: IAction;
  screen?: IScreen;
  infoBoxList?: Array<IInfoBox>;
  body?: IContextBody;
  disclaimer?: IPhotoDisclaimer;

  title?: string;
  subtitle?: string;
  placeholder?: string;
  nextButtonTitle?: string;
  shouldShowNextButtonArrow?: boolean;

  // Appended locally.
  path?: Array<string>;
}

export interface ISurveyNodeAnswer {
  nodeName: string;

  property?: string;
  allowedAnswers?: Array<IAllowedAnswer>;
  text?: string;
  date?: string;
  photos?: Array<{
    id: string;
    thumbnail: string;
  }>;
  componentAllowedAnswers?: Array<IAllowedAnswer>;
  taggablePhotos?: Array<{
    id: string;
    thumbnail: string;
    answerId: string;
  }>;
  observations?: Array<object>;

  answeredAt: number;
  visitedAt: number;
  path?: Array<string>;
}

export interface IAllowedAnswer {
  answerId: string;
  samplePointName: string;
  property?: string;
  isExplicitComplement?: boolean;
  value: string;
  img?: string;
  imgUrl?: string;
}

interface IAction {
  method: IRequestOptions['method'];
  endpoint: string;
  body: object;
}

interface IInput {
  validation: object;
  validationEndpoint?: string;
  validationErrorMessage?: string;
  allowedAnswers?: Array<IAllowedAnswer>;
  options?: Array<IComponent>;
  details?: {
    centerSplit?: string;
  };
  incompleteAlertTitle?: string;
  incompleteAlertMessage?: string;
}

interface IComponent {
  id: string;
  title?: string;
  defaultValue?: string;
  property?: string;
  values?: Array<IAllowedAnswer>;
}

interface IInfoBox {
  type: 'info';
  text: string;
  title: string;
}

interface IContextBody {
  content?: Array<
    | {
        type: 'checkbox';
        labelTitle: string;
      }
    | {
        type: 'prescription_consent_group';
        labelTitle: string;
        copy: string;
        doctorPrescriptionConsentGroup: Array<string>;
      }
    | {
        type: 'prescription_consent_info';
        labelTitle: string;
        copy: string;
      }
  >;
  meta?: {
    observations: Array<any>;
  };
}

interface IPhotoDisclaimer {
  descriptionText: string;
  linkText: string;
  flexNavigation: IFlexNavigation;
}

interface ISurveyNodeProps {
  node: ISurveyNode;
  isSubNode?: boolean;
}

const SurveyNode = (props: ISurveyNodeProps) => {
  const { font, color } = useTheme();
  const handleFlexNavigation = useFlexNavigation();

  const fadeInAnimation = keyframes`
        0% {opacity: 0}
        100% {opacity: 1}
    `;

  const getSurveyNodeComponent = (node: ISurveyNode) => {
    switch (node.nodeType) {
      case 'infotext':
        return <InfotextSurveyNode {...props} />;
      case 'infoboxes':
        return <InfoboxesSurveyNode {...props} />;
      case 'radio_button_list':
        return <RadioButtonListSurveyNode {...props} />;
      case 'checkboxes':
        return <CheckboxesSurveyNode {...props} />;
      case 'a_xor_b':
        return <AxorBSurveyNode {...props} />;
      case 'info_screen':
        return <InfoScreenSurveyNode {...props} />;
      case 'photo':
        return <PhotoSurveyNode {...props} />;
      case 'taggable_photos':
        return <TaggablePhotosSurveyNode {...props} />;
      case 'picker':
        return <PickerSurveyNode {...props} />;
      case 'contextable_object':
        return <ContextableObjectSurveyNode {...props} />;
      case 'contextable_prescription_records_consent':
        return <ContextablePrescriptionRecordsConsentSurveyNode {...props} />;
      case 'text':
      case 'textarea':
      case 'int_pad':
      case 'api_validated_text':
      case 'api_validated_textarea':
      case 'api_validated_int_pad':
        return (
          <CommonInputSurveyNode
            textArea={
              node.nodeType === 'textarea' ||
              node.nodeType === 'api_validated_textarea'
            }
            inputType={
              node.nodeType === 'int_pad' ||
              node.nodeType === 'api_validated_int_pad'
                ? 'number'
                : 'text'
            }
            {...props}
          />
        );
      default:
    }
  };

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        padding: '40px 0',
        animation: `${fadeInAnimation} 0.3s`,
      }}
    >
      {getSurveyNodeComponent(props.node)}

      {/* Privacy disclaimer */}
      {props.node.disclaimer && (
        <div css={{ padding: '0 20px' }}>
          {props.node.disclaimer.descriptionText}{' '}
          <Clickable
            onClick={() => {
              if (props.node.disclaimer?.flexNavigation) {
                handleFlexNavigation(props.node.disclaimer?.flexNavigation);
              }
            }}
            css={{
              display: 'inline-block',
              width: 'auto',
              backgroundColor: 'transparent',
              color: color.PRIMARY,
              fontFamily: font.FAMILY_BOLD,
              textDecoration: 'underline',
              padding: '3px 6px',
              margin: '-3px -6px',
            }}
          >
            {props.node.disclaimer.linkText}
          </Clickable>
        </div>
      )}

      <ScrollManager />
    </div>
  );
};

export default SurveyNode;
