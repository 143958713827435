/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';
import useTheme from '../hooks/useTheme';

interface IInput extends React.HTMLProps<HTMLInputElement> {
  foo?: string;
}

const Input = ({ className, label, ...props }: IInput) => {
  const { font, color } = useTheme();

  const INPUT_STYLE: CSSObject = {
    fontSize: 17,
    backgroundColor: color.LOCAL_WHITE,
    padding: '6px 0 8px',
    height: '26px',
    borderRadius: 0,
    borderStyle: 'none none solid',
    borderBottom: `2px solid ${color.UI_IDLE}`,
    ':focus': {
      borderBottom: `2px solid ${color.PRIMARY}`,
    },
    '::placeholder': {
      color: color.INPUT_PLACEHOLDER,
    },
  };

  const LABEL_STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12px',
    textTransform: 'uppercase',
    color: color.TEXT_SOFT,
    fontFamily: font.FAMILY_MEDIUM,
  };

  return (
    <label css={LABEL_STYLE} className={className}>
      {label}
      <input {...props} css={INPUT_STYLE} />
    </label>
  );
};

export default Input;
