/** @jsx jsx */
import React from 'react';
import { CSSObject, jsx, keyframes } from '@emotion/core';
import useTheme from '../../hooks/useTheme';

interface IMapPinCluster {
  // Children of the GoogleMapReact component automatically support the lat/lng props.
  // Those are used to position the component within the map, but are not needed in the component itself.
  /* eslint-disable react/no-unused-prop-types */
  lat: number;
  lng: number;
  count?: number;
  css?: CSSObject;

  onClick?: () => void;
}

const MapPinCluster = ({ count, css, onClick }: IMapPinCluster) => {
  const { color, font } = useTheme();

  const fadeInAnimation = keyframes`
    0% {opacity: 0}
    100% {opacity: 1}
  `;

  return (
    <div
      onClick={(event) => {
        if (onClick) {
          event.preventDefault();
          event.stopPropagation();
          onClick();
        }
      }}
      css={{
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transform: 'translate(-50%, -50%)',
        width: 32,
        height: 32,
        backgroundColor: color.SECONDARY,
        color: color.PRIMARY,
        fontSize: 17,
        fontFamily: font.FAMILY_BOLD,
        border: `2px solid ${color.PRIMARY}`,
        borderRadius: '50%',
        cursor: 'pointer',
        animation: `${fadeInAnimation} 0.3s forwards`,
        ...css,
      }}
    >
      {count}
    </div>
  );
};

export default MapPinCluster;
