/** @jsx jsx */
import React, { useContext } from 'react';
import { jsx, CSSObject } from '@emotion/core';
import { NavLink, Link } from 'react-router-dom';
import layout from '../constants/layout';
import useLanguage from '../hooks/useLanguage';
import NotificationBadge from './notificationBadge';
import { AppContext } from '../contexts/appContext';
import useTheme from '../hooks/useTheme';
import RemoteImage from './RemoteImage';
import LocalImage from './localImage';
import useViewport from '../hooks/useViewport';
import currentPartner from '../constants/partners';

const AppMenu = () => {
  const { appMenuItems, logout } = useContext(AppContext);

  const viewport = useViewport();
  const language = useLanguage();
  const { color, font } = useTheme();

  const isDesktopViewport = viewport === 'desktop';
  const isPartnerBranded = !!currentPartner;

  const MENU_CONTAINER_STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    backgroundColor: color.LOCAL_WHITE,

    [`@media (min-width: ${layout.BREAKPOINT_LARGE}px)`]: {
      width: layout.APP_MENU_WIDTH,
    },

    [`@media (max-width: ${layout.BREAKPOINT_MEDIUM}px)`]: {
      position: 'sticky',
      bottom: 0,
    },

    [`@media (max-width: ${layout.BREAKPOINT_LARGE}px)`]: {
      boxShadow: layout.DEFAULT_SHADOW_BOTTOM_ALIGNED,
      zIndex: layout.MOBILE_APP_MENU_ZINDEX,
    },
  };

  const DESKTOP_MENU_HEADER_STYLE: CSSObject = {
    display: 'flex',
    alignItems: 'center',
    height: 94,

    img: {
      height: 32,
      padding: 8,
    },
  };

  const DYNAMIC_ITEMS_CONTAINER_STYLE: CSSObject = {
    flex: 1,

    [`@media (max-width: ${layout.BREAKPOINT_LARGE}px)`]: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around',
      paddingBottom: 'env(safe-area-inset-bottom)',
    },
  };

  const MENU_ITEM_STYLE: CSSObject = {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    padding: '0 20px',
    fontSize: 17,
    color: color.TEXT,
    height: 60,
    transition: 'background-color 0.15s ease-in-out',

    // Layout overrides for mobile.
    [`@media (max-width: ${layout.BREAKPOINT_LARGE}px)`]: {
      flexDirection: 'column',
      width: 90,
      padding: 0,
      fontSize: 10,
      height: 44,
      margin: '5px 0',
    },

    // Menu item active and inactive image layout.
    img: {
      width: 28,
      height: 28,
      [`@media (min-width: ${layout.BREAKPOINT_LARGE}px)`]: {
        marginRight: 12,
      },
    },

    // Inactive image is hidden unless the menu item is active.
    'img+img': {
      display: 'none',
    },

    // Active menu item styling.
    '&.active': {
      color: color.PRIMARY,

      [`@media (min-width: ${layout.BREAKPOINT_LARGE}px)`]: {
        fontFamily: font.FAMILY_BOLD,
      },

      img: {
        display: 'none',
      },

      'img+img': {
        display: 'block',
      },
    },

    ':active': {
      backgroundColor: color.LIST_PRESS,
    },

    // Desktop-only menu item underlines
    [`@media (min-width: ${layout.BREAKPOINT_LARGE}px)`]: {
      ':not(:last-child):after': {
        content: `''`,
        position: 'absolute',
        left: 60,
        bottom: -1,
        width: 'calc(100% - 80px)', // (100% - 20px margin on each side, minus the full width of the image)
        height: 1,
        backgroundColor: color.DIVIDER_LINE,
      },
    },
  };

  const MENU_ITEM_BADGE_STYLE: CSSObject = {
    marginLeft: 'auto',
    [`@media (max-width: ${layout.BREAKPOINT_LARGE}px)`]: {
      position: 'absolute',
      right: 26,
      top: 0,
    },
  };

  const DESKTOP_MENU_FOOTER_STYLE: CSSObject = {
    position: 'relative',
    paddingBottom: 16,
    ':after': {
      content: `''`,
      position: 'absolute',
      left: 20,
      top: -1,
      width: 'calc(100% - 40px)',
      height: 1,
      backgroundColor: color.DIVIDER_LINE,
    },
  };

  return (
    <nav css={MENU_CONTAINER_STYLE}>
      {/* Desktop-only menu header */}
      {isDesktopViewport && !isPartnerBranded && (
        <div css={DESKTOP_MENU_HEADER_STYLE}>
          <Link to='/' css={{ marginLeft: 18 }} aria-hidden='true'>
            <LocalImage src='LOGO' alt='' />
          </Link>
        </div>
      )}

      {/* Dynamic menu items - corresponding with the tabs in the native apps. */}
      <div css={DYNAMIC_ITEMS_CONTAINER_STYLE}>
        {appMenuItems?.map((item, index) => {
          return (
            <NavLink key={index} css={MENU_ITEM_STYLE} to={item.path}>
              <RemoteImage {...item.icon} />
              {/* Set the tint of the active navigation item to always be primary
                  until we get proper theming support and the tintColor would be set
                  from the VL
              */}
              <RemoteImage {...item.selectedIcon} tintColor={color.PRIMARY} />
              {item.title}

              {/* Notification badge */}
              {item.badge?.resolvedText && (
                <NotificationBadge
                  text={item.badge.resolvedText}
                  css={MENU_ITEM_BADGE_STYLE}
                  variation={isDesktopViewport ? 'default' : 'small'}
                />
              )}
            </NavLink>
          );
        })}
      </div>

      {/* Desktop-only menu footer */}
      {isDesktopViewport && (
        <div css={DESKTOP_MENU_FOOTER_STYLE}>
          {/* "Log out" menu item. */}
          <NavLink
            to='/'
            css={MENU_ITEM_STYLE}
            isActive={() => false}
            onClick={(event) => {
              event.preventDefault();
              logout(true);
            }}
          >
            <LocalImage src='ICON32_LOG_OUT' tint='TEXT' />
            {language.get('logout')}
          </NavLink>
        </div>
      )}
    </nav>
  );
};

export default AppMenu;
