/** @jsx jsx */
import React, {
  useContext,
  Fragment,
  useRef,
  useEffect,
  useState,
} from 'react';
import { jsx, keyframes } from '@emotion/core';
import { dequal } from 'dequal';

import ScrollManager from '../../components/scrollManager';
import { ScreenContext } from '../../contexts/screenContext';

import Part, { IPart } from './part';
import BottomLabel, { IBottomLabel } from './components/bottomLabel';
import ScreenDecoration from './components/screenDecoration';
import useAction, { IAction } from '../../hooks/useAction';
import BottomAction, { IBottomAction } from './components/bottomAction';
import useTheme from '../../hooks/useTheme';
import useSnowplowTracker, {
  IStructuredSnowplowEvent,
} from '../../hooks/useSnowplowTracker';
import { scrollToViewportBottom } from '../../utils/smoothScroll';
import BottomPanel, { IBottomPanel } from './components/bottomPanel';

export interface IScreen {
  parts: Array<IPart>;
  screenName: string;
  title?: string;
  center?: boolean;
  hiddenIds?: Array<string>;

  bgColor?: string;
  headerColor?: string;
  footerColor?: string;
  ignoreSafeArea?: boolean;

  headerDecoration?: {
    height: number;
    type: number;
    color: string;
  };

  onLoadAction?: IAction;

  topBar?: {
    blurredEdge: boolean;
    transparent: boolean;
  };

  topRightAction?: {
    action: IAction;
    image?: string;
    text: string;
  };

  bottomLabel?: IBottomLabel;
  bottomAction?: IBottomAction;
  bottomPanel?: IBottomPanel;

  reloadConfig?: {
    autoReloadIntervalMillis?: number;
    shouldReloadOnShow: boolean;
    silent: boolean;
    preserveHiddenIds: 'keep' | 'merge' | 'replace';
  };

  snowplowTracker?: IStructuredSnowplowEvent;

  webSocketUrl?: string;
}

interface IScreenProps {
  scrollBottomOnPartsChanged?: boolean;
}

const Screen = React.memo(({ scrollBottomOnPartsChanged }: IScreenProps) => {
  const [screenHasReloaded, setScreenHasReloaded] = useState(false);

  const { screen, onAction } = useContext(ScreenContext);
  const { color, resolveColor } = useTheme();

  const prevScreen = useRef<IScreen>();

  const snowplowTracker = useSnowplowTracker();
  const handleAction = useAction();

  // If the screen has a transparent navbar, the navbar is collapsed,
  // meaning it has no height. Add padding corresponding the navbar height to push the content back down below it.
  const injectTopPadding = screen?.topBar?.transparent;

  onAction((action: IAction) => {
    handleAction(action);
  });

  const fadeInAnimation = keyframes`
    0% {opacity: 0}
    100% {opacity: 1}
  `;

  useEffect(() => {
    // Screens can reload and potentially get a different Snowplow event.
    // Only track unique events.
    if (
      screen?.snowplowTracker &&
      !dequal(screen?.snowplowTracker, prevScreen.current?.snowplowTracker)
    ) {
      snowplowTracker.trackEvent(screen.snowplowTracker);
    }

    // If scrollBottomOnPartsChanged is provided as a parameter for the screen,
    // scroll to the bottom of the viewport whenever its parts change.
    if (
      scrollBottomOnPartsChanged &&
      !dequal(screen?.parts, prevScreen.current?.parts)
    ) {
      scrollToViewportBottom();
    }

    // Check if screen has changed and has been loaded
    // before updating reload state.
    if (
      screen &&
      (screen.screenName === prevScreen?.current?.screenName ||
        prevScreen?.current?.screenName === undefined)
    ) {
      setScreenHasReloaded(true);
    } else {
      setScreenHasReloaded(false);
    }

    prevScreen.current = screen;
  }, [screen, snowplowTracker, scrollBottomOnPartsChanged]);

  return (
    <Fragment>
      {screen && (
        <div
          aria-live={screenHasReloaded ? 'polite' : 'off'}
          key={screen.screenName}
          css={{
            position: 'relative',
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            backgroundColor: resolveColor(screen.bgColor) || color.LOCAL_WHITE,
            width: '100%',
            opacity: 0,
            animation: `${fadeInAnimation} 0.3s forwards`,
            animationDelay: '0.1s',
          }}
        >
          {/* Inject the top padding to compensate for a collapsed/transparent navbar */}
          {injectTopPadding && (
            <div
              css={{
                height: 60,
                backgroundColor:
                  resolveColor(screen.headerColor || screen.bgColor) ||
                  color.LOCAL_WHITE,
              }}
            />
          )}

          {/* Screen/header decoration */}
          <ScreenDecoration
            color={resolveColor(screen.headerDecoration?.color)}
            height={screen.headerDecoration?.height}
          />

          {/* Part container, stretches to fill full screen height (minus the navbar)
          in order to be be able to vertically center parts if needed. */}
          <div
            css={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              justifyContent: screen.center ? 'center' : 'flex-start',
              width: '100%',
            }}
          >
            {screen.parts.map((part: IPart, i: number) => (
              <Part key={i} data={part} />
            ))}

            {/* If the display is taller than the actual screen and a footerColor
            is provided, fill the remaining space with the given color.  */}
            {screen.footerColor && (
              <div
                css={{
                  backgroundColor: resolveColor(screen.footerColor),
                  flex: 1,
                }}
              />
            )}
          </div>

          {/* The BottomPanel, takes precedence over the BottomLabel and BottomAction. */}
          {screen.bottomPanel && <BottomPanel {...screen.bottomPanel} />}

          {/* The BottomLabel, takes precedence over the BottomAction. */}
          {screen.bottomLabel && !screen.bottomPanel && (
            <BottomLabel data={screen.bottomLabel} />
          )}

          {/* The BottomAction, rendered only if no BottomPanel and no BottomLabel is provided. */}
          {screen.bottomAction &&
            !screen.bottomPanel &&
            !screen.bottomLabel && <BottomAction data={screen.bottomAction} />}

          <ScrollManager />
        </div>
      )}
    </Fragment>
  );
});

export default Screen;
