/** @jsx jsx */
import React, { useContext, useState } from 'react';
import { CSSObject, jsx } from '@emotion/core';
import useLanguage from '../../hooks/useLanguage';
import Clickable from '../../components/clickable';
import RadioButton from '../../components/radioButton';
import useTheme from '../../hooks/useTheme';
import Title from '../../components/title';
import { LanguageSelectorContext } from '../../contexts/languageSelectorContext';
import Navbar from '../../components/navbar';
import BottomSticky from '../../components/bottomSticky';

const LanguageSelectorView = () => {
  const { languageOptions, currentLanguage, setPreferredLanguage } = useContext(
    LanguageSelectorContext
  );

  const language = useLanguage();
  const { color } = useTheme();
  const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage);

  const BUTTON_CONTAINER_STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    backgroundColor: color.LOCAL_WHITE,
  };

  return (
    <div css={{ flex: 1 }}>
      {/* Navbar */}
      <Navbar />

      {/* View title */}
      <Title css={{ padding: 20 }} title={language.get('language')} />

      {/* Available languages radio buttons */}
      {languageOptions.map((option, index) => {
        return (
          <Clickable
            key={index}
            renderAs='label'
            styleAs='listItem'
            defaultHandler
          >
            <h2>{option.languageName}</h2>
            <RadioButton
              checked={option.languageCode === selectedLanguage}
              onChange={(e) => {
                e.stopPropagation();
                setSelectedLanguage(option.languageCode);
              }}
            />
          </Clickable>
        );
      })}

      <BottomSticky>
        <div css={BUTTON_CONTAINER_STYLE}>
          <Clickable
            styleAs='button'
            onClick={() => {
              setPreferredLanguage(selectedLanguage);
            }}
          >
            {language.get('save')}
          </Clickable>
        </div>
      </BottomSticky>
    </div>
  );
};

export default LanguageSelectorView;
