/** @jsx jsx */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { CSSObject, jsx } from '@emotion/core';
import { AnimatePresence } from 'framer-motion';
import {
  IMeetingInfo,
  IMeetingTimelineEvent,
  MeetingContext,
} from '../../../../../contexts/meetingContext';
import OpenTok, { IOpenTokSession } from './rtcProviders/opentok';
import IPhotoUploadScreenPanel from './photoUploadScreenPanel';
import VideoControls from './videoControls';
import device from '../../../../../utils/device';
import VideoMeetingInfo from './videoMeetingInfo';
import { IScreenSource } from '../../../../../contexts/screenContext';
import useLanguage from '../../../../../hooks/useLanguage';
import useSnowplowTracker from '../../../../../hooks/useSnowplowTracker';

interface IVideoMeeting {
  meetingInfo: IMeetingInfo;
  opentokSession?: IOpenTokSession;
  photoUploadScreenSource: IScreenSource;

  onHangupClicked: () => void;
  onConnecting: () => void;
  onRemoteStreamConnected: () => void;
  onVideoDisconnected: () => void;
  onStreamError: (
    eventType?: IMeetingTimelineEvent,
    eventDetails?: string
  ) => void;
  reportConnection: (sessionId: string, connectionId?: string) => void;
}

const VideoMeeting = ({
  meetingInfo,
  opentokSession,
  photoUploadScreenSource,

  onHangupClicked,
  onConnecting,
  onRemoteStreamConnected,
  onVideoDisconnected,
  onStreamError,
  reportConnection,
}: IVideoMeeting) => {
  // Initial video states.
  const [camDisabled, setCamDisabled] = useState(false);
  const [micDisabled, setMicDisabled] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);

  // Controls and photo uploader visibility states.
  const [videoControlsVisible, setVideoControlsVisible] = useState(true);
  const [photoUploadScreenVisible, setPhotoUploadScreenVisible] =
    useState(false);

  // Refs.
  const videoControlsAutoHideTimeoutRef = useRef<number>();
  const videoPanelElementRef = useRef<HTMLElement | null>();
  const lastMousePosRef = useRef({ x: 0, y: 0 });

  // Keep track of the keys currently pressed down to be able to determine
  // if the video controls should be visible and not. Will only contain
  // unique keys.
  const [keysPressed, setKeysPressed] = useState<Array<string>>([]);

  const snowplowTracker = useSnowplowTracker();
  const { trackTimelineEvent } = useContext(MeetingContext);

  const hideVideoControls = () => {
    setVideoControlsVisible(false);
  };

  // Shows the video controls and sets a timeout to automatically hide them
  // after a few seconds of no user input.
  const resetVideoControls = useCallback(() => {
    setVideoControlsVisible(true);

    clearTimeout(videoControlsAutoHideTimeoutRef.current);
    videoControlsAutoHideTimeoutRef.current = window.setTimeout(() => {
      hideVideoControls();
    }, 7000);
  }, []);

  const toggleMicrophone = () => {
    resetVideoControls();
    setMicDisabled((prevMicDisabled) => {
      if (meetingInfo?.meetingId) {
        trackTimelineEvent(
          meetingInfo?.meetingId,
          prevMicDisabled
            ? IMeetingTimelineEvent.VIDEO_PATIENT_MIC_UNMUTED
            : IMeetingTimelineEvent.VIDEO_PATIENT_MIC_MUTED
        );
      }
      snowplowTracker.trackEvent(
        prevMicDisabled
          ? snowplowTracker.event.MEETING_ROOM_MICROPHONE_ENABLE
          : snowplowTracker.event.MEETING_ROOM_MICROPHONE_DISABLE
      );

      return !prevMicDisabled;
    });
  };

  const toggleCamera = () => {
    resetVideoControls();
    setCamDisabled((prevCamDisabled) => {
      snowplowTracker.trackEvent(
        prevCamDisabled
          ? snowplowTracker.event.MEETING_ROOM_CAMERA_ENABLE
          : snowplowTracker.event.MEETING_ROOM_CAMERA_DISABLE
      );
      if (meetingInfo?.meetingId) {
        trackTimelineEvent(
          meetingInfo?.meetingId,
          prevCamDisabled
            ? IMeetingTimelineEvent.VIDEO_PATIENT_CAMERA_ENABLED
            : IMeetingTimelineEvent.VIDEO_PATIENT_CAMERA_DISABLED
        );
      }
      return !prevCamDisabled;
    });
  };

  const toggleFullscreen = () => {
    resetVideoControls();

    if (!document.fullscreenElement) {
      videoPanelElementRef.current?.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  const togglePhotoUploader = () => {
    if (photoUploadScreenVisible) {
      setPhotoUploadScreenVisible(false);
      resetVideoControls();
    } else {
      hideVideoControls();
      setPhotoUploadScreenVisible(true);
    }
  };

  // Set up event listeners for handling fullscreen mode
  // and update the state whenever the fullscreen status changes.
  useEffect(() => {
    const handleFullscreenChange = () => {
      if (document.fullscreenElement) {
        setFullscreen(true);
      } else {
        setFullscreen(false);
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  // Sets and clears the initial auto-hide timeout for the player controls.
  useEffect(() => {
    resetVideoControls();

    return () => {
      clearTimeout(videoControlsAutoHideTimeoutRef.current);
    };
  }, [resetVideoControls]);

  useEffect(() => {
    if (keysPressed.length === 0) {
      resetVideoControls();
    }
  }, [keysPressed, resetVideoControls]);

  // Set up event listeners for showing and hiding the video controls on user gestures.
  // The tab and escape keys show and hide the controls.
  // Clicking on the video panel toggles them while moving the mouse makes them appear.
  // While one or more keys are pressed down, the video controls will stay visible
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // Act on the pressed down key
      switch (e.code) {
        case 'Escape':
          hideVideoControls();
          break;
        default: {
          if (!keysPressed.includes(e.key)) {
            setKeysPressed((prevState) => {
              return [...prevState, e.key];
            });
          }
        }
      }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      setKeysPressed((prevState) => {
        return prevState.filter((key) => key !== e.key);
      });
    };

    const handleOnClick = (e: MouseEvent) => {
      lastMousePosRef.current = { x: e.x, y: e.y };

      if (e.target === e.currentTarget) {
        if (videoControlsVisible) {
          hideVideoControls();
        } else {
          resetVideoControls();
        }
      }
    };

    // Debounce showing the video controls on moving the mouse by only doing it if the cursor
    // has moved a certain minimum distance.
    // This avoids unwanted twitchy behavior, e.g. when the user clicks to hide the controls,
    // but then happens to slightly move the cursor while letting go of the mouse.
    const handleMouseMove = (e: MouseEvent) => {
      if (
        Math.abs(lastMousePosRef.current.x - e.x) > 50 ||
        Math.abs(lastMousePosRef.current.y - e.y) > 50
      ) {
        lastMousePosRef.current = { x: e.x, y: e.y };
        resetVideoControls();
      }
    };

    // Register event listeners only if the photo uploader isn't visible.
    if (!photoUploadScreenVisible) {
      document.addEventListener('keydown', handleKeyDown);
      document.addEventListener('keyup', handleKeyUp);
      videoPanelElementRef.current?.addEventListener('click', handleOnClick);

      // Register the mousemove event listener only on desktop as it makes no sense on mobile,
      // plus messes upp the toggle behavior as it is also triggered together with every tap.
      if (!device.IS_MOBILE) {
        videoPanelElementRef.current?.addEventListener(
          'mousemove',
          handleMouseMove
        );
      }
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
      videoPanelElementRef.current?.removeEventListener('click', handleOnClick);
      videoPanelElementRef.current?.removeEventListener(
        'mousemove',
        handleMouseMove
      );
    };
  }, [
    photoUploadScreenVisible,
    videoControlsVisible,
    resetVideoControls,
    keysPressed,
  ]);

  const LOCAL_VIDEO_STYLE: CSSObject = {
    position: 'absolute',
    left: 20,
    bottom: 128,
    height: 150,
    borderRadius: 7,
    overflow: 'hidden',
    backgroundColor: '#000',
    boxShadow: '0px 12px 19px rgba(21, 61, 87, 0.2)',
    transition: 'transform 0.3s',

    visibility: camDisabled ? 'hidden' : 'visible',
    transform: `translateY(${
      videoControlsVisible ? '0' : photoUploadScreenVisible ? '-180' : '108'
    }px)`,

    video: {
      height: '100%',
    },
  };
  const language = useLanguage();

  return (
    <div
      ref={(ref) => (videoPanelElementRef.current = ref)}
      css={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden',
        color: '#fff',
        backgroundColor: '#000',
      }}
    >
      {/* This button will only be visible for screen readers */}
      <button
        css={{
          position: 'absolute',
          left: '-10000px',
          top: 'auto',
          width: '1px',
          height: '1px',
          overflow: 'hidden',
        }}
        tabIndex={-1}
        onClick={() => {
          setVideoControlsVisible(true);
        }}
      >
        {language.get('meeting_room_show_controls')}
      </button>
      {/* Top info panel */}
      <AnimatePresence initial={false}>
        {videoControlsVisible && (
          <VideoMeetingInfo
            title={meetingInfo?.videoTitle}
            subtitle={meetingInfo?.videoSubtitle}
            onUploaderClick={togglePhotoUploader}
          />
        )}
      </AnimatePresence>

      {/* Video provider - OpenTok */}
      {opentokSession && (
        <OpenTok
          session={opentokSession}
          disableLocalParticipantAudio={micDisabled}
          disableLocalParticipantVideo={camDisabled}
          disconnectHandler={onVideoDisconnected}
          errorHandler={onStreamError}
          onConnecting={onConnecting}
          reportConnection={reportConnection}
          onRemoteStreamConnected={onRemoteStreamConnected}
          css={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            pointerEvents: 'none',

            '.opentok-remote-video': {
              display: 'flex',
              flexGrow: 1,
              justifyContent: 'center',

              '.OT_subscriber, .OT_widget-container': {
                display: 'contents',
              },
              video: {
                minHeight: '100%',
                objectFit: 'cover',
                width: 'auto',
              },
            },
            '.opentok-local-video': LOCAL_VIDEO_STYLE,
          }}
        />
      )}

      {/* Bottom video controls */}
      <AnimatePresence initial={false}>
        {videoControlsVisible && (
          <VideoControls
            camDisabled={camDisabled}
            micDisabled={micDisabled}
            inFullscreen={fullscreen}
            onToggleCam={toggleCamera}
            onToggleMic={toggleMicrophone}
            onToggleFullscreen={toggleFullscreen}
            onHangupClick={onHangupClicked}
            onFocus={resetVideoControls}
          />
        )}
      </AnimatePresence>

      {/* Photo uploader */}
      <AnimatePresence>
        {photoUploadScreenVisible && (
          <IPhotoUploadScreenPanel
            screenSource={photoUploadScreenSource}
            onDismiss={togglePhotoUploader}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default VideoMeeting;
