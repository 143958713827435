import React, { useContext } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import environment from '../utils/environment';
import { AppContext } from './appContext';
import { SwedishBankIdLoginContextProvider } from './swedishBankIdLoginContext';
import {
  PasswordLoginContextProvider,
  IPasswordLoginSource,
} from './passwordLoginContext';
import { OpenIdConnectLoginContextProvider } from './OpenIdConnectLoginContext';
import { ISSOLoginSource, SSOLoginContextProvider } from './ssoLoginContext';

interface IPasswordLoginViewSource extends IPasswordLoginSource {
  loginMethod?: 'PASSWORD_LOGIN';
}

interface ISwedishBankIdViewSource {
  loginMethod?: 'SWEDISH_BANK_ID';
}

interface IOpenIdConnectViewSource {
  loginMethod?: 'OPEN_ID_CONNECT';
}

interface ISSOLoginViewSource extends ISSOLoginSource {
  loginMethod?: 'SSO_LOGIN';
}

export type ILoginSource =
  | ISwedishBankIdViewSource
  | IPasswordLoginViewSource
  | IOpenIdConnectViewSource
  | ISSOLoginViewSource;

export const LoginContext = React.createContext({});

interface ILoginContextProvider {
  routeSource?: ILoginSource;
}

export const LoginContextProvider = ({
  routeSource,
}: ILoginContextProvider) => {
  const { userLoggedIn } = useContext(AppContext);
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const redirectPath = decodeURIComponent(params.get('redirect') || '/');

  const getLoginView = () => {
    switch (routeSource?.loginMethod) {
      case 'PASSWORD_LOGIN':
        return (
          <PasswordLoginContextProvider
            routeSource={{
              viewType: routeSource.viewType,
              screenResource: routeSource.screenResource,
            }}
          />
        );
      case 'SWEDISH_BANK_ID':
        return <SwedishBankIdLoginContextProvider />;
      case 'OPEN_ID_CONNECT':
        return <OpenIdConnectLoginContextProvider />;
      case 'SSO_LOGIN':
        return <SSOLoginContextProvider routeSource={routeSource} />;
      default:
        // If no login method is provided, show the market specific one.
        switch (environment.COUNTRY) {
          case 'SE':
            return <SwedishBankIdLoginContextProvider />;
          case 'NO':
            return <OpenIdConnectLoginContextProvider />;
          case 'GB':
            return <Redirect to='/livi-practice' />;
          default:
            return <PasswordLoginContextProvider />;
        }
    }
  };

  return (
    <LoginContext.Provider value={{}}>
      {(() => {
        /* Check whether the user is logged in and we should redirect
        back to the referrer or show the login screen.

        NOTE: The SSO login can be used to update an already existing session,
        meaning, a user should be able to go through it even while logged in. */
        if (userLoggedIn && routeSource?.loginMethod !== 'SSO_LOGIN') {
          return <Redirect to={redirectPath} />;
        }
        return getLoginView();
      })()}
    </LoginContext.Provider>
  );
};
