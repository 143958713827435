/** @jsx jsx */
import React from 'react';
import { CSSObject, jsx } from '@emotion/core';

import { IBasePart } from '../part';
import RemoteImage, { IRemoteImage } from '../../../components/RemoteImage';

type IContentMode = 'aspect_fit' | 'aspect_fill' | 'center' | 'left' | 'right';

export interface IImagePart extends IBasePart {
  type: 'image';

  name?: string;
  url?: string;
  image?: IRemoteImage;

  width?: number;
  height: number;

  contentMode?: IContentMode;
  leadingMargin: number;
  trailingMargin: number;

  ratio?: number;
  fullWidth?: boolean;
  cornerRadius?: number;
  forceCircular?: boolean;
}

const ImagePart = (data: IImagePart) => {
  const STYLE: CSSObject = {
    position: 'relative',
    width: data.fullWidth ? '100%' : data.width || '100%',
    height: !data.fullWidth ? data.height : 'unset',
    textAlign: 'center',
    borderRadius: data.cornerRadius,
    overflow: 'hidden',
    paddingBottom:
      data.fullWidth && data.ratio ? `${data.ratio * 100}%` : 'unset',
  };

  const WRAPPER_STYLE: CSSObject = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    overflow: 'hidden',

    // The image should be centered unless specifically aligned
    ...(data.contentMode !== 'left' &&
      data.contentMode !== 'right' && {
        margin: '0 auto',
      }),
  };

  const getImageStyle = (contentMode?: IContentMode) => {
    const baseStyle: CSSObject = {
      display: 'block',
      width: '100%',
      height: '100%',
    };

    switch (contentMode) {
      case 'aspect_fit':
        baseStyle.objectFit = 'contain';
        break;
      case 'aspect_fill':
        baseStyle.objectFit = 'cover';
        break;
      case 'center':
        break;
      case 'left':
        baseStyle.position = 'absolute';
        baseStyle.left = 0;
        baseStyle.width = 'unset';
        baseStyle.height = 'unset';
        baseStyle.top = '50%';
        baseStyle.transform = 'translateY(-50%)';
        break;
      case 'right':
        baseStyle.position = 'absolute';
        baseStyle.right = 0;
        baseStyle.width = 'unset';
        baseStyle.height = 'unset';
        baseStyle.top = '50%';
        baseStyle.transform = 'translateY(-50%)';
        break;
      default:
        break;
    }

    return baseStyle;
  };

  return (
    <div
      css={{
        marginLeft: data.leadingMargin,
        marginRight: data.trailingMargin,
      }}
    >
      <div css={STYLE}>
        <div css={WRAPPER_STYLE}>
          <RemoteImage
            {...{
              name: data.image?.name || data.name,
              url: data.image?.url || data.url,
              tintColor: data.image?.tintColor,
              forceCircular: data.image?.forceCircular,
            }}
            css={getImageStyle(data.contentMode)}
          />
        </div>
      </div>
    </div>
  );
};

export default ImagePart;
