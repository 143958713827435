/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';
import useTheme from '../hooks/useTheme';

export interface IToggle extends React.InputHTMLAttributes<HTMLInputElement> {
  checked: boolean;
  enabled?: boolean;
  invalid?: boolean;
  inactiveColor?: string;
  activeColor?: string;
}

const Toggle = ({
  checked,
  enabled = true,
  invalid,
  inactiveColor,
  activeColor,
  ...props
}: IToggle) => {
  const { color } = useTheme();

  const STYLE: CSSObject = {
    position: 'relative',
    display: 'inline-block',
    width: 52,
    height: 32,
    cursor: enabled ? 'pointer' : 'unset',

    input: {
      opacity: 0,
      width: 0,
      height: 0,
      ':checked + span': {
        backgroundColor: activeColor || color.PRIMARY,
        ':before': {
          transform: 'translateX(20px)',
        },
      },

      ':focus + span': {
        boxShadow: `inset 0px 0px 0px 3px ${color.LOCAL_FOCUS_BORDER}`,
      },
      ':focus:not([data-focus-visible-added]) + span': {
        boxShadow: 'none',
      },
    },

    span: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: 28,
      backgroundColor: inactiveColor || color.PRIMARY,
      transition: '.2s',
      opacity: enabled ? 1 : 0.4,

      ':before': {
        position: 'absolute',
        content: `''`,
        height: 28,
        width: 28,
        left: 2,
        bottom: 2,
        backgroundColor: invalid ? color.ALERT : color.LOCAL_WHITE,
        borderRadius: '50%',
        transition: '.2s',
      },
    },
  };

  return (
    <div css={STYLE}>
      <input
        type='checkbox'
        disabled={!enabled}
        checked={checked}
        tabIndex={-1}
        {...props}
      />
      <span />
    </div>
  );
};

export default Toggle;
