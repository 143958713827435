import React, { useContext } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { AppContext } from '../../contexts/appContext';

const RedirectToParamUrl = () => {
  const { appMenuItems } = useContext(AppContext);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const redirectParam = params.get('redirect');

  // Attempt to get the path of the first menu item. These are dynamic, come from VL,
  // and are unknown to the client.
  const homeScreenRoute = appMenuItems ? appMenuItems[0].path : '/';

  return <Redirect to={redirectParam || homeScreenRoute} />;
};

export default RedirectToParamUrl;
