/** @jsx jsx */
import React from 'react';
import { CSSObject, jsx } from '@emotion/core';
import RemoteImage, {
  IRemoteImage,
} from '../../../../../components/RemoteImage';
import { IBlockBaseItem } from '../blockItem';
import NotificationBadge, {
  INotificationBadge,
} from '../../../../../components/notificationBadge';
import { IAction } from '../../../../../hooks/useAction';
import Clickable from '../../../../../components/clickable';

type IContentMode = 'aspect_fit' | 'aspect_fill';

export interface IBlockImageItem extends IBlockBaseItem {
  type: 'image';

  image: IRemoteImage;
  contentMode?: IContentMode;
  badge?: INotificationBadge;

  action?: IAction;
}

interface IBlockImageItemProps {
  data: IBlockImageItem;
}

const BlockImageItem = ({ data, ...props }: IBlockImageItemProps) => {
  const getImageStyle = (contentMode?: IContentMode) => {
    const baseStyle: CSSObject = {
      display: 'block',
      width: '100%',
      height: '100%',
    };

    switch (contentMode) {
      case 'aspect_fit':
        baseStyle.objectFit = 'contain';
        break;
      case 'aspect_fill':
        baseStyle.objectFit = 'cover';
        break;
    }

    return baseStyle;
  };

  const INNER_WRAPPER_STYLE: CSSObject = {
    position: 'relative',
    width: '100%',
    height: '100%',
  };

  const NOTIFICATION_BADGE_STYLE: CSSObject = {
    position: 'absolute',
    top: -4,
    right: -8,
  };

  return (
    <Clickable dim action={data.action} {...props}>
      <div css={INNER_WRAPPER_STYLE}>
        <RemoteImage
          {...data.image}
          accessibilityLabel={
            data.accessibility?.label || data.image.accessibilityLabel
          }
          css={getImageStyle(data.contentMode)}
        />

        {data.badge && (
          <NotificationBadge css={NOTIFICATION_BADGE_STYLE} {...data.badge} />
        )}
      </div>
    </Clickable>
  );
};

export default BlockImageItem;
