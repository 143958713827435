/** @jsx jsx */
import React, { useContext, Fragment } from 'react';
import { jsx } from '@emotion/core';
import LoginViewTopSection from '../shared/loginViewTopSection';
import { OpenIdConnectLoginContext } from '../../../contexts/OpenIdConnectLoginContext';
import Clickable from '../../../components/clickable';
import { AppConfigContext } from '../../../contexts/appConfigContext';
import RemoteImage from '../../../components/RemoteImage';
import AttributedText from '../../../components/attributedText';

const OpenIdConnectLoginView = () => {
  const { initiateLogin } = useContext(OpenIdConnectLoginContext);
  const { loginViewContent } = useContext(AppConfigContext);

  return (
    <Fragment>
      {/* The shared view top section, containing contextual content, such as the header image,
          language picker, market-appropriate logo and the main header/subheader. */}
      <LoginViewTopSection />

      {/* The rest of the view - the login method itself, CTA and disclaimer. */}
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          padding: '0 20px 20px',
        }}
      >
        {/* The Sign-in button. */}
        <Clickable
          styleAs='button'
          css={{ marginTop: 4 }}
          onClick={initiateLogin}
        >
          {/* Button image */}
          {loginViewContent.buttonImage && (
            <RemoteImage
              {...loginViewContent.buttonImage}
              css={{ height: 24, marginRight: 14 }}
            />
          )}

          {/* Button text */}
          {loginViewContent.buttonTitle}
        </Clickable>

        {/* Disclaimer text */}
        {loginViewContent.disclaimerText && (
          <AttributedText
            text={loginViewContent.disclaimerText}
            css={{
              marginTop: 15,
            }}
          />
        )}
      </div>
    </Fragment>
  );
};

export default OpenIdConnectLoginView;
