/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';
import useStatefulInput, {
  IInputValidator,
} from '../../../../../hooks/useStatefulInput';
import useTheme from '../../../../../hooks/useTheme';
import RemoteImage, {
  IRemoteImage,
} from '../../../../../components/RemoteImage';
import AttributedText from '../../../../../components/attributedText';
import { IBlockBaseItem } from '../blockItem';
import InputValidationError from '../../../components/inputValidationError';

export interface IBlockPickerItem extends IBlockBaseItem {
  type: 'picker';

  inputId: string;
  label?: string;
  placeholder: string;
  inactiveLineColor: string;
  activeLineColor: string;

  items: [
    {
      itemId: string;
      title: string;
    }
  ];

  selectedItemId?: string;
  defaultSelectedItemId?: string;
  validators?: Array<IInputValidator>;

  expandIcon: IRemoteImage;
}

interface IBlockPickerItemProps {
  data: IBlockPickerItem;
}

const BlockPickerItem = ({ data, ...props }: IBlockPickerItemProps) => {
  const {
    inputValue,
    isValid,
    isDirty,
    isTouched,
    errorMessage,
    setInputValue,
    setIsTouched,
  } = useStatefulInput<string | null>({
    inputId: data.inputId,
    initialValue: data.selectedItemId || null,
    validators: data.validators,
  });

  const { color, resolveColor } = useTheme();

  const shouldShowErrorState = !isValid && isDirty && isTouched;

  const LABEL_STYLE: CSSObject = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  };

  const SELECT_WRAPPER_STYLE: CSSObject = {
    position: 'relative',
  };

  const SELECT_STYLE: CSSObject = {
    appearance: 'none',
    MozAppearance: 'none',
    width: '100%',
    fontSize: 17,
    lineHeight: '26px',
    padding: '6px 0 8px',
    backgroundColor: color.LOCAL_WHITE,
    borderStyle: 'none none solid',
    borderRadius: 0,
    borderBottom: `2px solid ${
      shouldShowErrorState
        ? color.ALERT_SECONDARY_PRESS
        : resolveColor(data.inactiveLineColor)
    }`,
    ':focus': {
      borderBottom: `2px solid ${
        shouldShowErrorState ? color.ALERT : resolveColor(data.activeLineColor)
      }`,
    },
    '::placeholder': {
      color: color.INPUT_PLACEHOLDER,
    },

    ':invalid': {
      color: color.TEXT_SOFT,
    },
    color: 'inherit',
  };

  const EXPAND_ICON_STYLE: CSSObject = {
    position: 'absolute',
    right: 0,
    bottom: 4,
    width: 38,
    height: 38,
    pointerEvents: 'none',
  };

  return (
    <label {...props} css={LABEL_STYLE}>
      {/* Input label text. */}
      <AttributedText text={data.label} />

      {/* The select input together with the toggle/expand arrow icon. */}
      <div css={SELECT_WRAPPER_STYLE}>
        <select
          required
          css={SELECT_STYLE}
          value={inputValue || ''}
          onChange={(e) => {
            e.stopPropagation();
            setInputValue(e.target.value);
          }}
          onBlur={() => {
            setIsTouched();
          }}
          aria-invalid={shouldShowErrorState}
          aria-errormessage={`${data.inputId}-validation-error`}
        >
          {/* The hidden placeholder item. */}
          <option disabled hidden value=''>
            {data.placeholder}
          </option>

          {/* Dynamic items provided by the model. */}
          {data.items.map((item, i: number) => (
            <option key={i} value={item.itemId}>
              {item.title}
            </option>
          ))}
        </select>

        {/* Toggle/expand arrow icon */}
        <RemoteImage {...data.expandIcon} css={EXPAND_ICON_STYLE} />
      </div>

      {/* Validation error message */}
      {shouldShowErrorState && (
        <InputValidationError inputId={data.inputId} message={errorMessage} />
      )}
    </label>
  );
};

export default BlockPickerItem;
