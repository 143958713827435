/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import useStatefulInput from '../../../../../hooks/useStatefulInput';
import useAction, { IAction } from '../../../../../hooks/useAction';

import RadioButton from '../../../../../components/radioButton';

export interface IFiveColumnRadioButtonCell {
  type: 'radio_button';
  selected: false;
  inputId: string;
  itemId: string;

  action: IAction;
}

interface IFiveColumnRadioButtonCellProps {
  data: IFiveColumnRadioButtonCell;
}

const FiveColumnRadioButtonCell = ({
  data,
}: IFiveColumnRadioButtonCellProps) => {
  const { inputValue, setInputValue } = useStatefulInput<string | null>({
    inputId: data.inputId,
    initialValue: data.selected ? data.itemId : null,
    keepLastOnNull: true,
  });

  const handleAction = useAction();

  return (
    <RadioButton
      name={data.inputId}
      checked={inputValue === data.itemId}
      onChange={(e) => {
        e.stopPropagation();
        handleAction(data.action);
        setInputValue(data.itemId);
      }}
    />
  );
};

export default FiveColumnRadioButtonCell;
