/** @jsx jsx */
import React, { ReactNode } from 'react';
import { jsx, CSSObject } from '@emotion/core';
import useTheme from '../hooks/useTheme';
import layout from '../constants/layout';

interface ICard {
  plain?: boolean;
  children: ReactNode;
}

const Card = ({ children, plain, ...props }: ICard) => {
  const { color } = useTheme();

  const STYLE: CSSObject = {
    borderRadius: 7,
    overflow: 'hidden',
    backgroundColor: color.LOCAL_WHITE,
    boxShadow: plain ? 'unset' : layout.DEFAULT_SHADOW_MEDIUM,
  };

  return (
    <article css={STYLE} {...props}>
      {children}
    </article>
  );
};

export default Card;
