/** @jsx jsx */
import React, { Fragment, useContext } from 'react';
import { jsx } from '@emotion/core';
import { IBlockBaseItem } from '../blockItem';
import InputValidationError from '../../../components/inputValidationError';
import { ScreenContext } from '../../../../../contexts/screenContext';

export interface IBlockInputValidationErrorItem extends IBlockBaseItem {
  type: 'error_label';
  inputId: string;
}

interface IBlockInputValidationErrorProps {
  data: IBlockInputValidationErrorItem;
}

const BlockInputValidationErrorItem = ({
  data,
  ...props
}: IBlockInputValidationErrorProps) => {
  const screenContext = useContext(ScreenContext);

  const inputState = screenContext.inputStates[data.inputId];
  const errorMessage = inputState?.errorMessage;

  const shouldShowErrorState =
    !inputState?.isValid && inputState?.isDirty && inputState?.isTouched;

  return (
    <Fragment>
      {shouldShowErrorState && errorMessage && (
        <InputValidationError
          inputId={data.inputId}
          message={errorMessage}
          {...props}
        />
      )}
    </Fragment>
  );
};

export default BlockInputValidationErrorItem;
