import useNavigation from '../useNavigation';

import { IBaseAction } from '../useAction';
import { IView } from '../../views/view';

export interface IViewAction extends IBaseAction {
  type: 'view';

  data: {
    value: string;
  };
}

const useViewAction = () => {
  const navigation = useNavigation();

  const handleViewAction = (action: IViewAction) => {
    const newView: IView = {
      type: 'SCREEN',
      source: {
        url: action.data.value,
      },
    };

    navigation.push(newView);
  };

  return handleViewAction;
};

export default useViewAction;
