import { useContext } from 'react';

import { ScreenContext } from '../../contexts/screenContext';
import { IBaseAction } from '../useAction';

export interface IModifyCardAction extends IBaseAction {
  type: 'modify_card';

  data: {
    partId: string;
    toggles: Array<{ id: string }>;
    shows: Array<{ id: string }>;
    hides: Array<{ id: string }>;
  };
}

const useModifyCardAction = () => {
  const screenContext = useContext(ScreenContext);

  const handleModifyCardAction = (action: IModifyCardAction) => {
    let state = screenContext.partStates[action.data.partId] as Array<string>;

    // Toggles
    action.data.toggles?.forEach((part) => {
      if (state.includes(part.id)) {
        state = state.filter((id) => id !== part.id);
      } else {
        state.push(part.id);
      }
    });

    // Shows
    action.data.shows?.forEach((part) => {
      state = state.filter((id) => id !== part.id);
    });

    // Hides
    action.data.hides?.forEach((part) => {
      if (!state.includes(part.id)) {
        state.push(part.id);
      }
    });

    screenContext.setPartState({ [action.data.partId]: state });
  };

  return handleModifyCardAction;
};

export default useModifyCardAction;
