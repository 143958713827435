export interface IParsedUri {
  source: string;
  protocol: string;
  authority: string;
  userInfo: string;
  user: string;
  password: string;
  host: string;
  port: string;
  relative: string;
  path: string;
  directory: string;
  file: string;
  query: URLSearchParams;
  anchor: string;
}

const parseUri = (str: string) => {
  const parsedString =
    /^(?:(?![^:@]+:[^:@/]*@)([^:/?#.]+):)?(?:\/\/)?((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:/?#]*)(?::(\d*))?)(((\/(?:[^?#](?![^?#/]*\.[^?#/.]+(?:[?#]|$)))*\/?)?([^?#/]*))(?:\?([^#]*))?(?:#(.*))?)/.exec(
      str
    ) || [];

  const uri: IParsedUri = {
    source: parsedString[0] || '',
    protocol: parsedString[1],
    authority: parsedString[2],
    userInfo: parsedString[3],
    user: parsedString[4],
    password: parsedString[5],
    host: parsedString[6],
    port: parsedString[7],
    relative: parsedString[8],
    path: parsedString[9],
    directory: parsedString[10],
    file: parsedString[11],
    query: new URLSearchParams(parsedString[12]),
    anchor: parsedString[13],
  };

  return uri;
};

export default parseUri;
