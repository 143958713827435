/** @jsx jsx */
import React, { useContext, useMemo } from 'react';
import { jsx } from '@emotion/core';
import { decamelizeKeys } from 'humps';
import {
  IScreenSource,
  ScreenContextProvider,
} from '../../../contexts/screenContext';
import Navbar from '../../../components/navbar';
import Screen from '../../screen/screen';
import { SSOLoginContext } from '../../../contexts/ssoLoginContext';

const SSOLoginView = () => {
  const { ssoLoginConfig, screenResource } = useContext(SSOLoginContext);

  const screenSource = useMemo<IScreenSource>(
    () => ({
      screen: screenResource,
      url: ssoLoginConfig?.screenHttpCall.url,
      requestOptions: {
        method: ssoLoginConfig?.screenHttpCall.method,
        body: {
          ...(typeof ssoLoginConfig?.screenHttpCall.body === 'object' && {
            ...decamelizeKeys(ssoLoginConfig?.screenHttpCall.body),
          }),
        },
        retryable: ssoLoginConfig?.screenHttpCall.retryable,
      },
      sourceUpdatedAt: Date.now(),
    }),
    [ssoLoginConfig?.screenHttpCall, screenResource]
  );

  return (
    <ScreenContextProvider routeSource={screenSource}>
      {/* Navbar */}
      <Navbar />

      <Screen />
    </ScreenContextProvider>
  );
};

export default SSOLoginView;
