import React, { useContext } from 'react';
import OpenIdConnectLoginView from '../views/login/OpenIdConnect/OpenIdConnectLoginView';
import useNavigation from '../hooks/useNavigation';
import { AppConfigContext } from './appConfigContext';

interface IOpenIdConnectLoginContext {
  initiateLogin: () => void;
}

export const OpenIdConnectLoginContext = React.createContext(
  {} as IOpenIdConnectLoginContext
);

export const OpenIdConnectLoginContextProvider = () => {
  const { appConfig } = useContext(AppConfigContext);
  const navigation = useNavigation();

  const initiateLogin = () => {
    navigation.present({
      type: 'LOGIN',
      hideAppMenu: true,
      source: {
        loginMethod: 'SSO_LOGIN',
        ssoLoginConfig: appConfig.ssoLogin,
      },
    });
  };

  return (
    <OpenIdConnectLoginContext.Provider
      value={{
        initiateLogin,
      }}
    >
      <OpenIdConnectLoginView />
    </OpenIdConnectLoginContext.Provider>
  );
};
