/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';
import useTheme from '../hooks/useTheme';
import LocalImage from './localImage';

interface ISelect extends React.SelectHTMLAttributes<HTMLSelectElement> {
  noArrow?: boolean;
}

const Select = ({ children, noArrow, ...props }: ISelect) => {
  const { color } = useTheme();

  const SELECT_STYLE: CSSObject = {
    appearance: 'none',
    MozAppearance: 'none',
    fontSize: 17,
    padding: '6px 0 10px',
    backgroundColor: color.LOCAL_WHITE,
    borderStyle: 'none none solid',
    borderRadius: 0,
    borderBottom: `2px solid ${color.UI_IDLE}`,
    ':focus': {
      borderBottom: `2px solid ${color.PRIMARY}`,
    },
    '::placeholder': {
      color: color.INPUT_PLACEHOLDER,
    },
    color: 'inherit',
  };

  const LABEL_STYLE: CSSObject = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12px',
    textTransform: 'uppercase',
    color: color.TEXT_SOFT,
  };

  const EXPAND_ICON_STYLE: CSSObject = {
    position: 'absolute',
    right: 0,
    top: 0,
    width: 38,
    height: 38,
    pointerEvents: 'none',
  };

  return (
    <label css={LABEL_STYLE}>
      <select css={SELECT_STYLE} {...props}>
        {children}
      </select>

      {/* Toggle/expand arrow icon */}
      {!noArrow && (
        <LocalImage
          src='CHEVRON_DOWN'
          tint='CHEVRON'
          alt=''
          css={EXPAND_ICON_STYLE}
        />
      )}
    </label>
  );
};

export default Select;
