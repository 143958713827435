import { useContext } from 'react';
import { ScreenContext } from '../contexts/screenContext';

const useStatefulPart = <T>(
  partId: string,
  initialValue: T
): [T, (value: T) => void] => {
  const screenContext = useContext(ScreenContext);
  const screenStateValue = screenContext.partStates[partId];

  // Set initial part state to screen state
  if (partId && screenStateValue === undefined) {
    screenContext.setPartState({ [partId]: initialValue });
  }

  const setState = (value: T) => {
    if (partId && screenStateValue !== value) {
      screenContext.setPartState({ [partId]: value });
    }
  };

  return [
    (screenStateValue !== undefined ? screenStateValue : initialValue) as T,
    setState,
  ];
};

export default useStatefulPart;
