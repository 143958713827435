/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';

// NOTE: The Swiper css is imported from a CDN in index.html
// as we can't import it directly from the library to to autoprefixer issues.
import { Swiper, SwiperSlide } from 'swiper/react';

import { IBasePart } from '../part';
import Clickable from '../../../components/clickable';
import { IAction } from '../../../hooks/useAction';
import useTheme from '../../../hooks/useTheme';
import RemoteImage, { IRemoteImage } from '../../../components/RemoteImage';

export interface IBoxCarouselPart extends IBasePart {
  type: 'box_carousel';

  boxes: Array<{
    title: string;
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
    image: IRemoteImage;
    cornerRadius: number;

    action: IAction;
  }>;
}

const BoxCarouselPart = (data: IBoxCarouselPart) => {
  const { resolveColor } = useTheme();

  return (
    <Swiper
      slidesPerView='auto'
      spaceBetween={10}
      slidesOffsetBefore={20}
      slidesOffsetAfter={20}
    >
      {data.boxes.map((box, i) => (
        <SwiperSlide key={i} style={{ width: 126 }}>
          <Clickable
            action={box.action}
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '14px 10px',
              marginTop: 1,
              marginBottom: 1,
              textAlign: 'center',
              height: 126,
              boxSizing: 'border-box',
              fontSize: 15,
              backgroundColor: resolveColor(box.backgroundColor),
              borderRadius: box.cornerRadius,
              border: `${box.borderWidth}px solid ${resolveColor(
                box.borderColor
              )}`,
            }}
            scale
            darken
          >
            <RemoteImage
              {...box.image}
              css={{
                width: 'auto',
                height: 56,
                marginBottom: 6,
              }}
            />
            {box.title}
          </Clickable>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default BoxCarouselPart;
