import { useContext } from 'react';
import { decamelizeKeys } from 'humps';
import { IBaseAction } from '../useAction';
import { FlexContext } from '../../contexts/flexContext';
import { ScreenContext } from '../../contexts/screenContext';
import { IFlexHttpCall } from '../useFlexHttpCall';

export interface IFlexChangeAction extends IBaseAction {
  type: 'flex_change_call';
  data: IFlexHttpCall;
}

const useFlexChangeAction = () => {
  const flexContext = useContext(FlexContext);
  const screenContext = useContext(ScreenContext);

  const handleFlexChangeAction = (action: IFlexChangeAction) => {
    screenContext.updateScreenSource({
      url: action.data.url,
      requestOptions: {
        method: action.data.method,
        body: {
          ...(typeof action.data.body === 'object' && {
            ...decamelizeKeys(action.data.body),
          }),
          ...(typeof flexContext.node?.memory === 'object' && {
            ...decamelizeKeys(flexContext.node.memory || {}),
          }),
        },
        retryable: action.data.retryable,
      },
      sourceUpdatedAt: Date.now(),
    });
  };

  return handleFlexChangeAction;
};

export default useFlexChangeAction;
