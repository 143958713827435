/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { ICardItem } from '../cardItem';

import FiveColumnView, {
  IFiveColumnModel,
} from '../../fiveColumn/fiveColumnView';
import { IAction } from '../../../../../hooks/useAction';

interface IFiveColumnCardItem {
  data: ICardItem<{
    columns: IFiveColumnModel;

    highlightColor?: string;
    minimumHeight?: number;
    margins: {
      top: number;
      bottom: number;
      leading: number;
      trailing: number;
    };

    action?: IAction;
  }>;
}

const FiveColumnCardItem = ({ data }: IFiveColumnCardItem) => {
  return <FiveColumnView data={data} />;
};

export default FiveColumnCardItem;
