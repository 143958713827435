/** @jsx jsx */
import React, { useEffect, useRef } from 'react';
import { jsx, CSSObject, keyframes } from '@emotion/core';
import useOverlay from '../hooks/useOverlay';
import AttributedText from '../components/attributedText';
import { IBaseOverlay } from '../contexts/overlayContext';
import useTheme from '../hooks/useTheme';
import RemoteImage, { IRemoteImage } from '../components/RemoteImage';

export interface ISplashOverlay extends IBaseOverlay {
  title: string;
  subtitle?: string;
  image?: IRemoteImage;

  autoDismissTime: number;
}

const SplashOverlay = ({
  title,
  subtitle,
  image,
  autoDismissTime,
}: ISplashOverlay) => {
  const { font, color } = useTheme();
  const overlayRef = useRef(useOverlay());

  const fadeInAnimationImage = keyframes`
    0% {transform: scale(0.25); opacity: 0}
    100% {transform: scale(1); opacity: 1}
  `;

  const BASE_STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    alignItems: 'center',
    textAlign: 'center',
  };

  const IMG_STYLE: CSSObject = {
    height: 84,
    marginBottom: 24,
    opacity: 0,
    animation: `${fadeInAnimationImage} 0.2s`,
    animationDelay: '0.15s',
    animationFillMode: 'forwards',
  };

  const TITLE_STYLE: CSSObject = {
    fontSize: 26,
    fontFamily: font.FAMILY_MEDIUM,
    color: color.LOCAL_WHITE,
  };

  const SUBTITLE_STYLE: CSSObject = {
    color: color.LOCAL_WHITE,
    marginTop: 8,
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      overlayRef.current.dismiss();
    }, autoDismissTime * 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [autoDismissTime]);

  return (
    <div css={BASE_STYLE}>
      {/* Image */}
      {image && (
        <div>
          <RemoteImage {...image} css={IMG_STYLE} />
        </div>
      )}

      {/* Title */}
      <AttributedText text={title} css={TITLE_STYLE} />

      {/* Subtitle */}
      {subtitle && <AttributedText text={subtitle} css={SUBTITLE_STYLE} />}
    </div>
  );
};

export default SplashOverlay;
