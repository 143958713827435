import React, { useContext, useEffect } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { AppConfigContext } from '../../contexts/appConfigContext';
import { AppContext } from '../../contexts/appContext';
import useNavigation from '../../hooks/useNavigation';
import View from '../../views/view';
import { logInfo } from '../../utils/remoteLogger';

const PrivateRoute = (props: RouteProps) => {
  const navigation = useNavigation();
  const currentView = navigation.getCurrentView();
  const { href } = window.location;
  const { flexStartCall } = useContext(AppConfigContext);
  const { userLoggedIn } = useContext(AppContext);

  const params = props?.location && new URLSearchParams(props.location.search);
  const referrer =
    (params && params.get('redirect')) ||
    (props?.location && props.location.pathname + props.location.search);

  useEffect(() => {
    const tag = 'SCHEME';
    if (referrer?.startsWith('/app-link/')) {
      // only log if it starts with /app-link/
      logInfo(tag, `Handling universal link ${href}`);
    }
  }, [referrer, href]);

  if (userLoggedIn) {
    // If the user is logged in and there is a flexStartCall, navigate to the root
    // where the flex flow will be started, and pass the wanted path as a redirect param.
    if (flexStartCall && props.location?.pathname !== '/') {
      return (
        <Redirect
          to={{
            pathname: '/',
            ...(referrer &&
              referrer?.length > 1 && {
                search: `redirect=${encodeURIComponent(referrer)}`,
              }),
          }}
        />
      );
    }

    return currentView ? <View view={currentView} /> : <Route {...props} />;
  }
  return (
    <Redirect
      to={{
        pathname: '/login',
        ...(referrer &&
          referrer?.length > 1 && {
            search: `redirect=${encodeURIComponent(referrer)}`,
          }),
      }}
    />
  );
};

export default PrivateRoute;
