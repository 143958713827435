/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';
import { IAction } from '../../../hooks/useAction';
import Clickable from '../../../components/clickable';
import useTheme from '../../../hooks/useTheme';
import BottomSticky from '../../../components/bottomSticky';

export interface IBottomAction {
  text: string;
  action: IAction;
}

interface IBottomActionProps {
  data: IBottomAction;
}

const BottomAction = ({ data }: IBottomActionProps) => {
  const { color } = useTheme();

  const STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    boxSizing: 'border-box',
    padding: 20,
    backgroundColor: color.LOCAL_WHITE,
  };

  return (
    <BottomSticky>
      <div css={STYLE}>
        <Clickable styleAs='button' action={data.action}>
          {data.text}
        </Clickable>
      </div>
    </BottomSticky>
  );
};

export default BottomAction;
