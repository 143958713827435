/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';
import { IAction } from '../../../hooks/useAction';
import { IBasePart } from '../part';
import Clickable from '../../../components/clickable';
import useStatefulInput, {
  IInputValidator,
} from '../../../hooks/useStatefulInput';
import useTheme from '../../../hooks/useTheme';
import InputValidationError from '../components/inputValidationError';

export interface IBinaryButtonsPart extends IBasePart {
  type: 'binary_buttons';

  inputId: string;
  leadingButton: {
    title: string;
    enabled: boolean;
    action: IAction;
    itemId: string;
    selected: boolean;
  };
  trailingButton: {
    title: string;
    enabled: boolean;
    action: IAction;
    itemId: string;
    selected: boolean;
  };
  buttonHeight: number;
  buttonMaxWidth: number;
  leadingMargin: number;
  trailingMargin: number;
  validators?: Array<IInputValidator>;
}

const BinaryButtonsPart = (data: IBinaryButtonsPart) => {
  const {
    inputValue,
    isValid,
    isDirty,
    isTouched,
    errorMessage,
    setInputValue,
    setIsTouched,
  } = useStatefulInput<string | null>({
    inputId: data.inputId,
    initialValue: data.leadingButton.selected
      ? data.leadingButton.itemId
      : data.trailingButton.selected
      ? data.trailingButton.itemId
      : null,
    validators: data.validators,
  });

  const { font, color } = useTheme();

  const shouldShowErrorState = !isValid && isDirty && isTouched;

  const OUTER_WRAPPER_STYLE: CSSObject = {
    marginLeft: data.leadingMargin - 4,
    marginRight: data.trailingMargin - 4,
  };

  const INNER_WRAPPER_STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: data.buttonHeight - 8,
  };

  const BUTTON_STYLE: CSSObject = {
    height: data.buttonHeight - 8,
    maxWidth: data.buttonMaxWidth,
    flexGrow: 1,

    marginLeft: 4,
    marginRight: 4,

    fontFamily: font.FAMILY_BOLD,
    textAlign: 'center',
    backgroundColor: color.LOCAL_WHITE,
    color: color.PRIMARY,
    border: `2px solid ${color.DIVIDER_LINE}`,
    borderRadius: 25,
  };

  const BUTTON_ACTIVE_STYLE: CSSObject = {
    height: data.buttonHeight,
    backgroundColor: color.PRIMARY,
    borderColor: color.PRIMARY,
    color: color.LOCAL_WHITE,
    borderRadius: 32,
  };

  return (
    <div css={OUTER_WRAPPER_STYLE}>
      <div css={INNER_WRAPPER_STYLE}>
        {/* Leading button */}
        <Clickable
          scale
          css={
            inputValue !== data.leadingButton.itemId
              ? BUTTON_STYLE
              : { ...BUTTON_STYLE, ...BUTTON_ACTIVE_STYLE }
          }
          aria-pressed={inputValue === data.leadingButton.itemId}
          action={data.leadingButton.action}
          onClick={() => {
            setInputValue(data.leadingButton.itemId);
          }}
          onBlur={() => {
            setIsTouched();
          }}
          aria-invalid={shouldShowErrorState}
          aria-errormessage={`${data.inputId}-validation-error`}
        >
          {data.leadingButton.title}
        </Clickable>

        {/* Trailing button */}
        <Clickable
          scale
          css={
            inputValue !== data.trailingButton.itemId
              ? BUTTON_STYLE
              : { ...BUTTON_STYLE, ...BUTTON_ACTIVE_STYLE }
          }
          aria-pressed={inputValue === data.trailingButton.itemId}
          action={data.trailingButton.action}
          onClick={() => {
            setInputValue(data.trailingButton.itemId);
          }}
          onBlur={() => {
            setIsTouched();
          }}
          aria-invalid={shouldShowErrorState}
          aria-errormessage={`${data.inputId}-validation-error`}
        >
          {data.trailingButton.title}
        </Clickable>
      </div>

      {/* Validation error message */}
      {shouldShowErrorState && (
        <InputValidationError inputId={data.inputId} message={errorMessage} />
      )}
    </div>
  );
};

export default BinaryButtonsPart;
