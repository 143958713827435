/** @jsx jsx */
import React, { useState, useEffect, Fragment, useRef } from 'react';
import { jsx, CSSObject, keyframes } from '@emotion/core';
import useTheme from '../hooks/useTheme';

interface ILoader {
  show: boolean;
  size?: number;
  stroke?: number;
  strokeColor?: string;
  transparent?: boolean;
}

const Loader = ({
  show,
  size,
  stroke,
  transparent,
  strokeColor,
  ...props
}: ILoader) => {
  const { color } = useTheme();
  const [busy, setBusy] = useState(show);
  const [shouldRender, setShouldRender] = useState(busy);

  const timeoutRef = useRef<number>();

  const fadeInAnimation = keyframes`
        0% {opacity: 0}
        100% {opacity: 1}
    `;

  const fadeOutAnimation = keyframes`
        0% {opacity: 1}
        100% {opacity: 0}
    `;

  const spinnerAnimation = keyframes`
        0% {transform: rotate(0deg)}
        100% {transform: rotate(360deg)}
    `;

  const WRAPPER_STYLE: CSSObject = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    backgroundColor: transparent ? 'transparent' : 'rgba(255, 255, 255, 0.6)',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    animation: `${busy ? fadeInAnimation : fadeOutAnimation} 0.2s`,
  };

  const STYLE: CSSObject = {
    width: size || 40,
    height: size || 40,
    div: {
      boxSizing: 'border-box',
      display: 'block',
      position: 'absolute',
      width: size || 40,
      height: size || 40,

      border: `${stroke || 4}px solid`,
      borderRadius: '50%',
      borderColor: `${
        strokeColor || color.PRIMARY
      } transparent transparent transparent`,

      animation: `${spinnerAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite`,
    },
    'div:nth-of-type(1)': {
      animationDelay: '-0.45s',
    },
    'div:nth-of-type(2)': {
      animationDelay: '-0.3s',
    },
    'div:nth-of-type(3)': {
      animationDelay: '-0.15s',
    },
  };

  useEffect(() => {
    // A simple debounce to avoid flicker, e.g. on sequential requests.
    if (show) {
      clearTimeout(timeoutRef.current);
      setBusy(true);
      setShouldRender(true);
    } else {
      timeoutRef.current = window.setTimeout(() => {
        setBusy(false);
      }, 50);
    }
  }, [show]);

  return (
    <Fragment>
      {shouldRender && (
        <div
          css={WRAPPER_STYLE}
          onAnimationEnd={() => {
            if (!busy) setShouldRender(false);
          }}
          {...props}
        >
          <div css={STYLE}>
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Loader;
