import {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useRef,
  useCallback,
} from 'react';
import { useLocation } from 'react-router-dom';
import sessionStorage from '../utils/sessionStorage';

interface IOptions {
  locationAware?: boolean;
}

const usePersistentState = <T>(
  storageKey: string,
  initialState: T,
  options?: IOptions
): [
  T,
  Dispatch<SetStateAction<T>>,
  (callback: (cachedState: T) => void) => void
] => {
  const location = useLocation();
  const key = options?.locationAware
    ? `${location.key}_${storageKey}`
    : storageKey;

  const [state, setState] = useState(
    sessionStorage.get<T>(key) || initialState
  );

  const onCachedStateRef = useRef<(cachedState: T) => void>();
  const initialStateRef = useRef(initialState);
  const optionsRef = useRef(options);

  const onCachedState = useCallback((callback: (cachedState: T) => void) => {
    onCachedStateRef.current = callback;
  }, []);

  // On location change, update state with stored data.
  // If no stored data (e.g. if we're navigating to a new route),
  // set the provided initial state.
  useEffect(() => {
    if (optionsRef.current?.locationAware) {
      const cachedState = sessionStorage.get<T>(key);
      setState(cachedState || initialStateRef.current);

      // Trigger the onCachedState callback.
      if (cachedState && onCachedStateRef.current) {
        onCachedStateRef.current(cachedState);
      }
    }
  }, [location, key]);

  // Whenever the state updates, store it in session storage.
  useEffect(() => {
    sessionStorage.set(key, state);
  }, [state, key]);

  return [state, setState, onCachedState];
};

export default usePersistentState;
