import { useContext } from 'react';
import { ScreenContext } from '../../contexts/screenContext';
import { IAction, IBaseAction } from '../useAction';
import useFlexHttpCall, { IFlexHttpCall } from '../useFlexHttpCall';

export interface IFlexSubmitInputsAction extends IBaseAction {
  type: 'flex_submit_inputs';

  data: {
    inputIds: Array<string>;
    call: IFlexHttpCall;
  };
}

const useFlexSubmitInputsAction = () => {
  const screenContext = useContext(ScreenContext);

  const handleFlexHttpCall = useFlexHttpCall();

  const handleFlexSubmitInputsAction = (
    action: IFlexSubmitInputsAction
  ): Promise<IAction> => {
    const resultMap: { [inputId: string]: unknown } = {};
    const screenInputStates = { ...screenContext.inputStates };

    let formIsValid = true;
    let firstInvalidInput = '';

    // Iterate over all the inputs to be submitted, check their validity,
    // flag them as dirty and touched (which will make them show their potential error states)
    // and generate an id-value map to be submitted in case the form is valid.
    action.data.inputIds.forEach((inputId) => {
      // The action can technically contain inputId's that are not present in the screen.
      // If that's the case, don't include it in the resulting model or attempt to flag it as dirty/touched.
      if (!screenContext.inputStates[inputId]) {
        return;
      }

      // Flag form as invalid if not all inputs are valid
      // and note the first invalid input.
      if (!screenContext.inputStates[inputId].isValid) {
        formIsValid = false;
        firstInvalidInput = firstInvalidInput || inputId;
      }

      // Flag all inputs as dirty and touched.
      screenInputStates[inputId] = {
        ...screenInputStates[inputId],
        isDirty: true,
        isTouched: true,
      };

      // Create the map to be submitted.
      resultMap[inputId] = screenContext.inputStates[inputId].value;
    });

    // Update the screen inputs state.
    screenContext.setInputState(screenInputStates);

    // Attempt to scroll the first invalid element to the top of the viewport.
    if (firstInvalidInput) {
      screenContext.scrollPartIntoView(
        screenContext.getPartIdFromInputId(firstInvalidInput)
      );
    }

    return new Promise((resolve, reject) => {
      // Don't submit the inputs data if the form is invalid.
      if (!formIsValid) {
        return;
      }

      // If the form is valid, submit all the input values.
      handleFlexHttpCall<{ completionAction: IAction }>(action.data.call, {
        body: {
          inputs_data: resultMap,
        },
      })
        .then((data) => {
          if (data?.completionAction) {
            resolve(data?.completionAction);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return handleFlexSubmitInputsAction;
};

export default useFlexSubmitInputsAction;
