/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import useTheme from '../hooks/useTheme';
import layout from '../constants/layout';
import useLanguage from '../hooks/useLanguage';

import LocalImage from './localImage';
import currentPartner from '../constants/partners';

const PartnerHeader = () => {
  const language = useLanguage();
  const { color } = useTheme();

  return (
    <div
      css={{
        backgroundColor:
          currentPartner?.theme?.headerBackgroundColor || color.LOCAL_WHITE,
        height: layout.PARTNER_HEADER_HEIGHT,
        padding: '0 20px',
        width: '100%',
        position: 'fixed',
        boxSizing: 'border-box',
        top: 0,
        zIndex: layout.PARTNER_HEADER_AND_APP_MENU_OVERLAY_ZINDEX,
        overflow: 'hidden',
        [`@media (min-width: ${layout.BREAKPOINT_MEDIUM}px)`]: {
          boxShadow:
            '0px 1px 6px rgba(21, 61, 87, 0.1), 0px 12px 19px rgba(21, 61, 87, 0.08)',
        },
      }}
    >
      <div
        css={{
          height: '100%',
          boxSizing: 'border-box',
          maxWidth: layout.APP_MAX_WIDTH + layout.APP_MENU_WIDTH,
          margin: '0 auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: currentPartner?.hideKryLiviLogoSection
            ? 'center'
            : 'space-between',
          [`@media (max-width: ${layout.BREAKPOINT_MEDIUM}px)`]: {
            borderBottom: !currentPartner?.theme?.headerBackgroundColor
              ? `1px solid ${color.DIVIDER_LINE}`
              : '0',
          },
        }}
      >
        {/* Partner logo */}
        <img
          css={{
            maxHeight: 32,
            maxWidth: currentPartner?.hideKryLiviLogoSection ? '100%' : '50%',
            marginRight: currentPartner?.hideKryLiviLogoSection ? 0 : 8,
          }}
          src={currentPartner?.logo}
          alt=''
        />

        {/* Livi/Kry logo */}
        {!currentPartner?.hideKryLiviLogoSection && (
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              overflow: 'hidden',
              overflowWrap: 'break-word',
            }}
          >
            <span
              css={{
                fontSize: 13,
                color:
                  currentPartner?.theme?.headerTextColor || color.TEXT_SOFT,
                lineHeight: 1,
                textAlign: 'right',
                paddingRight: 10,
                overflow: 'hidden',
              }}
            >
              {language.get('patient_web.partner_powered_by')}
            </span>
            <LocalImage
              tint={
                currentPartner?.theme?.headerKryLiviLogoTint || 'TEXT_HEADLINES'
              }
              src='LOGO'
              css={{ height: 32 }}
              alt=''
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PartnerHeader;
