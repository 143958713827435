import { useContext } from 'react';
import { FlexContext } from '../../../contexts/flexContext';
import { AppContext } from '../../../contexts/appContext';
import { IStructuredSnowplowEvent } from '../../useSnowplowTracker';

export interface IFlexNavigateFinish {
  type: 'finish';
  relaunch: boolean;
  urlToOpen?: string;
  snowplowEvent?: IStructuredSnowplowEvent;
}

const useFlexNavigateFinish = () => {
  const { reinitializeApp } = useContext(AppContext);
  const flexContext = useContext(FlexContext);

  const handleFlexNavigateFinish = (flexNavigate: IFlexNavigateFinish) => {
    // Relaunch the app if instructed.
    if (flexNavigate.relaunch && !flexNavigate.urlToOpen) {
      reinitializeApp();
      return;
    }
    if (flexNavigate.relaunch && flexNavigate.urlToOpen) {
      flexContext.finish(flexNavigate.urlToOpen);
      return;
    }

    flexContext.finish(flexNavigate.urlToOpen);
  };

  return handleFlexNavigateFinish;
};

export default useFlexNavigateFinish;
