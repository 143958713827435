/** @jsx jsx */
import React, { useState } from 'react';
import { jsx, CSSObject } from '@emotion/core';
import useAction, { IAction } from '../hooks/useAction';

import useTheme from '../hooks/useTheme';
import layout from '../constants/layout';

interface IClickable extends React.HTMLAttributes<HTMLElement> {
  renderAs?: keyof JSX.IntrinsicElements;
  styleAs?: 'button' | 'listItem' | 'card';

  scale?: boolean;
  darken?: boolean;
  dim?: boolean;

  defaultHandler?: boolean;
  action?: IAction;
  disabled?: boolean;
  autoFocus?: boolean;
}

const Clickable = ({
  renderAs,
  styleAs,
  scale,
  darken,
  dim,
  action,
  defaultHandler,
  children,
  onClick,
  ...props
}: IClickable) => {
  const [isActive, setIsActive] = useState(false);

  const handleAction = useAction();
  const { font, color } = useTheme();

  const isInteractive = !!(defaultHandler || action || onClick);
  const resultingTag = renderAs || (isInteractive ? 'button' : 'div');

  const BASE_STYLE: CSSObject = {
    position: 'relative',
    cursor: isInteractive ? 'pointer' : 'unset',
    userSelect: isInteractive ? 'none' : 'unset',

    transitionProperty:
      'transform, color, background-color, opacity, box-shadow',
    transitionDuration: '0.14s',
    transitionTimingFunction: 'ease',
  };

  const BUTTON_STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'left',
    borderRadius: 7,
    height: 56,
    fontFamily: font.FAMILY_BOLD,
    color: color.LOCAL_WHITE,
    backgroundColor: color.PRIMARY,

    ':disabled': {
      color: color.TEXT_SOFT,
      backgroundColor: color.BUTTON_BG_PRIMARY_DISABLED,
      '&[active]': {
        backgroundColor: color.BUTTON_BG_PRIMARY_DISABLED,
      },
    },

    '&[active]': {
      backgroundColor: color.PRIMARY_PRESS,
      transform: 'scale(0.96)',
    },
  };

  const LIST_ITEM_STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    minHeight: 62,
    padding: '10px 20px',
    fontSize: 16,

    ':not(:last-child):after': {
      content: `''`,
      position: 'absolute',
      left: 20,
      bottom: -1,
      width: 'calc(100% - 40px)',
      height: 1,
      backgroundColor: color.DIVIDER_LINE,
    },

    '&[active]': {
      backgroundColor: color.LIST_PRESS,
    },
  };

  const CARD_STYLE: CSSObject = {
    borderRadius: 7,
    overflow: 'hidden',
    backgroundColor: color.LOCAL_WHITE,
    boxShadow: layout.DEFAULT_SHADOW_MEDIUM,

    ':focus': {
      boxShadow: `${layout.DEFAULT_SHADOW_MEDIUM}, inset 0px 0px 0px 3px ${color.LOCAL_FOCUS_BORDER}`,
    },
    ':focus:not([data-focus-visible-added]):not([active])': {
      boxShadow: layout.DEFAULT_SHADOW_MEDIUM,
    },

    '&[active]': {
      boxShadow: layout.DEFAULT_SHADOW_SMALL,
      transform: 'scale(0.96)',
    },
  };

  const MODIFIERS_STYLE: CSSObject = {
    position: 'relative',

    ...(darken && {
      ':before': {
        content: `''`,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: 'inherit',
        pointerEvents: 'none',
        backgroundColor: 'transparent',
        transition: 'background-color 0.14s ease',
      },
    }),
    ...(scale && {
      transform: 'unset',
    }),
    ...(dim && {
      opacity: 'unset',
    }),

    '&[active]': {
      ...(darken && {
        ':before': {
          backgroundColor: 'rgba(0, 0, 0, 0.075)',
        },
      }),
      ...(scale && {
        transform: 'scale(0.96)',
      }),
      ...(dim && {
        opacity: 0.3,
      }),
    },
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    e.currentTarget.setAttribute('active', '');

    setIsActive(true);
  };

  const handleMouseUp = (e: React.MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();

    if (!isActive) {
      return;
    }

    // Delay removing the 'active' attribute in order to allow the state animations to finish.
    const { currentTarget } = e;
    setTimeout(() => {
      if (currentTarget) {
        currentTarget.removeAttribute('active');
      }
    }, 150);

    setIsActive(false);
  };

  const handleClick = (e: React.MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();

    if (onClick) {
      onClick(e);
    }

    handleAction(action);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLLIElement>) => {
    const key = e.charCode || e.keyCode;
    const element = e.currentTarget;

    // Only take action on return or space.
    if ((key === 13 || key === 32) && isInteractive) {
      e.preventDefault();
      e.stopPropagation();

      setTimeout(() => {
        element.click();
        element.focus();
      }, 1);
    }
  };

  return jsx(
    resultingTag,
    {
      css: {
        ...BASE_STYLE,

        ...(styleAs === 'button' && BUTTON_STYLE),
        ...(styleAs === 'listItem' && LIST_ITEM_STYLE),
        ...(styleAs === 'card' && CARD_STYLE),

        ...((darken || scale || dim) && MODIFIERS_STYLE),
      },

      // Attach event listeners only if the component
      // was provided an action or a click handler.
      ...(isInteractive && {
        onClick: handleClick,
        onKeyDown: handleKeyDown,

        onMouseDown: handleMouseDown,
        onMouseUp: handleMouseUp,
        onMouseOut: handleMouseUp,
        onTouchStart: handleMouseDown,
        onTouchEnd: handleMouseUp,
      }),

      tabIndex: isInteractive ? 0 : null,
      ...props,
    },
    children
  );
};

export default Clickable;
