/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';
import useTheme from '../../../../../hooks/useTheme';

export interface IFiveColumnShapeCell {
  type: 'shape';
  color: string;
  cornerRadius: string;

  size: {
    height: number;
    width: number;
  };
}

interface IFiveColumnShapeCellProps {
  data: IFiveColumnShapeCell;
}

const FiveColumnShapeCell = ({ data }: IFiveColumnShapeCellProps) => {
  const { resolveColor } = useTheme();

  const STYLE: CSSObject = {
    width: data.size.width,
    height: data.size.height || '100%',
    backgroundColor: resolveColor(data.color),
    borderRadius: data.cornerRadius,
  };

  return <div css={STYLE} />;
};

export default FiveColumnShapeCell;
