/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { IConversationInputStatus } from './conversationInput';
import LocalImage from '../../../../../components/localImage';
import Clickable from '../../../../../components/clickable';

interface ISubmitButton {
  status: IConversationInputStatus;
  disabled: boolean;
}

export const SubmitButton = ({ status, disabled }: ISubmitButton) => {
  return (
    <Clickable
      defaultHandler
      disabled={status === 'PROGRESS' || disabled}
      css={{
        width: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
      }}
    >
      <LocalImage
        src='APP_IMAGE_ASYNC_MESSAGING_SEND'
        tint={status === 'PROGRESS' || disabled ? 'TEXT_SOFT' : 'PRIMARY'}
        css={{
          width: 30,
          height: 30,
        }}
      />
    </Clickable>
  );
};
