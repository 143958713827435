import appStoreLinks from '../constants/appStoreLinks';
import device from './device';

// If a deeplink is provided, it will be passed to the respective app store
// which will then be passed to the app after install, on first launch.
// NOTE: passing the link through the AppStore in not supported on iOS.
export const redirectToAppStore = (deeplink?: string) => {
  // iOS
  if (device.IS_IOS) {
    window.location.href = appStoreLinks.IOS;
  }

  // Android
  if (device.IS_ANDROID) {
    const referrer = `utm_source=webapp&utm_content=${deeplink}`;
    const encodedReferrer = encodeURIComponent(referrer);

    window.location.href = deeplink
      ? `${appStoreLinks.ANDROID}&referrer=${encodedReferrer}`
      : appStoreLinks.ANDROID;
  }
};
