/** @jsx jsx */
import React, { Fragment, useContext, useEffect, useMemo } from 'react';
import { jsx } from '@emotion/core';
import { decamelizeKeys } from 'humps';
import Screen from '../../../screen/screen';
import { IBaseFlexNode } from '../../flexNode';
import {
  ScreenContextProvider,
  IScreenSource,
} from '../../../../contexts/screenContext';
import Navbar from '../../../../components/navbar';
import {
  IMeetingTimelineEvent,
  MeetingContext,
} from '../../../../contexts/meetingContext';
import VideoMeeting from './components/videoMeeting';
import { IFlexHttpCall } from '../../../../hooks/useFlexHttpCall';
import useSnowplowTracker from '../../../../hooks/useSnowplowTracker';
import VideoMeetingContainer from './components/videoMeetingContainer';
import useFlexNavigation, {
  IFlexNavigation,
} from '../../../../hooks/flex/useFlexNavigation';
import { FlexContext } from '../../../../contexts/flexContext';
import { logError } from '../../../../utils/remoteLogger';

export interface IMeetingRoomFlexNode extends IBaseFlexNode {
  type: 'meeting_room';
  isVideoCallOngoing: string;
  meetingId: string;

  screenCall: IFlexHttpCall;
  meetingRoomPollInterval?: number;

  photoUploadScreenEndpoint?: string;
  postMeetingNavigation?: IFlexNavigation;

  // @TODO: These properties are not implemented currently.
  videoControlsConfig: {
    minimizeEnabled: boolean;
    toggleAudioEnabled: boolean;
    toggleVideoEnabled: boolean;
    hangupEnabled: boolean;
    controlsHideDelayInMs: number;
  };
}

interface IMeetingRoomFlexNodeProps {
  data: IMeetingRoomFlexNode;
}

const MeetingRoomFlexNode = React.memo(
  ({ data }: IMeetingRoomFlexNodeProps) => {
    const flexContext = useContext(FlexContext);
    const {
      meetingInfo,
      meetingSession,
      callAccepted,
      getMeetingSession,
      setMeetingRoomId,
      endCall,
      showStreamError,
      trackTimelineEvent,
      reportConnection,
      resetMeetingSession,
    } = useContext(MeetingContext);

    const handleFlexNavigation = useFlexNavigation();
    const snowplowTracker = useSnowplowTracker();

    const screenSource = useMemo<IScreenSource>(
      () => ({
        url: data.screenCall.url,
        requestOptions: {
          method: data.screenCall.method,
          body: {
            ...(typeof data.screenCall.body === 'object' && {
              ...decamelizeKeys(data.screenCall.body),
            }),
            ...(typeof data.memory === 'object' && {
              ...decamelizeKeys(data.memory),
            }),
          },
          retryable: data.screenCall.retryable,
        },

        autoRefreshInterval: (data.meetingRoomPollInterval || 60) * 1000,
        externalOnLoadAction: data.destinationScreenAction,
        sourceUpdatedAt: data.nodeUpdatedAt,
      }),
      [data]
    );

    const photoUploadScreenSource = useMemo<IScreenSource>(
      () => ({
        url: data.photoUploadScreenEndpoint,
        requestOptions: {
          method: 'POST',
          body: {
            ...decamelizeKeys({ meetingId: data.meetingId }),
          },
        },
      }),
      [data]
    );

    const handleMeetingFinished = () => {
      resetMeetingSession();
      // When the meeting is done, navigate according to postMeetingNavigation
      // or finish the flex flow.
      if (data.postMeetingNavigation) {
        handleFlexNavigation(data.postMeetingNavigation);
      } else {
        flexContext.finish();
      }
    };

    const handleStreamError = (
      eventType?: IMeetingTimelineEvent,
      eventDetails?: string
    ) => {
      if (meetingInfo?.meetingId && eventType) {
        trackTimelineEvent(meetingInfo.meetingId, eventType, eventDetails);
      }

      showStreamError();
      logError(
        'MeetingRoomFlexNode',
        `Video provider error: ${eventDetails || 'Unknown'}`
      );
    };

    const handleHangupClicked = () => {
      if (meetingInfo?.meetingId) {
        endCall(meetingInfo?.meetingId);
      }
    };

    const handleConnecting = () => {
      if (meetingInfo?.meetingId) {
        trackTimelineEvent(
          meetingInfo.meetingId,
          IMeetingTimelineEvent.VIDEO_PATIENT_CONNECTING
        );
      }
    };

    const handleRemoteStreamConnected = () => {
      if (meetingInfo?.meetingId) {
        trackTimelineEvent(
          meetingInfo.meetingId,
          IMeetingTimelineEvent.VIDEO_PATIENT_REMOTE_STREAM_CONNECTED
        );
      }
    };

    // When the user accepts the call, get the meeting session.
    useEffect(() => {
      if (callAccepted && meetingInfo?.meetingId) {
        getMeetingSession(meetingInfo?.meetingId);

        // Snowplow tracking.
        snowplowTracker.trackEvent(snowplowTracker.event.MEETING_START);
      }
    }, [callAccepted, meetingInfo, snowplowTracker, getMeetingSession]);

    // Register the current node with its meetingId so we can track
    // whether we already are in the correct meeting room nor not.
    useEffect(() => {
      setMeetingRoomId(data.meetingId);

      return () => {
        setMeetingRoomId();
      };
    }, [data, setMeetingRoomId]);

    return (
      <Fragment>
        {/* If the node is visited before the meeting has started, show its screen, which is updated periodically. */}
        {!data.isVideoCallOngoing && !meetingInfo && (
          <ScreenContextProvider routeSource={screenSource}>
            {/* Navbar */}
            {data.visibleNavbar && <Navbar />}

            <Screen />
          </ScreenContextProvider>
        )}

        {/* If we have an ongoing meeting session, show the video meeting container (modal wrapper) instead. */}
        {meetingInfo && meetingSession && (
          <VideoMeetingContainer>
            <VideoMeeting
              meetingInfo={meetingInfo}
              opentokSession={meetingSession.opentokSession}
              photoUploadScreenSource={photoUploadScreenSource}
              onHangupClicked={handleHangupClicked}
              onVideoDisconnected={handleMeetingFinished}
              onStreamError={handleStreamError}
              onConnecting={handleConnecting}
              onRemoteStreamConnected={handleRemoteStreamConnected}
              reportConnection={reportConnection}
            />
          </VideoMeetingContainer>
        )}
      </Fragment>
    );
  }
);

export default MeetingRoomFlexNode;
