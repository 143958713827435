/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import { jsx } from '@emotion/core';

import Lottie from 'react-lottie-player';
import { IBlockBaseItem } from '../blockItem';
import request from '../../../../../utils/request';

export interface IBlockAnimationItem extends IBlockBaseItem {
  type: 'animation';

  url: string;
  playCount: number;
}

interface IBlockAnimationProps {
  data: IBlockAnimationItem;
}

const BlockAnimationItem = ({ data, ...props }: IBlockAnimationProps) => {
  const [animation, setAnimation] = useState<object>();

  useEffect(() => {
    request<object>(data.url, {
      credentials: 'omit',
      noCamelization: true,
    })
      .then((animationData) => {
        setAnimation(animationData);
      })
      .catch(() => {
        // Fetching the animation failed.
        // No error handling needed for now, we'll just not render.
      });
  }, [data.url]);

  return animation ? (
    // Rules for playCount:
    // -1: Autoplay the animation and loop infinitely.
    //  0: Load the animation but don't autoplay, only render the initial frame.
    // >0: Autoplay and repeat the given amount of times.
    <Lottie
      // The loop property accepts either a boolean or a number.
      loop={data.playCount === -1 ? true : data.playCount - 1}
      play={data.playCount !== 0}
      animationData={animation}
      {...props}
    />
  ) : (
    <div />
  );
};

export default BlockAnimationItem;
