import environment from '../../utils/environment';
import useOverlay from '../useOverlay';
import { IAction } from '../useAction';
import useLanguage from '../useLanguage';
import appStoreLinks from '../../constants/appStoreLinks';

const useUnhandledAction = () => {
  const overlay = useOverlay();
  const language = useLanguage();

  const handleUnhandledAction = (action: IAction, customMessage?: string) => {
    // While running the app locally or on test, show an alert containing the action name.
    if (environment.IS_PROD) {
      // In production, show a more user-friendly alert with links to download the app.
      overlay.presentActionList({
        title: language.get('patient_web.unsupported_feature.title'),
        message: language.get('patient_web.unsupported_feature.message'),
        items: [
          {
            title: language.get('patient_web.unsupported_feature.download_ios'),
            onClick: () => {
              window.open(appStoreLinks.IOS);
            },
          },
          {
            title: language.get(
              'patient_web.unsupported_feature.download_android'
            ),
            onClick: () => {
              window.open(appStoreLinks.ANDROID);
            },
          },
        ],
      });
    } else {
      // While running the app locally or on test, show an alert containing the action name.
      overlay.presentBasicAlert({
        title: 'Unsupported action',
        message:
          customMessage ||
          `"${action.type === 'url' ? action.data.value : action.type}"`,
      });
    }
  };

  return handleUnhandledAction;
};

export default useUnhandledAction;
