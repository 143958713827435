import { useCallback, useContext } from 'react';
import { decamelizeKeys } from 'humps';
import { IRequestOptions } from '../utils/request';
import { FlexContext } from '../contexts/flexContext';
import useHttpCall, { IHttpCall } from './useHttpCall';

export type IFlexHttpCall = IHttpCall;

const useFlexHttpCall = () => {
  const handleHttpCall = useHttpCall();
  const flexContext = useContext(FlexContext);

  const handleFlexHttpCall = useCallback(
    <T>(call: IFlexHttpCall, options?: IRequestOptions): Promise<T> => {
      return handleHttpCall<T>(call, {
        ...options,
        body: {
          ...options?.body,

          ...(typeof flexContext.node?.memory === 'object' && {
            ...decamelizeKeys(flexContext.node?.memory),
          }),
        },
      });
    },
    [handleHttpCall, flexContext.node]
  );

  return handleFlexHttpCall;
};

export default useFlexHttpCall;
