import { camelizeKeys } from 'humps';

const base64ToObject = <T>(string: string) => {
  const decodedJson = JSON.parse(atob(string));
  const camelizedJson = camelizeKeys(decodedJson, (key, convert) =>
    // Don't camelize keys that include uppercase chars, numbers or hyphens.
    // This way, camelization should be reversible.
    /^(?=.*?[A-Z])|(?=.*?[0-9])|(?=.*?[-])/.test(key) ? key : convert(key)
  ) as unknown;

  return camelizedJson as T;
};

export default base64ToObject;
