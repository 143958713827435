/** @jsx jsx */
import React, { Fragment, useMemo } from 'react';
import { jsx } from '@emotion/core';
import { decamelizeKeys } from 'humps';
import Screen, { IScreen } from '../../screen/screen';
import { IBaseFlexNode } from '../flexNode';
import {
  ScreenContextProvider,
  IScreenSource,
} from '../../../contexts/screenContext';
import Navbar from '../../../components/navbar';
import { IFlexHttpCall } from '../../../hooks/useFlexHttpCall';

export interface IScreenFlexNode extends IBaseFlexNode {
  type: 'screen';
  call?: IFlexHttpCall;
  screen?: IScreen;
}

interface IScreenFlexNodeProps {
  data: IScreenFlexNode;
}

const ScreenFlexNode = React.memo(({ data }: IScreenFlexNodeProps) => {
  const screenSource = useMemo<IScreenSource>(
    () => ({
      ...(data.call && { url: data.call.url }),
      ...(data.call && {
        requestOptions: {
          method: data.call.method,
          body: {
            ...(typeof data.call.body === 'object' && {
              ...decamelizeKeys(data.call.body),
            }),
            ...(typeof data.memory === 'object' && {
              ...decamelizeKeys(data.memory),
            }),
          },
          retryable: data.call.retryable,
        },
      }),
      screen: data.screen,
      externalOnLoadAction: data.destinationScreenAction,
      sourceUpdatedAt: data.nodeUpdatedAt,
    }),
    [data]
  );

  return (
    <Fragment>
      {(data.call || data.screen) && (
        <ScreenContextProvider routeSource={screenSource}>
          {/* Navbar */}
          {data.visibleNavbar && <Navbar />}

          <Screen />
        </ScreenContextProvider>
      )}
    </Fragment>
  );
});

export default ScreenFlexNode;
