import { useContext, useRef } from 'react';
import { OverlayContext } from '../contexts/overlayContext';
import { IAlertOverlay } from '../overlays/alertOverlay';
import { IIFrameOverlay } from '../overlays/iFrameOverlay';
import { ISplashOverlay } from '../overlays/splashOverlay';
import { IStripeCheckoutOverlay } from '../overlays/stripeCheckoutOverlay';
import { ICallingScreenOverlay } from '../overlays/callingScreenOverlay';
import { IActionListOverlay } from '../overlays/actionListOverlay';
import { IIconAlertOverlay } from '../overlays/iconAlertOverlay';
import useLanguage from './useLanguage';
import { IImagePreviewOverlay } from '../overlays/imagePreviewOverlay';
import { IVideoOverlay } from '../overlays/videoOverlay';
import { IBankIdOverlay } from '../overlays/bankIdOverlay';
import { IPdfPreviewOverlay } from '../overlays/pdfPreviewOverlay';

interface IBasicAlert extends Partial<IAlertOverlay> {
  buttonTitle?: string;
}

const useOverlay = () => {
  const overlayContext = useContext(OverlayContext);
  const language = useLanguage();

  const overlayContextRef = useRef(overlayContext);
  overlayContextRef.current = overlayContext;

  const presentAlert = (data: IAlertOverlay) => {
    overlayContextRef.current.presentOverlay({
      type: 'ALERT',
      data,
    });
  };

  const presentBasicAlert = (data: IBasicAlert) => {
    overlayContextRef.current.presentOverlay({
      type: 'ALERT',
      data: {
        ...data,
        title: data.title,
        message: data.message,
        items: [
          {
            title: data.buttonTitle || language.get('ok'),
            onClick: () => {},
          },
        ],
      },
    });
  };

  const presentIconAlert = (data: IIconAlertOverlay) => {
    overlayContextRef.current.presentOverlay({
      type: 'ICON_ALERT',
      data,
    });
  };

  const presentActionList = (data: IActionListOverlay) => {
    overlayContextRef.current.presentOverlay({
      type: 'ACTION_LIST',
      data: {
        dismissible: true,
        ...data,
      },
    });
  };

  const presentIFrame = (data: IIFrameOverlay) => {
    overlayContextRef.current.presentOverlay({
      type: 'IFRAME',
      data,
    });
  };

  const presentSplash = (data: ISplashOverlay) => {
    overlayContextRef.current.presentOverlay({
      type: 'SPLASH',
      data,
    });
  };

  const presentStripeCheckout = (data: IStripeCheckoutOverlay) => {
    overlayContextRef.current.presentOverlay({
      type: 'STRIPE_CHECKOUT',
      data,
    });
  };

  const presentBankId = (data: IBankIdOverlay) => {
    overlayContextRef.current.presentOverlay({
      type: 'BANK_ID',
      data,
    });
  };

  const presentCallingScreen = (data: ICallingScreenOverlay) => {
    overlayContextRef.current.presentOverlay({
      type: 'CALLING_SCREEN',
      data: {
        fullscreenOnMobile: true,
        ...data,
      },
    });
  };

  const presentImagePreview = (data: IImagePreviewOverlay) => {
    overlayContextRef.current.presentOverlay({
      type: 'IMAGE_PREVIEW',
      data: {
        noWidthConstrain: true,
        fullscreenOnMobile: true,
        dismissible: true,
        ...data,
      },
    });
  };

  const presentPdfPreview = (data: IPdfPreviewOverlay) => {
    overlayContextRef.current.presentOverlay({
      type: 'PDF_PREVIEW',
      data: {
        noWidthConstrain: true,
        fullscreenOnMobile: true,
        dismissible: true,
        ...data,
      },
    });
  };

  const presentVideo = (data: IVideoOverlay) => {
    overlayContextRef.current.presentOverlay({
      type: 'VIDEO',
      data: {
        noWidthConstrain: true,
        fullscreenOnMobile: true,
        dismissible: true,
        ...data,
      },
    });
  };

  const dismiss = () => {
    overlayContextRef.current.dismissOverlay();
  };

  const getCurrentOverlayType = () => {
    return overlayContextRef.current.getCurrentOverlay()?.type;
  };

  return useRef({
    presentAlert,
    presentBasicAlert,
    presentIconAlert,
    presentActionList,
    presentIFrame,
    presentSplash,
    presentStripeCheckout,
    presentBankId,
    presentCallingScreen,
    presentImagePreview,
    presentPdfPreview,
    presentVideo,

    dismiss,
    getCurrentOverlayType,
  }).current;
};

export default useOverlay;
