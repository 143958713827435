import { useRef } from 'react';
import { audio } from '../constants/assets';
import { logWarning } from '../utils/remoteLogger';

// Create a global element that will be re-used for audio playback
// throughout the runtime of the app.
const audioElement = new Audio();

// Mobile browsers only allow audio to be played in a synchronous click handler.
// After that, however, they allow changing the source and starting the playback asynchronously.
// Set up a global one-time click event handler that will trigger the initial faux-play,
// allowing us to control it freely later.
document.addEventListener(
  'click',
  () => {
    // This playback attempt will fail silently as it doesn't have any source.
    audioElement.play().catch(() => null);
  },
  { once: true }
);

const useAudio = () => {
  const startPlayback = (src: keyof typeof audio, loop?: boolean) => {
    audioElement.src = audio[src];
    audioElement.loop = !!loop;
    audioElement.play().catch((error) => {
      logWarning('useAudio', `Audio playback failed: ${src}`, error);
    });
  };

  const stopPlayback = () => {
    // There is no actual stop method.
    // When the playback is paused and there are no longer any references to it,
    // the memory will be freed by the garbage collector as usual.
    // As we will always keep a reference to the element, this does introduce a small memory overhead,
    // but as it will only be used to play short audio snippets, it should be negligible
    audioElement.pause();
  };

  return useRef({
    startPlayback,
    stopPlayback,
  }).current;
};

export default useAudio;
