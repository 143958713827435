/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';

import { IBasePart } from '../part';
import AttributedText from '../../../components/attributedText';
import Clickable from '../../../components/clickable';
import { IAction } from '../../../hooks/useAction';

export interface IDataTextRowLeftLabelPart extends IBasePart {
  type: 'data_text_row_left_label';

  topMargin: number;
  bottomMargin: number;
  leadingMargin: number;
  trailingMargin: number;
  minimumHeight: number;

  attributedText: string;

  imageName: string;
  imageUrl: string;
  imageHeight: number;

  action: IAction;
}

const DataTextRowLeftLabelPart = (data: IDataTextRowLeftLabelPart) => {
  const WRAPPER_STYLE: CSSObject = {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: data.leadingMargin || 20,
    paddingRight: data.trailingMargin || 20,
    paddingTop: data.topMargin || 20,
    paddingBottom: data.bottomMargin || 20,
    boxSizing: 'border-box',
    minHeight: data.minimumHeight || 60,
  };

  const IMAGE_STYLE: CSSObject = {
    width: 'auto',
    height: data.imageHeight,
    marginRight: 8,
    borderRadius: '50%',
  };

  return (
    <Clickable action={data.action} css={WRAPPER_STYLE}>
      {data.imageUrl && <img css={IMAGE_STYLE} src={data.imageUrl} alt='' />}
      <AttributedText text={data.attributedText} />
    </Clickable>
  );
};

export default DataTextRowLeftLabelPart;
