/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import { CSSObject, jsx } from '@emotion/core';
import GoogleMapReact from 'google-map-react';
import Supercluster from 'supercluster';

import { IBasePart } from '../part';
import useTheme from '../../../hooks/useTheme';
import useAction, { IAction } from '../../../hooks/useAction';
import RemoteImage from '../../../components/RemoteImage';
import MAP_STYLES from '../../../constants/googleMapsStyles';
import Clickable from '../../../components/clickable';
import layout from '../../../constants/layout';
import Map, {
  getMapBoundsToFitPins,
  IGeoJsonProperties,
  IMapCircularButton,
  IMapInitialPosition,
  IMapPin,
} from '../../../components/interactiveMap/map';

export interface IEmbeddedMapPart extends IBasePart {
  type: 'embedded_map';

  action?: IAction;
  button?: IMapCircularButton;
  initialPosition: IMapInitialPosition;
  height: number;
  pins: Array<IMapPin>;
}

// Disable all user interactions
const GOOGLE_MAPS_OPTIONS: GoogleMapReact.MapOptions = {
  zoomControl: false,
  fullscreenControl: false,
  clickableIcons: false,
  styles: MAP_STYLES,
  draggable: false,
  disableDoubleClickZoom: true,
  scrollwheel: false,
  panControl: false,
};

// Create a singleton instance of Supercluster.
// The radius is set in pixels, set to 80 to have the clustering happen
// before the pins have a chance to overlap.
const supercluster = new Supercluster({ radius: 80 });

const EmbeddedMapPart = (data: IEmbeddedMapPart) => {
  const [mapPoints, setMapPoints] = useState<
    Array<Supercluster.PointFeature<IGeoJsonProperties>>
  >([]);

  const handleAction = useAction();
  const { color, resolveColor } = useTheme();

  // Set mapPins and fit map to its bounds if desired
  const apiIsLoaded = (mapsArg: any) => {
    const { map, maps } = mapsArg;
    setMapPoints(
      data.pins.map((pin, index) => ({
        type: 'Feature',
        id: index,
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: [pin.coordinates.lng, pin.coordinates.lat],
        },
      }))
    );

    // Change bounds of map to fit all pin coordinates as tightly as possible
    if (data.initialPosition.type === 'fit_to_coordinates') {
      const bounds = getMapBoundsToFitPins(maps, data.pins);
      map.fitBounds(bounds);
    }
  };

  // Center position is only relevant when there is a manual zoom level
  // so the first position coordinates are chosen in both cases
  const centerPosition =
    data.initialPosition.type === 'centered_with_zoom_level'
      ? {
          lat: data.initialPosition.coordinates.lat,
          lng: data.initialPosition.coordinates.lng,
        }
      : {
          lat: data.initialPosition.coordinates[0].lat,
          lng: data.initialPosition.coordinates[0].lng,
        };

  const MAP_BUTTON: CSSObject = {
    position: 'absolute',
    right: 20,
    bottom: 20,
    borderRadius: '50%',
    height: data.button?.diameter,
    width: data.button?.diameter,
    backgroundColor:
      resolveColor(data.button?.backgroundColor) || color.SURFACE_BG_MAIN,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: layout.DEFAULT_SHADOW_MEDIUM,
  };

  // If there is an action and no button to attach the action to
  // the cursor should be a pointer
  const cursorCSS: CSSObject = {
    cursor: data.action && !data.button ? 'pointer' : 'default',
  };

  // Set the respective cursor for both the cluster and pin element
  const mapCSS = {
    cluster: cursorCSS,
    pin: cursorCSS,
  };

  useEffect(() => {
    // Supercluster works with data in the GeoJSON Feature format:
    // https://tools.ietf.org/html/rfc7946#section-3.2
    // Convert our own pin array into the appropriate format and feed it to Supercluster.
    supercluster.load(
      data.pins.map((pin, index) => ({
        type: 'Feature',
        id: index,
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: [pin.coordinates.lng, pin.coordinates.lat],
        },
      }))
    );
  }, [data.pins]);

  return (
    <div style={{ position: 'relative', height: data.height }}>
      <Map
        config={{
          zoom:
            data.initialPosition.type === 'centered_with_zoom_level'
              ? data.initialPosition.zoomLevel
              : 1, // The zoom level will be altered based on the bounds on the pins, so this zoom level is only the initial level
          center: centerPosition,
          onClick: () => {
            // Only handle action on the whole map, if there is no button
            if (data.action && !data.button) {
              handleAction(data.action);
            }
          },
          onGoogleApiLoaded: apiIsLoaded,
          options: GOOGLE_MAPS_OPTIONS,
        }}
        content={{
          mapPoints,
          pins: data.pins,
          css: mapCSS,
        }}
      />
      {/* Bottom right button */}
      {data.button && (
        <Clickable scale action={data.action} css={MAP_BUTTON}>
          <RemoteImage
            {...data.button.icon}
            css={{
              height: data.button.iconSize.height,
              width: data.button.iconSize.width,
            }}
          />
        </Clickable>
      )}
    </div>
  );
};

export default EmbeddedMapPart;
