/** @jsx jsx */
import React, { useContext, useRef, Fragment } from 'react';
import { jsx, CSSObject } from '@emotion/core';
import Clickable from '../../../components/clickable';
import useNavigation from '../../../hooks/useNavigation';
import request from '../../../utils/request';
import PhoneInput from '../../../components/phoneInput';
import useLanguage from '../../../hooks/useLanguage';
import useRequestError from '../../../hooks/useRequestError';
import useOverlay from '../../../hooks/useOverlay';
import { PasswordLoginContext } from '../../../contexts/passwordLoginContext';
import LoginViewTopSection from '../shared/loginViewTopSection';
import LocalImage from '../../../components/localImage';
import { AppConfigContext } from '../../../contexts/appConfigContext';
import AttributedText from '../../../components/attributedText';

const PhoneEntryView = () => {
  const { loginViewContent } = useContext(AppConfigContext);

  const navigation = useNavigation();
  const language = useLanguage();

  const handleRequestErrorRef = useRef(useRequestError());
  const { presentBasicAlert } = useOverlay();
  const { phone, setPhone, setSmsCode } = useContext(PasswordLoginContext);

  const onNextClick = async () => {
    if (!phone.phoneNumber) {
      presentBasicAlert({
        title: language.get('wrong_input'),
        message: language.get('invalid_phone'),
      });
      return;
    }

    try {
      await request('/api/view/register/device/sms', {
        method: 'POST',
        body: {
          phone_number: phone.phoneNumber,
          country_code: phone.countryCode,
        },
      });

      // remove cached sms code if present
      setSmsCode('');

      navigation.push({
        type: 'LOGIN',
        source: {
          loginMethod: 'PASSWORD_LOGIN',
          viewType: 'SMS_CODE_ENTRY',
        },
      });
    } catch (e) {
      handleRequestErrorRef.current(e);
    }
  };

  const WRAPPER_STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 20px 20px',
  };

  const BUTTON_STYLE: CSSObject = {
    marginTop: 30,

    img: {
      position: 'absolute',
      width: 24,
      right: 20,
    },
  };

  return (
    <Fragment>
      {/* The shared view top section, containing contextual content, such as the header image,
          language picker, market-appropriate logo and the main header/subheader. */}
      <LoginViewTopSection />

      {/* The rest of the view - the login method itself, CTA and disclaimer. */}
      <form
        css={WRAPPER_STYLE}
        onSubmit={(e) => {
          e.preventDefault();
          onNextClick();
        }}
      >
        <PhoneInput
          countryCodeSelectLabel={language.get('select_country_code')}
          phoneInputLabel={language.get('phone_number')}
          countryCode={phone.countryCode}
          phoneNumber={phone.phoneNumber}
          onChange={(newCountryCode, newPhone) => {
            setPhone(newCountryCode, newPhone);
          }}
        />

        {/* Next/Proceed button */}
        <Clickable styleAs='button' defaultHandler css={BUTTON_STYLE}>
          {/* Button text */}
          {loginViewContent.buttonTitle}

          {/* Right chevron image */}
          <LocalImage src='CHEVRON_RIGHT' tint='LOCAL_WHITE' alt='' />
        </Clickable>

        {/* Disclaimer text */}
        {loginViewContent.disclaimerText && (
          <AttributedText
            text={loginViewContent.disclaimerText}
            css={{
              marginTop: 15,
            }}
          />
        )}
      </form>
    </Fragment>
  );
};

export default PhoneEntryView;
