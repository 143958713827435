import { useContext } from 'react';
import { ScreenContext } from '../../contexts/screenContext';
import { IBaseAction } from '../useAction';

export interface IChangeAction extends IBaseAction {
  type: 'change';

  data: {
    value: string;
  };
}

const useChangeAction = () => {
  const screenContext = useContext(ScreenContext);

  const handleChangeAction = (action: IChangeAction) => {
    screenContext.updateScreenSource({
      url: action.data.value,
      sourceUpdatedAt: Date.now(),
    });
  };

  return handleChangeAction;
};

export default useChangeAction;
