// Audio
import ringtone from '../assets/audio/ringtone.mp3';

// Fonts
import youSansLight from '../assets/fonts/You_Sans-Light.woff2';
import youSansRegular from '../assets/fonts/You_Sans-Regular.woff2';
import youSansRegularItalic from '../assets/fonts/You_Sans-Regular_Italic.woff2';
import youSansMedium from '../assets/fonts/You_Sans-Medium.woff2';
import youSansBold from '../assets/fonts/You_Sans-Bold.woff2';
import youSansBlack from '../assets/fonts/You_Sans-Black.woff2';

// Icon32
import icon32LogOut from '../assets/images/icon32/icon32_log_out.svg';
import icon32LocationArrow from '../assets/images/icon32/icon32_location_arrow.svg';
import icon32Cross from '../assets/images/icon32/icon32_cross.svg';
import icon32CircleInfo from '../assets/images/icon32/icon32_circle_info.svg';
import icon32Camera from '../assets/images/icon32/icon32_camera.svg';
import icon32Paperclip from '../assets/images/icon32/icon32_paperclip.svg';
import icon32Document from '../assets/images/icon32/icon32_document.svg';
import icon32Envelope from '../assets/images/icon32/icon32_envelope.svg';
import icon32Download from '../assets/images/icon32/icon32_download.svg';

// AppImage
import appImageCheckmark from '../assets/images/app_image/app_image_checkmark.svg';
import appImageMeetingDisableVideo from '../assets/images/app_image/app_image_meeting_disable_video.svg';
import appImageMeetingDisableMic from '../assets/images/app_image/app_image_meeting_disable_mic.svg';
import appImageMeetingEnableVideo from '../assets/images/app_image/app_image_meeting_enable_video.svg';
import appImageMeetingEnableMic from '../assets/images/app_image/app_image_meeting_enable_mic.svg';
import appImageMeetingImageUpload from '../assets/images/app_image/app_image_meeting_image_upload.svg';
import appImageRemovePhoto from '../assets/images/app_image/app_image_remove_photo.svg';
import appImageMeetingHangup from '../assets/images/app_image/app_image_meeting_hangup.svg';
import appImageWarningCircle from '../assets/images/app_image/app_image_warning_circle.svg';
import appImageAsyncMessagingSend from '../assets/images/app_image/app_image_async_messaging_send.svg';
import appImageAsyncMessagingError from '../assets/images/app_image/app_image_async_messaging_error.svg';

// Material icons
import materialVisibility from '../assets/images/material/visibility.svg';
import materialVisibilityOff from '../assets/images/material/visibility_off.svg';

// Partner logos
import testpartnerLogo from '../assets/images/partners/logo/testpartner-dark-logo.png';
import testpartnerLightLogo from '../assets/images/partners/logo/testpartner-light-logo.png';
import telemedicaLogo from '../assets/images/partners/logo/telemedica-logo.png';
import vgrLogo from '../assets/images/partners/logo/vgr-logo.svg';
import malakoffLogo from '../assets/images/partners/logo/malakoff-logo.svg';
import elsanLogo from '../assets/images/partners/logo/elsan-logo.svg';
import aprilLogo from '../assets/images/partners/logo/april-logo.svg';
import benefizLogo from '../assets/images/partners/logo/benefiz-logo.svg';
import jeTransporteLogo from '../assets/images/partners/logo/jetransporte-logo.svg';
import sidecareLogo from '../assets/images/partners/logo/sidecare-logo.png';
import aglaeLogo from '../assets/images/partners/logo/aglae-logo.svg';
import prevoirLogo from '../assets/images/partners/logo/prevoir-logo.svg';
import mindDayLogo from '../assets/images/partners/logo/mindday-logo.svg';

// Partner backgrounds
import elsanBackground from '../assets/images/partners/background/elsan-background.png';
import benefizBackground from '../assets/images/partners/background/benefiz-background.svg';
import jeTransporteBackground from '../assets/images/partners/background/jeTransporte-background.svg';
import sidecareBackground from '../assets/images/partners/background/sidecare-background.svg';

// Misc images, to be sorted.
import logoKry from '../assets/images/logo-kry.svg';
import logoLivi from '../assets/images/logo-livi.svg';
import chevronLeft from '../assets/images/chevron-left.svg';
import chevronRight from '../assets/images/chevron-right.svg';
import chevronDown from '../assets/images/chevron-down.svg';
import fullscreenEnter from '../assets/images/fullscreen-enter.svg';
import fullscreenExit from '../assets/images/fullscreen-exit.svg';
import iconSearch from '../assets/images/icon-search.svg';
import iconAccept from '../assets/images/icon-accept.svg';
import iconDecline from '../assets/images/icon-decline.svg';
import generalError from '../assets/images/general-error.svg';
import bankidWhite from '../assets/images/bankid-white.svg';
import closeButtonRound from '../assets/images/close-button-round.svg';

import environment from '../utils/environment';

// Screen parts images
import WaitTimeHeaderOuterBlob from '../assets/images/screen_parts/wait-time-header-outer-blob.svg';
import WaitTimeHeaderInnerBlob from '../assets/images/screen_parts/wait-time-header-inner-blob.svg';
import WaitTimeHeaderBlob from '../assets/images/screen_parts/wait-time-header-blob.svg';
import WaitTimeHeaderBlobMask from '../assets/images/screen_parts/wait-time-header-blob-mask.svg';

export const audio = {
  RINGTONE: ringtone,
};

export const fonts = {
  YOU_SANS_LIGHT: youSansLight,
  YOU_SANS_REGULAR: youSansRegular,
  YOU_SANS_MEDIUM: youSansMedium,
  YOU_SANS_BOLD: youSansBold,
  YOU_SANS_ITALIC: youSansRegularItalic,
  YOU_SANS_BLACK: youSansBlack,
};

export const images = {
  LOGO: environment.IS_LIVI ? logoLivi : logoKry,

  ICON32_LOG_OUT: icon32LogOut,
  ICON32_LOCATION_ARROW: icon32LocationArrow,
  ICON32_CROSS: icon32Cross,
  ICON32_CAMERA: icon32Camera,
  ICON32_DOCUMENT: icon32Document,
  ICON32_PAPERCLIP: icon32Paperclip,
  ICON32_ENVELOPE: icon32Envelope,
  ICON32_CIRCLE_INFO: icon32CircleInfo,
  ICON32_DOWNLOAD: icon32Download,

  APP_IMAGE_CHECKMARK: appImageCheckmark,
  APP_IMAGE_REMOVE_PHOTO: appImageRemovePhoto,
  APP_IMAGE_MEETING_DISABLE_MIC: appImageMeetingDisableMic,
  APP_IMAGE_MEETING_ENABLE_MIC: appImageMeetingEnableMic,
  APP_IMAGE_MEETING_ENABLE_VIDEO: appImageMeetingEnableVideo,
  APP_IMAGE_MEETING_DISABLE_VIDEO: appImageMeetingDisableVideo,
  APP_IMAGE_MEETING_IMAGE_UPLOAD: appImageMeetingImageUpload,
  APP_IMAGE_MEETING_HANGUP: appImageMeetingHangup,
  APP_IMAGE_WARNING_CIRCLE: appImageWarningCircle,
  APP_IMAGE_ASYNC_MESSAGING_SEND: appImageAsyncMessagingSend,
  APP_IMAGE_ASYNC_MESSAGING_ERROR: appImageAsyncMessagingError,

  MATERIAL_VISIBILITY: materialVisibility,
  MATERIAL_VISIBILITY_OFF: materialVisibilityOff,

  CHEVRON_LEFT: chevronLeft,
  CHEVRON_RIGHT: chevronRight,
  CHEVRON_DOWN: chevronDown,
  FULLSCREEN_ENTER: fullscreenEnter,
  FULLSCREEN_EXIT: fullscreenExit,
  SEARCHBAR_ICON: iconSearch,
  ACCEPT_ICON: iconAccept,
  DECLINE_ICON: iconDecline,
  ERROR_SAD_FACE: generalError,
  BANKID_MONO: bankidWhite,
  CLOSE_BUTTON_ROUND: closeButtonRound,
};

export const partnerLogos = {
  TESTPARTNER: testpartnerLogo,
  TESTPARTNER_LIGHT: testpartnerLightLogo,
  TELEMEDICA: telemedicaLogo,
  VGR: vgrLogo,
  MALAKOFF: malakoffLogo,
  ELSAN: elsanLogo,
  APRIL: aprilLogo,
  BENEFIZ: benefizLogo,
  JETRANSPORTE: jeTransporteLogo,
  SIDECARE: sidecareLogo,
  AGLAE: aglaeLogo,
  PREVOIR: prevoirLogo,
  MINDDAY: mindDayLogo,
};

export const partnerBackgrounds = {
  ELSAN: elsanBackground,
  BENEFIZ: benefizBackground,
  JETRANSPORTE: jeTransporteBackground,
  SIDECARE: sidecareBackground,
};

export const screenPartsImages = {
  WAIT_TIME_HEADER_OUTER_BLOB: WaitTimeHeaderOuterBlob,
  WAIT_TIME_HEADER_INNER_BLOB: WaitTimeHeaderInnerBlob,
  WAIT_TIME_HEADER_BLOB: WaitTimeHeaderBlob,
  WAIT_TIME_HEADER_MASK: WaitTimeHeaderBlobMask,
};
