/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';

// Cells
import FiveColumnButtonCell, {
  IFiveColumnButtonCell,
} from './cells/fiveColumnButtonCell';
import FiveColumnCheckboxCell, {
  IFiveColumnCheckBoxCell,
} from './cells/fiveColumnCheckBoxCell';
import FiveColumnDateTimePickerCell, {
  IFiveColumnDateTimePickerCell,
} from './cells/fiveColumnDateTimePickerCell';
import FiveColumnImageCell, {
  IFiveColumnImageCell,
} from './cells/fiveColumnImageCell';
import FiveColumnPickerCell, {
  IFiveColumnPickerCell,
} from './cells/fiveColumnPickerCell';
import FiveColumnRadioButtonCell, {
  IFiveColumnRadioButtonCell,
} from './cells/fiveColumnRadioButtonCell';
import FiveColumnRationedWidthTextPairCell, {
  IFiveColumnRationedWidthTextPairCell,
} from './cells/fiveColumnRationedWidthTextPairCell';
import FiveColumnShapeCell, {
  IFiveColumnShapeCell,
} from './cells/fiveColumnShapeCell';
import FiveColumnTagCell, {
  IFiveColumnTagCell,
} from './cells/fiveColumnTagCell';
import FiveColumnTextCell, {
  IFiveColumnTextCell,
} from './cells/fiveColumnTextCell';
import FiveColumnToggleCell, {
  IFiveColumnToggleCell,
} from './cells/fiveColumnToggleCell';
import FiveColumnUserPortraitCell, {
  IFiveColumnUserPortraitCell,
} from './cells/fiveColumnUserPortraitCell';
import FiveColumnBadgeCell, {
  IFiveColumnBadgeCell,
} from './cells/fiveColumnBadgeCell';

const cellComponents: Record<string, ({ data }: any) => JSX.Element> = {
  button: FiveColumnButtonCell,
  checkbox: FiveColumnCheckboxCell,
  date_time_picker: FiveColumnDateTimePickerCell,
  image: FiveColumnImageCell,
  picker: FiveColumnPickerCell,
  radio_button: FiveColumnRadioButtonCell,
  rationed_width_text_pair: FiveColumnRationedWidthTextPairCell,
  shape: FiveColumnShapeCell,
  tag: FiveColumnTagCell,
  text: FiveColumnTextCell,
  toggle: FiveColumnToggleCell,
  user_portrait: FiveColumnUserPortraitCell,
  badge: FiveColumnBadgeCell,
};

type IFiveColumnCell =
  | IFiveColumnButtonCell
  | IFiveColumnCheckBoxCell
  | IFiveColumnDateTimePickerCell
  | IFiveColumnImageCell
  | IFiveColumnPickerCell
  | IFiveColumnRadioButtonCell
  | IFiveColumnRationedWidthTextPairCell
  | IFiveColumnShapeCell
  | IFiveColumnTagCell
  | IFiveColumnTextCell
  | IFiveColumnToggleCell
  | IFiveColumnUserPortraitCell
  | IFiveColumnBadgeCell;

export interface IFiveColumnViewColumn {
  content: IFiveColumnCell;
  verticalAlignment?: IVerticalAlignment;
  verticalOffset?: number;
}

type IVerticalAlignment = 'center' | 'top' | 'bottom' | 'fill';

interface IFiveColumnCellProps {
  data?: IFiveColumnViewColumn;
  center?: boolean;
}

const FiveColumnCell = ({ data, center, ...props }: IFiveColumnCellProps) => {
  const CellComponent = data?.content.type
    ? cellComponents[data?.content.type]
    : null;

  const getFlexboxVerticalAlignment = (
    verticalAlignment?: IVerticalAlignment
  ) => {
    switch (verticalAlignment) {
      case 'center':
        return 'center';
      case 'top':
        return 'flex-start';
      case 'bottom':
        return 'flex-end';
      case 'fill':
        return 'stretch';
      default:
        return 'center';
    }
  };

  const SURROUNDING_STYLE: CSSObject = {
    display: 'flex',
    alignItems: getFlexboxVerticalAlignment(data?.verticalAlignment),
    alignSelf: getFlexboxVerticalAlignment(data?.verticalAlignment),

    marginTop: data?.verticalOffset || 0,
    height: data?.verticalAlignment === 'fill' ? 'auto' : 0,
  };

  const CENTER_STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
  };

  return (
    <div css={center ? CENTER_STYLE : SURROUNDING_STYLE} {...props}>
      {CellComponent && data && <CellComponent data={data.content} />}
    </div>
  );
};

export default FiveColumnCell;
