/** @jsx jsx */
import React, { Fragment } from 'react';
import { jsx, CSSObject } from '@emotion/core';
import { IAction } from '../../../../../hooks/useAction';

import AttributedText from '../../../../../components/attributedText';
import Clickable from '../../../../../components/clickable';
import useTheme from '../../../../../hooks/useTheme';
import RemoteImage, {
  IRemoteImage,
} from '../../../../../components/RemoteImage';

interface IFiveColumnButtonCellIcon {
  image: IRemoteImage;
  size: {
    height: number;
    width: number;
  };
  side: string;
}

export interface IFiveColumnButtonCell {
  type: 'button';

  title: string;
  cornerRadius: number;
  color: string;
  highlightColor: string;
  minimumHeight: number;

  padding: {
    top: number;
    bottom: number;
    leading: number;
    trailing: number;
  };

  iconTitleGutter: number;
  icon?: IFiveColumnButtonCellIcon;
  edgeIcon?: IFiveColumnButtonCellIcon;

  action: IAction;
}

interface IFiveColumnButtonCellProps {
  data: IFiveColumnButtonCell;
}

const FiveColumnButtonCell = ({ data }: IFiveColumnButtonCellProps) => {
  const theme = useTheme();

  const BUTTON_STYLE: CSSObject = {
    height: 'auto',
    borderRadius: data.cornerRadius,
    minHeight: data.minimumHeight,
    paddingTop: data.padding.top,
    paddingBottom: data.padding.bottom,
    paddingLeft: data.padding.leading,
    paddingRight: data.padding.trailing,

    backgroundColor: theme.resolveColor(data.color),

    '&[active]': {
      backgroundColor: theme.resolveColor(data.highlightColor),
    },
  };

  const ICON_STYLE: CSSObject = {
    width: data.icon?.size?.width,
    height: data.icon?.size?.height,
    order: data.icon?.side === 'RIGHT' ? 1 : 0,
    marginLeft: data.icon?.side === 'RIGHT' ? data.iconTitleGutter : 0,
    marginRight: data.icon?.side === 'LEFT' ? data.iconTitleGutter : 0,
  };

  const EDGE_ICON_STYLE: CSSObject = {
    position: 'absolute',
    ...(data.edgeIcon?.side === 'RIGHT'
      ? { right: data.padding.trailing }
      : { left: data.padding.leading }),
    width: data.edgeIcon?.size?.width,
    height: data.edgeIcon?.size?.height,
  };

  return (
    <Fragment>
      <Clickable styleAs='button' action={data.action} css={BUTTON_STYLE}>
        <RemoteImage {...data.edgeIcon?.image} css={EDGE_ICON_STYLE} />
        <RemoteImage {...data.icon?.image} css={ICON_STYLE} />
        <AttributedText text={data.title} />
      </Clickable>
    </Fragment>
  );
};

export default FiveColumnButtonCell;
