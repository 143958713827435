/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import useLanguage from '../../../../../hooks/useLanguage';
import Clickable from '../../../../../components/clickable';
import LocalImage from '../../../../../components/localImage';

interface IUploadButton {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const UploadButton = ({ onChange }: IUploadButton) => {
  const language = useLanguage();

  return (
    <Clickable
      renderAs='label'
      scale
      css={{
        width: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
      }}
      defaultHandler
    >
      <LocalImage
        src='ICON32_CAMERA'
        tint='PRIMARY'
        css={{ width: 30, height: 30 }}
        alt={language.get('messaging_photo_upload_icon')}
      />
      <input
        type='file'
        accept='image/*'
        css={{ display: 'none' }}
        onChange={onChange}
      />
    </Clickable>
  );
};
