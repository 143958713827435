/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';
import { ICardItem } from '../cardItem';

interface ISpaceCardItem {
  data: ICardItem<{
    height: number;
  }>;
}

const SpaceCardItem = ({ data }: ISpaceCardItem) => {
  const STYLE: CSSObject = {
    height: data.height,
  };

  return <div css={STYLE} />;
};

export default SpaceCardItem;
