/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import useStatefulInput from '../../../../../hooks/useStatefulInput';
import useAction, { IAction } from '../../../../../hooks/useAction';
import Toggle from '../../../../../components/toggle';
import useTheme from '../../../../../hooks/useTheme';

export interface IFiveColumnToggleCell {
  type: 'toggle';
  inputId: string;
  active: boolean;
  enabled: boolean;
  inactiveColor: string;
  activeColor: string;

  action: IAction;
}

interface IFiveColumnToggleCellProps {
  data: IFiveColumnToggleCell;
}

const FiveColumnToggleCell = ({ data }: IFiveColumnToggleCellProps) => {
  const { inputValue, setInputValue } = useStatefulInput<boolean>({
    inputId: data.inputId,
    initialValue: data.active,
  });

  const { resolveColor } = useTheme();
  const handleAction = useAction();

  return (
    <Toggle
      checked={!!inputValue}
      enabled={data.enabled}
      activeColor={resolveColor(data.activeColor)}
      inactiveColor={resolveColor(data.inactiveColor)}
      onChange={(e) => {
        e.stopPropagation();
        handleAction(data.action);
        setInputValue(!!e.target.checked);
      }}
    />
  );
};

export default FiveColumnToggleCell;
