/** @jsx jsx */
import React, { useEffect, useRef } from 'react';
import { jsx } from '@emotion/core';
import useStatefulInput from '../../../../../hooks/useStatefulInput';
import useAction, { IAction } from '../../../../../hooks/useAction';

import CheckBox from '../../../../../components/checkBox';

export interface IFiveColumnCheckBoxCell {
  type: 'checkbox';

  selected: false;
  inputId: string;

  action: IAction;
}

interface IFiveColumnCheckBoxCellProps {
  data: IFiveColumnCheckBoxCell;
}

const FiveColumnCheckBoxCell = ({ data }: IFiveColumnCheckBoxCellProps) => {
  const { inputValue, setInputValue } = useStatefulInput<boolean>({
    inputId: data.inputId,
    initialValue: data.selected,
  });

  const handleAction = useAction();
  const hasMountedRef = useRef(false);
  const actionRef = useRef(data.action);

  useEffect(() => {
    if (hasMountedRef.current) {
      handleAction(actionRef.current);
    }

    hasMountedRef.current = true;
  }, [inputValue, handleAction]);

  return (
    <CheckBox
      checked={!!inputValue}
      onChange={(e) => {
        setInputValue(!!e.target.checked);
      }}
    />
  );
};

export default FiveColumnCheckBoxCell;
