/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import useTheme from '../../../../../hooks/useTheme';
import useLanguage from '../../../../../hooks/useLanguage';
import { IConversationInputStatus } from './conversationInput';

interface IMessageInput {
  status: IConversationInputStatus;
  message: string;
  withAttachments: boolean;

  onChange: (value: string) => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
}

export const MessageInput = ({
  status,
  message,
  withAttachments,
  onChange,
  onKeyDown,
}: IMessageInput) => {
  const language = useLanguage();
  const { color } = useTheme();

  return (
    <textarea
      placeholder={language.get('async_messaging_compose_text_placeholder')}
      css={{
        flex: 1,
        padding: 10,
        paddingLeft: 20,
        marginLeft: withAttachments ? 0 : 20,
        fontSize: '14px',
        backgroundColor: color.SEARCH_FIELD_BG,

        borderRadius: '8px',
        border: `1px solid ${
          status === 'ERROR' ? color.ALERT : color.DIVIDER_LINE
        }`,

        '::placeholder': {
          color: color.TEXT,
        },

        MozAppearance: 'none',
        WebkitAppearance: 'none',
        resize: 'none',
      }}
      value={message}
      onChange={(e) => {
        e.stopPropagation();
        onChange(e.currentTarget.value);
      }}
      onKeyDown={onKeyDown}
    />
  );
};
