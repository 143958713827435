import { IScreen } from '../views/screen/screen';
import { IScreenState, IScreenSource } from '../contexts/screenContext';
import { IAction } from '../hooks/useAction';

interface ISetStateDispatch {
  type: 'SET_STATE';
  payload: IScreenState;
}

interface ISetExternalActionDispatch {
  type: 'SET_EXTERNAL_ACTION';
  payload: IAction;
}

interface IUpdateScreenStateDispatch {
  type: 'UPDATE_SCREEN_STATE';
  payload: {
    screen?: IScreen;
    onLoadActions?: Array<IAction>;
    source: IScreenSource;
  };
}

interface IClearOnLoadActionsDispatch {
  type: 'CLEAR_ONLOAD_ACTIONS';
}

interface ISetPartStateDispatch {
  type: 'SET_PART_STATE';
  payload: IScreenState['partStates'];
}

interface ISetInputStateDispatch {
  type: 'SET_INPUT_STATE';
  payload: {
    inputState: IScreenState['inputStates'];
    keepLastOnNull?: boolean;
  };
}

interface ISetHiddenIdsDispatch {
  type: 'SET_HIDDEN_IDS';
  payload: IScreenState['hiddenIds'];
}

export function screenContextReducer(
  state: IScreenState,
  dispatch:
    | ISetStateDispatch
    | ISetExternalActionDispatch
    | IUpdateScreenStateDispatch
    | IClearOnLoadActionsDispatch
    | ISetPartStateDispatch
    | ISetInputStateDispatch
    | ISetHiddenIdsDispatch
) {
  switch (dispatch.type) {
    case 'SET_STATE':
      return dispatch.payload;
    case 'SET_EXTERNAL_ACTION':
      return {
        ...state,
        onLoadActions: [dispatch.payload],
      };
    case 'UPDATE_SCREEN_STATE':
      let hiddenIds: Array<string>;
      switch (state.screen?.reloadConfig?.preserveHiddenIds) {
        case 'keep':
          hiddenIds = [...state.hiddenIds];
          break;
        case 'merge':
          hiddenIds = [
            ...state.hiddenIds,
            ...(dispatch.payload.screen?.hiddenIds || []),
          ];
          break;
        default:
          hiddenIds = dispatch.payload.screen?.hiddenIds || [];
          break;
      }
      return {
        ...state,
        source: dispatch.payload.source,
        screen: dispatch.payload.screen && {
          ...dispatch.payload.screen,
          parts: dispatch.payload.screen.parts,
        },
        onLoadActions: dispatch.payload.onLoadActions,
        hiddenIds: hiddenIds.filter(
          (item, pos) => hiddenIds.indexOf(item) === pos
        ),

        // Input states are always reset.
        // Part states are only retained if reloadConfig.preserveHiddenIds in the new screen is true.
        inputStates: {},
        ...(!dispatch.payload.screen?.reloadConfig?.preserveHiddenIds && {
          partStates: {},
        }),
      };
    case 'CLEAR_ONLOAD_ACTIONS':
      return {
        ...state,
        onLoadAction: [],
      };
    case 'SET_PART_STATE':
      return {
        ...state,
        partStates: {
          ...state.partStates,
          ...dispatch.payload,
        },
      };
    case 'SET_INPUT_STATE':
      // Don't replace the state with a null value if keepLastOnNull is true.
      const inputId = Object.keys(dispatch.payload.inputState)[0];
      const shouldUpdateState = !(
        dispatch.payload.keepLastOnNull &&
        state.inputStates[inputId]?.value &&
        dispatch.payload.inputState[inputId]?.value === null
      );

      return {
        ...state,
        inputStates: {
          ...state.inputStates,
          ...(shouldUpdateState && dispatch.payload.inputState),
        },
      };
    case 'SET_HIDDEN_IDS':
      return {
        ...state,
        hiddenIds: dispatch.payload,
      };
    default:
      return state;
  }
}
