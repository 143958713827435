import { useContext, useMemo } from 'react';
import { AppConfigContext } from '../contexts/appConfigContext';
import useEventCallback from './useEventCallback';

const useLanguage = () => {
  const { appLanguage, inferredLanguageKey, selectedLanguage } =
    useContext(AppConfigContext);

  const get = useEventCallback((key: string, byLanguage?: string): string => {
    const lang = byLanguage || selectedLanguage || inferredLanguageKey;
    return (appLanguage[lang] && appLanguage[lang][key]) || key;
  });

  const getTemplated = useEventCallback(
    (
      key: string,
      valueMap: { [x: string]: string | number },
      byLanguage?: string
    ) => {
      const text = get(key, byLanguage);

      try {
        let result = text;
        const valueMapValues = Object.values(valueMap);
        const valueMapKeys = Object.keys(valueMap);

        valueMapKeys.forEach((valueMapkey, index) => {
          result = text.replace(
            new RegExp(`\\[${valueMapkey}\\]`, 'g'),
            valueMapValues[index] as string
          );
        });

        return result;
      } catch (e) {
        return text;
      }
    }
  );

  const getCurrentLanguage = useEventCallback(() => {
    return selectedLanguage || inferredLanguageKey;
  });

  return useMemo(
    () => ({
      get,
      getTemplated,
      getCurrentLanguage,
    }),
    [get, getTemplated, getCurrentLanguage]
  );
};

export default useLanguage;
