export type ICountry = 'SE' | 'NO' | 'GB' | 'FR';
export type IDomain =
  | 'kry.se'
  | 'kry.no'
  | 'livi.co.uk'
  | 'livi.fr'

  // Universal docker-compose domains.
  | 'localhost.se'
  | 'localhost.no'
  | 'localhost.co.uk'
  | 'localhost.fr';

const getProtocol = () => {
  return `${window.location.protocol}//`;
};

const getDomain = (): IDomain => {
  const host = window.location.host.toLowerCase();
  const envCountry = process.env.REACT_APP_FORCE_COUNTRY;

  // Getting the domain from a url is surprisingly difficult, given multiple (or no) subdomains,
  // second-level domains etc, it's just not worth the hassle.
  switch (true) {
    case host.includes('kry.se') || envCountry === 'SE':
      return 'kry.se';
    case host.includes('kry.no') || envCountry === 'NO':
      return 'kry.no';
    case host.includes('kry.de') || envCountry === 'DE':
      return 'livi.co.uk';
    case host.includes('livi.fr') || envCountry === 'FR':
      return 'livi.fr';

    // Universal docker-compose domains.
    case host.includes('localhost.se'):
      return 'localhost.se';
    case host.includes('localhost.no'):
      return 'localhost.no';
    case host.includes('localhost.co.uk'):
      return 'localhost.co.uk';
    case host.includes('localhost.fr'):
      return 'localhost.fr';

    default:
      return 'kry.se';
  }
};

const getCountry = (): ICountry => {
  const domain = getDomain();

  switch (domain) {
    case 'kry.se':
    case 'localhost.se':
      return 'SE';
    case 'kry.no':
    case 'localhost.no':
      return 'NO';
    case 'livi.co.uk':
    case 'localhost.co.uk':
      return 'GB';
    case 'livi.fr':
    case 'localhost.fr':
      return 'FR';

    default:
      return 'SE';
  }
};

const isKry = () => {
  switch (getCountry()) {
    case 'GB':
    case 'FR':
      return false;
    default:
      return true;
  }
};

const isLivi = () => {
  switch (getCountry()) {
    case 'GB':
    case 'FR':
      return true;
    default:
      return false;
  }
};

const isDevEnvironment = () => {
  return process.env.NODE_ENV !== 'production';
};

const isProdEnvironment = () => {
  const host = window.location.host.toLowerCase();
  return (
    !isDevEnvironment() &&
    !host.includes('test') &&
    !host.includes('localhost') &&
    !host.includes('amplifyapp')
  );
};

const getApiUrlForSubdomain = (subdomain: string, path: string) => {
  return `${getProtocol()}${subdomain}.${getDomain()}${path}`;
};

const getApiUrlFrance = (path: string) => {
  return `${getProtocol()}${getDomain()}${path}`;
};

const environment = {
  DOMAIN: getDomain(),
  COUNTRY: getCountry(),

  IS_KRY: isKry(),
  IS_LIVI: isLivi(),

  IS_DEV: isDevEnvironment(),
  IS_PROD: isProdEnvironment(),

  getApiUrlForSubdomain,
  getApiUrlFrance,
};

export default environment;
