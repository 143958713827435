import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import sessionStorage from '../utils/sessionStorage';

interface IOptions {
  locationAware?: boolean;
}

const useSessionStorage = <T>(
  storageKey: string,
  options?: IOptions
): [() => T | undefined, (state: T) => void, () => void] => {
  const location = useLocation();
  const history = useHistory();

  const key = options?.locationAware
    ? `${location.key}_${storageKey}`
    : storageKey;
  const navigationAction = history.action;

  const get = useCallback(() => {
    return navigationAction !== 'POP'
      ? undefined
      : sessionStorage.get<T>(key) || undefined;
  }, [key, navigationAction]);

  const set = useCallback(
    (state: T) => {
      sessionStorage.set(key, state);
    },
    [key]
  );

  const remove = useCallback(() => {
    sessionStorage.remove(key);
  }, [key]);

  return [get, set, remove];
};

export default useSessionStorage;
