import { useContext } from 'react';
import useNavigation from '../../useNavigation';
import { FlexContext } from '../../../contexts/flexContext';
import { IView } from '../../../views/view';
import { IFlexNode } from '../../../views/flex/flexNode';
import { IStructuredSnowplowEvent } from '../../useSnowplowTracker';

export interface IFlexNavigateForward {
  type: 'forward';
  node: IFlexNode;
  snowplowEvent?: IStructuredSnowplowEvent;
}

const useFlexNavigateForward = () => {
  const flexContext = useContext(FlexContext);
  const navigation = useNavigation();

  const handleFlexNavigateForward = (flexNavigate: IFlexNavigateForward) => {
    const view: IView = {
      type: 'FLEX',
      source: {
        node: {
          ...flexNavigate.node,
          memory: flexNavigate.node.memory || flexContext.node?.memory,
        },
      },
      preventBack: !flexContext.node?.revisitable,
    };

    // If this is the initial navigation, part of actually starting the flow,
    // we want to just replace the empty flex route, rather than navigating to a new one.
    if (!flexContext.node) {
      navigation.replaceWithModal(view);
    } else {
      navigation.push(view);
    }
  };

  return handleFlexNavigateForward;
};

export default useFlexNavigateForward;
