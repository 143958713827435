import { useContext } from 'react';
import { ScreenContext } from '../contexts/screenContext';
import { logError } from '../utils/remoteLogger';

// Complex input types.
import { IPhoneInputValue } from '../views/screen/parts/phoneInputPart';

interface IValidatorRegEx {
  type: 'reg_ex';
  pattern: string;
  errorMessage?: string;
}

interface IValidatorBoolean {
  type: 'boolean';
  value: boolean;
  errorMessage?: string;
}

interface IValidatorGreaterThan {
  type: 'greater_than';
  minValue: number;
  errorMessage?: string;
}

interface IValidatorLowerThan {
  type: 'lower_than';
  maxValue: number;
  errorMessage?: string;
}

interface IValidatorLongRequired {
  type: 'long_required';
  errorMessage?: string;
}

interface IValidationResult {
  isValid: boolean;
  errorMessage?: string;
}

export type IInputValidator =
  | IValidatorRegEx
  | IValidatorBoolean
  | IValidatorGreaterThan
  | IValidatorLowerThan
  | IValidatorLongRequired;

const isStringInputValue = (value: unknown): value is string => {
  return typeof value === 'string';
};

const isNumberInputValue = (value: unknown): value is number => {
  return typeof value === 'number';
};

const isBooleanInputValue = (value: unknown): value is boolean => {
  return typeof value === 'boolean';
};

const isArrayInputValue = (value: unknown): value is Array<unknown> => {
  return Array.isArray(value);
};

const isPhoneInputValue = (value: unknown): value is IPhoneInputValue => {
  return typeof value === 'object' && value !== null && 'phoneNumber' in value;
};

const useStatefulInput = <T>({
  inputId,
  initialValue,
  validators,
  keepLastOnNull,
}: {
  inputId: string;
  initialValue: T;
  validators?: Array<IInputValidator>;
  keepLastOnNull?: boolean;
}): {
  inputValue: T;
  isValid: boolean;
  isDirty: boolean;
  isTouched: boolean;
  errorMessage?: string;
  setInputValue: (value: T) => void;
  setIsTouched: () => void;
} => {
  const screenContext = useContext(ScreenContext);
  const screenInputState = screenContext.inputStates[inputId];

  const validateValue = (value: unknown): IValidationResult => {
    let offendingValidator: IInputValidator | undefined;

    validators?.forEach((validator) => {
      // If the input is already considered invalid, just skip the iteration.
      if (offendingValidator) {
        return;
      }

      switch (validator.type) {
        case 'reg_ex':
          // Validate string values.
          // If the provided regexp is invalid, flag the validator
          // as valid as to not prevent form submission.
          if (isStringInputValue(value)) {
            try {
              if (!new RegExp(validator.pattern).test(value)) {
                offendingValidator = validator;
              }
              return;
            } catch {
              logError(
                'useStatefulInput',
                `Invalid validator regex for input ${inputId}: ${validator.pattern}`
              );
              return;
            }
          }

          // Validate phone input values.
          // If the provided regexp is invalid, flag the validator
          // as valid as to not prevent form submission.
          if (isPhoneInputValue(value)) {
            try {
              if (
                !new RegExp(validator.pattern).test(
                  `${value.countryCode}${value.phoneNumber}`
                )
              ) {
                offendingValidator = validator;
              }
              return;
            } catch {
              logError(
                'useStatefulInput',
                `Invalid validator regex for input ${inputId}: ${validator.pattern}`
              );
              return;
            }
          }

          offendingValidator = validator;
          break;

        case 'boolean':
          if (isBooleanInputValue(value)) {
            if (value !== validator.value) {
              offendingValidator = validator;
            }
            return;
          }

          offendingValidator = validator;
          break;

        case 'lower_than':
          // Validate number values.
          if (isNumberInputValue(value)) {
            if (value > validator.maxValue) {
              offendingValidator = validator;
            }
            return;
          }

          // Validate array values.
          if (isArrayInputValue(value)) {
            if (value.length > validator.maxValue) {
              offendingValidator = validator;
            }
            return;
          }

          offendingValidator = validator;
          break;

        case 'greater_than':
          // Validate number values.
          if (isNumberInputValue(value)) {
            if (value < validator.minValue) {
              offendingValidator = validator;
            }
            return;
          }

          // Validate array values.
          if (isArrayInputValue(value)) {
            if (value.length < validator.minValue) {
              offendingValidator = validator;
            }
            return;
          }

          offendingValidator = validator;
          break;

        case 'long_required':
          if (isNumberInputValue(value)) {
            if (!value) {
              offendingValidator = validator;
            }
            return;
          }

          offendingValidator = validator;
          break;
      }
    });

    return {
      isValid: !offendingValidator,
      errorMessage: offendingValidator?.errorMessage,
    };
  };

  // Set initial input value to screen state
  if (inputId && screenInputState?.value === undefined) {
    const validationResult = validateValue(initialValue);

    screenContext.setInputState(
      {
        [inputId]: {
          value: initialValue,
          isValid: validationResult.isValid,
          isDirty: false,
          isTouched: false,
          errorMessage: validationResult.errorMessage,
        },
      },
      keepLastOnNull
    );
  }

  const setInputValue = (value: T) => {
    if (inputId && screenInputState?.value !== value) {
      const validationResult = validateValue(value);

      screenContext.setInputState({
        [inputId]: {
          ...screenInputState,

          value,
          isDirty: true,
          isValid: validationResult.isValid,
          errorMessage: validationResult.errorMessage,
        },
      });
    }
  };

  const setIsTouched = () => {
    screenContext.setInputState({
      [inputId]: {
        ...screenInputState,
        isTouched: true,
      },
    });
  };

  return {
    inputValue: (screenInputState?.value !== undefined
      ? screenInputState?.value
      : initialValue) as T,
    isValid: screenInputState?.isValid,
    isDirty: screenInputState?.isDirty,
    isTouched: screenInputState?.isTouched,
    errorMessage: screenInputState?.errorMessage,
    setInputValue,
    setIsTouched,
  };
};

export default useStatefulInput;
