/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { ICardItem } from '../cardItem';
import { IRemoteImage } from '../../../../../components/RemoteImage';
import { IAction } from '../../../../../hooks/useAction';
import MapSnapshotPart, { IMapSnapshotPart } from '../../mapSnapshotPart';

interface IMapCardItem {
  data: ICardItem<{
    visibleMapHeight: number;
    gradientHeight: number;
    pinImageVerticalOffset: number;
    coordinateDelta: number;
    pinImage: IRemoteImage;

    lat: number;
    lng: number;

    action?: IAction;
  }>;
}

const MapCardItem = ({ data }: IMapCardItem) => {
  return <MapSnapshotPart {...(data as IMapSnapshotPart)} />;
};

export default MapCardItem;
