/** @jsx jsx */
import React, { useContext } from 'react';
import { jsx, CSSObject } from '@emotion/core';
import Part, { IMargins, IPart } from '../part';
import useTheme from '../../../hooks/useTheme';
import { ScreenContext } from '../../../contexts/screenContext';
import BottomSticky from '../../../components/bottomSticky';

export interface IBottomPanel {
  parts: Array<IPart>;
  bgColor: string;
  borderColor: string;
  margins: IMargins;
}

const BottomPanel = ({
  parts,
  bgColor,
  borderColor,
  margins,
}: IBottomPanel) => {
  const { hiddenIds } = useContext(ScreenContext);
  const { resolveColor } = useTheme();

  const STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    backgroundColor: resolveColor(bgColor),
    borderTop: `1px solid ${resolveColor(borderColor)}`,

    paddingTop: margins.top,
    paddingRight: margins.trailing,
    paddingBottom: margins.bottom,
    paddingLeft: margins.leading,
  };

  // Checks whether or not we have actually visible parts.
  // Effectively hides the panel if the parts array is empty or all parts are hidden.
  const hasVisibleParts = !!parts.find((part) => !hiddenIds.includes(part.id));

  return (
    <BottomSticky>
      {hasVisibleParts && (
        <div css={STYLE}>
          {parts.map((part: IPart, i: number) => (
            <Part key={i} data={part} />
          ))}
        </div>
      )}
    </BottomSticky>
  );
};

export default BottomPanel;
