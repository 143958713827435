/** @jsx jsx */
import React, { useRef, useState } from 'react';
import { jsx, CSSObject } from '@emotion/core';
import useStatefulInput, {
  IInputValidator,
} from '../../../hooks/useStatefulInput';
import { IBasePart, IMargins } from '../part';
import useTheme from '../../../hooks/useTheme';
import AttributedText from '../../../components/attributedText';
import useAction, { IAction } from '../../../hooks/useAction';
import InputValidationError from '../components/inputValidationError';
import Clickable from '../../../components/clickable';
import useLanguage from '../../../hooks/useLanguage';
import LocalImage from '../../../components/localImage';

export interface ITextInputPart extends IBasePart {
  type: 'text_input';

  inputId: string;
  inputType: 'text' | 'url' | 'number' | 'phone' | 'email' | 'text_password';

  text: string;
  placeholder: string;

  minHeight: number;
  lineColor: string;
  inactiveLineColor: string;
  activeLineColor: string;

  returnKeyType?: string;
  returnKeyAction?: IAction;

  label?: string;
  validators?: Array<IInputValidator>;

  margins: IMargins;
}

const TextInputPart = (data: ITextInputPart) => {
  const {
    inputValue,
    isValid,
    isDirty,
    isTouched,
    errorMessage,
    setInputValue,
    setIsTouched,
  } = useStatefulInput<string>({
    inputId: data.inputId,
    initialValue: data.text,
    validators: data.validators,
  });

  const [isFocused, setIsFocused] = useState(false);
  const [shouldShowPassword, setShouldShowPassword] = useState(false);

  const { color, resolveColor } = useTheme();
  const language = useLanguage();
  const handleAction = useAction();

  const inputElementRef = useRef<HTMLInputElement | null>();
  const isPassword = data.inputType === 'text_password';
  const shouldShowErrorState = !isValid && isDirty && isTouched;

  const LABEL_STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: data.margins.leading,
    marginRight: data.margins.trailing,
    marginTop: data.margins.top,
    marginBottom: data.margins.bottom,
  };

  const INPUT_WRAPPER_STYLE: CSSObject = {
    position: 'relative',
  };

  const INPUT_STYLE: CSSObject = {
    width: isPassword ? 'calc(100% - 32px)' : '100%',
    fontSize: 17,
    padding: '6px 0 8px',
    height: '26px',
    borderStyle: 'none none solid',
    borderRadius: 0,

    '::placeholder': {
      color: color.INPUT_PLACEHOLDER,
    },
  };

  const INPUT_BORDER_STYLE: CSSObject = {
    height: 2,
    backgroundColor: isFocused
      ? shouldShowErrorState
        ? color.ALERT
        : resolveColor(data.activeLineColor)
      : shouldShowErrorState
      ? color.ALERT_SECONDARY_PRESS
      : resolveColor(data.inactiveLineColor),
  };

  const TOGGLE_PASSWORD_ICON_STYLE: CSSObject = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    position: 'absolute',
    right: 0,
    bottom: 6,
    width: 32,
    height: 32,
    padding: 3,

    img: {
      width: '100%',
    },
  };

  const getNativeInputType = (type: ITextInputPart['inputType']) => {
    switch (type) {
      case 'phone':
        return 'tel';
      case 'text_password':
        return shouldShowPassword ? 'text' : 'password';
      default:
        return type;
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShouldShowPassword((prevState) => !prevState);
    inputElementRef.current?.focus();
  };

  return (
    <label css={LABEL_STYLE}>
      {/* Input label text. */}
      <AttributedText text={data.label} />

      {/* The input together with the bottom border and the toggle password visibility button. */}
      <div css={INPUT_WRAPPER_STYLE}>
        <input
          ref={(ref) => (inputElementRef.current = ref)}
          formNoValidate
          type={getNativeInputType(data.inputType)}
          value={inputValue}
          placeholder={data.placeholder}
          css={INPUT_STYLE}
          onChange={(e) => {
            e.stopPropagation();
            setInputValue(e.currentTarget.value);
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleAction(data.returnKeyAction);
            }
          }}
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
            setIsTouched();
          }}
          aria-invalid={shouldShowErrorState}
          aria-errormessage={`${data.inputId}-validation-error`}
          // @TODO: enterKeyHint is not supported in React 16, force the property until we update.
          {...{
            enterkeyhint: data.returnKeyType,
          }}
        />

        {/* Bottom border. */}
        <div css={INPUT_BORDER_STYLE} />

        {/* Toggle password visibility button. */}
        {isPassword && (
          <Clickable
            css={TOGGLE_PASSWORD_ICON_STYLE}
            onClick={handleTogglePasswordVisibility}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              setIsFocused(false);
            }}
            aria-pressed={shouldShowPassword}
            aria-label={language.get(
              'accessibility_label_password_toggle_visibility'
            )}
          >
            <LocalImage
              src={
                shouldShowPassword
                  ? 'MATERIAL_VISIBILITY_OFF'
                  : 'MATERIAL_VISIBILITY'
              }
              tint={isFocused ? 'PRIMARY' : 'UI_IDLE'}
              alt=''
            />
          </Clickable>
        )}
      </div>

      {/* Validation error message */}
      {shouldShowErrorState && (
        <InputValidationError inputId={data.inputId} message={errorMessage} />
      )}
    </label>
  );
};

export default TextInputPart;
