/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';

import AttributedText from '../../../components/attributedText';
import Toggle from '../../../components/toggle';
import { IBasePart } from '../part';
import useAction, { IAction } from '../../../hooks/useAction';
import useStatefulPart from '../../../hooks/useStatefulPart';
import useTheme from '../../../hooks/useTheme';

export interface IToggleAttributedLabelPart extends IBasePart {
  type: 'toggle_attributed_label';

  topMargin: number;
  bottomMargin: number;
  leadingMargin: number;
  trailingMargin: number;
  minHeight: number;

  attributedText: string;

  toggleColor: string;
  toggleColorActive: string;
  isActive: false;

  action: IAction;
}

const ToggleAttributedLabelPart = (data: IToggleAttributedLabelPart) => {
  const [state, setState] = useStatefulPart<boolean>(data.id, data.isActive);
  const handleAction = useAction();
  const { resolveColor } = useTheme();

  const STYLE: CSSObject = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    paddingLeft: data.leadingMargin,
    paddingRight: data.trailingMargin,
    paddingTop: data.topMargin,
    paddingBottom: data.bottomMargin,
    minHeight: data.minHeight,
  };

  return (
    <label css={STYLE}>
      <AttributedText text={data.attributedText} />
      <Toggle
        checked={!!state}
        activeColor={resolveColor(data.toggleColorActive)}
        inactiveColor={resolveColor(data.toggleColor)}
        onChange={(e) => {
          e.stopPropagation();
          handleAction(data.action);
          setState(!!e.target.checked);
        }}
      />
    </label>
  );
};

export default ToggleAttributedLabelPart;
