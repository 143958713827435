/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';

import { IBasePart } from '../part';
import useTheme from '../../../hooks/useTheme';
import layout from '../../../constants/layout';

export interface IShadowEdgePart extends IBasePart {
  type: 'shadow_edge';

  height: number;
  cornerRadius: number;
  edgeColor: string;
  direction: 'up' | 'down';
}

const ShadowEdgePart = (data: IShadowEdgePart) => {
  const { color, resolveColor } = useTheme();

  const STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: data.direction === 'down' ? 'flex-start' : 'flex-end',
    height: data.height,
    overflow: 'hidden',
    ':before': {
      content: `''`,
      display: 'block',
      height: data.cornerRadius,
      backgroundColor: resolveColor(data.edgeColor) || color.LOCAL_WHITE,
      boxShadow: layout.DEFAULT_SHADOW_MEDIUM,

      ...(data.direction === 'down' && {
        borderBottomLeftRadius: data.cornerRadius,
        borderBottomRightRadius: data.cornerRadius,
      }),

      ...(data.direction === 'up' && {
        borderTopLeftRadius: data.cornerRadius,
        borderTopRightRadius: data.cornerRadius,
      }),
    },
  };

  return <div css={STYLE} />;
};

export default ShadowEdgePart;
