const sessionStorage = {
  get: <T>(key: string) => {
    const data = window.sessionStorage.getItem(key);

    try {
      return data && (JSON.parse(data) as T);
    } catch {}
  },

  set: (key: string, value: any) => {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  },

  remove: (key: string) => {
    window.sessionStorage.removeItem(key);
  },

  clear: () => {
    window.sessionStorage.clear();
  },
};

export default sessionStorage;
