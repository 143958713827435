import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import useNavigation from '../../hooks/useNavigation';
import View from '../../views/view';

const PublicRoute = (props: RouteProps) => {
  const navigation = useNavigation();
  const currentView = navigation.getCurrentView();

  return currentView ? <View view={currentView} /> : <Route {...props} />;
};

export default PublicRoute;
