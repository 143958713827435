/** @jsx jsx */
import React, { Fragment, useMemo } from 'react';
import { jsx } from '@emotion/core';
import { decamelizeKeys } from 'humps';
import Screen from '../../screen/screen';
import { IBaseFlexNode } from '../flexNode';
import {
  ScreenContextProvider,
  IScreenSource,
} from '../../../contexts/screenContext';
import Navbar from '../../../components/navbar';
import { IFlexHttpCall } from '../../../hooks/useFlexHttpCall';
import { IFlexNavigation } from '../../../hooks/flex/useFlexNavigation';

export interface IPaymentFlexNode extends IBaseFlexNode {
  type: 'payment';
  screenCall: IFlexHttpCall;
  completionNavigation: IFlexNavigation;
}

interface IPaymentFlexNodeProps {
  data: IPaymentFlexNode;
}

const PaymentFlexNode = React.memo(({ data }: IPaymentFlexNodeProps) => {
  const screenSource = useMemo<IScreenSource>(
    () => ({
      ...(data.screenCall && { url: data.screenCall.url }),
      ...(data.screenCall && {
        requestOptions: {
          method: data.screenCall.method,
          body: {
            ...(typeof data.screenCall.body === 'object' && {
              ...decamelizeKeys(data.screenCall.body),
            }),
            ...(typeof data.memory === 'object' && {
              ...decamelizeKeys(data.memory),
            }),
          },
          retryable: data.screenCall.retryable,
        },
      }),

      externalOnLoadAction: data.destinationScreenAction,
      sourceUpdatedAt: data.nodeUpdatedAt,
    }),
    [data]
  );

  return (
    <Fragment>
      <ScreenContextProvider routeSource={screenSource}>
        {/* Navbar */}
        {data.visibleNavbar && <Navbar />}

        <Screen />
      </ScreenContextProvider>
    </Fragment>
  );
});

export default PaymentFlexNode;
