import { IAppStartResponse } from '../contexts/appConfigContext';
import { IAppContextState } from '../contexts/appContext';

interface ISetBootstrapDispatch {
  type: 'SET_APP_STATE';
  payload: {
    userId?: IAppStartResponse['userId'];
    authorizedContent?: IAppStartResponse['authorizedContent'];
  };
}

interface ISetMenuItemBadgeTextDispatch {
  type: 'SET_MENU_ITEM_BADGE_TEXT';
  payload: {
    id: string;
    text?: string;
  };
}

interface ISetIdleDetectionEnabledDispatch {
  type: 'SET_IDLE_DETECTION_ENABLED';
  payload: boolean;
}

export function appContextReducer(
  state: IAppContextState,
  dispatch:
    | ISetBootstrapDispatch
    | ISetMenuItemBadgeTextDispatch
    | ISetIdleDetectionEnabledDispatch
): IAppContextState {
  switch (dispatch.type) {
    case 'SET_APP_STATE':
      const { userId, authorizedContent } = dispatch.payload;
      return {
        userLoggedIn: !!userId,
        idleDetectionEnabled: true,

        appMenuItems: authorizedContent?.tabSpecification.tabs.map((tab) => ({
          id: tab.id,
          title: tab.title,
          path: tab.webPath === '/' ? '/home' : tab.webPath,
          icon: tab.icon,
          selectedIcon: tab.selectedIcon,
          snowplowEvent: tab.snowplowEvent,

          flexNode: tab.content.type === 'flex' ? tab.content.node : undefined,

          badge: {
            textInfo: tab.badge?.textInfo,
            onRouteVisitedEndpoint: tab.badge?.onTabViewedEndpoint,
          },
        })),
      };
    case 'SET_MENU_ITEM_BADGE_TEXT':
      return {
        ...state,
        appMenuItems: state.appMenuItems?.map((item) => ({
          ...item,
          ...(item.id === dispatch.payload.id && {
            badge: {
              ...item.badge,
              resolvedText: dispatch.payload.text,
            },
          }),
        })),
      };
    case 'SET_IDLE_DETECTION_ENABLED':
      return {
        ...state,
        idleDetectionEnabled: dispatch.payload,
      };
    default:
      return state;
  }
}
