/** @jsx jsx */
import React, { useContext, Fragment } from 'react';
import { jsx, CSSObject } from '@emotion/core';
import { decamelizeKeys } from 'humps';
import SurveyNode, { ISurveyNode } from './surveyNode';
import { SurveyContext } from '../../../../../contexts/surveyContext';
import useOverlay from '../../../../../hooks/useOverlay';
import useLanguage from '../../../../../hooks/useLanguage';
import useNavigation from '../../../../../hooks/useNavigation';
import request from '../../../../../utils/request';
import RequestError from '../../../../../utils/requestError';
import useRequestError from '../../../../../hooks/useRequestError';
import Clickable from '../../../../../components/clickable';
import useTheme from '../../../../../hooks/useTheme';
import { IFlexHttpCall } from '../../../../../hooks/useFlexHttpCall';
import LocalImage from '../../../../../components/localImage';
import BottomSticky from '../../../../../components/bottomSticky';
import { IFlexNavigation } from '../../../../../hooks/flex/useFlexNavigation';

export interface ISurvey {
  graph: {
    nodes: Array<ISurveyNode>;
    structure: Array<ISurveyNeighborhood>;
    meta: object;
  };
  saveCall: IFlexHttpCall;
}

export interface ISurveyEdge {
  toNode: string;
  clientCondition: object;
  newView: boolean;
  canGoBack: boolean;
}

export interface ISurveyNeighborhood {
  fromNode?: string;
  toEdges: Array<ISurveyEdge>;
}

export interface ISurveySubmissionResponse {
  navigation: IFlexNavigation;
}

const Survey = () => {
  const { color } = useTheme();
  const language = useLanguage();
  const {
    flexNode,
    nodeStack,
    nodeHasValidAnswer,
    nodeHasValidApiAnswer,
    getNextNodeFrom,
    approximateProgressFromNode,
    registerAnswer,
    submitSurvey,
  } = useContext(SurveyContext);

  const handleRequestError = useRequestError();
  const navigation = useNavigation();
  const overlay = useOverlay();

  const activeNode = nodeStack && nodeStack[nodeStack.length - 1];

  const navigateToNode = (
    fromNode?: ISurveyNode,
    destinationNode?: ISurveyNode
  ) => {
    if (fromNode && destinationNode) {
      // If the destination node is of type "goto",
      // submit the survey while staying on the current node.
      if (destinationNode?.nodeType === 'goto') {
        submitSurvey(destinationNode);
        return;
      }

      // Navigate to the destination node.
      navigation.push({
        type: 'FLEX',
        source: {
          node: {
            ...flexNode,
            toSurveyNode: destinationNode?.name,
            path: [...(fromNode.path || []), destinationNode?.name],
            surveyProgress: approximateProgressFromNode(fromNode),
          },
        },
      });
    }
  };

  const handleNextStep = () => {
    if (activeNode) {
      const destinationNode = getNextNodeFrom(activeNode);

      // The action node is an "invisible" node that triggers a http request.
      // Not until after the the request is successful do we want to proceed.
      if (destinationNode?.nodeType === 'action') {
        // Register an empty answer for the node.
        registerAnswer({
          nodeName: destinationNode.name,
          path: [...(activeNode.path || []), destinationNode?.name],
          property: destinationNode.property,
          answeredAt: Date.now(),
          visitedAt: Date.now(),
        });

        // If the node contains an action, handle it, otherwise move ahead.
        if (destinationNode.action) {
          const nodeAfterInvisible = getNextNodeFrom(destinationNode);

          request(destinationNode.action.endpoint, {
            method: destinationNode.action.method,
            body: decamelizeKeys(destinationNode.action.body),
          })
            .then(() =>
              navigateToNode(
                {
                  ...activeNode,
                  // Append the invisible node to the current path.
                  path: [...(activeNode.path || []), destinationNode.name],
                },
                nodeAfterInvisible
              )
            )
            .catch((error: RequestError) => handleRequestError(error));
          // Request
        } else {
          navigateToNode(activeNode, destinationNode);
        }

        return;
      }

      // If the node has a validation endpoint, validate before navigating.
      if (activeNode.input?.validationEndpoint) {
        nodeHasValidApiAnswer(activeNode)
          .then(() => {
            navigateToNode(activeNode, destinationNode);
          })
          .catch(() => {
            overlay.presentBasicAlert({
              title: language.get('error'),
              message: activeNode.input?.validationErrorMessage,
            });
          });

        return;
      }

      navigateToNode(activeNode, destinationNode);
    }
  };

  const FOOTER_STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    backgroundColor: color.LOCAL_WHITE,
  };

  const FOOTER_BUTTON_STYLE: CSSObject = {
    position: 'relative',
    img: {
      position: 'absolute',
      width: 24,
      right: 20,
    },
  };

  return (
    <Fragment>
      {nodeStack && activeNode && (
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          }}
        >
          {/* Nodes */}
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              flex: 1,
            }}
          >
            {nodeStack.map((node, i) => {
              return (
                <SurveyNode key={node.name} node={node} isSubNode={i > 0} />
              );
            })}
          </div>

          {/* Survey footer */}
          <BottomSticky>
            <div css={FOOTER_STYLE}>
              <Clickable
                styleAs='button'
                css={FOOTER_BUTTON_STYLE}
                onClick={handleNextStep}
                disabled={!nodeHasValidAnswer(activeNode)}
              >
                {activeNode.nextButtonTitle || language.get('next')}
                {activeNode?.shouldShowNextButtonArrow && (
                  <LocalImage
                    src='CHEVRON_RIGHT'
                    tint={
                      nodeHasValidAnswer(activeNode)
                        ? 'LOCAL_WHITE'
                        : 'TEXT_SOFT'
                    }
                    alt=''
                  />
                )}
              </Clickable>
            </div>
          </BottomSticky>
        </div>
      )}
    </Fragment>
  );
};

export default Survey;
