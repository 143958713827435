import React from 'react';
import DebugView from '../views/debug/debugView';

export const DebugContext = React.createContext({});

export const DebugContextProvider = () => {
  return (
    <DebugContext.Provider value={{}}>
      <DebugView />
    </DebugContext.Provider>
  );
};
