/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import useStatefulInput, {
  IInputValidator,
} from '../../../../../hooks/useStatefulInput';
import useAction, { IAction } from '../../../../../hooks/useAction';

import RadioButton from '../../../../../components/radioButton';
import { IBlockBaseItem } from '../blockItem';

export interface IBlockRadioButtonItem extends IBlockBaseItem {
  type: 'radio_button';

  inputId: string;
  selected: false;
  itemId: string;
  validator?: IInputValidator;

  action: IAction;
}

interface IBlockRadioButtonItemProps {
  data: IBlockRadioButtonItem;
}

const BlockRadioButtonItem = ({
  data,
  ...props
}: IBlockRadioButtonItemProps) => {
  const {
    inputValue,
    isValid,
    isDirty,
    isTouched,
    setInputValue,
    setIsTouched,
  } = useStatefulInput<string | null>({
    inputId: data.inputId,
    initialValue: data.selected ? data.itemId : null,
    validators: data.validator ? [data.validator] : undefined,

    keepLastOnNull: true,
  });

  const handleAction = useAction();

  const shouldShowErrorState = !isValid && isDirty && isTouched;

  return (
    <div {...props}>
      <RadioButton
        name={data.inputId}
        checked={inputValue === data.itemId}
        onChange={(e) => {
          e.stopPropagation();
          handleAction(data.action);
          setInputValue(data.itemId);
        }}
        onBlur={() => {
          setIsTouched();
        }}
        isInvalid={shouldShowErrorState}
        aria-invalid={shouldShowErrorState}
        // aria-errormessage={`${data.inputId}-validation-error`}
      />
    </div>
  );
};

export default BlockRadioButtonItem;
