/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';

import { IBasePart } from '../part';
import AttributedText from '../../../components/attributedText';
import Clickable from '../../../components/clickable';
import { IAction } from '../../../hooks/useAction';
import useTheme from '../../../hooks/useTheme';

export interface IImageInfoBannerPart extends IBasePart {
  type: 'image_info_banner';

  imageName: string;
  imageUrl: string;
  imageHeight: number;
  imageWidth: number;
  imageTopAligned: boolean;

  attributedText: string;

  topMargin: number;
  bottomMargin: number;
  leadingMargin: number;
  trailingMargin: number;

  innerButton?: {
    title: string;
    action: IAction;

    buttonColor: string;
    buttonColorActive: string;
    textColor: string;
    textColorActive: string;

    buttonHeight: number;
    enabled: boolean;
  };
}

const ImageInfoBannerPart = (data: IImageInfoBannerPart) => {
  const { font, resolveColor } = useTheme();

  const STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: data.imageTopAligned ? 'flex-start' : 'center',
    justifyContent: 'space-between',

    marginLeft: data.leadingMargin,
    marginRight: data.trailingMargin,
    marginTop: data.topMargin,
    marginBottom: data.bottomMargin,
  };

  const BUTTON_STYLE: CSSObject = {
    backgroundColor: resolveColor(data.innerButton?.buttonColor),
    color: resolveColor(data.innerButton?.textColor),
    height: data.innerButton?.buttonHeight,
    fontFamily: font.FAMILY_MEDIUM,
    minHeight: 30,
    minWidth: 76,
    padding: 8,

    '&[active]': {
      backgroundColor: resolveColor(data.innerButton?.buttonColorActive),
      color: resolveColor(data.innerButton?.textColorActive),
    },
  };

  return (
    <div css={STYLE}>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {/* Image */}
        {data.imageUrl && (
          <img
            css={{
              width: data.imageWidth,
              height: data.imageTopAligned ? 'auto' : data.imageHeight,
              flexShrink: 0,
              marginRight: 10,
              fontSize: 0,
            }}
            src={data.imageUrl}
            alt=''
          />
        )}

        {/* Text */}
        <AttributedText text={data.attributedText} css={{ marginLeft: 10 }} />
      </div>

      {/* Button */}
      {data.innerButton && (
        <Clickable
          styleAs='button'
          disabled={!data.innerButton.enabled}
          action={data.innerButton.action}
          css={BUTTON_STYLE}
        >
          {data.innerButton.title}
        </Clickable>
      )}
    </div>
  );
};

export default ImageInfoBannerPart;
