const callingCodes = [
  { country: 'AF', countryName: 'Afghanistan', code: '93' },
  { country: 'AL', countryName: 'Albania', code: '355' },
  { country: 'DZ', countryName: 'Algeria', code: '213' },
  { country: 'AS', countryName: 'American Samoa', code: '1684' },
  { country: 'AD', countryName: 'Andorra', code: '376' },
  { country: 'AO', countryName: 'Angola', code: '244' },
  { country: 'AI', countryName: 'Anguilla', code: '1264' },
  { country: 'AQ', countryName: 'Antarctica', code: '672' },
  { country: 'AG', countryName: 'Antigua and Barbuda', code: '1268' },
  { country: 'AR', countryName: 'Argentina', code: '54' },
  { country: 'AM', countryName: 'Armenia', code: '374' },
  { country: 'AW', countryName: 'Aruba', code: '297' },
  { country: 'AU', countryName: 'Australia', code: '61' },
  { country: 'AT', countryName: 'Austria', code: '43' },
  { country: 'AZ', countryName: 'Azerbaijan', code: '994' },
  { country: 'BS', countryName: 'Bahamas', code: '1' },
  { country: 'BH', countryName: 'Bahrain', code: '973' },
  { country: 'BD', countryName: 'Bangladesh', code: '880' },
  { country: 'BB', countryName: 'Barbados', code: '1' },
  { country: 'BY', countryName: 'Belarus', code: '375' },
  { country: 'BE', countryName: 'Belgium', code: '32' },
  { country: 'BZ', countryName: 'Belize', code: '501' },
  { country: 'BJ', countryName: 'Benin', code: '229' },
  { country: 'BM', countryName: 'Bermuda', code: '1' },
  { country: 'BT', countryName: 'Bhutan', code: '975' },
  { country: 'BO', countryName: 'Bolivia', code: '591' },
  { country: 'BA', countryName: 'Bosnia and Herzegovina', code: '387' },
  { country: 'BW', countryName: 'Botswana', code: '267' },
  { country: 'BR', countryName: 'Brazil', code: '55' },
  { country: 'IO', countryName: 'Brazil', code: '246' },
  { country: 'VG', countryName: 'British Virgin Islands', code: '1284' },
  { country: 'BN', countryName: 'Brunei', code: '673' },
  { country: 'BG', countryName: 'Bulgaria', code: '359' },
  { country: 'BF', countryName: 'Burkina Faso', code: '226' },
  { country: 'BI', countryName: 'Burundi', code: '257' },
  { country: 'KH', countryName: 'Cambodia', code: '855' },
  { country: 'CM', countryName: 'Cameroon', code: '237' },
  { country: 'CA', countryName: 'Canada', code: '1' },
  { country: 'CV', countryName: 'Cape', code: '238' },
  { country: 'KY', countryName: 'Cayman Islands', code: '1345' },
  { country: 'CF', countryName: 'Central African Republic', code: '236' },
  { country: 'TD', countryName: 'Chad', code: '235' },
  { country: 'CL', countryName: 'Chile', code: '56' },
  { country: 'CN', countryName: 'China', code: '86' },
  { country: 'CX', countryName: 'Christmas Island', code: '61' },
  { country: 'CC', countryName: 'Cocos Islands', code: '891' },
  { country: 'CO', countryName: 'Colombia', code: '57' },
  { country: 'KM', countryName: 'Comoros', code: '269' },
  { country: 'CK', countryName: 'Cook Islands', code: '682' },
  { country: 'CR', countryName: 'Costa Rica', code: '506' },
  { country: 'HR', countryName: 'Croatia', code: '385' },
  { country: 'CU', countryName: 'Cuba', code: '53' },
  { country: 'CW', countryName: 'Curacao', code: '599' },
  { country: 'CY', countryName: 'Cyprus', code: '357' },
  { country: 'CZ', countryName: 'Czech Republic', code: '420' },
  { country: 'CD', countryName: 'Democratic Republic of Congo', code: '243' },
  { country: 'DK', countryName: 'Denmark', code: '45' },
  { country: 'DJ', countryName: 'Djibouti', code: '253' },
  { country: 'DM', countryName: 'Dominica', code: '1' },
  { country: 'DO', countryName: 'Dominican Republic', code: '1' },
  { country: 'TL', countryName: 'East Timor', code: '670' },
  { country: 'EC', countryName: 'Ecuador', code: '593' },
  { country: 'EG', countryName: 'Egypt', code: '20' },
  { country: 'SV', countryName: 'El Salvador', code: '503' },
  { country: 'GQ', countryName: 'Equatorial Guinea', code: '240' },
  { country: 'ER', countryName: 'Eritrea', code: '291' },
  { country: 'EE', countryName: 'Estonia', code: '372' },
  { country: 'ET', countryName: 'Ethiopia', code: '251' },
  { country: 'FK', countryName: 'Falkland Islands', code: '500' },
  { country: 'FO', countryName: 'Faroe Islands', code: '298' },
  { country: 'FJ', countryName: 'Fiji', code: '679' },
  { country: 'FI', countryName: 'Finland', code: '358' },
  { country: 'FR', countryName: 'France', code: '33' },
  { country: 'GF', countryName: 'French Guiana', code: '594' },
  { country: 'PF', countryName: 'French Polynesia', code: '689' },
  { country: 'GA', countryName: 'Gabon', code: '241' },
  { country: 'GM', countryName: 'Gambia', code: '220' },
  { country: 'GE', countryName: 'Georgia', code: '995' },
  { country: 'DE', countryName: 'Germany', code: '49' },
  { country: 'GH', countryName: 'Ghana', code: '233' },
  { country: 'GI', countryName: 'Gibraltar', code: '350' },
  { country: 'GR', countryName: 'Greece', code: '30' },
  { country: 'GL', countryName: 'Greenland', code: '299' },
  { country: 'GP', countryName: 'Guadeloupe', code: '590' },
  { country: 'GU', countryName: 'Guam', code: '1671' },
  { country: 'GT', countryName: 'Guatemala', code: '502' },
  { country: 'GG', countryName: 'Guernsey', code: '44' },
  { country: 'GN', countryName: 'Guinea', code: '224' },
  { country: 'GW', countryName: 'Guinea-Bissau', code: '245' },
  { country: 'GY', countryName: 'Guyana', code: '592' },
  { country: 'HT', countryName: 'Haiti', code: '509' },
  { country: 'HN', countryName: 'Honduras', code: '504' },
  { country: 'HK', countryName: 'Hong Kong', code: '852' },
  { country: 'HU', countryName: 'Hungary', code: '36' },
  { country: 'IS', countryName: 'Iceland', code: '354' },
  { country: 'IN', countryName: 'India', code: '91' },
  { country: 'ID', countryName: 'Indonesia', code: '62' },
  { country: 'IR', countryName: 'Iran', code: '98' },
  { country: 'IQ', countryName: 'Iraq', code: '964' },
  { country: 'IE', countryName: 'Ireland', code: '353' },
  { country: 'IM', countryName: 'Isle of Man', code: '44' },
  { country: 'IL', countryName: 'Israel', code: '972' },
  { country: 'IT', countryName: 'Italy', code: '39' },
  { country: 'CI', countryName: 'Ivory Coast', code: '225' },
  { country: 'JM', countryName: 'Jamaica', code: '1' },
  { country: 'JP', countryName: 'Japan', code: '81' },
  { country: 'JE', countryName: 'Jersey', code: '44' },
  { country: 'JO', countryName: 'Jordan', code: '962' },
  { country: 'KZ', countryName: 'Kazakhstan', code: '7' },
  { country: 'KE', countryName: 'Kenya', code: '254' },
  { country: 'KI', countryName: 'Kiribati', code: '686' },
  { country: 'XK', countryName: 'Kosovo', code: '383' },
  { country: 'KW', countryName: 'Kuwait', code: '965' },
  { country: 'KG', countryName: 'Kyrgyzstan', code: '996' },
  { country: 'LA', countryName: 'Laos', code: '856' },
  { country: 'LV', countryName: 'Latvia', code: '371' },
  { country: 'LB', countryName: 'Lebanon', code: '961' },
  { country: 'LS', countryName: 'Lesotho', code: '266' },
  { country: 'LR', countryName: 'Liberia', code: '231' },
  { country: 'LY', countryName: 'Libya', code: '218' },
  { country: 'LI', countryName: 'Liechtenstein', code: '423' },
  { country: 'LT', countryName: 'Lithuania', code: '370' },
  { country: 'LU', countryName: 'Luxembourg', code: '352' },
  { country: 'MO', countryName: 'Macau', code: '853' },
  { country: 'MK', countryName: 'Macedonia', code: '389' },
  { country: 'MG', countryName: 'Madagascar', code: '261' },
  { country: 'MW', countryName: 'Malawi', code: '265' },
  { country: 'MY', countryName: 'Malaysia', code: '60' },
  { country: 'MV', countryName: 'Maldives', code: '960' },
  { country: 'ML', countryName: 'Mali', code: '223' },
  { country: 'MT', countryName: 'Malta', code: '356' },
  { country: 'MH', countryName: 'Marshall Islands', code: '692' },
  { country: 'MQ', countryName: 'Martinique', code: '596' },
  { country: 'MR', countryName: 'Mauritania', code: '222' },
  { country: 'MU', countryName: 'Mauritius', code: '230' },
  { country: 'YT', countryName: 'Mayotte', code: '262' },
  { country: 'MX', countryName: 'Mexico', code: '52' },
  { country: 'FM', countryName: 'Micronesia', code: '691' },
  { country: 'MD', countryName: 'Moldova', code: '373' },
  { country: 'MC', countryName: 'Monaco', code: '377' },
  { country: 'MN', countryName: 'Mongolia', code: '976' },
  { country: 'ME', countryName: 'Montenegro', code: '382' },
  { country: 'MS', countryName: 'Montserrat', code: '1664' },
  { country: 'MA', countryName: 'Morocco', code: '212' },
  { country: 'MZ', countryName: 'Mozambique', code: '258' },
  { country: 'MM', countryName: 'Myanmar', code: '95' },
  { country: 'NA', countryName: 'Namibia', code: '264' },
  { country: 'NR', countryName: 'Nauru', code: '674' },
  { country: 'NP', countryName: 'Nepal', code: '977' },
  { country: 'NL', countryName: 'Netherlands', code: '31' },
  { country: 'AN', countryName: 'Netherlands Antilles', code: '599' },
  { country: 'NC', countryName: 'New Caledonia', code: '687' },
  { country: 'NZ', countryName: 'New Zealand', code: '64' },
  { country: 'NI', countryName: 'Nicaragua', code: '505' },
  { country: 'NE', countryName: 'Niger', code: '227' },
  { country: 'NG', countryName: 'Nigeria', code: '234' },
  { country: 'NU', countryName: 'Niue', code: '683' },
  { country: 'NF', countryName: 'Norfolk Island', code: '672' },
  { country: 'KP', countryName: 'North Korea', code: '850' },
  { country: 'MP', countryName: 'Northern Mariana Islands', code: '1670' },
  { country: 'NO', countryName: 'Norway', code: '47' },
  { country: 'OM', countryName: 'Oman', code: '968' },
  { country: 'PK', countryName: 'Pakistan', code: '92' },
  { country: 'PW', countryName: 'Palau', code: '680' },
  { country: 'PA', countryName: 'Panama', code: '507' },
  { country: 'PG', countryName: 'Papua New Guinea', code: '675' },
  { country: 'PY', countryName: 'Paraguay', code: '595' },
  { country: 'PE', countryName: 'Peru', code: '51' },
  { country: 'PH', countryName: 'Philippines', code: '63' },
  { country: 'PN', countryName: 'Pitcairn Islands', code: '64' },
  { country: 'PL', countryName: 'Poland', code: '48' },
  { country: 'PT', countryName: 'Portugal', code: '351' },
  { country: 'PR', countryName: 'Puerto Rico', code: '1' },
  { country: 'QA', countryName: 'Qatar', code: '974' },
  { country: 'CG', countryName: 'Republic of the Congo', code: '242' },
  { country: 'RE', countryName: 'Reunion', code: '262' },
  { country: 'RO', countryName: 'Romania', code: '40' },
  { country: 'RU', countryName: 'Russia', code: '7' },
  { country: 'RW', countryName: 'Rwanda', code: '250' },
  { country: 'BL', countryName: 'Saint Barthélemy', code: '590' },
  { country: 'SH', countryName: 'Saint Helena', code: '290' },
  { country: 'KN', countryName: 'Saint Kitts and Nevis', code: '1' },
  { country: 'LC', countryName: 'Saint Lucia', code: '1' },
  { country: 'MF', countryName: 'Saint Martin', code: '590' },
  { country: 'PM', countryName: 'Saint Pierre and Miquelon', code: '508' },
  { country: 'VC', countryName: 'Saint Vincent and the Grenadines', code: '1' },
  { country: 'WS', countryName: 'Samoa', code: '685' },
  { country: 'SM', countryName: 'San Marino', code: '378' },
  { country: 'ST', countryName: 'Sao Tome and Principe', code: '239' },
  { country: 'SA', countryName: 'Saudi Arabia', code: '966' },
  { country: 'SN', countryName: 'Senegal', code: '221' },
  { country: 'RS', countryName: 'Serbia', code: '381' },
  { country: 'SC', countryName: 'Seychelles', code: '248' },
  { country: 'SL', countryName: 'Sierra Leone', code: '232' },
  { country: 'SG', countryName: 'Singapore', code: '65' },
  { country: 'SX', countryName: 'Sint Maarten', code: '1' },
  { country: 'SK', countryName: 'Slovakia', code: '421' },
  { country: 'SI', countryName: 'Slovenia', code: '386' },
  { country: 'SB', countryName: 'Solomon Islands', code: '677' },
  { country: 'SO', countryName: 'Somalia', code: '252' },
  { country: 'ZA', countryName: 'South Africa', code: '27' },
  { country: 'KR', countryName: 'South Korea', code: '82' },
  { country: 'SS', countryName: 'South Sudan', code: '211' },
  { country: 'ES', countryName: 'Spain', code: '34' },
  { country: 'LK', countryName: 'Sri Lanka', code: '94' },
  { country: 'SD', countryName: 'Sudan', code: '249' },
  { country: 'SR', countryName: 'Suriname', code: '597' },
  { country: 'SJ', countryName: 'Svalbard and Jan Mayen', code: '47' },
  { country: 'SZ', countryName: 'Swaziland', code: '268' },
  { country: 'SE', countryName: 'Sweden', code: '46' },
  { country: 'CH', countryName: 'Switzerland', code: '41' },
  { country: 'SY', countryName: 'Syria', code: '963' },
  { country: 'TW', countryName: 'Taiwan', code: '886' },
  { country: 'TJ', countryName: 'Tajikistan', code: '992' },
  { country: 'TZ', countryName: 'Tanzania', code: '255' },
  { country: 'TH', countryName: 'Thailand', code: '66' },
  { country: 'TG', countryName: 'Togo', code: '228' },
  { country: 'TK', countryName: 'Tokelau', code: '690' },
  { country: 'TO', countryName: 'Tonga', code: '676' },
  { country: 'TT', countryName: 'Trinidad and Tobago', code: '1' },
  { country: 'TN', countryName: 'Tunisia', code: '216' },
  { country: 'TR', countryName: 'Turkey', code: '90' },
  { country: 'TM', countryName: 'Turkmenistan', code: '993' },
  { country: 'TC', countryName: 'Turks and Caicos Islands', code: '1' },
  { country: 'TV', countryName: 'Tuvalu', code: '688' },
  { country: 'VI', countryName: 'U.S. Virgin Islands', code: '1' },
  { country: 'UG', countryName: 'Uganda', code: '256' },
  { country: 'UA', countryName: 'Ukraine', code: '380' },
  { country: 'AE', countryName: 'United Arab Emirates', code: '971' },
  { country: 'GB', countryName: 'United Kingdom', code: '44' },
  { country: 'US', countryName: 'United States', code: '1' },
  { country: 'UY', countryName: 'Uruguay', code: '598' },
  { country: 'UZ', countryName: 'Uzbekistan', code: '998' },
  { country: 'VU', countryName: 'Vanuatu', code: '678' },
  { country: 'VA', countryName: 'Vatican', code: '379' },
  { country: 'VE', countryName: 'Venezuela', code: '58' },
  { country: 'VN', countryName: 'Vietnam', code: '84' },
  { country: 'WF', countryName: 'Wallis and Futuna', code: '681' },
  { country: 'EH', countryName: 'Western Sahara', code: '212' },
  { country: 'YE', countryName: 'Yemen', code: '967' },
  { country: 'ZM', countryName: 'Zambia', code: '260' },
  { country: 'ZW', countryName: 'Zimbabwe', code: '263' },
];

export default callingCodes;
