/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { IMessageAttachment } from './conversationInput';
import ImageUploader from '../../../../../components/imageUploader';

interface IAttachments {
  attachments: Array<IMessageAttachment>;
  onClear: (id: string) => void;
}

export const Attachments = ({ attachments, onClear }: IAttachments) => {
  return (
    <div
      css={{
        height: 'auto',
        flex: 1,
        flexBasis: 'auto',
        backgroundColor: 'white',
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 20,
        display: 'flex',
        width: '100%',
        overflowX: 'auto',
        boxSizing: 'border-box',
        marginBottom: 10,
      }}
    >
      {attachments.map((attachment) => (
        <ImageUploader
          height={100}
          width={100}
          marginRight={20}
          key={attachment.referenceId}
          presetThumbnail={attachment.thumbnail}
          onImageCleared={() => onClear(attachment.referenceId)}
        />
      ))}
    </div>
  );
};
