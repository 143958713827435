/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';

import AttributedText from '../../../../../components/attributedText';

export interface IFiveColumnRationedWidthTextPairCell {
  type: 'rationed_width_text_pair';
  leadingText: string;
  trailingText: string;
  leadingTextWidthRatio: number;
  spacing: number;
}

interface IFiveColumnRationedWidthTextPairCellProps {
  data: IFiveColumnRationedWidthTextPairCell;
}

const FiveColumnRationedWidthTextPairCell = ({
  data,
}: IFiveColumnRationedWidthTextPairCellProps) => {
  const spacing = data.spacing ? data.spacing / 2 : 0;
  const ratio = data.leadingTextWidthRatio;

  const WRAPPER_STYLE: CSSObject = {
    padding: '0, 20px',
    display: 'flex',
  };

  const LEADING_STYLE: CSSObject = {
    marginRight: spacing,
    flexGrow: ratio >= 1 ? ratio : 'unset',
  };

  const TRAILING_STYLE: CSSObject = {
    marginLeft: spacing,
    flexGrow: ratio < 1 ? 2 - ratio : 'unset',
  };

  return (
    <div css={WRAPPER_STYLE}>
      <AttributedText css={LEADING_STYLE} text={data.leadingText} />
      <AttributedText css={TRAILING_STYLE} text={data.trailingText} />
    </div>
  );
};

export default FiveColumnRationedWidthTextPairCell;
