import { IFlexStartConfig } from '../views/flex/flex';
import { IFlexState } from '../contexts/flexContext';
import { IFlexMemory, IFlexNode } from '../views/flex/flexNode';

interface ISetFlexStateDispatch {
  type: 'SET_FLEX_STATE';
  payload: IFlexState;
}

interface ISetFlexConfigDispatch {
  type: 'SET_FLEX_CONFIG';
  payload: IFlexStartConfig;
}

interface ISetNodeDispatch {
  type: 'SET_FLEX_NODE';
  payload: {
    node: IFlexNode;
    unwind?: boolean;
  };
}

const getProgress = (state: IFlexState, node?: IFlexNode) => {
  // If the flex flow doesn't have a progress map, exit.
  if (!state.progressMap) {
    return;
  }

  const currentSegment = state.progressMap.segments.find(
    (segment) => segment.nodeId === node?.id
  );

  // If the current node for whatever reason is not present in the progress map,
  // return the current progress value, as to still show the progress bar.
  if (!currentSegment) {
    return state.progress;
  }

  // Handle survey nodes.
  if (node?.type === 'survey') {
    const delta = currentSegment.to - currentSegment.from;
    return currentSegment.from + delta * (node.surveyProgress || 0);
  }

  return currentSegment?.from;
};

const getUpdatedMemory = (node: IFlexNode) => {
  const clientContainer = {
    'client-container': {
      value: {
        nodeId: node.id,
      },
    },
  };

  const updatedMemory: IFlexMemory = {
    ...node.memory,
    flex: {
      ...node.memory?.flex,
      containers: {
        ...node.memory?.flex.containers,
        ...clientContainer,
      },
    },
  };

  return updatedMemory;
};

export function flexContextReducer(
  state: IFlexState,
  dispatch: ISetFlexStateDispatch | ISetFlexConfigDispatch | ISetNodeDispatch
): IFlexState {
  switch (dispatch.type) {
    case 'SET_FLEX_STATE':
      return dispatch.payload;
    case 'SET_FLEX_CONFIG':
      return {
        progressMap: dispatch.payload.progressMap,
        topRightButton: dispatch.payload.topRightButton,
        nodeSequence: [],
      };
    case 'SET_FLEX_NODE':
      return {
        ...state,
        progress: getProgress(state, dispatch.payload.node),

        node: {
          ...dispatch.payload.node,
          memory: getUpdatedMemory(dispatch.payload.node),
        },

        // If the node is being updated due to an unwind, the sequence should remain unchanged.
        ...(!dispatch.payload.unwind && {
          nodeSequence: [...state.nodeSequence, dispatch.payload.node.id],
        }),
      };
    default:
      return state;
  }
}
