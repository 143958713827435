/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';

import { IBasePart } from '../part';
import AttributedText from '../../../components/attributedText';
import Clickable from '../../../components/clickable';
import { IAction } from '../../../hooks/useAction';
import useTheme from '../../../hooks/useTheme';

export interface IAttributedButton extends IBasePart {
  type: 'attributed_button';

  textColor: string;
  textColorActive: string;
  buttonColor: string;
  buttonColorActive: string;

  icon: string;
  iconTint: string;
  enabled: boolean;
  buttonHeight: number;
  hasArrow: boolean;
  leadingMargin: number;
  trailingMargin: number;

  imageUrl: string;
  imageName: string;
  imageRadius: number;

  attributedText: string;

  action: IAction;
}

const AttributedButton = (data: IAttributedButton) => {
  const { resolveColor } = useTheme();

  const WRAPPER_STYLE: CSSObject = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: data.buttonHeight,
    marginLeft: data.leadingMargin || 20,
    marginRight: data.trailingMargin || 20,
    paddingLeft: 20,
    paddingRight: 9,
    paddingTop: 12,
    paddingBottom: 12,
    backgroundColor: resolveColor(data.buttonColor),
    color: resolveColor(data.textColor),
    borderRadius: 7,
    '&[active]': {
      backgroundColor: resolveColor(data.buttonColorActive),
      color: resolveColor(data.textColorActive),
    },
  };

  const LABEL_STYLE: CSSObject = {
    flex: 1,
  };

  const IMAGE_STYLE: CSSObject = {
    width: 'auto',
    height: data.buttonHeight,
    marginRight: 8,
    borderRadius: data.imageRadius || 32,
  };

  return (
    <Clickable action={data.action} css={WRAPPER_STYLE} scale>
      {data.imageUrl && <img css={IMAGE_STYLE} src={data.imageUrl} alt='' />}
      <AttributedText css={LABEL_STYLE} text={data.attributedText} />
    </Clickable>
  );
};

export default AttributedButton;
