/** @jsx jsx */
import React, { useEffect, useRef } from 'react';
import { jsx } from '@emotion/core';
import OT from '@opentok/client';
import { IMeetingTimelineEvent } from '../../../../../../contexts/meetingContext';

const OPENTOK_RELEVANT_ERRORS = {
  [IMeetingTimelineEvent.CLIENT_AUTH_ERROR]: [
    'OT_AUTHENTICATION_ERROR',
    'OT_INVALID_SESSION_ID',
    'OT_PERMISSION_DENIED',
  ],
  [IMeetingTimelineEvent.CLIENT_CONNECTION_ERROR]: [
    'OT_MEDIA_ERR_NETWORK',
    'OT_BADLY_FORMED_RESPONSE',
    'OT_CONNECT_FAILED',
    'OT_CONNECTION_LIMIT_EXCEEDED',
    'OT_EMPTY_RESPONSE_BODY',
    'OT_INVALID_PARAMETER',
    'OT_NOT_CONNECTED',
    'OT_TERMS_OF_SERVICE_FAILURE',
    'OT_INVALID_HTTP_STATUS',
    'OT_XDOMAIN_OR_PARSING_ERROR',
    'OT_ICE_WORKFLOW_FAILED',
    'OT_CREATE_PEER_CONNECTION_FAILED',
    'OT_TIMEOUT',
    'OT_UNEXPECTED_SERVER_RESPONSE',
    'OT_STREAM_CREATE_FAILED',
    'OT_STREAM_LIMIT_EXCEEDED',
  ],
  [IMeetingTimelineEvent.CLIENT_LIFECYCLE_ERROR]: [
    'OT_HARDWARE_UNAVAILABLE',
    'OT_MEDIA_ERR_ABORTED',
    'OT_MEDIA_ERR_DECODE',
    'OT_MEDIA_ERR_SRC_NOT_SUPPORTED',
    'OT_NOT_SUPPORTED',
    'OT_NO_DEVICES_FOUND',
    'OT_NO_VALID_CONSTRAINTS',
    'SET_PROXY_URL_TIMING_ERROR',
    'PROXY_URL_ALREADY_SET_ERROR',
    'OT_CHROME_MICROPHONE_ACQUISITION_ERROR',
    'OT_CONSTRAINTS_NOT_SATISFIED',
    'OT_SET_REMOTE_DESCRIPTION_FAILED',
    'OT_DISCONNECTED',
    'OT_STREAM_DESTROYED',
    'OT_STREAM_NOT_FOUND',
  ],
};

export interface IOpenTokSession {
  apiKey: string;
  sessionId: string;
  token: string;
  proxyUrl: string;
}

interface IOpenTok {
  session: IOpenTokSession;
  disableLocalParticipantAudio: boolean;
  disableLocalParticipantVideo: boolean;
  disconnectHandler: () => void;
  errorHandler: (
    eventType?: IMeetingTimelineEvent,
    eventDetails?: string
  ) => void;
  onConnecting: () => void;
  onRemoteStreamConnected: () => void;
  reportConnection: (sessionId: string, connectionId?: string) => void;
}

const OpenTok = ({
  session,
  disableLocalParticipantAudio,
  disableLocalParticipantVideo,
  disconnectHandler,
  errorHandler,
  onConnecting,
  onRemoteStreamConnected,
  reportConnection,
  ...props
}: IOpenTok) => {
  const sessionRef = useRef<OT.Session>();
  const publisherRef = useRef<OT.Publisher>();
  const localTrackNodeRef = useRef<HTMLDivElement>();
  const remoteTrackNodeRef = useRef<HTMLDivElement>();

  const disconnectHandlerRef = useRef(disconnectHandler);
  const errorHandlerRef = useRef(errorHandler);
  const onConnectingRef = useRef(onConnecting);
  const onRemoteStreamConnectedRef = useRef(onRemoteStreamConnected);
  const reportConnectionRef = useRef(reportConnection);

  const setLocalTracksContainerNode = (node: HTMLDivElement) => {
    localTrackNodeRef.current = node;
  };

  const setRemoteTracksContainerNode = (node: HTMLDivElement) => {
    remoteTrackNodeRef.current = node;
  };

  const tearDownSession = () => {
    if (sessionRef.current) {
      if (publisherRef.current) {
        publisherRef.current.off();
        sessionRef.current.unpublish(publisherRef.current);
        publisherRef.current.destroy();
      }
      sessionRef.current.off();
      sessionRef.current.disconnect();
    }
  };

  const handleError = (e: OT.OTError) => {
    if (OPENTOK_RELEVANT_ERRORS.CLIENT_CONNECTION_ERROR.includes(e.name)) {
      errorHandlerRef.current(
        IMeetingTimelineEvent.CLIENT_CONNECTION_ERROR,
        e.message
      );
    } else if (
      OPENTOK_RELEVANT_ERRORS.CLIENT_LIFECYCLE_ERROR.includes(e.name)
    ) {
      errorHandlerRef.current(
        IMeetingTimelineEvent.CLIENT_LIFECYCLE_ERROR,
        e.message
      );
    } else if (OPENTOK_RELEVANT_ERRORS.CLIENT_AUTH_ERROR.includes(e.name)) {
      errorHandlerRef.current(
        IMeetingTimelineEvent.CLIENT_AUTH_ERROR,
        e.message
      );
    } else {
      // If it is a different type of error, don't sent a timeline event but log it.
      errorHandlerRef.current(undefined, e.message);
    }
  };

  useEffect(() => {
    // Will be set to true if we disconnect from the room by reloading the browser
    // or manually navigating to another route.
    let forcedDisconnect = false;

    const forceDisconnectFromRoom = () => {
      forcedDisconnect = true;

      tearDownSession();
      window.removeEventListener('beforeunload', forceDisconnectFromRoom);
    };

    if (session.proxyUrl) {
      OT.setProxyUrl(session.proxyUrl);
    }

    const opentokSession = OT.initSession(session.apiKey, session.sessionId);
    sessionRef.current = opentokSession;

    // Subscribe to a newly created stream
    opentokSession.on({
      streamCreated: (event: { stream: OT.Stream }) => {
        const subscriberOptions: OT.SubscriberProperties = {
          insertMode: 'append',
          showControls: false,
          width: '100%',
          height: '100%',
          fitMode: 'cover',
        };
        onRemoteStreamConnectedRef.current();
        opentokSession.subscribe(
          event.stream,
          remoteTrackNodeRef.current,
          subscriberOptions,
          (error) => {
            if (error) {
              handleError(error);
            }
          }
        );
      },
      sessionDisconnected: () => {
        if (!forcedDisconnect) {
          tearDownSession();
          disconnectHandlerRef.current();
        }
      },
    });

    const publisherOptions: OT.PublisherProperties = {
      insertMode: 'append',
      showControls: false,
      mirror: false,
    };

    // Initializing the publisher
    const publisher = OT.initPublisher(
      localTrackNodeRef.current,
      publisherOptions,
      (error) => {
        if (error) {
          tearDownSession();
          handleError(error);
        }
      }
    );
    publisherRef.current = publisher;

    // Reporting connection ID for potential kry-meeting force disconnection from clinician side
    reportConnectionRef.current(
      session.sessionId,
      opentokSession.connection?.connectionId
    );

    opentokSession.connect(session.token, (error) => {
      if (error) {
        tearDownSession();
        handleError(error);
      } else {
        onConnectingRef.current();
        // If the connection is successful, publish the publisher to the session
        opentokSession.publish(publisher, (openError) => {
          if (openError) {
            handleError(openError);
          }
        });
      }
    });

    // If the app is reloaded, we'll disconnect from the room,
    // bit don't want to actually act upon it.
    window.addEventListener('beforeunload', forceDisconnectFromRoom);

    return forceDisconnectFromRoom;
  }, [session]);

  // Handle user enabling/disabling the camera.
  useEffect(() => {
    publisherRef.current?.publishVideo(!disableLocalParticipantVideo);
  }, [disableLocalParticipantVideo]);

  // Handle user enabling/disabling the microphone.
  useEffect(() => {
    publisherRef.current?.publishAudio(!disableLocalParticipantAudio);
  }, [disableLocalParticipantAudio]);

  return (
    <div {...props}>
      <div
        className='opentok-remote-video'
        ref={setRemoteTracksContainerNode}
      />
      <div className='opentok-local-video' ref={setLocalTracksContainerNode} />
    </div>
  );
};

export default OpenTok;
