import {
  IScheduleDay,
  IScheduleSlot,
} from '../views/flex/flexNodes/scheduleFlexNode/components/schedule';
import { IScheduleState, IScheduleResponse } from '../contexts/scheduleContext';

interface ISetScheduleDataDispatch {
  type: 'SET_SCHEDULE_DATA';
  payload: IScheduleResponse;
}

interface ISetSelectedDayDispatch {
  type: 'SET_SELECTED_DAY';
  payload: IScheduleDay;
}

interface ISetSelectedSlotDispatch {
  type: 'SET_SELECTED_SLOT';
  payload: {
    day: IScheduleDay;
    slot: IScheduleSlot;
  };
}

export function scheduleContextReducer(
  state: IScheduleState,
  dispatch:
    | ISetScheduleDataDispatch
    | ISetSelectedSlotDispatch
    | ISetSelectedDayDispatch
): IScheduleState {
  switch (dispatch.type) {
    case 'SET_SCHEDULE_DATA':
      return {
        ...state,
        scheduleData: dispatch.payload,
        selectedDay: dispatch.payload.schedule?.days[0],
      };
    case 'SET_SELECTED_DAY':
      return {
        ...state,
        selectedDay: dispatch.payload,
      };
    case 'SET_SELECTED_SLOT':
      return {
        ...state,
        selectedSlot: dispatch.payload,
      };
    default:
      return state;
  }
}
