/** @jsx jsx */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { jsx, CSSObject } from '@emotion/core';

import { IBasePart, IMargins } from '../part';
import useTheme from '../../../hooks/useTheme';
import RemoteImage, { IRemoteImage } from '../../../components/RemoteImage';
import AttributedText from '../../../components/attributedText';
import useStatefulInput from '../../../hooks/useStatefulInput';
import Clickable from '../../../components/clickable';
import useLanguage from '../../../hooks/useLanguage';

export interface IMicCheckPart extends IBasePart {
  type: 'mic_check';

  inputId: string;
  margins: IMargins;
  timeout: number;
  lowLabel: string;
  highLabel: string;
  successLabel: string;
  errorLabel: string;
  activeBarColors: Array<string>;
  inactiveBarColor: string;

  icon: IRemoteImage;
  disabledIcon: IRemoteImage;
  successIndicatorIcon: IRemoteImage;
  errorIndicatorIcon: IRemoteImage;
}

const MicCheckPart = ({
  inputId,
  margins,
  timeout,
  lowLabel,
  highLabel,
  successLabel,
  errorLabel,
  activeBarColors,
  inactiveBarColor,
  icon,
  disabledIcon,
  successIndicatorIcon,
  errorIndicatorIcon,
}: IMicCheckPart) => {
  const { inputValue, setInputValue } = useStatefulInput<number>({
    inputId,
    initialValue: 0,
  });

  const [audioLevel, setAudioLevel] = useState(inputValue);
  const [peakAudioLevel, setPeakAudioLevel] = useState(inputValue);
  const [permissionDenied, setPermissionDenied] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const language = useLanguage();

  const mediaStreamRef = useRef<MediaStream>();
  const pollMicIntervalRef = useRef<number>();
  const showStatusTimeoutRef = useRef<number>(timeout);

  const { font, color, resolveColor } = useTheme();

  const monitorMicrophone = (audioStream: MediaStream) => {
    const audioContext = new AudioContext();
    const audioSource = audioContext.createMediaStreamSource(audioStream);
    const analyser = audioContext.createAnalyser();

    analyser.fftSize = 512;
    analyser.minDecibels = -127;
    analyser.maxDecibels = 0;
    analyser.smoothingTimeConstant = 0.4;

    audioSource.connect(analyser);
    const values = new Uint8Array(analyser.frequencyBinCount);

    const volumeCallback = () => {
      analyser.getByteFrequencyData(values);
      const noOfValues = values.length;
      const valueSum = values.reduce(
        (prevValue, curValue) => prevValue + curValue
      );

      const currentAudioLevel = valueSum / noOfValues / 127;

      // Update audio level and peak level states.
      setAudioLevel(currentAudioLevel);
      setPeakAudioLevel((prevPeakAudioLevel) => {
        return Math.max(prevPeakAudioLevel, currentAudioLevel);
      });
    };

    // Poll microphone values every 100ms.
    pollMicIntervalRef.current = window.setInterval(() => {
      volumeCallback();
    }, 100);
  };

  const initiateMonitoring = useCallback(() => {
    // Ask for media device permission and upon success, start polling the microphone audio levels.
    navigator.mediaDevices
      ?.getUserMedia({ audio: true, video: false })
      .then((audioStream) => {
        mediaStreamRef.current = audioStream;
        monitorMicrophone(mediaStreamRef.current);
        setPermissionDenied(false);
      })
      .catch(() => {
        setPermissionDenied(true);
      });
  }, []);

  useEffect(() => {
    initiateMonitoring();

    // Show the confirmation panel once the provided timeout has been reached.
    window.setTimeout(() => {
      setShowStatus(true);
    }, showStatusTimeoutRef.current);

    return () => {
      // Stop polling audio levels.
      clearInterval(pollMicIntervalRef.current);

      // Release the media stream.
      mediaStreamRef.current?.getTracks().forEach((track) => {
        track.stop();
      });
    };
  }, [initiateMonitoring]);

  // Show confirmation as soon as we have audio, rather that wait for the timeout
  // and update the input value to be submitted.
  useEffect(() => {
    if (peakAudioLevel > 0) {
      setShowStatus(true);
      setInputValue(peakAudioLevel);
    }
  }, [peakAudioLevel, setInputValue]);

  const WRAPPER_STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: margins.top,
    paddingRight: margins.trailing,
    paddingBottom: margins.bottom,
    paddingLeft: margins.leading,
  };

  const LABELS_STYLE: CSSObject = {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 45,
    marginBottom: 28,
  };

  const ICON_STYLE: CSSObject = {
    width: 32,
    height: 32,
    marginRight: 13,
  };

  const BARS_WRAPPER_STYLE: CSSObject = {
    display: 'flex',
    alignItems: 'center',
    height: 42,
    marginBottom: '48px',
  };

  const BAR_STYLE: CSSObject = {
    flex: 1,
    height: 42,
    borderRadius: 42,
    backgroundColor: resolveColor(inactiveBarColor),
    transition: 'all 0.1s ease',

    ':not(:last-child)': {
      marginRight: 10,
    },
  };

  const STATUS_STYLE: CSSObject = {
    display: 'flex',
    alignItems: 'center',
    height: 32,
    padding: '0 12px',
    margin: '0 auto',
    borderRadius: 20,
    color: peakAudioLevel > 0 ? color.PRIMARY : color.ALERT,
    backgroundColor:
      peakAudioLevel > 0 ? color.SECONDARY : color.ALERT_SECONDARY,

    img: {
      width: 16,
      height: 16,
      marginRight: 8,
    },
  };

  const PERMISSION_DENIED_STYLE: CSSObject = {
    padding: '18px 20px',
    borderRadius: 16,
    backgroundColor: color.ALERT_SECONDARY,
    lineHeight: '150%',

    h3: {
      fontFamily: font.FAMILY_BOLD,
    },

    button: {
      display: 'inline-block',
      width: 'auto',
      padding: '3px 6px',
      margin: '23px -6px -3px -6px',

      fontSize: 12,
      fontFamily: font.FAMILY_BOLD,
      color: color.ALERT,
      textTransform: 'uppercase',
    },
  };

  return (
    <div css={WRAPPER_STYLE}>
      {/* Low/High labels */}
      <div css={LABELS_STYLE} aria-hidden>
        <AttributedText text={lowLabel} />
        <AttributedText text={highLabel} />
      </div>

      {/* Icon and bars */}
      <div css={BARS_WRAPPER_STYLE} aria-hidden>
        {/* Icon */}
        <RemoteImage
          {...(permissionDenied ? disabledIcon : icon)}
          css={ICON_STYLE}
        />

        {/* Bars */}
        {activeBarColors.map((activeColor, i) => {
          // audioLevel is a decimal value between 0 and 1. The number of visual bars is dynamic.
          // Multiply audioLevel by the number of bars so that it can be divided evenly.
          const noOfBars = activeBarColors.length;
          const currentBarActive = audioLevel * noOfBars > i + 1;
          const nextBarActive = audioLevel * noOfBars > i + 2;

          return (
            <div
              key={i}
              css={{
                ...BAR_STYLE,
                ...(currentBarActive && {
                  backgroundColor: resolveColor(activeColor),
                  height: 58,
                }),
                ...(currentBarActive &&
                  !nextBarActive && {
                    height: 78,
                  }),
              }}
            />
          );
        })}
      </div>

      {/* Audio status */}
      {showStatus && !permissionDenied && (
        <div css={STATUS_STYLE} aria-live='polite'>
          {peakAudioLevel > 0 && <RemoteImage {...successIndicatorIcon} />}
          {peakAudioLevel === 0 && <RemoteImage {...errorIndicatorIcon} />}

          {peakAudioLevel > 0 ? successLabel : errorLabel}
        </div>
      )}

      {/* Permission denied info */}
      {permissionDenied && (
        <div css={PERMISSION_DENIED_STYLE}>
          <h3>{language.get('mic_check_part.permissions_error_title_web')}</h3>
          <div>
            {language.get('mic_check_part.permissions_error_description_web')}
          </div>

          <Clickable
            scale
            onClick={() => {
              initiateMonitoring();
            }}
          >
            {language.get('mic_check_part.permissions_error_button_web')}
          </Clickable>
        </div>
      )}
    </div>
  );
};

export default MicCheckPart;
