/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';

import { IBasePart } from '../part';
import Title from '../../../components/title';

export interface ITitlePart extends IBasePart {
  type: 'title';

  title: string;
  subtitle: string;
  topMargin: number;
}

const TitlePart = (data: ITitlePart) => {
  return (
    <Title
      title={data.title}
      subtitle={data.subtitle}
      large
      css={{
        margin: `${data.topMargin || 0}px 20px 20px`,
      }}
    />
  );
};

export default TitlePart;
