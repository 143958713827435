/** @jsx jsx */
import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react';
import { jsx } from '@emotion/core';

import { ISurveyNode, IAllowedAnswer } from '../surveyNode';
import Title from '../../../../../../components/title';
import { SurveyContext } from '../../../../../../contexts/surveyContext';
import CheckBox from '../../../../../../components/checkBox';
import Clickable from '../../../../../../components/clickable';
import useTheme from '../../../../../../hooks/useTheme';

interface ICheckboxesSurveyNode {
  node: ISurveyNode;
  isSubNode?: boolean;
}

const CheckboxesSurveyNode = ({ node, isSubNode }: ICheckboxesSurveyNode) => {
  const { color } = useTheme();
  const nodeVisistedAt = useRef(Date.now());
  const { registerAnswer, getAnswerByPath } = useContext(SurveyContext);
  const [state, setState] = useState<Array<IAllowedAnswer>>(
    getAnswerByPath(node.path)?.allowedAnswers || []
  );

  useEffect(() => {
    registerAnswer({
      nodeName: node.name,
      path: node.path,
      property: node.property,
      allowedAnswers: state,
      answeredAt: Date.now(),
      visitedAt: nodeVisistedAt.current,
    });
  }, [node, state, registerAnswer]);

  return (
    <Fragment>
      <Title
        title={node.title}
        subtitle={node.subtitle}
        subdued={isSubNode}
        css={{
          padding: '0 20px',
          marginBottom: isSubNode ? 12 : 20,
        }}
      />

      <div>
        {node.input?.allowedAnswers?.map((answer, i) => {
          const checked = !!state.find(
            (option) => option.answerId === answer.answerId
          );

          return (
            <Clickable
              key={i}
              renderAs='label'
              styleAs='listItem'
              defaultHandler
              css={{ color: checked ? color.PRIMARY : color.TEXT }}
            >
              {/* Image and text */}
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '6px 12px 6px 0',
                  flex: 1,
                }}
              >
                {answer.imgUrl && (
                  <img
                    src={answer.imgUrl}
                    css={{ height: 50, marginRight: 12 }}
                    alt=''
                  />
                )}
                {answer.value}
              </div>

              {/* Checkbox */}
              <CheckBox
                checked={checked}
                value={answer.answerId}
                onChange={(e) => {
                  let options = [...state];
                  const isExplicitComplement =
                    !!node.input?.allowedAnswers?.find(
                      (option) =>
                        option.answerId === e.target.value &&
                        option.isExplicitComplement
                    );

                  if (e.target.checked) {
                    if (isExplicitComplement) {
                      options = [answer];
                    } else {
                      options = [
                        ...options.filter(
                          (option) => !option.isExplicitComplement
                        ),
                        answer,
                      ];
                    }
                  } else {
                    options = options.filter(
                      (option) => option.answerId !== answer.answerId
                    );
                  }

                  setState(options);
                }}
              />
            </Clickable>
          );
        })}
      </div>
    </Fragment>
  );
};

export default CheckboxesSurveyNode;
