/** @jsx jsx */
import React, { useContext } from 'react';
import { jsx, CSSObject } from '@emotion/core';
import FiveColumnCell, { IFiveColumnViewColumn } from './fiveColumnCell';
import Clickable from '../../../../components/clickable';
import { IAction } from '../../../../hooks/useAction';
import useTheme from '../../../../hooks/useTheme';
import { ScreenContext } from '../../../../contexts/screenContext';

export interface IFiveColumnView {
  columns: IFiveColumnModel;

  minimumHeight?: number;
  margins: {
    top: number;
    bottom: number;
    leading: number;
    trailing: number;
  };

  highlightColor?: string;
  action?: IAction;
}

export interface IFiveColumnModel {
  leading?: IFiveColumnViewColumn;
  centerLeading?: IFiveColumnViewColumn;
  center?: IFiveColumnViewColumn;
  centerTrailing?: IFiveColumnViewColumn;
  trailing?: IFiveColumnViewColumn;
  gutters: {
    leading: number;
    centerLeading: number;
    centerTrailing: number;
    trailing: number;
  };
}

interface IFiveColumnViewProps {
  data: IFiveColumnView;
}
const FiveColumnView = ({ data }: IFiveColumnViewProps) => {
  const { resolveColor } = useTheme();
  const { hiddenIds } = useContext(ScreenContext);

  // Check whether the view contains input elements that do not handle
  // their own focus state. In those cases, the entire view is rendered
  // as a label, is clickable and renders the focus border.
  const viewContainsNonFocusableInput = () => {
    const columnContainsInput = (column?: IFiveColumnViewColumn) => {
      switch (column?.content.type) {
        case 'checkbox':
        case 'radio_button':
        case 'toggle':
          return true;
        default:
          return false;
      }
    };

    switch (true) {
      case columnContainsInput(data.columns.leading):
      case columnContainsInput(data.columns.centerLeading):
      case columnContainsInput(data.columns.center):
      case columnContainsInput(data.columns.centerTrailing):
      case columnContainsInput(data.columns.trailing):
        return true;
      default:
        return false;
    }
  };

  // A crude way of making accordions more accessible.
  // If the part has a "modify" action with a toggles array, assume it's an accordion.
  // Cross-reference the first part id in the array with the current state of screen hiddenIds array
  // to guesstimate whether the accordion is expanded or collapsed.
  const getPotentialAccordionState = () => {
    const { action } = data;
    if (action && action.type === 'modify' && action.data.toggles?.length > 0) {
      return !hiddenIds.includes(action.data.toggles[0].id);
    }
  };

  const STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'stretch',
    boxSizing: 'border-box',
    minHeight: data.minimumHeight,
    paddingLeft: data.margins.leading,
    paddingRight: data.margins.trailing,
    paddingTop: data.margins.top,
    paddingBottom: data.margins.bottom,
    '&[active]': {
      backgroundColor: resolveColor(data.highlightColor),
    },
  };

  return (
    // If the view contains a checkbox, a radiobutton or a toggle cell, render it as a label.
    // Currently, to make such a view clickable and have it toggle the input element,
    // it has a "update_inputs" action that toggles the input.
    // Since labels have browser-native functionality and do that for us, we'll ignore those actions.
    // The input element will still get toggled and trigger its own action.
    <Clickable
      renderAs={viewContainsNonFocusableInput() ? 'label' : undefined}
      defaultHandler={viewContainsNonFocusableInput()}
      action={!viewContainsNonFocusableInput() ? data.action : undefined}
      css={STYLE}
      aria-expanded={getPotentialAccordionState()}
    >
      {/* Leading */}
      <FiveColumnCell
        data={data.columns.leading}
        css={{ marginRight: data.columns.gutters.leading }}
      />

      {/* Center leading */}
      <FiveColumnCell
        data={data.columns.centerLeading}
        css={{ marginRight: data.columns.gutters.centerLeading }}
      />

      {/* Center */}
      <FiveColumnCell data={data.columns.center} center />

      {/* Center trailing */}
      <FiveColumnCell
        data={data.columns.centerTrailing}
        css={{ marginLeft: data.columns.gutters.centerTrailing }}
      />

      {/* Trailing */}
      <FiveColumnCell
        data={data.columns.trailing}
        css={{ marginLeft: data.columns.gutters.trailing }}
      />
    </Clickable>
  );
};

export default FiveColumnView;
