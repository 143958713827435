/** @jsx jsx */
import React, { useEffect, useRef } from 'react';
import { jsx } from '@emotion/core';
import useStatefulInput, {
  IInputValidator,
} from '../../../../../hooks/useStatefulInput';
import useAction, { IAction } from '../../../../../hooks/useAction';

import CheckBox from '../../../../../components/checkBox';
import { IBlockBaseItem } from '../blockItem';

export interface IBlockCheckboxItem extends IBlockBaseItem {
  type: 'checkbox';

  inputId: string;
  selected: false;
  validator?: IInputValidator;

  action?: IAction;
}

interface IBlockCheckboxItemProps {
  data: IBlockCheckboxItem;
}

const BlockCheckboxItem = ({ data, ...props }: IBlockCheckboxItemProps) => {
  const {
    inputValue,
    isValid,
    isDirty,
    isTouched,
    setInputValue,
    setIsTouched,
  } = useStatefulInput<boolean>({
    inputId: data.inputId,
    initialValue: data.selected,
    validators: data.validator ? [data.validator] : undefined,
  });

  const handleAction = useAction();
  const hasMountedRef = useRef(false);
  const actionRef = useRef(data.action);

  const shouldShowErrorState = !isValid && isDirty && isTouched;

  useEffect(() => {
    if (hasMountedRef.current) {
      handleAction(actionRef.current);
    }

    hasMountedRef.current = true;
  }, [inputValue, handleAction]);

  return (
    <div {...props}>
      <CheckBox
        checked={!!inputValue}
        onChange={(e) => {
          setInputValue(!!e.target.checked);
        }}
        onBlur={() => {
          setIsTouched();
        }}
        isInvalid={shouldShowErrorState}
        aria-invalid={shouldShowErrorState}
        // aria-errormessage={`${data.inputId}-validation-error`}
      />
    </div>
  );
};

export default BlockCheckboxItem;
