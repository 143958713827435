/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import useStatefulInput, {
  IInputValidator,
} from '../../../../../hooks/useStatefulInput';
import useAction, { IAction } from '../../../../../hooks/useAction';
import Toggle from '../../../../../components/toggle';
import useTheme from '../../../../../hooks/useTheme';
import { IBlockBaseItem } from '../blockItem';

export interface IBlockToggleItem extends IBlockBaseItem {
  type: 'toggle';

  inputId: string;
  active: boolean;
  enabled: boolean;
  inactiveColor: string;
  activeColor: string;
  validator?: IInputValidator;

  action: IAction;
}

interface IBlockToggleItemProps {
  data: IBlockToggleItem;
}

const BlockToggleItem = ({ data, ...props }: IBlockToggleItemProps) => {
  const {
    inputValue,
    isValid,
    isDirty,
    isTouched,
    setInputValue,
    setIsTouched,
  } = useStatefulInput<boolean>({
    inputId: data.inputId,
    initialValue: data.active,
    validators: data.validator ? [data.validator] : undefined,
  });

  const { resolveColor } = useTheme();
  const handleAction = useAction();

  const shouldShowErrorState = !isValid && isDirty && isTouched;

  return (
    <div {...props}>
      <Toggle
        checked={!!inputValue}
        enabled={data.enabled}
        activeColor={resolveColor(data.activeColor)}
        inactiveColor={resolveColor(data.inactiveColor)}
        onChange={(e) => {
          e.stopPropagation();
          handleAction(data.action);
          setInputValue(!!e.target.checked);
        }}
        onBlur={() => {
          setIsTouched();
        }}
        invalid={shouldShowErrorState}
        aria-invalid={shouldShowErrorState}
        // aria-errormessage={`${data.inputId}-validation-error`}
      />
    </div>
  );
};

export default BlockToggleItem;
