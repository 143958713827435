import React, { useCallback, useContext, useEffect, useState } from 'react';
import SSOLoginView from '../views/login/sso/ssoLoginView';
import { IScreen } from '../views/screen/screen';
import apiKeys from '../constants/apiKeys';
import useHttpCall, { IHttpCall } from '../hooks/useHttpCall';
import useRequestError from '../hooks/useRequestError';
import { AppConfigContext } from './appConfigContext';

export type ISSOLoginConfig = IExternalSSOLoginConfig | IScreenSSOLoginConfig;

export interface ISSOLoginSource {
  ssoLoginDeeplinkParams?: string;
  ssoLoginConfig?: ISSOLoginConfig;
}

export const SSOLoginContext = React.createContext({} as ISSOLoginContext);

interface ISSOLoginContext {
  ssoLoginConfig?: ISSOLoginConfig;
  screenResource?: IScreen;
}

interface ISSOLoginContextProvider {
  routeSource?: ISSOLoginSource;
}

interface IExternalSSOLoginConfig {
  type: 'external';

  screenHttpCall: IHttpCall;
  loginHttpCall: IHttpCall;
}

interface IScreenSSOLoginConfig {
  type: 'screen';
  screenHttpCall: IHttpCall;
}

export const SSOLoginContextProvider = ({
  routeSource,
}: ISSOLoginContextProvider) => {
  const [ssoLoginConfig, setSSOLoginConfig] = useState<ISSOLoginConfig>();
  const [screenResource, setScreenResource] = useState<IScreen>();

  const { appConfig } = useContext(AppConfigContext);
  const handleHttpCall = useHttpCall();
  const handleRequestError = useRequestError();

  // Handles the ssoLoginConfig, provided either by unwrapping a deeplink
  // or directly by the SSOLogin screen action.
  const handleSSOLogin = useCallback(
    (config: ISSOLoginConfig) => {
      setSSOLoginConfig(config);

      // Set up the message event listener if we have an external sso login.
      if (config.type === 'external') {
        const handleWindowMessage = (event: MessageEvent) => {
          // Check the validity of the message event.
          if (
            event.data.includes &&
            event.data.includes('kry://sso_redirect')
          ) {
            window.removeEventListener('message', handleWindowMessage);

            const redirectUrl = new URL(event.data);

            // Parse the parameter into a key-value object.
            const redirectParams = Object.fromEntries(
              new URLSearchParams(redirectUrl.search)
            );

            handleHttpCall<IScreen>(config.loginHttpCall, {
              body: {
                client: {
                  params: {
                    ...redirectParams,
                  },
                  device_info: {
                    pub_key: apiKeys.KRY_PUB,
                    timestamp: Date.now(),
                  },
                },
              },
            })
              .then((screenData) => {
                setScreenResource(screenData);
              })
              .catch((e) => handleRequestError(e));
          }
        };

        window.addEventListener('message', handleWindowMessage);
      }
    },
    [handleHttpCall, handleRequestError]
  );

  // Resolves a ssoLoginConfig object given the deeplink url parameters.
  const unwrapSSODeeplink = useCallback(
    (ssoLoginDeeplinkParams: string) => {
      return new Promise<ISSOLoginConfig>((resolve, reject) => {
        if (appConfig.ssoDeeplinkHttpCall) {
          // Parse the parameter into a key-value object.
          const params = Object.fromEntries(
            new URLSearchParams(ssoLoginDeeplinkParams)
          );

          handleHttpCall<ISSOLoginConfig>(appConfig.ssoDeeplinkHttpCall, {
            body: {
              ...params,
            },
          })
            .then((ssoConfigData) => {
              resolve(ssoConfigData);
            })
            .catch((e) => reject(e));
        } else {
          reject();
        }
      });
    },
    [appConfig.ssoDeeplinkHttpCall, handleHttpCall]
  );

  useEffect(() => {
    // Clear any preexisting screen resource, as this might be a retry
    // due to login failure.
    setScreenResource(undefined);

    if (routeSource?.ssoLoginDeeplinkParams) {
      unwrapSSODeeplink(routeSource?.ssoLoginDeeplinkParams)
        .then((config) => handleSSOLogin(config))
        .catch((error) => handleRequestError(error));
    } else if (routeSource?.ssoLoginConfig) {
      handleSSOLogin(routeSource?.ssoLoginConfig);
    }
  }, [
    routeSource?.ssoLoginDeeplinkParams,
    routeSource?.ssoLoginConfig,
    handleSSOLogin,
    unwrapSSODeeplink,
    handleRequestError,
  ]);

  return (
    <SSOLoginContext.Provider
      value={{
        ssoLoginConfig,
        screenResource,
      }}
    >
      <SSOLoginView />
    </SSOLoginContext.Provider>
  );
};
