/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';

import { IBasePart } from '../part';

import FiveColumnView, { IFiveColumnView } from './fiveColumn/fiveColumnView';

export interface IFiveColumnPart extends IFiveColumnView, IBasePart {
  type: 'five_column';
}

const FiveColumnPart = (data: IFiveColumnPart) => {
  return <FiveColumnView data={data} />;
};

export default FiveColumnPart;
