/** @jsx jsx */
import React, { Fragment } from 'react';
import { jsx } from '@emotion/core';
import useTheme from '../../../hooks/useTheme';

interface IScreenDecoration {
  height?: number;
  color?: string;
}

const ScreenDecoration = ({ height, color }: IScreenDecoration) => {
  const { resolveColor } = useTheme();

  return (
    <Fragment>
      {height && color && (
        <div
          css={{
            height,
            marginBottom: -height,
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <svg
            css={{
              width: 700,
              height: '100%',
              fill: resolveColor(color),
              marginLeft: -270,
            }}
            x='0px'
            y='0px'
            viewBox='0 0 910 210'
            preserveAspectRatio='none'
          >
            <path d='M0,0c270.6,0,298.2,209.9,454,209.9s183.4-209.9,454-209.9z' />
          </svg>
        </div>
      )}
    </Fragment>
  );
};

export default ScreenDecoration;
