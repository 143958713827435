import { ISSOLoginConfig } from '../../contexts/ssoLoginContext';
import base64ToObject from '../../utils/base64ToObject';
import { IBaseAction } from '../useAction';
import useNavigation from '../useNavigation';

export interface ISSOLoginAction extends IBaseAction {
  type: 'sso_login';

  // Actions containing polymorphic types don't include the `type` property in the data object,
  // only in the base64 encoded representation, hence we need to use `value` here.
  value: string;
}

const useSSOLoginAction = () => {
  const navigation = useNavigation();

  const handleSSOLoginAction = (action: ISSOLoginAction) => {
    const decodedActionValue = base64ToObject<ISSOLoginConfig>(action.value);

    navigation.push({
      type: 'LOGIN',
      hideAppMenu: true,
      source: {
        loginMethod: 'SSO_LOGIN',
        ssoLoginConfig: decodedActionValue,
      },
    });
  };

  return handleSSOLoginAction;
};

export default useSSOLoginAction;
