/** @jsx jsx */
import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react';
import { jsx, CSSObject } from '@emotion/core';

import { ISurveyNode, IAllowedAnswer } from '../surveyNode';
import Title from '../../../../../../components/title';
import { SurveyContext } from '../../../../../../contexts/surveyContext';
import useTheme from '../../../../../../hooks/useTheme';
import LocalImage from '../../../../../../components/localImage';

interface IAxorBSurveyNode {
  node: ISurveyNode;
  isSubNode?: boolean;
}

const AxorBSurveyNode = ({ node, isSubNode }: IAxorBSurveyNode) => {
  const { font, color } = useTheme();
  const nodeVisistedAt = useRef(Date.now());
  const { registerAnswer, getAnswerByPath } = useContext(SurveyContext);
  const [state, setState] = useState<Array<IAllowedAnswer>>(
    getAnswerByPath(node.path)?.allowedAnswers || []
  );

  const WRAPPER_STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 48,
    margin: '8px 20px 0 20px',
  };

  const BUTTON_STYLE: CSSObject = {
    position: 'relative',
    height: 44,
    width: 'calc(50% - 11px)',
    fontFamily: font.FAMILY_BOLD,
    backgroundColor: color.LOCAL_WHITE,
    color: color.PRIMARY,
    textAlign: 'center',
    border: `2px solid ${color.DIVIDER_LINE}`,
    borderRadius: 28,
    boxSizing: 'content-box',
  };

  const BUTTON_ACTIVE_STYLE: CSSObject = {
    backgroundColor: color.PRIMARY,
    border: `4px solid ${color.PRIMARY}`,
    fontSize: '18px',
    color: color.LOCAL_WHITE,
    span: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 30,
      height: 30,
      top: 7,
      right: 10,
      backgroundColor: color.LOCAL_WHITE,
      borderRadius: '100%',
    },
  };

  useEffect(() => {
    registerAnswer({
      nodeName: node.name,
      path: node.path,
      property: node.property,
      allowedAnswers: state,
      answeredAt: Date.now(),
      visitedAt: nodeVisistedAt.current,
    });
  }, [node, state, registerAnswer]);

  return (
    <Fragment>
      <Title
        title={node.title}
        subtitle={node.subtitle}
        subdued={isSubNode}
        css={{
          padding: '0 20px',
          marginBottom: isSubNode ? 12 : 20,
        }}
      />

      <div css={WRAPPER_STYLE}>
        {node.input?.allowedAnswers?.map((answer, i) => {
          const checked = state.find(
            (option) => option.answerId === answer.answerId
          );
          return (
            <button
              aria-pressed={!!checked}
              key={i}
              css={
                checked
                  ? { ...BUTTON_STYLE, ...BUTTON_ACTIVE_STYLE }
                  : BUTTON_STYLE
              }
              onClick={() => {
                setState([answer]);
              }}
            >
              {answer.value}
              {checked && (
                <span>
                  <LocalImage src='APP_IMAGE_CHECKMARK' tint='PRIMARY' alt='' />
                </span>
              )}
            </button>
          );
        })}
      </div>
    </Fragment>
  );
};

export default AxorBSurveyNode;
