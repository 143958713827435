/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';
import { ICardItem } from '../cardItem';

import useTheme from '../../../../../hooks/useTheme';

interface ILineCardItem {
  data: ICardItem<{
    color: string;

    margins?: {
      top: number;
      bottom: number;
      leading: number;
      trailing: number;
    };
  }>;
}

const LineCardItem = ({ data }: ILineCardItem) => {
  const { resolveColor } = useTheme();

  const STYLE: CSSObject = {
    height: 1,
    backgroundColor: resolveColor(data.color),

    marginLeft: data.margins?.leading,
    marginRight: data.margins?.trailing,
    marginTop: data.margins?.top,
    marginBottom: data.margins?.bottom,
  };

  return <div css={STYLE} />;
};

export default LineCardItem;
