/** @jsx jsx */
import React, { useContext, useEffect, useRef } from 'react';
import { jsx } from '@emotion/core';
import { decamelizeKeys } from 'humps';

import { ISurveyNode } from '../surveyNode';
import { SurveyContext } from '../../../../../../contexts/surveyContext';

import { ScreenContextProvider } from '../../../../../../contexts/screenContext';
import Screen from '../../../../../screen/screen';

interface IContextableObjectSurveyNode {
  node: ISurveyNode;
}

const ContextableObjectSurveyNode = ({
  node,
}: IContextableObjectSurveyNode) => {
  const nodeVisistedAt = useRef(Date.now());
  const { registerAnswer } = useContext(SurveyContext);

  useEffect(() => {
    registerAnswer({
      nodeName: node.name,
      path: node.path,
      property: node.property,
      observations:
        node.body?.meta?.observations &&
        (decamelizeKeys(node.body?.meta?.observations) as Array<object>),
      answeredAt: Date.now(),
      visitedAt: nodeVisistedAt.current,
    });
  }, [node, registerAnswer]);

  return (
    <ScreenContextProvider routeSource={{ screen: node.screen }}>
      <Screen />
    </ScreenContextProvider>
  );
};

export default ContextableObjectSurveyNode;
