import { useContext } from 'react';
import useNavigation from '../../../useNavigation';
import { FlexContext } from '../../../../contexts/flexContext';
import { IView } from '../../../../views/view';
import { IFlexHttpCall } from '../../../useFlexHttpCall';
import { IFlexNavigation } from '../../useFlexNavigation';

export interface IFlexOverlaySearch {
  type: 'search';
  call: IFlexHttpCall;
  placeholder?: string;
}

const useFlexOverlaySearch = () => {
  const flexContext = useContext(FlexContext);
  const navigation = useNavigation();

  const handleFlexOverlaySearch = (
    flexOverlay: IFlexOverlaySearch
  ): Promise<IFlexNavigation> => {
    return new Promise(() => {
      const newView: IView = {
        type: 'FLEX',
        source: {
          node: {
            type: 'search',
            id: 'search',
            call: flexOverlay.call,
            placeholder: flexOverlay.placeholder,
            memory: flexContext.node?.memory,
            visibleNavbar: true,
            revisitable: true,
          },
        },
      };

      navigation.push(newView);
    });
  };

  return handleFlexOverlaySearch;
};

export default useFlexOverlaySearch;
