/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';

import { IBasePart } from '../part';
import AttributedText from '../../../components/attributedText';
import Clickable from '../../../components/clickable';
import { IAction } from '../../../hooks/useAction';
import useTheme from '../../../hooks/useTheme';
import { IRemoteImage } from '../../../components/RemoteImage';

export interface IPromoCardPart extends IBasePart {
  type: 'promo_card';

  attributedText: string;
  cardColor: string;
  borderColor: string;
  buttonTitle: string;
  image?: IRemoteImage;
  imageHeight: number;
  imageWidth: number;
  imageRightMargin: number;
  imageTopMargin: number;
  shouldRoundImage: boolean;
  buttonColor: string;

  action: IAction;
}

const PromoCardPart = (data: IPromoCardPart) => {
  const { resolveColor } = useTheme();

  const WRAPPER_STYLE: CSSObject = {
    backgroundColor: resolveColor(data.cardColor),
    backgroundImage: `url(${data.image?.url || ''})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: `${data.imageWidth}px auto`,

    backgroundPosition: `right ${
      data.imageRightMargin ? `${data.imageRightMargin}px` : '0'
    } top ${data.imageTopMargin ? `${data.imageTopMargin}px` : '50%'}`,

    border:
      `2px solid ${resolveColor(data.borderColor)}` ||
      resolveColor(data.cardColor),
    borderRadius: 12,

    paddingLeft: 18,
    paddingRight: `${18 + data.imageWidth + data.imageRightMargin}px`,
    paddingTop: 18,
    paddingBottom: 18,

    margin: '8px 20px',
  };

  const CTA_STYLE: CSSObject = {
    marginTop: 20,
  };

  return (
    <Clickable action={data.action} css={WRAPPER_STYLE} scale darken>
      <AttributedText text={data.attributedText} />
      {data.buttonTitle && (
        <AttributedText css={CTA_STYLE} text={data.buttonTitle} />
      )}
    </Clickable>
  );
};

export default PromoCardPart;
