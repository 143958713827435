import ContextStore from '../services/ContextStore';

type UseServiceWorker<T> = {
  clearSWContext: () => void;
  setSWContext: (context: T) => void;
};
type SWContext = { deviceId: string; sessionId: string; appConfig: object }; // cannot import IAppConfig
const useServiceWorker = (): UseServiceWorker<SWContext> => {
  return {
    clearSWContext: async () => {
      await ContextStore.clearContext();
    },
    setSWContext: async (context: SWContext) => {
      await ContextStore.setContext(context);
    },
  };
};

export { useServiceWorker };
