/** @jsx jsx */
import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react';
import { jsx } from '@emotion/core';

import { ISurveyNode, ISurveyNodeAnswer } from '../surveyNode';
import Title from '../../../../../../components/title';
import { SurveyContext } from '../../../../../../contexts/surveyContext';
import useTheme from '../../../../../../hooks/useTheme';
import SurveyImageUploader from '../shared/surveyImageUploader';
import useOverlay from '../../../../../../hooks/useOverlay';
import LocalImage from '../../../../../../components/localImage';

interface ITaggablePhotosSurveyNode {
  node: ISurveyNode;
  isSubNode?: boolean;
}

const TaggablePhotosSurveyNode = ({
  node,
  isSubNode,
}: ITaggablePhotosSurveyNode) => {
  const { font, color } = useTheme();
  const nodeVisistedAt = useRef(Date.now());
  const { registerAnswer, getAnswerByPath } = useContext(SurveyContext);
  const [state, setState] = useState<ISurveyNodeAnswer['taggablePhotos']>(
    getAnswerByPath(node.path)?.taggablePhotos || []
  );

  const overlay = useOverlay();

  useEffect(() => {
    registerAnswer({
      nodeName: node.name,
      path: node.path,
      property: node.property,
      taggablePhotos: state,
      answeredAt: Date.now(),
      visitedAt: nodeVisistedAt.current,
    });
  }, [node, state, registerAnswer]);

  return (
    <Fragment>
      <Title
        title={node.title}
        subtitle={node.subtitle}
        subdued={isSubNode}
        css={{
          padding: '0 20px',
          marginBottom: isSubNode ? 12 : 20,
        }}
      />

      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          padding: '0 20px',
        }}
      >
        {node.input?.options?.map((option, i) => {
          const answer = state?.find(
            (taggablePhoto) => taggablePhoto.answerId === option.id
          );

          return (
            <div
              key={i}
              css={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 20,
              }}
            >
              {/* Image uploader */}
              <SurveyImageUploader
                taggable
                presetThumbnail={answer?.thumbnail}
                onImageUploaded={(res, thumbnail) => {
                  const answers = [...(state || [])];
                  const index = answers.findIndex(
                    (currentAnswer) => currentAnswer.answerId === option.id
                  );

                  if (index > -1) {
                    answers[index] = {
                      id: res,
                      thumbnail,
                      answerId: option.id,
                    };
                  } else {
                    answers.push({
                      id: res,
                      thumbnail,
                      answerId: option.id,
                    });
                  }

                  setState(answers);
                }}
                onImageCleared={() => {
                  const answers = [...(state || [])];
                  const index = answers.findIndex(
                    (currentAnswer) => currentAnswer.answerId === option.id
                  );
                  answers.splice(index, 1);
                  setState(answers);
                }}
                onPreviewRequested={() => {
                  overlay.presentImagePreview({
                    url: `/api/meeting/patientsymptoms/image/${state?.[i].id}`,
                  });
                }}
              />

              {/* Checkbox */}
              {answer?.id && (
                <div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 26,
                    height: 26,
                    margin: '0 6px 0 0',
                    borderRadius: '100%',
                    backgroundColor: color.PRIMARY,
                  }}
                >
                  <LocalImage src='APP_IMAGE_CHECKMARK' tint='LOCAL_WHITE' />
                </div>
              )}

              {/* Option title */}
              <div
                css={{
                  fontFamily: font.FAMILY_BOLD,
                  color: color.TEXT_SOFT,
                }}
              >
                {option.title}
              </div>
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};

export default TaggablePhotosSurveyNode;
