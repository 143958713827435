/** @jsx jsx */
import React, { Fragment, useMemo } from 'react';
import { jsx } from '@emotion/core';
import { decamelizeKeys } from 'humps';
import Screen from '../../../screen/screen';
import {
  IScreenSource,
  ScreenContextProvider,
} from '../../../../contexts/screenContext';
import { IFlexHttpCall } from '../../../../hooks/useFlexHttpCall';
import Navbar from '../../../../components/navbar';
import { IBaseFlexNode } from '../../flexNode';
import ConversationInput from './components/conversationInput';

export interface IAsyncMessagingUpload {
  call: IFlexHttpCall;
  type: string;
  maxFileSize: number;
  maxNumberOfAttachments: number;
}

export interface IAsyncMessagingFlexNode extends IBaseFlexNode {
  type: 'async_messaging';
  screenCall: IFlexHttpCall;
  sendMessageCall?: IFlexHttpCall;
  reloadScreenInterval: number;
  attachmentUpload?: IAsyncMessagingUpload;
  conversationId: string;
  interactions?: Array<IAsyncMessagingInteraction>;
}

export type IAsyncMessagingInteractionType = 'http_message_update';

export interface IAsyncMessagingInteraction {
  call: IFlexHttpCall;
  debounceTimeInMillis: number;
  type: IAsyncMessagingInteractionType;
}

interface IAsyncMessagingFlexNodeProps {
  data: IAsyncMessagingFlexNode;
}

const AsyncMessagingFlexNode = React.memo(
  ({ data }: IAsyncMessagingFlexNodeProps) => {
    const screenSource = useMemo<IScreenSource>(
      () => ({
        url: data.screenCall.url,
        requestOptions: {
          method: data.screenCall.method,
          body: {
            ...(typeof data.screenCall.body === 'object' && {
              ...decamelizeKeys(data.screenCall.body),
            }),
            ...(typeof data.memory === 'object' && {
              ...decamelizeKeys(data.memory),
            }),
          },
          retryable: data.screenCall.retryable,
        },

        ...(data.reloadScreenInterval && {
          autoRefreshInterval: data.reloadScreenInterval * 1000,
        }),

        externalOnLoadAction: data.destinationScreenAction,
        sourceUpdatedAt: data.nodeUpdatedAt,
      }),
      [data]
    );

    return (
      <Fragment>
        <ScreenContextProvider routeSource={screenSource}>
          {/* Navbar */}
          {data.visibleNavbar && <Navbar />}

          <Screen scrollBottomOnPartsChanged />
          {data.sendMessageCall && (
            <ConversationInput
              sendMessageCall={data.sendMessageCall}
              uploadAttachmentSettings={data.attachmentUpload}
              conversationId={data.conversationId}
              messageUpdateConfig={data.interactions?.find(
                ({ type }) => type === 'http_message_update'
              )}
            />
          )}
        </ScreenContextProvider>
      </Fragment>
    );
  }
);

export default AsyncMessagingFlexNode;
