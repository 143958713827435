/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';
import { IAction } from '../../../hooks/useAction';
import { IBasePart } from '../part';
import Clickable from '../../../components/clickable';
import useTheme from '../../../hooks/useTheme';

export interface IButtonPart extends IBasePart {
  type: 'button';

  text: string;
  textColor: string;
  textColorActive: string;
  buttonColor: string;
  buttonColorActive: string;
  enabled: boolean;
  buttonHeight: number;
  hasArrow: boolean;

  topMargin?: number;
  bottomMargin?: number;
  leadingMargin?: number;
  trailingMargin?: number;

  action: IAction;
}

const ButtonPart = (data: IButtonPart) => {
  const { font, color, resolveColor } = useTheme();

  const WRAPPER_STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'column',
  };

  const BUTTON_STYLE: CSSObject = {
    height: data.buttonHeight,
    backgroundColor: resolveColor(data.buttonColor) || color.PRIMARY,
    color: resolveColor(data.textColor) || color.LOCAL_WHITE,
    fontFamily: font.FAMILY_BOLD,
    borderRadius: 7,
    marginLeft: data.leadingMargin,
    marginRight: data.trailingMargin,
    marginBottom: 8,
    '&[active]': {
      backgroundColor: resolveColor(data.buttonColorActive) || color.PRIMARY,
      color: resolveColor(data.textColorActive) || color.LOCAL_WHITE,
    },
  };

  return (
    <div css={WRAPPER_STYLE}>
      <Clickable styleAs='button' css={BUTTON_STYLE} action={data.action}>
        {data.text}
      </Clickable>
    </div>
  );
};

export default ButtonPart;
