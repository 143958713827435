/** @jsx jsx */
import React, { Fragment, useMemo } from 'react';
import { jsx } from '@emotion/core';
import { decamelizeKeys } from 'humps';
import { IBaseFlexNode } from '../../flexNode';
import {
  ISurveySource,
  SurveyContextProvider,
} from '../../../../contexts/surveyContext';
import Survey from './components/survey';
import Navbar from '../../../../components/navbar';
import { IFlexHttpCall } from '../../../../hooks/useFlexHttpCall';

export interface ISurveyFlexNode extends IBaseFlexNode {
  type: 'survey';
  call: IFlexHttpCall;

  // Appended locally.
  toSurveyNode?: string;
  path?: Array<string>;
  surveyProgress?: number;
}

interface ISurveyFlexNodeProps {
  data: ISurveyFlexNode;
}

const SurveyFlexNode = React.memo(({ data }: ISurveyFlexNodeProps) => {
  const surveySource = useMemo<ISurveySource>(
    () => ({
      flexNode: data,
      url: data.call.url,
      requestOptions: {
        method: data.call.method,
        body: {
          ...(typeof data.call.body === 'object' && {
            ...decamelizeKeys(data.call.body),
          }),
          ...(typeof data.memory === 'object' && {
            ...decamelizeKeys(data.memory),
          }),
        },
        retryable: data.call.retryable,
      },
      toNode: data.toSurveyNode,
      path: data.path,
    }),
    [data]
  );

  return (
    <Fragment>
      <SurveyContextProvider routeSource={surveySource}>
        {/* Navbar */}
        {data.visibleNavbar && <Navbar />}

        <Survey />
      </SurveyContextProvider>
    </Fragment>
  );
});

export default SurveyFlexNode;
