import device from './device';
import parseUri from './parseUri';

export const attemptToLaunchBankId = (
  startUrl: string,
  mobileSafariRedirect?: boolean
) => {
  const getRedirectUrl = () => {
    // Safari on iOS requires a callback url to be passed to the BankID app,
    // which is used to redirect back to the browser after a finished or cancelled action.
    // Third party browsers on iOS are redirected to using their respective protocols.
    // Android simply redirects to the previous app and the redirect url should be left empty.
    if (device.IS_IOS) {
      switch (device.BROWSER_NAME) {
        case 'Safari':
          return mobileSafariRedirect
            ? `${
                window.location.href.split('#')[0]
              }#redirect_from_bankid_${Date.now()}`
            : '';
        case 'Chrome Mobile':
          // Yes, "chromes".
          return 'googlechromes://';
        case 'Firefox for iOS':
          return 'firefox://';
      }
    }

    return '';
  };

  // Attempt to launch the BankID app.
  // Don't launch the BankID app when running in the Instagram, Facebook or Snapchat in-app browsers on iOS.

  if (device.IS_IOS && device.IS_SOCIAL_MEDIA_IN_APP_BROWSER) {
    return;
  }

  const token = parseUri(startUrl).query.get('autostarttoken');
  const redirectUrl = encodeURIComponent(getRedirectUrl());

  const url = device.IS_MOBILE
    ? `https://app.bankid.com/?autostarttoken=${token}&redirect=${redirectUrl}`
    : `bankid:///?autostarttoken=${token}&redirect=`;

  window.open(url, '_self');
};
