/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { motion } from 'framer-motion';
import Clickable from '../../../../../components/clickable';
import LocalImage from '../../../../../components/localImage';
import useLanguage from '../../../../../hooks/useLanguage';
import {
  IScreenSource,
  ScreenContextProvider,
} from '../../../../../contexts/screenContext';
import Screen from '../../../../screen/screen';
import layout from '../../../../../constants/layout';

export interface IPhotoUploadScreenPanel {
  screenSource: IScreenSource;
  onDismiss: () => void;
}

const PhotoUploadScreenPanel = ({
  screenSource,
  onDismiss,
}: IPhotoUploadScreenPanel) => {
  const language = useLanguage();
  return (
    <motion.div
      css={{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        height: 280,
        overflow: 'hidden',
        backgroundColor: '#FFF',
        borderRadius: '12px 12px 0px 0px',
      }}
      initial={{ opacity: 0, y: '100%' }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: '100%' }}
      transition={{ duration: 0.3 }}
    >
      {/* Close photo uploader button */}
      <Clickable
        scale
        css={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          top: 20,
          right: 20,
          width: 30,
          height: 30,
          zIndex: layout.BASIC_ZINDEX,
        }}
        onClick={onDismiss}
      >
        <LocalImage
          src='APP_IMAGE_REMOVE_PHOTO'
          tint='PRIMARY'
          alt={language.get('close')}
        />
      </Clickable>

      {/* Screen wrapper, has the same height has the uploader overlay but allows the screen to be scrollable. */}
      <div
        css={{
          height: '100%',
          overflow: 'auto',
        }}
      >
        <ScreenContextProvider routeSource={screenSource}>
          <Screen />
        </ScreenContextProvider>
      </div>
    </motion.div>
  );
};

export default PhotoUploadScreenPanel;
