/** @jsx jsx */
import React, { Fragment } from 'react';
import { jsx } from '@emotion/core';

import { IBasePart } from '../part';
import { IAction } from '../../../hooks/useAction';
import Clickable from '../../../components/clickable';
import useOverlay from '../../../hooks/useOverlay';

export interface IImageCarouselPart extends IBasePart {
  type: 'image_carousel';

  images: Array<{
    id: string;
    url?: string;
    cornerRadius?: number;
    action?: IAction;
  }>;

  height?: number;
  topMargin: number;
  bottomMargin: number;
  sectionInsets?: unknown;
  spacing?: number;
}

const ImageCarouselPart = (data: IImageCarouselPart) => {
  const overlay = useOverlay();

  return (
    <div
      css={{
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: data.topMargin,
        marginRight: 20 - (data.spacing || 8),
        marginBottom: data.bottomMargin - (data.spacing || 8),
        marginLeft: 20,
      }}
    >
      {data.images.map((image, i) => (
        <Fragment key={i}>
          {image.url && (
            <Clickable
              scale
              // This is a bit iffy but the way image preview works in the native apps,
              // is that the images will have a spring_details action attached to them,
              // which navigates to a black screen with a centered ZoomableImage screen part.
              //
              // This would look quite horrible on desktop so rather than respecting the spring_details action,
              // we intercept the handler and use the ImagePreviewOverlay instead.
              // If the provided action is anything else but spring_details, handle it normally.
              action={
                image.action?.type !== 'spring_details'
                  ? image.action
                  : undefined
              }
              onClick={() => {
                if (image.url) {
                  overlay.presentImagePreview({
                    // The url is absolute needs to be relative
                    // in order for the reverse proxy to do its job.
                    url: new URL(image.url).pathname,
                  });
                }
              }}
              css={{
                width: 130,
                height: 130,
                marginRight: data.spacing || 8,
                marginBottom: data.spacing || 8,
                borderRadius: image.cornerRadius,
                backgroundImage: `url(${image.url})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
            />
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default ImageCarouselPart;
