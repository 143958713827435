/** @jsx jsx */
import React from 'react';
import { jsx, CSSObject } from '@emotion/core';

import AttributedText from '../../../components/attributedText';
import { IBasePart } from '../part';
import useTheme from '../../../hooks/useTheme';

export interface IBoxPart extends IBasePart {
  type: 'box';

  attributedText: string;
  boxColor: string;
  borderColor: string;
}

const BoxPart = (data: IBoxPart) => {
  const { resolveColor } = useTheme();

  const STYLE: CSSObject = {
    backgroundColor: resolveColor(data.boxColor),
    border: `2px solid ${resolveColor(data.borderColor)}`,
    borderRadius: 12,
    padding: 18,
    marginBottom: 20,

    marginLeft: 20,
    marginRight: 20,
  };

  return (
    <div css={STYLE}>
      <AttributedText text={data.attributedText} />
    </div>
  );
};

export default BoxPart;
